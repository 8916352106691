import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"
import "./CompanyInfo.css";

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
      data: {},
      id: null,
      companyid: null,
    }
  }

  setupData = () => {
    if ( this.props.data && this.props.data != this.state.data ){
      let data = {};
      data = this.props.data;
      this.setState({data: data})
      this.setupCompanyData(data.company);
    } else if (this.props.id && this.props.id != this.state.id) {
      let organizationRef = this.props.db.collection('organizations').doc(this.props.id);
      let getDoc = organizationRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such organization document!')
          } else {
            if ( doc.data().active ) {
              let data = {};
              data = doc.data();
              data.id = doc.id;
              this.setState({
                data: data,
                id : data.id
              })
              this.setupCompanyData(data.company);
            }
          }
        })
        .catch(err => {
          console.log('Error getting organization document', err)
        })
    }
    
  }

  setupCompanyData = (id) => {
    if ( id && id != this.state.companyid ){
      let companyRef = this.props.db.collection('company').doc(id);
      let getDoc = companyRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such company document!')
          } else {
            if ( doc.data().active ) {
              let data = {};
              data = doc.data();
              data.id = doc.id;
              this.setState({
                company: data,
                companyid: data.id,
              })
              this.setupCompanyData();
            }
          }
        })
        .catch(err => {
          console.log('Error getting company document', err)
        })
    }
  }
  componentDidMount() {
    this.setupData();
  }
  componentDidUpdate() {
    this.setupData();
  }

  render() {

    //console.log(this.state.company.id)

    if (this.props.mode=='buyerinfo' && this.state.company.id == undefined ){
      return (
        <>
        取引先を選択してください。
        </>
      )
    }
    if (this.props.mode=='makerinfo' && this.state.company.id == undefined ){
      return (
        <>
        取引先を選択してください。
        </>
      )
    }


    return (
      <>
      {this.props.mode=='comapnyinfo' &&
      <h2>企業情報</h2>
      }
      {this.props.mode=='sellerinfo' &&
      <h2>取引先情報</h2>
      }
      {this.props.mode=='buyerinfo' &&
      <h2>取引先情報</h2>
      }
      {this.props.mode=='makerinfo' &&
      <h2>取引先情報</h2>
      }


      <MDBCard>
        <MDBCardBody className="companyInfo">
          <MDBCardTitle>{this.state.company.name}</MDBCardTitle>
            <dl className="row">
              <dt className="col-sm-3">郵便番号</dt><dd className="col-sm-9">{this.state.company.zipcode}</dd>
              <dt className="col-sm-3">住所</dt><dd className="col-sm-9">{this.state.company.address}</dd>
              <dt className="col-sm-3">TEL</dt><dd className="col-sm-9">{this.state.company.TEL}</dd>
              <dt className="col-sm-3">FAX</dt><dd className="col-sm-9">{this.state.company.FAX}</dd>
            </dl>

          <MDBCard cascade>
            <MDBCardBody className="companyInfo">
              <MDBCardTitle>{this.state.data.name}</MDBCardTitle>
              <dl className="row">
                <dt className="col-sm-3">郵便番号</dt><dd className="col-sm-9">{this.state.data.zipcode}</dd>
                <dt className="col-sm-3">住所</dt><dd className="col-sm-9">{this.state.data.address}</dd>
                <dt className="col-sm-3">TEL</dt><dd className="col-sm-9">{this.state.data.TEL}</dd>
                <dt className="col-sm-3">FAX</dt><dd className="col-sm-9">{this.state.data.FAX}</dd>
              </dl>
            </MDBCardBody>
          </MDBCard>


        </MDBCardBody>
      </MDBCard>


      </>
    
  
    );
  }
}

export default CompanyInfo;