import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
    MDBCollapse
} from "mdbreact";
import "./SupportDetail.css";

class SupportDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: <></>
    }
  }

  componentDidMount() {
    let data = this.props.data[this.props.category].filter((v) => v.id === this.props.id*1);
    console.log(this.props.data[this.props.category],data)
    this.setState({data: data[0]})
  }

  render() {
    return (
      <>
        <MDBContainer>
          <MDBRow style={{height: 100, background: '#2e75b6', color: '#ffffff', alignItems: 'center', paddingLeft: 20, paddingRight: 20, justifyContent: 'space-between'}}>
            <Link to={'/support'} style={{color: '#ffffff'}}>
              <img src="/logo.png" className="img-fluid" alt="HOME" style={{height: 22, marginRight: 10}} />
              サポートサイト
            </Link>
            <div>
              <Link to={'/support#operation'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>基本的な操作</Link>
              <Link to={'/support#faq'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>よくある質問</Link>
              <Link to={'/support#information'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>お役立ち情報</Link>
            </div>
          </MDBRow>
          <MDBRow>
            <div style={{padding: '10px 20px'}}>
              {
                this.props.data.categoryBreadcrumbArray[this.props.category].map((v, i) => {
                  let link = '/support';
                  if (i === 1) {
                    link = '/support/'+this.props.category;
                  }
                  return <><Link to={link}>{v}</Link> » </>
                })
              }
              {this.state.data['title']}
            </div>
          </MDBRow>
          <MDBRow style={{backgroundColor: '#f5f5f5'}}>
            <div className={'support-detail'}>
              <h3>{this.state.data['title']}</h3>
              <br/><br/>
              {this.state.data['data']}
              <br/><br/>
              {this.state.data['backText'] ?
                  <Link to={'/support/'+this.state.data['backLink']} style={{border: '1px solid', background: '#007bff', color: '#fff', borderRadius: 30, paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, }}><MDBIcon icon={'mail-reply'}/> {this.state.data['backText']}</Link>
                  :
                  <Link to={'/support/'+this.props.category} style={{border: '1px solid', borderRadius: 30, paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5}}><MDBIcon icon={'mail-reply'}/> 一覧に戻る</Link>}
            </div>

          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default SupportDetail;

