import React from "../../../node_modules/react";
import ItemCard from '../../components/Item/ItemCardOld.js';

class TopPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="d-flex align-items-stretch flex-wrap min-vh-100">
      </div>
    )
  }
}

export default TopPage;
