import React, { Component } from 'react';
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBSpinner
} from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"

class OtasukeCartAllSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.isOpen,
      carts: [],
      selectCartId: null,
      modalReplace: false,
      loading: false
    }
  }

  toggle = (cartId) => {
    this.setState({
      modal: !this.state.modal
    }, () => {
      this.props.isOpenEvent(this.state.modal, cartId);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen){
      this.setState({
        modal: this.props.isOpen,
      });

      if (this.props.isOpen) {
        this.getCarts();
      }
    }
  }

  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && collection){
      this.setState({loading: true});
      let cRef = this.props.db.collection('carts').doc(this.props.user.id).collection('carts').doc(this.props.org.id)
      let c = await cRef.get();
      let cData = c.data();

      if (cData) {
        // let withDeletedItem = false;
        for (let i=0; i<5; i++) {
          let cart = cData[i];
          if (cart && cart.items) {
            let items = cart.items;
            delete cart['deletedItem'];
            delete cart['deletedText'];
            await Promise.all(items.map(async (item) => {
              // console.log(collection, item.id)
              let itemSell = await collection.doc(item.id).get();
              let itemSellData = itemSell.data();

              delete item['deletedItem'];
              delete item['deletedText'];

              if (!itemSellData) {
                cart.deletedItem = true;
                cart.deletedText = '販売終了商品が含まれています';
                item.deletedItem = true;
                item.deletedText = '販売終了商品が含まれています';
              } else if (!itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                cart.deletedItem = true;
                cart.deletedText = '要見積商品が含まれています';
                item.deletedItem = true;
                item.deletedText = '要見積商品が含まれています';
              } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                cart.deletedItem = true;
                cart.deletedText = '要見積商品が含まれています';
                item.deletedItem = true;
                item.deletedText = '要見積商品が含まれています';
              } else if (itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                let expiration_date = itemSellData['price'][0]['expiration_date'];
                let expDate = expiration_date.toDate();

                if ((expDate.getTime() - this.props.dateNow) < 0) {
                  cart.deletedItem = true;
                  cart.deletedText = '価格有効期限切れ要見積';
                  item.deletedItem = true;
                  item.deletedText = '価格有効期限切れ要見積';
                } else {
                  item.price = itemSellData['price'][0]['price'];
                }
              } else {
                item.price = itemSellData['price'][0]['price'];
              }
              // if (itemSellData && itemSellData['price']) {
              //   item.price = itemSellData['price'][0]['price'];
              // } else {
              //   // withDeletedItem = true;
              //   // cart.items = cart.items.filter((obj) => obj.id !== item.id);
              // }
            }));
          }
        }

        // if (withDeletedItem) {
        //   cRef.set(cData, {merge: true});
        // }

        this.setState({carts: cData})
      }

      this.setState({loading: false})

      // this.props.db.collection('carts').doc(this.props.user.id)
      //     .collection('carts').doc(this.props.org.id)
      //     .get()
      //     .then((doc) => {
      //       if(doc.exists) {
      //         let data = doc.data();
      //         this.setState({carts: data})
      //       }
      //     })
    }
  }

  componentDidMount() {
    this.getCarts();
  }

  modalReplaceClose = (e) => {
    const replaceId = e && e.currentTarget.dataset.id;

    if (replaceId) {
      let carts = this.state.carts;
      let propsData = this.props.data;

      let date = firebase.firestore.Timestamp.fromDate(new Date());
      for (let i=0; i<propsData.items.length; i++) {
        propsData.items[i].update = date;
      }

      // console.log('1',propsData)
      // return;

      propsData.toUser = '';
      propsData.toUserName = '';

      if (propsData.orderBasyo === 'アドレス帳から選択する' || propsData.orderBasyo === '前回の納品先') {
        propsData.orderBasyo = '販売側からお助けカートで指定された納品先';
        propsData.otasukeDeliveryAddress = propsData.deliveryAddress
        console.log('last or selected')

        delete propsData.deliveryAddress;
      } else {
        if (propsData.orderBasyo === '納品先を直接入力する') {
          propsData.orderBasyo = '販売側からお助けカートで指定された納品先';
          propsData.otasukeDeliveryAddress = {
            propertyName: propsData.orderPropertyName || "",
            postCode: propsData.orderZipcode,
            address1: propsData.orderAddr1,
            address2: propsData.orderAddr2,
            officeName: propsData.orderJigyosyo,
            personInCharge: propsData.orderTanto,
            phoneNumber: propsData.orderPhone
          }

          propsData.orderPropertyName = '';
          propsData.orderZipcode = '';
          propsData.orderAddr1 = '';
          propsData.orderAddr2 = '';
          propsData.orderJigyosyo = '';
          propsData.orderTanto = '';
          propsData.orderPhone = '';
          console.log('direct')
        } else if (propsData.orderBasyo === '納品先を指定しない' || propsData.orderBasyo === '') {
          propsData.orderBasyo = '';
          propsData.orderPropertyName = '';
          propsData.orderZipcode = '';
          propsData.orderAddr1 = '';
          propsData.orderAddr2 = '';
          propsData.orderJigyosyo = '';
          propsData.orderTanto = '';
          propsData.orderPhone = '';
          delete propsData.deliveryAddress;
          delete propsData.otasukeDeliveryAddress;
          console.log('empty')
          // return;
        } else {
          propsData.orderBasyo = '販売側からお助けカートで指定された納品先';
          propsData.otasukeDeliveryAddress = {
            propertyName: propsData.orderPropertyName || "",
            postCode: propsData.orderZipcode,
            address1: propsData.orderAddr1,
            address2: propsData.orderAddr2,
            officeName: propsData.orderJigyosyo,
            personInCharge: propsData.orderTanto,
            phoneNumber: propsData.orderPhone
          }
          console.log('old')
        }
      }

      propsData.created = date;
      propsData.latestUpdate= date;
      propsData.creatorUser = this.props.user.id;
      propsData.creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;

      carts[this.state.selectCartId] = propsData;//this.props.data;
      carts['update'] = date;

      console.log(carts)

      let cartRef = this.props.db.collection('carts').doc(this.props.user.id)
          .collection('carts').doc(this.props.org.id);

      let setWithMerge = cartRef.set(carts)
          .then(() => {
            this.toggle(this.state.selectCartId*1+1);
          });
    }
    this.setState({
      modalReplace: false
    })
  }

  addCart = (e) => {
    const cartId = e.currentTarget.dataset.id;

    // console.log(cartId)
    // return;

    let carts = this.state.carts;
    if(!carts[cartId]){
      carts[cartId] = {items: []}
    }

    let cart = carts[cartId].items;
    if(!cart){
      cart=[];
    }

    if (carts[cartId].items && carts[cartId].items.length > 0) {
      let self = this;
      this.setState({selectCartId: cartId}, () => {
        self.setState({
          modalReplace: true
        });
      })

      return;
    } else {
      let propsData = this.props.data;

      let date = firebase.firestore.Timestamp.fromDate(new Date());
      for (let i=0; i<propsData.items.length; i++) {
        propsData.items[i].update = date;
      }

      propsData.toUser = '';
      propsData.toUserName = '';

      if (propsData.orderBasyo === 'アドレス帳から選択する' || propsData.orderBasyo === '前回の納品先') {
        propsData.orderBasyo = '販売側からお助けカートで指定された納品先';
        propsData.otasukeDeliveryAddress = propsData.deliveryAddress
        console.log('last or selected')

        delete propsData.deliveryAddress;
      } else {
        if (propsData.orderBasyo === '納品先を直接入力する') {
          propsData.orderBasyo = '販売側からお助けカートで指定された納品先';
          propsData.otasukeDeliveryAddress = {
            propertyName: propsData.orderPropertyName || "",
            postCode: propsData.orderZipcode,
            address1: propsData.orderAddr1,
            address2: propsData.orderAddr2,
            officeName: propsData.orderJigyosyo,
            personInCharge: propsData.orderTanto,
            phoneNumber: propsData.orderPhone
          }

          propsData.orderPropertyName = '';
          propsData.orderZipcode = '';
          propsData.orderAddr1 = '';
          propsData.orderAddr2 = '';
          propsData.orderJigyosyo = '';
          propsData.orderTanto = '';
          propsData.orderPhone = '';
          console.log('direct')
        } else if (propsData.orderBasyo === '納品先を指定しない' || propsData.orderBasyo === '') {
          propsData.orderBasyo = '';
          propsData.orderPropertyName = '';
          propsData.orderZipcode = '';
          propsData.orderAddr1 = '';
          propsData.orderAddr2 = '';
          propsData.orderJigyosyo = '';
          propsData.orderTanto = '';
          propsData.orderPhone = '';
          delete propsData.deliveryAddress;
          delete propsData.otasukeDeliveryAddress;
          console.log('empty')
          // return;
        } else {

          propsData.orderBasyo = '販売側からお助けカートで指定された納品先';
          propsData.otasukeDeliveryAddress = {
            propertyName: propsData.orderPropertyName || "",
            postCode: propsData.orderZipcode,
            address1: propsData.orderAddr1,
            address2: propsData.orderAddr2,
            officeName: propsData.orderJigyosyo,
            personInCharge: propsData.orderTanto,
            phoneNumber: propsData.orderPhone
          }
          console.log('old', propsData)
        }
      }

      propsData.created = date;
      propsData.latestUpdate= date;
      propsData.creatorUser = this.props.user.id;
      propsData.creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;

      carts[cartId] = propsData; //this.props.data;

      // carts[cartId] = propsData;//this.props.data;
      carts['update'] = date;

      let cartRef = this.props.db.collection('carts').doc(this.props.user.id)
          .collection('carts').doc(this.props.org.id);


      let setWithMerge = cartRef.set(carts)
          .then(() => {
            this.toggle(cartId*1+1);
          });


    }
  }

  render() {
    let cart = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    for(let i=0; i<5; i++){
      if(this.state.carts[i] && this.state.carts[i].items && this.state.carts[i].items.length > 0){
        let items = this.state.carts[i].items;
        let date = '---';
        let amount = 0;
        let deletedItem = this.state.carts[i].deletedItem;
        let deletedText = this.state.carts[i].deletedText;
        let d = [];
        let deletedDescription = '';

        let name=<></>;
        items.forEach((item, index) => {
          if (!item.deletedItem) {
            amount += (item.price * item.count);
          } else {
            if (!d.includes(item.deletedText)) {
              d.push(item.deletedText)
              deletedDescription = <>{deletedDescription}<span style={{color: 'red'}}>{item.deletedText}</span><br /></>
            }
          }
          if(name != ''){
            name = <>{name}{item.name}<br /></>;
          }else{
            name = <>{item.name}<br /></>;
          }
          date = item.update.toDate().getFullYear() + '/'
              + n(item.update.toDate().getMonth() + 1) + '/'
              + n(item.update.toDate().getDate()) + ' '
              + n(item.update.toDate().getHours()) + ':'
              + n(item.update.toDate().getMinutes());
        });

        if (deletedItem) {
          name = <>{name}{deletedDescription}</>
        }

        cart.push({
          'no': <><MDBIcon icon="shopping-cart" size="2x" className="indigo-text mr-3" />カート{i+1}</>,
          '商品名': name,
          '金額': <div style={{textAlign: 'right'}}>{amount > 0 && amount.toLocaleString()}</div>,
          'date': date,
          btn: <div style={{textAlign: 'center'}}>
            {(this.state.carts[i].requested ?
              <MDBBtn size="sm4" className="text-nowrap"
                      onClick={this.addCart}
                      disabled={true}
                      color="warning"
                      data-id={i}
              >承認待</MDBBtn>
              :
              <MDBBtn size="sm4" className="text-nowrap"
                      onClick={this.addCart}
                      data-id={i}
              >選択</MDBBtn>)}
          </div>,
        })
      }else{
        cart.push({
          'no': <><MDBIcon icon="shopping-cart" size="2x" className="indigo-text mr-3" />カート{i+1}</>,
          '商品名': 'カートは空です',
          '金額': <div style={{textAlign: 'right'}}>---</div>,
          'date': '---',
          btn: <div style={{textAlign: 'center'}}>
            <MDBBtn size="sm4" className="text-nowrap"
                       onClick={this.addCart}
                       data-id={i}
          >選択</MDBBtn>
          </div>,
        })
      }
    }

    const data = {
      columns: [
        {
          label: 'カート番号',
          field: 'no',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: '商品名',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: '金額',
          sort: 'amount',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '保存日時',
          field: 'date',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>選択</div>,
          field: 'btn',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      //rows: this.state.dbItems

      rows: cart
    }


    return (
        <>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader toggle={this.toggle}>カート選択</MDBModalHeader>
            <MDBModalBody>
              カートを選択してください
              <MDBTable btn responsive>
                <MDBTableHead color="primary-color" textWhite columns={data.columns} />
                {!this.state.loading && <MDBTableBody rows={data.rows} />}
                {/*<MDBTableBody rows={data.rows} />*/}
              </MDBTable>

              {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                  className="btn-lg btn-block"
                  color="secondary"
                  onClick={this.toggle}
              >キャンセル</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalReplace} toggle={this.modalReplaceClose}>
            <MDBModalHeader toggle={this.modalReplaceClose}>カート内商品削除</MDBModalHeader>
            <MDBModalBody>
              カート{this.state.selectCartId*1+1}の内容を全て破棄して お助けカート「{this.props.data && this.props.data.cartName}」の内容を追加します。 よろしいですか？
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={this.modalReplaceClose} color={'secondary'} size={'sm'}>キャンセル</MDBBtn>
              <MDBBtn onClick={this.modalReplaceClose} color={'danger'} size={'sm'} data-id={1}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </>


    );
  }
}

export default OtasukeCartAllSelect;
