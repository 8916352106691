import React from "react";
import { MDBSpinner, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter} from 'mdbreact';
import StorageLink from './StorageLink'

class P4BillsTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbP4Bills: [],
      last: null,
      p4billsPerPage: 12,
      applyModal: false,
      waitModal: false,
    }
  }

  getP4Bills = (props) => {
    console.log("###### getP4Bills ########", props)
    
    if (this.state.loading) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    if(props.userOrg && props.userOrg.administrator && props.org ) {
      toOrg = props.org.id;
    } else {
      toOrg = this.props.user.organization;
    }
    if (toOrg == ""){
      return;
    }

    let first;
    this.setState({loading: true})
    if(this.state.last){
      first = this.props.db.collection('p4bills')
      .where("toOrganization", "==", toOrg)
      .where("dueDate", ">", 0)
      .orderBy('dueDate','desc')
      .startAfter(this.state.last)
      .limit(this.state.p4billsPerPage);
    }else{
      first = this.props.db.collection("p4bills")
      .where("toOrganization", "==", toOrg)
      .where("dueDate", ">", 0)
      .orderBy('dueDate','desc')
      .limit(this.state.p4billsPerPage);
    }

    let paginate = first.get()
    .then((snapshot) => {
      this.setState({loading: false})
      if(snapshot.docs.length > 0) {
        let last = snapshot.docs[snapshot.docs.length - 1];
    
        this.setState({last: last})


        let p4bills = this.state.dbP4Bills;
        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          p4bills.push(data)
        })
        this.setState({dbP4Bills: p4bills})
      }else{
        
      }

    });
    
  }

  componentDidMount() {
    //this.getP4Bills();
    this.getP4Bills(this.props);
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  componentWillReceiveProps(nextProps) {
    this.getP4Bills(nextProps);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    console.log("LAST:" , this.state.last);
    this.getP4Bills(this.props);
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);

    let esId = e.currentTarget.dataset.id;

    let p4billsRef = this.props.db.collection('p4bills').doc(esId);
    p4billsRef.set({
      visited: {[this.props.user.id]: true},
    }, { merge: true });
    this.dbP4BillsUpdate(esId,"visited",{[this.props.user.id]:true});
  }

  applyFile = (e) => {
    console.log("applyFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      applyModal: true,
      applyId: esId,
      applyTitle: esTitle,
    })
  }
  applyClose = (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
      applyTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let p4billsRef = this.props.db.collection('p4bills').doc(esId);
      p4billsRef.set({
        apply: {[this.props.user.id]: true},
      }, { merge: true });
      this.dbP4BillsUpdate(esId,"apply",{key:1});
    }

  }

  waitFile = (e) => {
    console.log("waitFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      waitModal: true,
      waitId: esId,
      waitTitle: esTitle,
    })
  }
  waitClose = (e) => {
    this.setState({
      waitModal: false,
      waitId: null,
      waitTitle: null,
    })

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let p4billsRef = this.props.db.collection('p4bills').doc(esId);
      p4billsRef.set({
        wait: {[this.props.user.id]: true},
      }, { merge: true });
      this.dbP4BillsUpdate(esId,"wait",{key:1});
    }
  }

  dbP4BillsUpdate = (id,target,value) => {
    let dbP4BillsUpdated = this.state.dbP4Bills.map((es)=>{
      if(es.id === id){
        if(target === 'visited') {
          let visited = es.visited;
          if(visited){
            es[target] = Object.assign(visited, value);
          } else {
            es[target] = value;
          }
        }else{
          es[target] = value;
        }
      }
      return es;
    })
    this.setState({
      dbP4Bills: dbP4BillsUpdated,
    })
  }

  render() {
    if (this.props.userOrg && this.props.userOrg.administrator && !this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let kaigawa = false;
    let urigawa = false;
    if (this.props.userOrg && this.props.userOrg.administrator) 
    {
      urigawa = true;
    }else{
      kaigawa = true;
    }

    if(! kaigawa && ! urigawa){
      return (
        <>
        </>
      )
    }

    const p4bills = [];

    this.state.dbP4Bills.forEach((bill,index) => {
      const date = bill['date'].toDate().getFullYear() + '/'
        + (bill['date'].toDate().getMonth()+1) + '/'
        + bill['date'].toDate().getDate() + '';

      const endDate = bill['endDate'] 
        ? bill['endDate'].toDate().getFullYear() + '/'
          + (bill['endDate'].toDate().getMonth()+1) + '/'
          + bill['endDate'].toDate().getDate() + ''
        : '';

      let visited = '';
      let apply = '';
      let toVisited = '';
      if(urigawa) {
        if(bill['visited'] && bill['visited'][bill['toUser']]) {
          visited = <MDBChip className="text-nowrap">確認済</MDBChip>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }
        if(bill['apply'] && Object.keys(bill['apply']).length > 0) {
          apply = <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">受注済み</MDBChip>;
        } else if(bill['wait'] && Object.keys(bill['wait']).length > 0) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">未受注</MDBChip>;
        }
      }

      if ( kaigawa ){
        if(this.props.user.authorizer){
          if(bill['visited'] && bill['visited'][bill['toUser']]) {
            toVisited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
          } else {
            toVisited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
          }
        }

        
        if(bill['visited'] && bill['visited'][this.props.user.id]) {
          visited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }

        if(bill['apply'] && Object.keys(bill['apply']).length > 0) {
          apply = <MDBChip bgColor="info-color" text="white" className="text-nowrap">承認発注済み</MDBChip>;
        } else if(bill['wait'] && Object.keys(bill['wait']).length > 0) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap">承認待ち</MDBChip>;
        }
      }
      

      let data = {
        '請求番号':<span className="text-nowrap">{bill['number']}</span>,
        '発行日':<span className="text-nowrap">{date}</span>,
        //'有効期限':<span className="text-nowrap">{endDate}</span>,
        '請求受取者': <span className="text-nowrap">{bill['toUserName']} {toVisited}</span>,
        '請求タイトル': <div className="overflow-hidden" style={{ height: "1.5em" }}>{bill['title']}</div>,
        '内容表示': <StorageLink src={bill['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={bill.id} onClick={this.openFile} >内容表示</MDBChip></StorageLink>,
        '確認':visited,
        //'受注': apply,
      };
      /*
      if ( kaigawa ){
        data['操作'] = ''
        if(this.props.user.authorizer){
          if(bill['apply'] && Object.keys(bill['apply']).length > 0) {
          } else {
            data['操作'] = <MDBChip bgColor="default-color" text="white" data-id={bill.id} data-title={bill.title} onClick={this.applyFile}>承認</MDBChip>
          }
        }else{
          if( (bill['wait'] && Object.keys(bill['wait']).length > 0)
            || (bill['apply'] && Object.keys(bill['apply']).length > 0)
          ) {
          } else {
            data['操作'] = <MDBChip bgColor="default-color" text="white" data-id={bill.id} data-title={bill.title} onClick={this.waitFile}>承認依頼</MDBChip>
          }
        }
      }
      */
      p4bills.push(data);

    });

    const data = {
      columns: [
        {
          label: '請求番号',
          field: '請求番号',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: '発行日',
          field: '発行日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        //{
        //  label: '有効期限',
        //  field: '有効期限',
        //  sort: 'asc',
        //  width: 80,
        //  minimal: 'sm'
        //},
        {
          label: '請求受取者',
          field: '請求受取者',
          sort: 'asc',
          width: 200,
          minimal: 'sm'
        },
        {
          label: '請求タイトル',
          field: '請求タイトル',
          sort: 'disabled',
          width: 370,
          minimal: 'sm'
        },
        {
          label: '内容表示',
          field: '内容表示',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '自確認',
          field: '自確認',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        //{
        //  label: (kaigawa ? '承認' : '受注'),
        //  field: '受注',
        //  sort: 'disabled',
        //  width: 50,
        //  minimal: 'sm'
        //},
      ],
      rows: p4bills
    }

    /*
    if(kaigawa) {
      data.columns.push({
        label: '操作',
        field: '操作',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      });
    }
    */

    return (
      <>
      <h2>請求書一覧</h2>
      <MDBTable responsive btn small>
        <MDBTableHead color="primary-color" textWhite columns={data.columns} />
        <MDBTableBody rows={data.rows} />
      </MDBTable>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

        <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
          <MDBModalHeader toggle={this.applyClose}>承認(発注確定)</MDBModalHeader>
          <MDBModalBody>
          {this.state.applyTitle} の請求書内容での発注を承認しますか？<br />
          承認すると発注が確定し、販売会社に通知されます。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.applyClose} color="danger" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.waitModal} toggle={this.waitClose}>
          <MDBModalHeader toggle={this.waitClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
            {this.state.waitTitle} の請求書から発注するために、上長に承認依頼をします。<br />
            よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.waitClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.waitClose} color="danger" data-id={this.state.waitId} >上長に承認依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

      </>
    )

  }
}

export default P4BillsTableList;