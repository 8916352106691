import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
    MDBCollapse
} from "mdbreact";

class SupportFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredList: []
    }
  }

  componentDidMount() {
    // console.log(this.props.search)
    // console.log(this.props.data[this.props.search])
    console.log(this.props.data.search)

    let filteredDatas = this.props.data.search.filter(data => {
      return (data.text.toLowerCase().includes(this.props.search) || data.title.toLowerCase().includes(this.props.search))
    })

    console.log('filtered datas', filteredDatas)

    this.setState({
      filteredList: filteredDatas
    })
  }

  render() {
    return (
      <>
        <MDBContainer>
          <MDBRow>
            <div>
              <h3>「{this.props.search}」の検索結果</h3>
              <br/><br/>
              <ul>
              {this.state.filteredList.map((v) => {
                return <li>
                  <h4>{v.title}</h4>
                  <p style={{fontSize: '0.9rem'}}>{v.text.slice(0, 100)}</p>
                  <Link to={v.link} style={{fontSize: '0.9rem'}}>もっと読む »</Link>
                  <br/><br/>
                </li>
              })}
              </ul>
            </div>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default SupportFilter;

