import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom'
import { MDBContainer, MDBRow, MDBBtn, MDBChip, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact';
import CartSelect from '../../components/CartSelect/CartSelect';
import FILELink from './FILELink'
import SDSLink from './SDSLink'

class ItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      image2: '',
      image3: '',
      cartselect: false,
    }
  }

  getItemImage = () => {
    if (this.props.data.image) {
      let gsReference = this.props.storage.refFromURL(this.props.data.image)
      .getDownloadURL()
      .then( (url) => {
        this.setState({image: url})
      } )
    }
    if (this.props.data.image2) {
      let gsReference = this.props.storage.refFromURL(this.props.data.image2)
      .getDownloadURL()
      .then( (url) => {
        this.setState({image2: url})
      } )
    }
    if (this.props.data.image3) {
      let gsReference = this.props.storage.refFromURL(this.props.data.image3)
      .getDownloadURL()
      .then( (url) => {
        this.setState({image3: url})
      } )
    }
  }

  componentDidMount() {
    this.getItemImage();
  }

  addCart = () => {
    this.setState({cartselect: true});
  }

  showHideCart = (val) => {
    this.setState({cartselect: val});
  }

  render() {
    const style = {marginBottom: 10};

    const now = new Date();

    let files = [];
    let taxes = [];

    let isBuyer = false;
    let isSeller = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
        || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      isBuyer = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
        || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      isSeller = true;
    }

    for(var i in this.props.data['files']){
      let e=this.props.data['files'][i];
      if (e.name && (e.name.toLowerCase().indexOf("ｓｄｓ") !== -1 || e.name.toLowerCase().indexOf("sds") !== -1)) {
        files.push(
            <>
              <dd>
                <FILELink src={e['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={e['file']} >{e['name']}</MDBChip></FILELink>
              </dd>
            </>
        );

        if (this.props.data['sdsManagement']) {
          files.push(
              <>
                <dd>
                  <Link to={"/sdsManagement"}><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >SDS管理中</MDBChip></Link>
                </dd>
              </>
          );
        }

      } else {
        files.push(
            <>
              <dd>
                <FILELink src={e['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={e['file']} >{e['name']}</MDBChip></FILELink>
              </dd>
            </>
        );
      }

    }

    let priceText = '';

    // console.log('detail',!this.props.data['price'])

    if (!this.props.data['price'] || this.props.data['price'] && this.props.data['price'][0] && !this.props.data['price'][0]['price']) {
      priceText = '要見積';
    }

    if (this.props.data['price'] && this.props.data['price'][0] && !this.props.data['price'][0]['expiration_date']) {
      // disableButton = true;
      priceText = '要見積';
    }

    if (this.props.data['price'] && this.props.data['price'][0] && this.props.data['price'][0]['expiration_date']) {
      let expiration_date = this.props.data['price'][0]['expiration_date'];
      let expDate = expiration_date.toDate();

      if ((expDate.getTime() - this.props.dateNow) < 0) {
        priceText = '価格有効期限切れ要見積';
      }
    }

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="mb-3">
            <h2>{this.props.data['P4CLOUD商品名']}</h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="4" md="12" className="mb-3">
            <img src={this.state.image} className="img-fluid z-depth-1" alt="" />
          </MDBCol>
          <MDBCol lg="4" md="6" className="mb-3">
            <img src={this.state.image2} className="img-fluid z-depth-1" alt="" />
          </MDBCol>
          <MDBCol lg="4" md="6" className="mb-3">
            <img src={this.state.image3} className="img-fluid z-depth-1" alt="" />
          </MDBCol>
      </MDBRow>
      <MDBRow>
          <MDBCol md="12" className="mb-3">
            <dl className="row">
            {priceText ?
                <>
                  <dt className={"col-sm-3 mb-1 border-bottom"}>価格</dt>
                  <dd className={'col-sm-9 mb-1 border-bottom'} style={{color: 'red'}}>{priceText}</dd>
                </>
                :
                <>
                { this.props.userOrg && this.props.userOrg.maker ? <dt className="col-sm-3 mb-1 border-bottom">価格</dt> : <dt className="col-sm-3 mb-1 border-bottom">価格</dt> }
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['price'][0]['price'].toLocaleString() + "円"}</dd>
                </>
            }
            {this.props.data['消費税率'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">消費税率</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['消費税率'] * 100}%</dd>
                </>
                }
              {this.props.data['No'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">No</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['No']}</dd>
                </>
              }
              {this.props.data['販売企業商品コード'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">販売企業商品コード</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['販売企業商品コード']}</dd>
                </>
              }

              {this.props.data['メーカー商品コード'] &&
                  <>
                    <dt className="col-sm-3 mb-1 border-bottom">メーカー商品コード</dt>
                    <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['メーカー商品コード']}</dd>
                  </>
              }

              {((this.props.userOrg && this.props.userOrg.maker) || (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller)) &&
              // メーカー || 販社・販売モード
              <>
                {this.props.data['仕入先（本社）'] &&
                  <>
                  <dt className="col-sm-3 mb-1 border-bottom text-muted">仕入先名</dt>
                  <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['仕入先（本社）']}</dd>
                  </>
                }
                {this.props.data['仕入先事業所名'] &&
                  <>
                  <dt className="col-sm-3 mb-1 border-bottom text-muted">仕入先事業所名</dt>
                  <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['仕入先事業所名']}</dd>
                  </>
                }
                {this.props.data['仕入先事業所コード'] &&
                  <>
                  <dt className="col-sm-3 mb-1 border-bottom text-muted">仕入先事業所コード</dt>
                  <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['仕入先事業所コード']}</dd>
                  </>
                }
                {this.props.data['仕入先EDIコード商品コード'] &&
                  <>
                  <dt className="col-sm-3 mb-1 border-bottom text-muted">仕入先EDIコード商品コード</dt>
                  <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['仕入先EDIコード商品コード']}</dd>
                  </>
                }
                {this.props.data['仕入先発注コード'] &&
                  <>
                  <dt className="col-sm-3 mb-1 border-bottom text-muted">仕入先発注コード</dt>
                  <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['仕入先発注コード']}</dd>
                  </>
                }
              </>
              }
              {this.props.data['正式メーカー名'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">メーカー名</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['正式メーカー名']}</dd>
                </>
              }

              {this.props.data['容量・入数'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">容量・入数</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['容量・入数']}</dd>
                </>
                }

                {this.props.data['発注数量'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">発注数量</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['発注数量']}</dd>
                </>
                }
                {this.props.data['単位'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">単位</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['単位']}</dd>
                </>
                }
                {this.props.data['発注数'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">発注数</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['発注数']}</dd>
                </>
                }
                {this.props.data['発注単位'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">発注単位</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['発注単位']}</dd>
                </>
                }
                {this.props.data['劇毒物区分名'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">劇毒物区分名</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['劇毒物区分名']}</dd>
                </>
                }
                {this.props.data['危険物区分名'] &&
                <>
                <dt className="col-sm-3 mb-1 border-bottom">危険物区分名</dt>
                <dd className="col-sm-9 mb-1 border-bottom">{this.props.data['危険物区分名']}</dd>
                </>
                }


                {this.props.data['data'] ?
                <>
                 {this.props.data['data'].map((data) => (
                    <>
                    <dt className="col-sm-3 mb-1 border-bottom">{data.title}</dt>
                    <dd className="col-sm-9 mb-1 border-bottom">{data.value}</dd>
                    </>
                  ))}
                </>
                : ""}





                {this.props.data['sds'] ?
                <>
                <dd>
                <SDSLink src={this.props.data['sds']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={this.props.data['sds']} >SDS表示</MDBChip></SDSLink>
                </dd>
                </>
                : ""}
                {files}


            </dl>
            {this.props.userOrg && this.props.userOrg.buyer &&
              <div className="text-center">
              {priceText ?
                <MDBBtn
                    className="btn-lg btn-block"
                    color=""
                    style={{background: '#8E8E8E'}}
                    disabled={true}
                    //onClick={this.addCart}
                >{priceText}</MDBBtn>
                :
                <MDBBtn
                  className="btn-lg btn-block"
                  onClick={this.addCart}
                >再発注</MDBBtn>
              }
              </div>
            }


            {this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker &&
              <div className="text-center">
                {priceText ?
                    <MDBBtn
                        className="btn-lg btn-block"
                        color=""
                        style={{background: '#8E8E8E'}}
                        disabled={true}
                        //onClick={this.addCart}
                    >{priceText}</MDBBtn>
                    :
                    <MDBBtn
                        className="btn-lg btn-block"
                        onClick={this.addCart}
                    >再発注</MDBBtn>
                }
              </div>
            }


          </MDBCol>
        </MDBRow>
        <CartSelect isOpen={this.state.cartselect} isOpenEvent={(val) => this.showHideCart(val)} db={this.props.db} user={this.props.user} data={this.props.data}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
    </MDBContainer>
    );
  }
}
export default ItemCard;
