import React from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBContainer, MDBInput, MDBCol
} from 'mdbreact';
import firebase from "../../firebase";
import "./SDSManagement.css";
import Upload from "../Upload/Upload";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import DatePicker from "react-datepicker";

class SDSManagementRisk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbSDSRisk: {},
      sdsManagement: {},
      selectedSDSRiskList: [],
      uploadModal: false,
      errModal: false,
      errModalText: '',
      uploadTemplateModal: false,
      uploadTemplateConditionModal: false,
      files: [],
      downloading: false,
      checkDate: new Date(),
      deleteModal: false,
      deleteIndex: null,
      deleteSDSRisk: {},
      deleteTemplateModal: false,
      deleteSDSRiskTemplate: {},
      deleteTemplateConditionModal: false,
      showMenu: null,
      showMenuIndex: null,
      showMenuTemplate: null,
      showMenuTemplateIndex: null,
      showMenuConditionTemplate: null,
      showMenuConditionTemplateIndex: null,
    }
  }

  getSDSRisks = (props) => {
    // console.log("###### getSDS risks ########", props)

    if (this.state.loading) {
      return;
    }

    this.setState({loading: true});

    this.props.db.collection('sds_management').doc(props.id).get()
        .then((snapshot) => {
          let data = snapshot.data();
          this.setState({
            sdsManagement: data
          })
        })
        .catch((e) => {
          console.log('sds error', e);
          this.setState({
            sdsManagement: {}
          })
        })

    this.props.db.collection('sds_management_risk').doc(props.id).get()
    .then((snapshot) => {
      let data = snapshot.data();
      // data.id = doc.id;
      // console.log(data);
      this.setState({
        dbSDSRisk: data || {},
        loading: false
      })
    }).catch((e) => {
      console.log('error',e)
      this.setState({
        dbSDSRisk: [],
        loading: false
      })
    });

  }

  componentDidMount() {
    this.getSDSRisks(this.props);
    // console.log('this.props.id', this.props.id)
  }
  componentWillReceiveProps(nextProps) {
    this.getSDSRisks(nextProps);
  }

  uploadTemplateConditionModalClose = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;

    if (confirm) {
      this.fileUpload(e, 'templateCondition');
    } else {
      this.setState({
        uploadTemplateConditionModal: false
      })
    }
  }

  uploadTemplateModalClose = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    console.log('confirm',confirm);

    if (confirm) {
      this.fileUpload(e, 'template');
    } else {
      this.setState({
        uploadTemplateModal: false
      })
    }
  }

  uploadModalClose = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    console.log('confirm',confirm)
    if (confirm) {
      this.fileUpload(e, 'risk');
    } else {
      this.setState({
        uploadModal: false
      })
    }
  }

  onFilesAdded = (files) => {
    this.setState({
      files: files
    });
    console.log(files);
  }

  fileUpload = (e, type) => {
    e.preventDefault();

    console.log("fileUpload",e);
    console.log(this.state.files[0]);


    if(this.state.files.length > 0){
      const file = this.state.files[0];

      //this.props.storage.refFromURL(this.props.src);
      // console.log(file.name);

      let fileRef = this.props.storage.ref().child('sds_risk/' + this.props.user.id +'/' + this.props.id + '/' + type + '/' + file.name);

      fileRef.getDownloadURL().then((onResolve) => {
        console.log("Same File Exist");
        let errModalText = '';
        switch (type) {
          case 'template': errModalText = '同名のテンプレートファイルが存在するため、処理を中止しました。'; break;
          case 'templateCondition': errModalText = '同名のチェック済テンプレートファイルが存在するため、処理を中止しました。'; break;
          case 'risk': errModalText = '同名のリスクアセスメント実施済ファイルが存在するため、処理を中止しました。'; break;
          default: errModalText = ''; break;
        }
        this.setState({
          errModal: true,
          errModalText
        })
      }, (onReject) => {
        // console.log(fileRef.location.bucket);
        // console.log(fileRef.location.path);

        fileRef.put(file).then(function(snapshot) {
          console.log('Uploaded a blob or file!');
        });

        if (type === 'template') {
          this.dataSet(fileRef, file.name);
        }

        if (type === 'templateCondition') {
          this.dataSetCondition(fileRef, file.name);
        }

        if (type === 'risk') {
          this.dataSetRisk(fileRef, file.name);
        }
      });
    }
  }

  dataSetCondition = (snapshot, fileTemplateConditionName) => {
    let sdsRef = this.props.db.collection('sds_management_risk').doc(this.props.id);
    let dueDate = new Date();

    if (this.state.dbSDSRisk.created) {
      if (this.state.dbSDSRisk.fileTemplateCondition) {
        this.props.storage.ref(this.state.dbSDSRisk.fileTemplateCondition).delete();
      }
      sdsRef.set({
        fileTemplateConditionDate: firebase.firestore.Timestamp.fromDate(dueDate),
        fileTemplateCondition: snapshot.location.path,
        fileTemplateConditionName,
        fileTemplateConditionOfficeName: this.props.userOrg ? this.props.userOrg.company_name : '',
        fileTemplateConditionUser: this.props.user.id,
        fileTemplateConditionUserName: this.props.user.last_name + this.props.user.first_name,
      }, { merge: true })
    } else {
      sdsRef.set({
        dueDate: dueDate.getTime(),
        created: firebase.firestore.Timestamp.fromDate(dueDate),
        createdUser: this.props.user.id,
        createdUserName: this.props.user.last_name + this.props.user.first_name,
        fileTemplateConditionDate: firebase.firestore.Timestamp.fromDate(dueDate),
        fileTemplateCondition: snapshot.location.path,
        fileTemplateConditionName,
        fileTemplateConditionOfficeName: this.props.userOrg ? this.props.userOrg.company_name : '',
        fileTemplateConditionUser: this.props.user.id,
        fileTemplateConditionUserName: this.props.user.last_name + this.props.user.first_name,
        fileRiskAssessment: []
      }, { merge: true });
    }

    this.getSDSRisks(this.props);

    this.setState({
      uploadTemplateModal: false,
      uploadTemplateConditionModal: false,
      uploadModal: false
    });
  }

  dataSet = (snapshot, fileTemplateName) => {
    let sdsRef = this.props.db.collection('sds_management_risk').doc(this.props.id);
    let dueDate = new Date();

    if (this.state.dbSDSRisk.created) {
      if (this.state.dbSDSRisk.fileTemplate) {
        this.props.storage.ref(this.state.dbSDSRisk.fileTemplate).delete();
      }
      sdsRef.set({
        fileTemplateDate: firebase.firestore.Timestamp.fromDate(dueDate),
        fileTemplate: snapshot.location.path,
        fileTemplateName,
        fileTemplateOfficeName: this.props.userOrg ? this.props.userOrg.company_name : '',
        fileTemplateUser: this.props.user.id,
        fileTemplateUserName: this.props.user.last_name + this.props.user.first_name,
      }, { merge: true });
    } else {
      sdsRef.set({
        dueDate: dueDate.getTime(),
        created: firebase.firestore.Timestamp.fromDate(dueDate),
        createdUser: this.props.user.id,
        createdUserName: this.props.user.last_name + this.props.user.first_name,
        fileTemplateDate: firebase.firestore.Timestamp.fromDate(dueDate),
        fileTemplate: snapshot.location.path,
        fileTemplateName,
        fileTemplateOfficeName: this.props.userOrg ? this.props.userOrg.company_name : '',
        fileTemplateUser: this.props.user.id,
        fileTemplateUserName: this.props.user.last_name + this.props.user.first_name,
        fileRiskAssessment: []
      }, { merge: true });
    }


    this.getSDSRisks(this.props);

    this.setState({
      uploadTemplateModal: false,
      uploadTemplateConditionModal: false,
      uploadModal: false
    });
  }

  dataSetRisk = (snapshot, fileName) => {
    let sdsRef = this.props.db.collection('sds_management_risk').doc(this.props.id);
    let sdsRisk = this.state.dbSDSRisk;
    let fileRiskAssessment = sdsRisk.fileRiskAssessment;
    let checkDueDate = new Date(this.state.checkDate);
    let createdDueDate = new Date();

    fileRiskAssessment.push({
      checkDueDate: checkDueDate.getTime(),
      checkDate: firebase.firestore.Timestamp.fromDate(this.state.checkDate),
      createdDueDate: createdDueDate.getTime(),
      createdDate: firebase.firestore.Timestamp.fromDate(createdDueDate),
      createdUser: this.props.user.id,
      createdUserName: this.props.user.last_name + this.props.user.first_name,
      file: snapshot.location.path,
      fileName
    })

    sdsRef.set({fileRiskAssessment}, {merge: true});

    this.getSDSRisks(this.props);

    this.setState({
      uploadTemplateModal: false,
      uploadTemplateConditionModal: false,
      uploadModal: false
    });
  }

  sdsIsSelected = (docId) => {
    let filtered = this.state.selectedSDSRiskList.filter(item => {
      if (item.objectID) {
        return item.objectID === docId
      } else {
        return item.id === docId
      }

    });
    return filtered.length > 0
  };

  errModalClose = () => {
    this.setState({
      errModal: false,
      errModalText: ''
    })
  }

  downloadSDSRiskAssessment = async (file, fileName) => {
    this.setState({downloading: true});

    const fileBlob = await this.props.storage.ref(file).getDownloadURL().then( (url) => {
      return fetch(url).then((response) => response.blob());
    });

    saveAs(fileBlob, fileName);
    this.setState({downloading: false})
  }

  showDeleteModal = (index, sdsRisk) => {
    this.setState({deleteModal: true, deleteIndex: index, deleteSDSRisk: sdsRisk, showMenu: null, showMenuIndex: null})
  }

  showDeleteTemplateConditionModal = (index, sdsRiskTemplateCondition) => {
    this.setState({deleteTemplateConditionModal: true, deleteTemplateConditionIndex: index, deleteSDSRiskTemplate: sdsRiskTemplateCondition, showMenuTemplate: null, showMenuTemplateIndex: null});
  }

  showDeleteTemplateModal = (index, sdsRiskTemplate) => {
    // console.log('sdsRiskTemplate', sdsRiskTemplate)
    this.setState({deleteTemplateModal: true, deleteTemplateIndex: index, deleteSDSRiskTemplate: sdsRiskTemplate, showMenuTemplate: null, showMenuTemplateIndex: null});
  }

  deleteTemplateConditionModalClose = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    this.setState({
      deleteTemplateConditionModal: false,
      deleteTemplateConditionIndex: null,
      deleteSDSRiskTemplate: {}
    });
    if (confirm) {
      console.log('confirm');
      let sdsRisk = this.state.dbSDSRisk;
      this.props.storage.ref(sdsRisk.fileTemplateCondition).delete();
      let sdsRef = this.props.db.collection('sds_management_risk').doc(this.props.id);
      let dueDate = new Date();

      sdsRisk.fileTemplateCondition = null;
      sdsRisk.fileTemplateConditionDate = firebase.firestore.Timestamp.fromDate(dueDate);
      sdsRisk.fileTemplateConditionName = '';
      sdsRisk.fileTemplateConditionOfficeName = this.props.userOrg ? this.props.userOrg.company_name : '';
      sdsRisk.fileTemplateConditionUser = this.props.user.id;
      sdsRisk.fileTemplateConditionUserName = this.props.user.last_name + this.props.user.first_name;

      sdsRef.set({...sdsRisk});
      this.setState({
        sdsRisk
      })
    } else {
      console.log('not confirm')
    }
  }

  deleteTemplateModalClose = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    this.setState({
      deleteTemplateModal: false,
      deleteTemplateIndex: null,
      deleteSDSRiskTemplate: {}
    })
    if (confirm) {
      let sdsRisk = this.state.dbSDSRisk;
      this.props.storage.ref(sdsRisk.fileTemplate).delete();
      let sdsRef = this.props.db.collection('sds_management_risk').doc(this.props.id);
      // delete sdsRisk.fileTemplate;
      // delete sdsRisk.fileTemplateDate;
      // delete sdsRisk.fileTemplateName;
      // delete sdsRisk.fileTemplateOfficeName;
      // delete sdsRisk.fileTemplateUser;
      // delete sdsRisk.fileTemplateUserName;
      let dueDate = new Date();

      sdsRisk.fileTemplate = null;
      sdsRisk.fileTemplateDate = firebase.firestore.Timestamp.fromDate(dueDate);
      sdsRisk.fileTemplateName = '';
      sdsRisk.fileTemplateOfficeName = this.props.userOrg ? this.props.userOrg.company_name : '';
      sdsRisk.fileTemplateUser = this.props.user.id;
      sdsRisk.fileTemplateUserName = this.props.user.last_name + this.props.user.first_name;

      sdsRef.set({...sdsRisk});
      this.setState({
        sdsRisk
      })
      // console.log('confirm', sdsRisk);
    } else {
      console.log('not confirm');
    }
  }

  deleteModalClose = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    if (confirm) {
      let sdsRisk = this.state.dbSDSRisk;
      let fileRiskAssessment = sdsRisk.fileRiskAssessment;
      let deleteIndex = this.state.deleteIndex;

      let filteredRA = fileRiskAssessment.filter(function(item, index) {
        return index !== deleteIndex
      });

      sdsRisk.fileRiskAssessment = filteredRA;

      this.props.storage.ref(this.state.deleteSDSRisk.file).delete();
      let sdsRef = this.props.db.collection('sds_management_risk').doc(this.props.id);
      sdsRef.set({fileRiskAssessment: filteredRA}, {merge: true});

      this.setState({
        dbSDSRisk: sdsRisk,
        deleteModal: false,
        deleteIndex: null
      });
    } else {
      this.setState({
        deleteModal: false,
        deleteIndex: null
      })
    }
  }

  handleChange = date => {
    this.setState({
      checkDate: date
    });
  };

  showMenu = (sdsRisk, index) => {
    this.setState({
      showMenu: sdsRisk,
      showMenuIndex: index,
    })
  }

  showMenuTemplate = (templateName, index) => {
    this.setState({
      showMenuTemplate: templateName,
      showMenuTemplateIndex: index
    });
  }

  showMenuConditionTemplate = (conditionTemplateName, index) => {
    this.setState({
      showMenuConditionTemplate: conditionTemplateName,
      showMenuConditionTemplateIndex: index
    })
  }

  render() {
    if (! this.props.org ) {
      return (
          <>
          </>
      )
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let isBuyer = false;
    let isSeller = false;
    // let isMaker = false;
    let isAdmin = false;

    if (this.props.user && this.props.user.admin) {
      isAdmin = true;
    } else {
      if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
          || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
      ){
        isBuyer = true;
      }
      if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
          || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
      ){
        isSeller = true;
      }
    }

    const dbSDSRisks = [];
    const dbSDSRiskTemplates = [];
    const dbSDSRiskConditionTemplates = [];
    const dbSDSRiskData = this.state.dbSDSRisk || {}
    const dbSDSRisk = dbSDSRiskData.fileRiskAssessment || [];

    let fileTemplateConditionDate;
    if (dbSDSRiskData['fileTemplateConditionDate']) {
      fileTemplateConditionDate = dbSDSRiskData['fileTemplateConditionDate'].toDate().getFullYear() + '/'
          + n(dbSDSRiskData['fileTemplateConditionDate'].toDate().getMonth()+1) + '/'
          + n(dbSDSRiskData['fileTemplateConditionDate'].toDate().getDate()) + ' '
          + n(dbSDSRiskData['fileTemplateConditionDate'].toDate().getHours()) + ':'
          + n(dbSDSRiskData['fileTemplateConditionDate'].toDate().getMinutes());
    }

    dbSDSRiskConditionTemplates.push({
      date: fileTemplateConditionDate,
      officeName: dbSDSRiskData['fileTemplateConditionOfficeName'],
      user: dbSDSRiskData['fileTemplateConditionUserName'],
      fileName: dbSDSRiskData['fileTemplateConditionName'],
      action: <div style={{display: 'flex', justifyContent: 'flex-end', width: 50, alignItems: 'center', position: 'absolute', marginTop: -15, marginLeft: -5}}>
        {dbSDSRiskData['fileTemplateConditionName'] && <div className={'menuButtonView'}>
          <div className={'menuButton'} onClick={(e) => {
            e.stopPropagation();
            this.showMenuConditionTemplate(dbSDSRiskData['fileTemplateConditionName'], 0);
          }}>
            <MDBIcon icon={'ellipsis-v'}/>
          </div>
          {dbSDSRiskData['fileTemplateConditionName'] === this.state.showMenuConditionTemplate && this.state.showMenuConditionTemplateIndex === 0 && <div className={'menuArea'}>
            <div onClick={() => this.downloadSDSRiskAssessment(dbSDSRiskData['fileTemplateCondition'], dbSDSRiskData['fileTemplateConditionName'])}>
              ダウンロード
            </div>
            <div onClick={() => this.showDeleteTemplateConditionModal(0, dbSDSRiskData)}>
              削除
            </div>
          </div>}
        </div>}
      </div>
    })

    let fileTemplateDate;
    if (dbSDSRiskData['fileTemplateDate']) {
      fileTemplateDate = dbSDSRiskData['fileTemplateDate'].toDate().getFullYear() + '/'
          + n(dbSDSRiskData['fileTemplateDate'].toDate().getMonth()+1) + '/'
          + n(dbSDSRiskData['fileTemplateDate'].toDate().getDate()) + ' '
          + n(dbSDSRiskData['fileTemplateDate'].toDate().getHours()) + ':'
          + n(dbSDSRiskData['fileTemplateDate'].toDate().getMinutes());
    }

    dbSDSRiskTemplates.push({
      date: fileTemplateDate,
      officeName: dbSDSRiskData['fileTemplateOfficeName'],
      user: dbSDSRiskData['fileTemplateUserName'],
      fileName: dbSDSRiskData['fileTemplateName'],
      action: <div style={{display: 'flex', justifyContent: 'flex-end', width: 50, alignItems: 'center', position: 'absolute', marginTop: -15, marginLeft: -5}}>
        {dbSDSRiskData['fileTemplateName'] && <div className={'menuButtonView'}>
          <div className={'menuButton'} onClick={(e) => {
            e.stopPropagation();
            this.showMenuTemplate(dbSDSRiskData['fileTemplateName'], 0);
          }}>
            <MDBIcon icon={'ellipsis-v'}/>
          </div>
          {dbSDSRiskData['fileTemplateName'] === this.state.showMenuTemplate && this.state.showMenuTemplateIndex === 0 && <div className={'menuArea'}>
            <div onClick={() => this.downloadSDSRiskAssessment(dbSDSRiskData['fileTemplate'], dbSDSRiskData['fileTemplateName'])}>
              ダウンロード
            </div>

            <div onClick={() => this.showDeleteTemplateModal(0, dbSDSRiskData)}>
              削除
            </div>
          </div>}
        </div>}
      </div>
    });

    dbSDSRisk.forEach((sdsRisk,index) => {
      // console.log('sdsRisk',sdsRisk)
      let sdsId = sdsRisk.objectID ? sdsRisk.objectID : sdsRisk.id
      let checked = this.sdsIsSelected(sdsId);
      let checkDate = '';
      let createdDate = '';

      if (sdsRisk['checkDate']) {
        checkDate = sdsRisk['checkDate'].toDate().getFullYear() + '/'
            + n(sdsRisk['checkDate'].toDate().getMonth()+1) + '/'
            + n(sdsRisk['checkDate'].toDate().getDate()) + ' '
            + n(sdsRisk['checkDate'].toDate().getHours()) + ':'
            + n(sdsRisk['checkDate'].toDate().getMinutes());
      }

      if (sdsRisk['createdDate']) {
        createdDate = sdsRisk['createdDate'].toDate().getFullYear() + '/'
            + n(sdsRisk['createdDate'].toDate().getMonth()+1) + '/'
            + n(sdsRisk['createdDate'].toDate().getDate()) + ' '
            + n(sdsRisk['createdDate'].toDate().getHours()) + ':'
            + n(sdsRisk['createdDate'].toDate().getMinutes());
      }

      let createdUser = sdsRisk.createdUserName || '';
      let fileName = sdsRisk.fileName || '';

      dbSDSRisks.push({
        'チェック日': checkDate,
        '登録日': createdDate,
        '登録者': createdUser,
        'ファイル名': fileName,
        'action': <div style={{display: 'flex', justifyContent: 'flex-end', width: 50, alignItems: 'center', position: 'absolute', marginTop: -15, marginLeft: -5}}>
          <div className={'menuButtonView'}>
            <div className={'menuButton'} onClick={(e) => {
              e.stopPropagation();
              this.showMenu(sdsRisk, index);
            }}>
              <MDBIcon icon={'ellipsis-v'}/>
            </div>
            {sdsRisk === this.state.showMenu && this.state.showMenuIndex === index && <div className={'menuArea'}>
              <div onClick={() => this.downloadSDSRiskAssessment(sdsRisk.file, sdsRisk.fileName)}>
                ダウンロード
              </div>
              {isBuyer && <div onClick={() => this.showDeleteModal(index, sdsRisk)}>
                削除
              </div>}
            </div>}
          </div>
        </div>
        // 'action': <div>
        //   <MDBBtn size="sm4" color={''} style={{background: '#eeeeee', color: '#000000', padding: '0.26em 1.5em'}} className={'text-nowrap'} onClick={() => this.downloadSDSRiskAssessment(sdsRisk.file, sdsRisk.fileName)}>ダウンロード</MDBBtn>
        //   {isBuyer && <MDBBtn size="sm4" color={''} style={{padding: '0.05em 1em', boxShadow: 'none'}} className={'text-nowrap'} onClick={() => this.showDeleteModal(index, sdsRisk)}>
        //     <MDBIcon style={{fontSize: 20, color: '#ababab'}} icon="trash" />
        //   </MDBBtn>}
        // </div>
      });
    });

    let columns = [
      {
        label: 'チェック日',
        field: 'チェック日',
        sort: 'asc',
        minimal: 'sm'
      },
      {
        label: '登録日',
        field: '登録日',
        sort: 'asc',
        minimal: 'sm'
      },
      {
        label: '登録者',
        field: '登録者',
        sort: 'asc',
        minimal: 'sm'
      },
      {
        label: 'ファイル名',
        field: 'ファイル名',
        sort: 'asc',
        minimal: 'sm'
      },
      {
        label: '',
        field: 'action',
        sort: 'asc',
        minimal: 'sm',
        width: 100
      }
    ];

    const data = {
      columns: columns,
      rows: dbSDSRisks
    }

    return (
      <MDBContainer>
        {(this.state.showMenu || this.state.showMenuTemplate || this.state.showMenuConditionTemplate) && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1041}} onClick={() => this.setState({showMenu: null, showMenuIndex: null, showMenuTemplate: null, showMenuTemplateIndex: null, showMenuConditionTemplate: null, showMenuConditionTemplateIndex: null})}/>}
        {/*<div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1041}} onClick={() => this.setState({showMenu: null, showMenuIndex: null})}/>*/}
        <MDBRow>
          <h2>リスクアセスメント管理詳細</h2>
        </MDBRow>
        <br/>

        <MDBRow>
          <span>商品名：{(this.state.sdsManagement.item || {})['P4CLOUD商品名']}</span>
        </MDBRow>
        <br/>
        <MDBRow style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <span>テンプレートファイル</span>
          <MDBBtn size={'sm'} color="default" onClick={() => this.setState({uploadTemplateModal: true})}>テンプレートファイルアップロード</MDBBtn>
        </MDBRow>

        {/*<MDBRow style={{alignItems: 'center', justifyContent: 'space-between'}}>*/}
        {/*  <div>*/}
        {/*    {isBuyer && <MDBBtn size={'sm'} color="default" onClick={() => this.downloadSDSRiskAssessment(this.state.dbSDSRisk.fileTemplate, this.state.dbSDSRisk.fileTemplateName)} disabled={!this.state.dbSDSRisk.fileTemplate}>フォーマットダウンロード</MDBBtn>}*/}
        {/*    {isSeller && <MDBBtn size={'sm'} color="default" onClick={() => this.setState({uploadTemplateModal: true})}>フォーマットアップロード</MDBBtn>}*/}
        {/*    {this.state.dbSDSRisk.fileTemplate && <span>{this.state.dbSDSRisk.fileTemplateName}</span>}*/}
        {/*  </div>*/}
        {/*  {isBuyer && <MDBBtn size={'sm'} color="" style={{background: '#eeeeee', color: '#000000'}} onClick={() => this.setState({uploadModal: true})} disabled={!this.state.dbSDSRisk.fileTemplate}>アップロード</MDBBtn>}*/}
        {/*</MDBRow>*/}

        <MDBRow>
          <MDBTable responsive btn small>
            <MDBTableHead color={'primary-color'} textWhite>
              <tr>
                <th style={{width: 150}}>更新日時</th>
                <th style={{width: 200}}>事業所名</th>
                <th>更新者</th>
                <th>ファイル名</th>
                <th style={{width: 50}}/>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {dbSDSRiskTemplates.map((item, index) => {
                return <tr>
                  <td>{item['date']}</td>
                  <td>{item['officeName']}</td>
                  <td>{item['user']}</td>
                  <td>{item['fileName']}</td>
                  <td>{item['action']}</td>
                </tr>
              })}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>
        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
        <br/>

        <MDBRow style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <span>条件記入済みテンプレートファイル</span>
          <MDBBtn size={'sm'} color="default" onClick={() => this.setState({uploadTemplateConditionModal: true})}>条件記入済みテンプレートファイルアップロード</MDBBtn>
        </MDBRow>
        <MDBRow>
          <MDBTable responsive btn small>
            <MDBTableHead color={'primary-color'} textWhite>
              <tr>
                <th style={{width: 150}}>更新日時</th>
                <th style={{width: 200}}>事業所名</th>
                <th>更新者</th>
                <th>ファイル名</th>
                <th style={{width: 50}}/>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {dbSDSRiskConditionTemplates.map((item, index) => {
                return <tr>
                  <td>{item['date']}</td>
                  <td>{item['officeName']}</td>
                  <td>{item['user']}</td>
                  <td>{item['fileName']}</td>
                  <td>{item['action']}</td>
                </tr>
              })}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>
        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
        <br/>

        <MDBRow style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <span>チェック済ファイル</span>
          {isBuyer && <MDBBtn size={'sm'} color="" style={{background: '#eeeeee', color: '#000000', padding: '0.782em 1.6em'}} className={'text-nowrap'} onClick={() => this.setState({uploadModal: true})}>チェック済ファイルアップロード</MDBBtn>}
        </MDBRow>
        <MDBRow>
          <MDBTable responsive btn small>
            <MDBTableHead color="primary-color" textWhite>
              <tr>
                <th style={{width: 150}}>チェック日</th>
                <th style={{width: 150}}>登録日</th>
                <th>登録者</th>
                <th>ファイル名</th>
                <th style={{width: 50}}/>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data.rows.map((item, index) => {
                return <tr>
                  <td>{item['チェック日']}</td>
                  <td>{item['登録日']}</td>
                  <td>{item['登録者']}</td>
                  <td>{item['ファイル名']}</td>
                  <td>{item['action']}</td>
                </tr>
              })}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>

        <MDBModal isOpen={this.state.uploadTemplateConditionModal} toggle={this.uploadTemplateConditionModalClose}>
          <MDBModalHeader toggle={this.uploadTemplateConditionModalClose}>
            <span style={{fontSize: '1.2rem'}}>リスクアセスメント管理<br/>条件記入済みテンプレートファイルアップロード</span>
          </MDBModalHeader>
          <MDBModalBody>
            <div style={{padding: 10}}>
              <MDBRow>
                <MDBCol>
                  <label htmlFor={'title'} className={'grey-text'}>商品名</label><div id={'title'}>{(this.state.sdsManagement.item || {})['P4CLOUD商品名']}</div>
                </MDBCol>
              </MDBRow>
              <br/>
              <MDBRow>
                <MDBCol>
                  <label htmlFor="fileSelection" className={'grey-text'}>ファイル選択</label>
                  <Upload id={'fileSelection'} accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} onFilesAdded={this.onFilesAdded} ref="UploadComponent" />
                </MDBCol>
              </MDBRow>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.uploadTemplateConditionModalClose} color="secondary" >キャンセル</MDBBtn>
            <MDBBtn onClick={this.uploadTemplateConditionModalClose} color="default" data-confirm={true} >確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.uploadTemplateModal} toggle={this.uploadTemplateModalClose}>
          <MDBModalHeader toggle={this.uploadTemplateModalClose}>
            <span style={{fontSize: '1.3rem'}}>リスクアセスメント管理<br/>テンプレートファイルアップロード</span>
          </MDBModalHeader>
          <MDBModalBody>
            <div style={{padding: 10}}>
              <MDBRow>
                <MDBCol>
                  <label htmlFor={'title'} className={'grey-text'}>商品名</label><div id={'title'}>{(this.state.sdsManagement.item || {})['P4CLOUD商品名']}</div>
                </MDBCol>
              </MDBRow>
              <br/>
              <MDBRow>
                <MDBCol>
                  <label htmlFor="fileSelection" className={'grey-text'}>ファイル選択</label>
                  <Upload id={'fileSelection'} accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} onFilesAdded={this.onFilesAdded} ref="UploadComponent" />
                </MDBCol>
              </MDBRow>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.uploadTemplateModalClose} color="secondary" >キャンセル</MDBBtn>
            <MDBBtn onClick={this.uploadTemplateModalClose} color="default" data-confirm={true} >確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.uploadModal} toggle={this.uploadModalClose}>
          <MDBModalHeader toggle={this.uploadModalClose}>
            <span style={{fontSize: '1.3rem'}}>リスクアセスメント管理ファイルアップロード</span>
          </MDBModalHeader>
          <MDBModalBody>
            <div style={{padding: 10}}>
              <MDBRow>
                <MDBCol>
                  <label htmlFor={'title'} className={'grey-text'}>商品名</label><div id={'title'}>{(this.state.sdsManagement.item || {})['P4CLOUD商品名']}</div>
                </MDBCol>
              </MDBRow>
              <br/>
              <MDBRow>
                <MDBCol>
                  <label htmlFor="checkDate" className={'grey-text'}>チェック日</label>
                  <DatePicker
                      dateFormat="yyyy/MM/dd"
                      id="checkDate"
                      className="form-control"
                      selected={this.state.checkDate}
                      onChange={this.handleChange}
                      locale="ja-JP"
                  />
                </MDBCol>
              </MDBRow>
              <br/>
              <MDBRow>
                <MDBCol>
                  <label htmlFor="fileSelection" className={'grey-text'}>ファイル選択</label>
                  <Upload id={'fileSelection'} accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} onFilesAdded={this.onFilesAdded} ref="UploadComponent" />
                </MDBCol>
              </MDBRow>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.uploadModalClose} color="secondary" >キャンセル</MDBBtn>
            <MDBBtn onClick={this.uploadModalClose} color="default" data-confirm={true} >確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errModal} toggle={this.errModalClose}>
          <MDBModalHeader className="warning-color-dark white-text" toggle={this.errModalClose}>重複確認</MDBModalHeader>
          <MDBModalBody>
            {this.state.errModalText}
            {/*同名のSDSリスクが存在するので処理を中止しました。*/}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="orange darken-3" onClick={this.errModalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.deleteModal} toggle={this.deleteModalClose}>
          <MDBModalHeader toggle={this.deleteModalClose}>削除</MDBModalHeader>
          <MDBModalBody>
            このファイルを削除します。<br/>
            よろしいですか？<br/>
            ファイル名：{this.state.deleteSDSRisk.fileName}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteModalClose}>キャンセル</MDBBtn>
            <MDBBtn color="default" onClick={this.deleteModalClose} data-confirm={true}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.deleteTemplateConditionModal} toggle={this.deleteTemplateConditionModalClose}>
          <MDBModalHeader toggle={this.deleteTemplateConditionModalClose}>削除</MDBModalHeader>
          <MDBModalBody>
            このファイルを削除します。<br/>
            よろしいですか？<br/>
            ファイル名：{this.state.deleteSDSRiskTemplate['fileTemplateConditionName']}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteTemplateConditionModalClose}>キャンセル</MDBBtn>
            <MDBBtn color="default" onClick={this.deleteTemplateConditionModalClose} data-confirm={true}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.deleteTemplateModal} toggle={this.deleteTemplateModalClose}>
          <MDBModalHeader toggle={this.deleteTemplateModalClose}>削除</MDBModalHeader>
          <MDBModalBody>
            このファイルを削除します。<br/>
            よろしいですか？<br/>
            ファイル名：{this.state.deleteSDSRiskTemplate['fileTemplateName']}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.deleteTemplateModalClose}>キャンセル</MDBBtn>
            <MDBBtn color="default" onClick={this.deleteTemplateModalClose} data-confirm={true}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
      </MDBContainer>
    )

  }
}

export default SDSManagementRisk;
