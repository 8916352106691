import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
} from "mdbreact";
import "./Support.css";

class Support extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchword: '',
      filteredData: [],
      collapseID: ''
    };
  }

  componentDidMount() {
    if (window.decodeURIComponent(window.location.hash)) {
      setTimeout(
          () => document.querySelector(window.decodeURIComponent(window.location.hash)).scrollIntoView({
            behavior: 'smooth'
          }),
          500
      )
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('didUpdate')
  }

  toggleCollapse = collapseID => () => {
    console.log(collapseID, this.state.collapseID)
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.searchword) {
      // let filteredDatas = this.props.data.search.filter(data => {
      //   return (data.text.toLowerCase().includes(this.state.searchword) || data.title.toLowerCase().includes(this.state.searchword))
      // })
      //
      // this.setState({
      //   filteredData: filteredDatas
      // })
      // console.log('enter key press', this.state.searchword, filteredDatas)

      this.props.cProps.history.push("/supportFilter/" + this.state.searchword)
    }
  }

  handleSearchwordChange = (e) => {
    this.setState({
      searchword: e.currentTarget.value,
    });
  }

  smoothScroll = (id) => {
    document.querySelector('#'+id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <>
        <MDBContainer>
          {/*<MDBRow>*/}
          {/*  {this.state.orders[3].data}*/}
          {/*</MDBRow>*/}
          <MDBRow style={{height: 100, background: '#2e75b6', color: '#ffffff', alignItems: 'center', paddingLeft: 20, paddingRight: 20, justifyContent: 'space-between'}}>
            <Link to={'/support'} style={{color: '#ffffff'}}>
              <img src="/logo.png" className="img-fluid" alt="HOME" style={{height: 22, marginRight: 10}} />
              サポートサイト
            </Link>
            <div>
              <span onClick={() => this.smoothScroll('operation')} style={{padding: 10, cursor: 'pointer'}}>基本的な操作</span>
              <span onClick={() => this.smoothScroll('faq')} style={{padding: 10, cursor: 'pointer'}}>よくある質問</span>
              <span onClick={() => this.smoothScroll('information')} style={{padding: 10, cursor: 'pointer'}}>お役立ち情報</span>
            </div>
          </MDBRow>
          <MDBRow style={{height: 300, background: '#2e75b6', color: '#ffffff', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <h1>どのようなことでお困りですか？</h1>
            <MDBCol md="6">
              <div className="form-inline mt-4 mb-4">
                <MDBIcon icon="search" />
                <input className="form-control form-control-sm ml-3 w-75" type="text" placeholder="Search" aria-label="Search" onChange={this.handleSearchwordChange} onKeyPress={this.handleKeyPress} />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 50, paddingBottom: 50}} id={'operation'}>
            <div style={{margin: 30}}>
              <h2>基本的な操作</h2>
            </div>
            <MDBRow className={'category'} style={{gap: 20}}>
              {this.props.data.category.map(value => {
                return <div style={{display: 'flex', flex: 1, minWidth: 145}}>
                  <Link to={value.link} style={{color: '#000000', display: 'flex', flex: 1}}>
                    <div className={'item'}>
                      <div>
                        <MDBIcon icon={value.icon} size={'5x'} style={{color: '#2bbbad', padding: 20}} />
                      </div>
                      <div>
                        <h4 style={{textAlign: 'center'}}>{value.title.map(v => {
                          return <div>{v}</div>
                        })}</h4>
                        <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: value.desc}}/>
                      </div>
                    </div>
                  </Link>
                </div>
              })}
            </MDBRow>
          </MDBRow>
          <MDBRow style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 50, paddingBottom: 50}}>
            <div style={{margin: 30}}>
              <h2>便利な機能</h2>
            </div>
            <MDBRow className={'category'}>
              {this.props.data.useFulFunction.map(value => {
                return <MDBCol xs="12" sm="12" md="6" lg="3">
                  <Link to={value.link} style={{color: '#000000'}}>
                    <div className={'item'}>
                      <div>
                        <MDBIcon icon={value.icon} size={'5x'} style={{color: '#2bbbad', padding: 20}} />
                      </div>
                      <div>
                        <h4 style={{textAlign: 'center'}}>{value.title.map(v => {
                          return <div>{v}</div>
                        })}</h4>
                        <p style={{textAlign: 'center'}}>{value.desc}</p>
                      </div>
                    </div>
                  </Link>
                </MDBCol>
              })}
            </MDBRow>
          </MDBRow>
          <MDBRow style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 50, paddingBottom: 50, background: '#f5f5f5'}} id={'faq'}>
            <div style={{margin: 30}}>
              <h2>よくある質問</h2>
            </div>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse1')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> パスワードを忘れました。
              </MDBBtn>
              <MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  P4CLOUDインフォメーションまでお問い合わせください。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse2')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> ログインID（メールアドレス）を変更できますか。
              </MDBBtn>
              <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  一度登録したログインIDは変更できません。新たにログインIDを登録する場合、以前使っていたログインIDでの取引履歴を引き継ぐことはできません。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse3')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> ユーザーを追加したいです。
              </MDBBtn>
              <MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  営業担当者にご連絡ください。<br/>
                  ※追加にお時間をいただく場合がございます。ご了承ください。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse4')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> 注文をキャンセルできますか。
              </MDBBtn>
              <MDBCollapse id="collapse4" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  キャンセル処理が可能かどうかについて直接営業担当者にお問い合わせください。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse5')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> 登録されていない商品を注文したいです。
              </MDBBtn>
              <MDBCollapse id="collapse5" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  営業担当者にご連絡ください。<br/>
                  ※登録にお時間をいただく場合がございます。ご了承ください。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse6')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> 上長権限と担当者権限の違いはなんですか。
              </MDBBtn>
              <MDBCollapse id="collapse6" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  上長権限は発注を承認・却下することができます。担当者権限は承認・却下ができません。<br/>
                  発注操作を行った場合、上長権限者の承認が得られるまで注文情報は相手先に送信されません。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse7')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> カートはアカウント作成した人全員と共有されますか？
              </MDBBtn>
              <MDBCollapse id="collapse7" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  カートは個人が保有しており他人とは共有されません。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse8')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> 発注時に登録住所を選択し、納品場所の受取担当者だけ変更したい。
              </MDBBtn>
              <MDBCollapse id="collapse8" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  登録住所を選択すると納品場所はご登録いただいている住所とログイン者名が自動で入る仕様になっております。<br/>
                  受取担当者名を変更したい場合は、納品場所に登録住所以外を選択し、住所等必要な情報を記入した上で受取担当者を入力してください。
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse9')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> 発注した商品の単価に間違いがあった。発注書の金額を訂正したい。
              </MDBBtn>
              <MDBCollapse id="collapse9" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  １．訂正したい発注書を開き、スクリーンショット（画面コピー）をとってください。<br/>
                  ２．訂正したい対象商品に印を付けてください。（<a href={'https://firebasestorage.googleapis.com/v0/b/ds-p4cloud.appspot.com/o/support%2Forder%2F単価訂正.png?alt=media&token=31b6d022-5fa1-45e8-b0d3-2f7a87004e2c'} target={'_blank'}>サンプル参照</a>）<br/>
                  ※サンプル画面左上の細い赤枠で囲った部分に出ている「p4cloud.jp/order/xxxxxxxxxxxxxx」の部分が弊社作業で必要です 。<br/>
                  スクリーンショットした画像を加工編集する際は、この部分を含めてお送り下さい。<br/>
                  ３．下記の情報をP4NEXTへチャットで送信してください。<br/>
                  ①スクリーンショットした画像（印入り）<br/>
                  ②訂正したい商品の発注時の単価<br/>
                  ③訂正したい商品の訂正後の単価（総額は不要です）
                </p>
              </MDBCollapse>
              <MDBBtn
                  color=""
                  onClick={this.toggleCollapse('collapse10')}
                  style={{ marginBottom: "1rem", border: '1px solid #D2D2D2', borderRadius: 2, boxShadow: 'none', textAlign: 'left', color: '#000', background: '#fff', padding: 10}}
              >
                <MDBIcon icon={'plus'} style={{color: '#2bbbad', marginRight: 10}} /> SDSをダウンロードするとファイル名が文字化けします。
              </MDBBtn>
              <MDBCollapse id="collapse10" isOpen={this.state.collapseID}>
                <p style={{padding: 10, paddingLeft: 40}}>
                  UTF-8に対応していないzipファイル解凍ソフトで解凍した場合、ファイル名が文字化けすることがあります。<br/>
                  以下の方法をお試しください。<br/><br/>

                  1）Windowsの標準の解凍機能を利用する<br/>
                  zipファイルを右クリックし、［すべて展開］をクリックします。<br/><br/>

                  2）UTF-8に対応した解凍ソフトを利用する<br/>
                  「7-Zip」等、UTF-8に対応したzipファイル解凍ソフトをインストールしてご利用ください。<br/>
                </p>
              </MDBCollapse>
            </div>
          </MDBRow>
          <MDBRow style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 50, paddingBottom: 50}} id={'information'}>
            <div style={{margin: 30}}>
              <h2>お役立ち情報</h2>
            </div>
            <MDBRow className={'category'}>
              {this.props.data.useFulInformation.map(value => {
                return <MDBCol xs="12" sm="12" md="6" lg="3">
                  <div className={'item'}>
                    <div>
                      <MDBIcon icon={value.icon} size={'5x'} style={{color: '#2bbbad', padding: 20}} />
                    </div>
                    <div>
                      <h4 style={{textAlign: 'center'}}>{value.title.map(v => {
                        return <div>{v}</div>
                      })}</h4>
                      <Link to={value.link} style={{textAlign: 'center'}}>{value.desc}</Link>
                    </div>
                  </div>
                </MDBCol>
              })}
            </MDBRow>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default Support;

