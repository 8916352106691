import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
    MDBCollapse
} from "mdbreact";

class SupportUpdateGroupYear extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    }
  }

  groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  componentDidMount() {
    let id = this.props.id;
    if (id) {
      console.log(id.slice(0,5), id.slice(5, id.length))
    }
    // console.log('support update group id',this.props.id)
    let data = this.props.data['update-list'].filter((v) => v.year === this.props.id);
    this.setState({data})
  }

  render() {
    return (
      <>
        <MDBContainer>
          <MDBRow style={{height: 100, background: '#2e75b6', color: '#ffffff', alignItems: 'center', paddingLeft: 20, paddingRight: 20, justifyContent: 'space-between'}}>
            <Link to={'/support'} style={{color: '#ffffff'}}>
              <img src="/logo.png" className="img-fluid" alt="HOME" style={{height: 22, marginRight: 10}} />
              サポートサイト
            </Link>
            <div>
              <Link to={'/support#operation'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>基本的な操作</Link>
              <Link to={'/support#faq'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>よくある質問</Link>
              <Link to={'/support#information'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>お役立ち情報</Link>
            </div>
          </MDBRow>
          <MDBRow>
            <div style={{padding: '10px 20px'}}>
              <Link to={'/support'}>ホーム</Link> » {this.props.id}
            </div>
          </MDBRow>
          <MDBRow style={{backgroundColor: '#f5f5f5'}}>
            <h3 style={{margin: '20px 50px', padding: 40}}>年: {this.props.id}</h3>
          </MDBRow>
          <MDBRow style={{backgroundColor: '#f5f5f5', padding: '0 50px'}}>
            {this.state.data.map(v => {
              return <div style={{marginBottom: 50, backgroundColor: '#ffffff', padding: 40, width: '100%'}}>
                <div style={{height: 220, overflow: "hidden", marginBottom: 20}}>
                  {v.data}
                </div>
                <Link to={'/supportUpdate/'+v.id}>もっと読む »</Link>
              </div>
            })}
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default SupportUpdateGroupYear;

