import React from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBBtn, MDBContainer, MDBSelect } from "mdbreact";

import Upload from "../Upload/Upload";


import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';

import "react-datepicker/dist/react-datepicker.css";
import "./Bills.css";

import firebase from "../../firebase"
import {toast} from "react-toastify";

registerLocale('ja', ja);
setDefaultLocale('ja')

class Bills extends React.Component {
  constructor(props) {
    const dt = new Date();
    super(props);
    this.state = {
      estNum: '',
      startDate: new Date(),
      startDate2: new Date(dt.getFullYear()+1,dt.getMonth(),dt.getDate()),
      title: '',
      title2: '',
      toUser: '',
      toUserCC: [],
      toUserCCName: [],
      userList: [],
      files: [],
      modal: false,
      errModal: false,
      billingDate: new Date(),
      totalAmount: null
    }
  }

  handleNumChange = (e) => {
    this.setState({
      estNum: e.currentTarget.value
    });
  }
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleBillingDateChange = date => {
    this.setState({
      billingDate: date
    })
  };

  handleChange2 = date => {
    this.setState({
      startDate2: date
    });
  };

  handleTitleChange = (e) => {
    this.setState({
      title: e.currentTarget.value
    });
  }

  handleTitle2Change = (e) => {
    this.setState({
      title2: e.currentTarget.value
    });
  }

  handleTotalAmountChange = (e) => {
    this.setState({
      totalAmount: e.currentTarget.value*1
    });
  }

  handleToUserChange = (e) => {
    if (e.currentTarget.selectedIndex > 0) {
      this.setState({
        toUserName: e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name,
        toUser: e.currentTarget.value
      }, () => {
        this.setUserList();
      });
    } else {
      this.setState({
        toUserName: '',
        toUser: ''
      }, () => {
        this.setUserList();
      });
    }
  }

  handleToUserCCChange = (e) => {
    let toUserCCName = e.map(userId => {
      return (this.props.org.users.find(data => data.user === userId) || {}).name;
    }) || [];

    this.setState({
      toUserCC: e,
      toUserCCName: toUserCCName
    })
  }

  selectPersonAlert = () => {
    toast.warning("相手先営業担当者を先に選択してください。", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  modalClose = () => {
    this.setState({
      modal: false,
      title: '',
      title2: '',
      toUser: '',
      files: [],
      totalAmount: '',
      // billingDate
    })
    this.refs.UploadComponent.resetFile();
  }
  errModalClose = () => {
    this.setState({
      errModal: false
    })
  }

  regModalClose = (e) => {
    this.setState({
      regModal: false
    })

    let esConfirm = e.currentTarget.dataset.confirm;
    if (esConfirm) {
      this.fileUpload(e);
    }
  }

  billRegister = (e) => {
    e.preventDefault();
    this.setState({
      regModal: true
    })
  }

  fileUpload = (e) => {
    e.preventDefault();

    console.log("fileUpload",e);
    console.log(this.state.files[0]);


    if(this.state.files.length > 0){
      const file = this.state.files[0];

      //this.props.storage.refFromURL(this.props.src);
      console.log(file.name);

      var fileRef = this.props.storage.ref().child('bills/' + '/' + this.props.user.id + '/' + file.name);

      fileRef.getDownloadURL().then((onResolve) => {
        console.log("Same File Exist");
        this.setState({
          errModal: true,
        })
      }, (onReject) => {
        console.log(fileRef.location.bucket);
        console.log(fileRef.location.path);

        fileRef.put(file).then(function(snapshot) {
          console.log('Uploaded a blob or file!');
        });
        this.dataSet(fileRef);

      });
    }
  }

  dataSet = (snapshot) => {

    console.log("dataSet");
    console.log(this.state.startDate);
    console.log(this.state.startDate2);

    let billRef = this.props.db.collection('bills').doc();
    let dueDate = new Date(this.state.startDate);
    let billingDueDate = new Date(this.state.billingDate);

    let setWithMerge = billRef.set({
      title: this.state.title,
      title2: this.state.title2,
      number: this.state.estNum,
      billingDate: firebase.firestore.Timestamp.fromDate(this.state.billingDate),
      billingDueDate: billingDueDate.getTime(),
      totalAmount: this.state.totalAmount,
      date: firebase.firestore.Timestamp.fromDate(this.state.startDate),
      //endDate: firebase.firestore.Timestamp.fromDate(this.state.startDate2),
      dueDate: dueDate.getTime(),
      toUser: this.state.toUser,
      toUserName: this.state.toUserName,
      toUserCC: this.state.toUserCC,
      toUserCCName: this.state.toUserCCName,
      toOrganization: this.props.org.id,
      fromUser: this.props.user.id,
      fromUserName: this.props.user.last_name + this.props.user.first_name,
      fromOrganization: this.props.user.organization,
      readBuyer: false,
      readSeller: false,
      deleted: false,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      file: snapshot.location.path,
    }, { merge: true });
    this.setState({
      modal: true,
    })
  }

  componentDidMount() {
    if (this.props.org) {
      this.setUserList();
    }
  }

  setUserList = () => {
    let toUserCC = this.state.toUserCC;
    let toUserCCName = this.state.toUserCCName;
    let toUser = this.state.toUser;
    let toUserName = this.state.toUserName;
    let userList = this.props.org.users.map((user) => {
      let found = false;
      for (let i=0; i<toUserCC.length; i++) {
        if (toUserCC[i] === user.user) {
          found = true;
        }
      }

      if (found) {
        return {value: user.user, text: user.name, checked: true}
      } else {
        return {value: user.user, text: user.name}
      }
    });

    toUserCC = toUserCC.filter(v => v !== toUser);
    toUserCCName = toUserCCName.filter(v => v!== toUserName);

    userList = userList.filter(v => v.value !== toUser);
    this.setState({userList, toUserCC, toUserCCName});
  }

  onFilesAdded = (files) => {
    this.setState({
      files: files
    });
    console.log(files);
  }

  handleInputChange = inputName => value => {
    const nextValue = value;
    this.setState({
        [inputName]: nextValue
    });
  };

  render() {
    const isEnable = (this.state.files.length > 0)
    && (this.state.estNum !== '')
    && (this.state.startDate !== '')
    && (this.state.startDate2 !== '')
    && (this.state.title !== '')
    && (this.state.title2 !== '')
        && (this.state.billingDate !== '')
        && (this.state.totalAmount !== null)
    && (this.state.toUser !== '');

    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
      || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }
    if(! urigawa){
      return (
        <>
        </>
      )
    }

    return (
      <>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="mb-3">
            <h2>請求書登録</h2>
            <form>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="estNum" className="grey-text">
                  請求番号
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>

              <input
                type="text"
                id="estNum"
                className="form-control"
                name="estNum"
                onChange={this.handleNumChange}
                value={this.state.estNum}
              />
              <br />

              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor={"billingDate"} className={"grey-text"}>
                  請求締日
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <DatePicker
                dateFormat={"yyyy/MM/dd"}
                id={"billDate"}
                className={"form-control"}
                selected={this.state.billingDate}
                onChange={this.handleBillingDateChange}
                locale={"ja-JP"}
              />
              <br/>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="billDate" className="grey-text">
                  請求書作成日
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                id="billDate"
                className="form-control"
                selected={this.state.startDate}
                onChange={this.handleChange}
                locale="ja-JP"
              />
              <br />

              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="billTitle" className="grey-text">
                  請求書タイトル
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <input
                type="text"
                id="billTitle"
                className="form-control"
                name="title"
                onChange={this.handleTitleChange}
                value={this.state.title}
              />
              <br />

              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="billTitle2" className="grey-text">
                  請求書タイトル（ふりがな）
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <input
                  type="text"
                  id="billTitle2"
                  className="form-control"
                  name="title"
                  onChange={this.handleTitle2Change}
                  value={this.state.title2}
              />
              <br />

              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor={"totalAmount"} className="grey-text">
                  税込合計金額（円）
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <input
                  type="number"
                  id="totalAmount"
                  className="form-control"
                  name="totalAmount"
                  maxLength={10}
                  style={{fontWeight: 'bold'}}
                  onChange={this.handleTotalAmountChange}
                  value={this.state.totalAmount}
              />
              <br />

              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="billToUser" className="grey-text">
                  相手先担当者選択
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <select
                className="browser-default custom-select"
                id="billToUser"
                onChange={this.handleToUserChange}
                value={this.state.toUser}
                >
                <option>選択してください</option>
                {this.props.org && this.props.org.users &&
                  this.props.org.users.map((user) =>
                    <option key={user.user} value={user.user} data-name={user.name}>{user.name}</option>
                  )
                }
              </select>
              <br />

            <div style={{position: 'relative'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="billToUserCC" className="grey-text">
                  相手先CC選択（複数選択可）
                </label>
                &nbsp;
                <span className={'cart-require-any'} style={{marginBottom: '0.5rem', marginTop: 0}}>任意</span>
              </div>
              <MDBSelect disabled={true} id={'billToUserCC'} className={'cart-select custom-select'} options={this.state.userList} getValue={this.handleToUserCCChange} multiple={true}/>
              {!this.state.toUser && <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, cursor: 'pointer'}} onClick={() => this.selectPersonAlert()}/>}
            </div>
            <br/>

            <label htmlFor="billFile" className="grey-text">
              請求書PDF選択
            </label>
            <Upload onFilesAdded={this.onFilesAdded} ref="UploadComponent" />

            {false &&
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="billFile"
                  aria-describedby="inputGroupFileAddon01"
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  ファイルを選択してください
                </label>
              </div>
            </div>
            }


            <div className="text-center mt-4">
              <MDBBtn
                color="default"
                type="submit"
                disabled={!isEnable}
                onClick={this.billRegister}
              >新規請求書登録</MDBBtn>
            </div>
          </form>

          </MDBCol>
        </MDBRow>

        <MDBModal isOpen={this.state.regModal} toggle={this.regModalClose}>
          <MDBModalHeader toggle={this.regModalClose}>請求書登録</MDBModalHeader>
          <MDBModalBody>
            請求書を登録します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.regModalClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.regModalClose} data-confirm={true}>登録</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>登録完了</MDBModalHeader>
          <MDBModalBody>
            請求書を登録しました
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errModal} toggle={this.errModalClose}>
          <MDBModalHeader className="warning-color-dark white-text" toggle={this.errModalClose}>重複確認</MDBModalHeader>
          <MDBModalBody>
            同名の請求書が存在するので処理を中止しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="orange darken-3" onClick={this.errModalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>


      </MDBContainer>
      </>
    )
  }
}
export default Bills;

