import React from "react";
import OrderUrigawa from './OrderUrigawa';
import OrderKaigawa from './OrderKaigawa';

import firebase from "../../firebase"

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
    || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
      || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    return (
      <>
        { urigawa &&
        <OrderUrigawa db={this.props.db} storage={this.props.storage} id={this.props.id} user={this.props.user} org={this.props.org} userOrg={this.props.userOrg} readOrder={(readCount) => this.props.readOrder(readCount)} shippedOrder={(shippedCount) => this.props.shippedOrder(shippedCount)} dateNow={this.props.dateNow} />
        }
        { kaigawa &&
        <OrderKaigawa db={this.props.db} storage={this.props.storage} id={this.props.id} user={this.props.user} org={this.props.org} userOrg={this.props.userOrg} readOrder={(readCount) => this.props.readOrder(readCount)} shippedOrder={(shippedCount) => this.props.shippedOrder(shippedCount)} dateNow={this.props.dateNow} />
        }
      </>
    )
  }
}
export default Order;

