import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBSelect
} from "mdbreact";

import Upload from "../Upload/Upload";


import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';

import "react-datepicker/dist/react-datepicker.css";
import "./Estimates2.css";

import firebase from "../../firebase"
import {toast} from "react-toastify";

registerLocale('ja', ja);
setDefaultLocale('ja')

class Estimates2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estNum: '',
      startDate: new Date(),
      expireDate: '',
      title: '',
      titlekana: '',
      toUser: '',
      toUserCC: [],
      toUserCCName: [],
      userList: [],
      files: [],
      modal: false,
      errModal: false,
      regModal: false,
      totalAmount: null,
    }
  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleTotalAmountChange = (e) => {
    this.setState({
      totalAmount: e.currentTarget.value*1
    });
  }
  handleExpireDateChange = date => {
    this.setState({
      expireDate: date
    });
  }

  handleTitleChange = (e) => {
    this.setState({
      title: e.currentTarget.value
    });
  }
  handleTitleKanaChange = (e) => {
    this.setState({
      titlekana: e.currentTarget.value
    });
  }

  handleToUserChange = (e) => {
    if (e.currentTarget.selectedIndex > 0) {
      this.setState({
        toUserName: e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name,
        toUser: e.currentTarget.value
      }, () => {
        this.setUserList();
      });
    } else {
      this.setState({
        toUserName: '',
        toUser: ''
      }, () => {
        this.setUserList();
      });
    }
  }

  handleToUserCCChange = (e) => {
    let toUserCCName = e.map(userId => {
      return (this.props.org.users.find(data => data.user === userId) || {}).name;
    }) || [];

    this.setState({
      toUserCC: e,
      toUserCCName: toUserCCName
    })
  }

  selectPersonAlert = () => {
    toast.warning("相手先営業担当者を先に選択してください。", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  modalClose = () => {
    this.setState({
      modal: false,
      title: '',
      toUser: '',
      files: [],
    })
    this.refs.UploadComponent.resetFile();
  }
  errModalClose = () => {
    this.setState({
      errModal: false
    })
  }

  estimates2Register = (e) => {
    e.preventDefault();
    this.setState({
      regModal: true
    })
  }

  regModalClose = (e) => {
    this.setState({
      regModal: false
    })

    let esConfirm = e.currentTarget.dataset.confirm;
    if (esConfirm) {
      this.fileUpload(e);
    }
  }

  fileUpload = (e) => {
    e.preventDefault();

    console.log("fileUpload",e);
    console.log(this.state.files[0]);


    if(this.state.files.length > 0){
      const file = this.state.files[0];

      //this.props.storage.refFromURL(this.props.src);
      console.log(file.name);

      var fileRef = this.props.storage.ref().child('estimates2/' + '/' + this.props.user.id + '/' + file.name);

      fileRef.getDownloadURL().then((onResolve) => {
        console.log("Same File Exist");
        this.setState({
          errModal: true,
        })
      }, (onReject) => {
        console.log(fileRef.location.bucket);
        console.log(fileRef.location.path);

        fileRef.put(file).then(function(snapshot) {
          console.log('Uploaded a blob or file!');
        });
        this.dataSet(fileRef);

      });
    }
  }

  dataSet = (snapshot) => {
    let estimates2Ref = this.props.db.collection('estimates2').doc();
    let dueDate = new Date(this.state.startDate);
    let expireDate = new Date(this.state.expireDate);
    let setWithMerge = estimates2Ref.set({
      title: this.state.title,
      number: this.state.estNum,
      titlekana: this.state.titlekana,
      date: firebase.firestore.Timestamp.fromDate(this.state.startDate),
      dueDate: dueDate.getTime(),
      expireDate: firebase.firestore.Timestamp.fromDate(this.state.expireDate),
      toUser: this.state.toUser,
      toUserName: this.state.toUserName,
      toUserCC: this.state.toUserCC,
      toUserCCName: this.state.toUserCCName,
      toOrganization: this.props.org.id,
      fromUser: this.props.user.id,
      fromUserName: this.props.user.last_name + this.props.user.first_name,
      fromOrganization: this.props.user.organization,
      deleted: false,
      readBuyer: false,
      readSeller: false,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      file: snapshot.location.path,
      totalAmount: this.state.totalAmount,
    }, { merge: true });
    this.setState({
      modal: true,
    })
  }

  componentDidMount() {
    if (this.props.org) {
      this.setUserList();
    }
  }

  setUserList = () => {
    let toUserCC = this.state.toUserCC;
    let toUserCCName = this.state.toUserCCName;
    let toUser = this.state.toUser;
    let toUserName = this.state.toUserName;
    let userList = this.props.org.users.map((user) => {
      let found = false;
      for (let i=0; i<toUserCC.length; i++) {
        if (toUserCC[i] === user.user) {
          found = true;
        }
      }

      if (found) {
        return {value: user.user, text: user.name, checked: true}
      } else {
        return {value: user.user, text: user.name}
      }
    });

    toUserCC = toUserCC.filter(v => v !== toUser);
    toUserCCName = toUserCCName.filter(v => v!== toUserName);

    userList = userList.filter(v => v.value !== toUser);
    this.setState({userList, toUserCC, toUserCCName});
  }

  onFilesAdded = (files) => {
    this.setState({
      files: files
    });
    console.log(files);
  }

  handleInputChange = inputName => value => {
    const nextValue = value;
    this.setState({
        [inputName]: nextValue
    });
  };

  handleNumChange = (e) => {
    this.setState({
      estNum: e.currentTarget.value
    });
  }

  render() {
    console.log()
    const isEnable = (this.state.files.length > 0)
    && (this.state.startDate !== '')
        && (this.state.expireDate !== '')
        && (this.state.estNum !== '')
    && (this.state.title !== '')
    && (this.state.titlekana !== '')
    && (this.state.toUser !== '')
        && (this.state.totalAmount !== null)
    if ( (this.props.userOrg && this.props.userOrg.reseller)
      || (this.props.userOrg && this.props.userOrg.maker)
    ){
    }else{
      return (
        <>
        </>
      )
    }

    return (
      <>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="mb-3">
            <h2>見積書登録</h2>
            <form>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <label htmlFor="estNum" className="grey-text">
                見積番号
              </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <input
                  type="text"
                  id="estNum"
                  className="form-control"
                  name="estNum"
                  onChange={this.handleNumChange}
                  value={this.state.estNum}
              />
              <br />
              <div style={{display: 'flex', alignItems: 'center'}}>
            <label htmlFor="estimates2Date" className="grey-text">
              発行日付指定
            </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              id="estimates2Date"
              className="form-control"
              selected={this.state.startDate}
              onChange={this.handleChange}
              locale="ja-JP"
            />
            <br />
              <div style={{display: 'flex', alignItems: 'center'}}>
            <label htmlFor="estimates2ExpireDate" className="grey-text">
              有効期限指定
            </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
            <DatePicker
                dateFormat="yyyy/MM/dd"
                id="estimates2ExpireDate"
                className="form-control"
                selected={this.state.expireDate}
                onChange={this.handleExpireDateChange}
                locale="ja-JP"
            />
            <br />
              <div style={{display: 'flex', alignItems: 'center'}}>
            <label htmlFor="estimates2Title" className="grey-text">
            見積書タイトル
            </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
            <input
              type="text"
              id="estimates2Title"
              className="form-control"
              name="title"
              onChange={this.handleTitleChange}
              value={this.state.title}
            />
              <br />
              <div style={{display: 'flex', alignItems: 'center'}}>
            <label htmlFor="estimates2TitleKana" className="grey-text">
            見積書タイトル（ふりがな）
            </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
            <input
              type="text"
              id="estimates2TitleKana"
              className="form-control"
              name="title"
              onChange={this.handleTitleKanaChange}
              value={this.state.titlekana}
            />
              <br />
              <div style={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor={"totalAmount"} className="grey-text">
                  税込合計金額（円）
                </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
              <input
                  type="number"
                  id="totalAmount"
                  className="form-control"
                  name="totalAmount"
                  maxLength={10}
                  style={{fontWeight: 'bold'}}
                  onChange={this.handleTotalAmountChange}
                  value={this.state.totalAmount}
              />
            <br />
              <div style={{display: 'flex', alignItems: 'center'}}>
            <label htmlFor="estimates2ToUser" className="grey-text">
              相手先担当者選択
            </label>
                &nbsp;
                <span className={'cart-require'} style={{marginBottom: '0.5rem', marginTop: 0}}>必須</span>
              </div>
            <select
                className="browser-default custom-select"
                id="estimates2ToUser"
                onChange={this.handleToUserChange}
                value={this.state.toUser}
            >
              <option>選択してください</option>
              {this.props.org && this.props.org.users &&
                  this.props.org.users.map((user) =>
                      <option key={user.user} value={user.user} data-name={user.name}>{user.name}</option>
                  )
              }
            </select>
            <br />

            <div style={{position: 'relative'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <label htmlFor="estimateToUserCC" className="grey-text">
                相手先CC選択（複数選択可）
              </label>
              &nbsp;
              <span className={'cart-require-any'} style={{marginBottom: '0.5rem', marginTop: 0}}>任意</span>
            </div>
              <MDBSelect disabled={true} id={'estimateToUserCC'} className={'cart-select custom-select'} options={this.state.userList} getValue={this.handleToUserCCChange} multiple={true}/>
              {!this.state.toUser && <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, cursor: 'pointer'}} onClick={() => this.selectPersonAlert()}/>}
            </div>
            <br/>

            <label htmlFor="estimates2File" className="grey-text">
            見積書 PDF選択
            </label>
            <Upload onFilesAdded={this.onFilesAdded} ref="UploadComponent" />

            {false &&
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="estimates2File"
                  aria-describedby="inputGroupFileAddon01"
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  ファイルを選択してください
                </label>
              </div>
            </div>
            }


            <div className="text-center mt-4">
              <MDBBtn
                color="default"
                type="submit"
                disabled={!isEnable}
                onClick={this.estimates2Register}
              >新規見積書登録</MDBBtn>
            </div>
          </form>

          </MDBCol>
        </MDBRow>

        <MDBModal isOpen={this.state.regModal} toggle={this.regModalClose}>
          <MDBModalHeader toggle={this.regModalClose}>見積書登録</MDBModalHeader>
          <MDBModalBody>
            見積書を登録します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.regModalClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.regModalClose} data-confirm={true}>登録</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>登録完了</MDBModalHeader>
          <MDBModalBody>
          見積書を登録しました
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errModal} toggle={this.errModalClose}>
          <MDBModalHeader className="warning-color-dark white-text" toggle={this.errModalClose}>重複確認</MDBModalHeader>
          <MDBModalBody>
            同名の見積書が存在するので処理を中止しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="orange darken-3" onClick={this.errModalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>


      </MDBContainer>
      </>
    )
  }
}
export default Estimates2;

