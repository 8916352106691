import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact';


class ItemsListCell extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    const style = {marginBottom: 10};

    return (
    <MDBCol md="4" style={style}>
      <MDBCard style={{ height: "100%" }}>
        <MDBCardImage className="img-fluid" src={this.props.image} waves />
        <MDBCardBody>
          <MDBCardTitle>{this.props.title}</MDBCardTitle>
          <MDBCardText>
            {this.props.description}
          </MDBCardText>
          <MDBCardText>
            参考価格 {this.props.price}円
          </MDBCardText>
          <MDBBtn href="#">カートに入れる</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    )
  }
}
export default ItemsListCell;