import React, { Component } from 'react';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';

class UserLogin extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
    <MDBModal isOpen={!this.props.isLogin && this.props.companyLogin}>
      <MDBModalHeader
        className="text-center teal lighten-1 white-text"
        titleClass="w-100 font-weight-bold"
      >
       ユーザーログイン
      </MDBModalHeader>
      <MDBModalBody>
          <form>
            <div className="grey-text">
              <MDBInput
                label="ユーザーID"
                icon="user"
                group
                type="text"
                validate
                error="wrong"
                success="right"
              />
              <MDBInput
                label="パスワード"
                icon="lock"
                group
                type="password"
                validate
              />
            </div>
          </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <MDBBtn
          color="info"
          onClick={() => {
          this.props.login();
          }}
        >
          ログイン
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    );
  }
}

export default UserLogin;