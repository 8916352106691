import React, { Component } from 'react';
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBSpinner
} from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"

class CartSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.isOpen,
      carts: [],
      loading: false
    }
  }

  toggle = (added) => {
    this.setState({
      modal: !this.state.modal
    }, () => {
      this.props.isOpenEvent(this.state.modal, added);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen){
      this.setState({
        modal: this.props.isOpen,
      });

      if (this.props.isOpen) {
        this.getCarts();
      }
    }
  }

  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && collection){
      this.setState({loading: true});
      let cRef = this.props.db.collection('carts').doc(this.props.user.id).collection('carts').doc(this.props.org.id);
      let c = await cRef.get();
      let cData = c.data();

      if (cData) {
        // let withDeletedItem = false;
        for (let i=0; i<5; i++) {
          let cart = cData[i];
          if (cart && cart.items) {
            let items = cart.items;
            delete cart['deletedItem'];
            delete cart['deletedText'];
            await Promise.all(items.map(async (item) => {
              let itemSell = await collection.doc(item.id).get();
              let itemSellData = itemSell.data();

              delete item['deletedItem'];
              delete item['deletedText'];

              if (!itemSellData) {
                cart.deletedItem = true;
                cart.deletedText = '販売終了商品が含まれています';
                item.deletedItem = true;
                item.deletedText = '販売終了商品が含まれています';
              } else if (!itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                cart.deletedItem = true;
                cart.deletedText = '要見積商品が含まれています';
                item.deletedItem = true;
                item.deletedText = '要見積商品が含まれています';
              } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                cart.deletedItem = true;
                cart.deletedText = '要見積商品が含まれています';
                item.deletedItem = true;
                item.deletedText = '要見積商品が含まれています';
              } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                let expiration_date = itemSellData['price'][0]['expiration_date'];
                let expDate = expiration_date.toDate();

                if ((expDate.getTime() - this.props.dateNow) < 0) {
                  cart.deletedItem = true;
                  cart.deletedText = '価格有効期限切れ要見積';
                  item.deletedItem = true;
                  item.deletedText = '価格有効期限切れ要見積';
                } else {
                  item.price = itemSellData['price'][0]['price'];
                }
              } else {
                item.price = itemSellData['price'][0]['price'];
              }

              // if (itemSellData && itemSellData['price']) {
              //   item.price = itemSellData['price'][0]['price'];
              // } else {
              //   // withDeletedItem = true;
              //   // cart.items = cart.items.filter((obj) => obj.id !== item.id);
              // }
            }));
          }
        }

        // if (withDeletedItem) {
        //   cRef.set(cData, {merge: true});
        // }
        this.setState({carts: cData})
      }

      this.setState({loading: false})

      // this.props.db.collection('carts').doc(this.props.user.id)
      //   .collection('carts').doc(this.props.org.id)
      //   .get()
      //   .then((doc) => {
      //     if(doc.exists) {
      //       let data = doc.data();
      //       this.setState({carts: data})
      //     }
      //   })
    }
  }

  componentDidMount() {
    this.getCarts();
  }

  addCart = (e) => {
    const cartId = e.currentTarget.dataset.id;

    let carts = this.state.carts;
    if(!carts[cartId]){
      carts[cartId] = {items: []}
    }

    let cart = carts[cartId].items;
    if(!cart){
      cart=[];
    }

    let date = firebase.firestore.Timestamp.fromDate(new Date());

    const cart_filter_result =  cart.filter(item => item.name === this.props.data['P4CLOUD商品名'])
    if (cart_filter_result.length === 0) {
      cart.push({
        name: this.props.data['P4CLOUD商品名'] ? this.props.data['P4CLOUD商品名'] : '',
        maker: this.props.data['正式メーカー名'] ? this.props.data['正式メーカー名'] : '',
        code: this.props.data['販売企業商品コード'] ? this.props.data['販売企業商品コード'] : '',
        sellercode: this.props.data['仕入先事業所コード'] ? this.props.data['仕入先事業所コード'] : '',
        sellername: this.props.data['仕入先事業所名'] ? this.props.data['仕入先事業所名'] : '',
        makeredicode: this.props.data['仕入先EDIコード商品コード'] ? this.props.data['仕入先EDIコード商品コード'] : '',
        makerordercode: this.props.data['仕入先発注コード'] ? this.props.data['仕入先発注コード'] : '',
        price: this.props.data['price'][0]['price'] ? this.props.data['price'][0]['price'] : 0,
        tax: this.props.data['消費税率'] ? this.props.data['消費税率'] : 0,
        id: this.props.data.id ? this.props.data.id : this.props.data.objectID,
        count:1,
        update: date,
      });
    } else {
      cart_filter_result[0].count = cart_filter_result[0].count+1;
      cart_filter_result[0].update = date;
    }

    carts[cartId].items = cart;
    carts[cartId].rejected = false;

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true});

    let setWithMerge = cartRef.set({
      [cartId]: carts[cartId],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then((doc) => {
          this.toggle(true);
        });
  }

  render() {
    let cart = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    for(let i=0; i<5; i++){
      if(this.state.carts[i] && this.state.carts[i].items && this.state.carts[i].items.length > 0){
        let items = this.state.carts[i].items;
        let date = '---';
        let amount = 0;
        let name=<></>;
        let lastItemChangeTime = 0;
        let deletedItem = this.state.carts[i].deletedItem;
        let deletedText = this.state.carts[i].deletedText;
        let d = [];
        let deletedDescription = '';

        // let lastItemChangeDate = '';
        items.forEach((item, index) => {
          // console.log()
          if (lastItemChangeTime < item.update.toDate().getTime()) {
            lastItemChangeTime = item.update.toDate().getTime();

            date = item.update.toDate().getFullYear() + '/'
                + n(item.update.toDate().getMonth() + 1) + '/'
                + n(item.update.toDate().getDate())+ ' '
                + n(item.update.toDate().getHours()) + ':'
                + n(item.update.toDate().getMinutes());
          }
          if (!item.deletedItem) {
            amount += (item.price * item.count);
          } else {
            if (!d.includes(item.deletedText)) {
              d.push(item.deletedText)
              deletedDescription = <>{deletedDescription}<span style={{color: 'red'}}>{item.deletedText}</span><br /></>
            }
          }
          if(name != ''){
            name = <>{name}{item.name}<br /></>;
          }else{
            name = <>{item.name}<br /></>;
          }

        });

        if (deletedItem) {
          name = <>{name}{deletedDescription}</>
        }

        cart.push({
          'no': <><MDBIcon icon="shopping-cart" size="2x" className="indigo-text mr-3" />カート{i+1}</>,
          '商品名': name,
          '金額': <div style={{textAlign: 'right'}}>{amount > 0 && amount.toLocaleString()}</div>,
          'date': date,
          btn: (this.state.carts[i].requested ?
                <MDBBtn size="sm4" className="text-nowrap"
                onClick={this.addCart}
                disabled={true}
                color="warning"
                data-id={i}
                >承認待</MDBBtn>
                :
                <MDBBtn size="sm4" className="text-nowrap"
                  onClick={this.addCart}
                  data-id={i}
                >選択</MDBBtn>),
        })
      }else{
        cart.push({
          'no': <><MDBIcon icon="shopping-cart" size="2x" className="indigo-text mr-3" />カート{i+1}</>,
          '商品名': 'カートは空です',
          '金額': <div style={{textAlign: 'right'}}>---</div>,
          'date': '---',
          btn: <MDBBtn size="sm4" className="text-nowrap"
                  onClick={this.addCart}
                  data-id={i}
                >選択</MDBBtn>,
        })
      }
    }

    const data = {
      columns: [
        {
          label: 'カート番号',
          field: 'no',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: '商品名',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: '金額',
          sort: 'amount',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '保存日時',
          field: 'date',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '選択',
          field: 'btn',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      //rows: this.state.dbItems

      rows: cart
    }


    return (
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>カート選択</MDBModalHeader>
      <MDBModalBody>
        カートを選択してください
        <MDBTable btn responsive>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          {!this.state.loading && <MDBTableBody rows={data.rows} />}
        </MDBTable>

        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          className="btn-lg btn-block"
          color="secondary"
          onClick={this.toggle}
        >キャンセル</MDBBtn>
      </MDBModalFooter>
    </MDBModal>



    );
  }
}

export default CartSelect;
