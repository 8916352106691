import React, {useState, useEffect} from "react";
import moment from "moment";
import {MDBBtn, MDBSpinner, MDBIcon} from "mdbreact";
import DatePicker from "react-datepicker";
import Measurements from "./Components/Measurements";
import DrawMeasureChart from "./Components/DrawMeasureChart";
import {useFieldArray, useForm} from "react-hook-form";
import {format} from 'date-fns';
import firebase from "../../firebase";
import * as Excel from "exceljs";
import {saveAs} from "file-saver";

const PreviewMeasurement = (props) => {
    const [data, setData] = useState({});
    const [processData, setProcessData] = useState([]);
    const [title, setTitle] = useState('測定データ');
    const [title1, setTitle1] = useState('現地測定値');
    const [title2, setTitle2] = useState('ラボ測定値');
    const [title3, setTitle3] = useState('自動測定値');
    const [whichIsClicked, setWhichIsClicked] = useState('none');
    const [whichIsClickedPrev, setWhichIsClickedPrev] = useState('none');
    const [insertClicked, setInsertClicked] = useState(false);
    const [insertionDate, setInsertionDate] = useState(new Date());
    const [reloader, setReloader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchChart, setSearChart] = useState(90);
    const [visibleUrl, setVisibleUrl] = useState(false);
    const [reloaderMeasurement, setReloaderMeasurement] = useState(false);
    const [appendItem, setAppendItem] = useState(null);
    const [appendComment, setAppendComment] = useState(null);
    const [removeMeasure, setRemoveMeasure] = useState([]);
    const [limit, setLimit] = useState(200);
    const [enableNext, setEnableNext] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [processId, setProcessId] = useState("");
    const [loaderProcess, setLoaderProcess] = useState(false);
    const [selectedTypeXlsx, setSelectedTypeXlsx] = useState("selectDateExcel");
    const [loaderXlsx, setLoaderXlsx] = useState(false);
    const [startDateExcel, setStartDateExcel] = useState(null);
    const [endDateExcel, setEndDateExcel] = useState(null);
    useEffect(() => {
        loadData()
    }, [reloader])
    useEffect(() => {
        searchChart === "selectDate" && searchChart && endDate && handleSearchChart(0, searchChart)
    }, [startDate, endDate])
    const startAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd 00:00:00:00.000');
        return new Date(parsed);
    };

    const endAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd 23:59:59:00.000');
        return new Date(parsed);
    };
    const loadData = async () => {
        try {
            //console.log('searchChart', searchChart);
            let fromOrg = "";
            let toOrg = "";
            if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
                //console.log('logged seller')
                toOrg = props.org.id;
                fromOrg = props.user.organization;
            } else if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
                //console.log('logged maker')
                toOrg = props.org.id;
                fromOrg = props.user.organization;
            } else if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
                //console.log('props maker')
                toOrg = props.org.id;
                //fromOrg = props.user.organization;
            } else if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
                //console.log('logged buyer')
                //fromOrg = props.org.id;
                toOrg = props.user.organization;
            } else if (props.orgSeller && props.user && props.user.admin) {
                //console.log("props.orgSeller.id", props.orgSeller.id)
                fromOrg = props.orgSeller.id;
            }
            /*else {
                fromOrg = props.user.organization;
            }*/
            setLoading(true);
            const snap = await props.db.collection('measurement').doc(props.cProps.match.params.id).get();
            let tData;
            tData = {id: snap.id, process: [], ...snap.data()};
            let checkOrg = tData.measureOrgs.filter(ele => [fromOrg, toOrg].includes(ele));
            if (checkOrg && checkOrg.length > 0) {
                setVisibleUrl(true);
            }
            setData(tData);

            let process = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').orderBy('index', 'asc').get();
            let pData = [];
            if (process.docs.length > 0) {
                process.docs.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    pData.push(data)
                })
            }
            setProcessData(pData);
            setLoading(false);
            //return tData;
        } catch (e) {
            console.log('error -> ', e);
            setLoading(false);
        }
    }
    const loadProcessData = async (month, id) => {
        try {
            setLoading(true);
            setLoaderProcess(true);
            //let tData = {process:[]};
            let process = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(id).get();
            let pData = [];
            pData = [{
                id: process.id,
                select: month,
                itemsIds: [],
                items: [],
                fieldComments: [],
                labComments: [],
                automaticComments: [],
                fieldMeasureIds: [],
                labMeasureIds: [],
                automaticMeasureIds: [],
                fieldCommentsIds: [],
                labCommentsIds: [],
                automaticCommentsIds: [],
                fieldNotMore: false,
                labNotMore: false,
                automaticNotMore: false, ...process.data()
            }];
            let items = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('items').orderBy('index', 'asc').get();
            let itemData = [];
            if (items.docs.length > 0) {
                for (let i1 = 0; i1 < items.docs.length; i1++) {
                    let doc = items.docs[i1];
                    let data1 = doc.data();
                    data1.id = doc.id;
                    data1.processId = pData[0].id;
                    data1.fieldMeasure = [];
                    data1.labMeasure = [];
                    data1.automaticMeasure = [];
                    pData[0].itemsIds.push(data1.id);
                    itemData.push(data1)
                }
            }
            await Promise.all([
                props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('fieldComments')
                    .where("insertedDate", ">=", startAt((new Date()).setDate((new Date()).getDate() - month))).where("insertedDate", "<=", endAt(new Date())).limit(limit)
                    //.orderBy('insertedDate', 'asc')
                    .get(),
                props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('labComments')
                    .where("insertedDate", ">=", startAt((new Date()).setDate((new Date()).getDate() - month))).where("insertedDate", "<=", endAt(new Date())).limit(limit)
                    //.orderBy('insertedDate', 'asc')
                    .get(),
                props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('automaticComments')
                    .where("insertedDate", ">=", startAt((new Date()).setDate((new Date()).getDate() - month))).where("insertedDate", "<=", endAt(new Date())).limit(limit)
                    //.orderBy('insertedDate', 'asc')
                    .get()
            ])
                .then((res) => {
                    if (res[0].docs.length > 0) {
                        pData[0].fieldCommentsIds.push(res[0].docs[res[0].docs.length - 1]);
                        res[0].docs.forEach((doc) => {
                            let fcommentData = doc.data();
                            fcommentData.id = doc.id;
                            fcommentData.processId = pData[0].id;
                            pData[0].fieldComments.push(fcommentData)
                        })
                        if (res[0].docs.length < limit) pData[0].fieldNotMore = true;
                    } else {
                        pData[0].fieldNotMore = true;
                    }
                    if (res[1].docs.length > 0) {
                        pData[0].labCommentsIds.push(res[1].docs[res[1].docs.length - 1]);
                        res[1].docs.forEach((doc) => {
                            let lcommentData = doc.data();
                            lcommentData.id = doc.id;
                            lcommentData.processId = pData[0].id;
                            pData[0].labComments.push(lcommentData)
                        })
                        if (res[1].docs.length < limit) pData[0].labNotMore = true;
                    } else {
                        pData[0].labNotMore = true;
                    }
                    if (res[2].docs.length > 0) {
                        pData[0].automaticCommentsIds.push(res[2].docs[res[2].docs.length - 2]);
                        res[2].docs.forEach((doc) => {
                            let acommentData = doc.data();
                            acommentData.id = doc.id;
                            acommentData.processId = pData[0].id;
                            pData[0].automaticComments.push(acommentData)
                        })
                        if (res[2].docs.length < limit) pData[0].automaticNotMore = true;
                    } else {
                        pData[0].automaticNotMore = true;
                    }
                })
            if (itemData && itemData.length > 0) {
                for (let j = 0; j < itemData.length; j++) {
                    if (pData[0].id === itemData[j].processId) {
                        pData[0].items.push(itemData[j]);
                        await Promise.all([
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('items').doc(itemData[j].id).collection('fieldMeasure')
                                .where("insertedDate", ">=", startAt((new Date()).setDate((new Date()).getDate() - month))).where("insertedDate", "<=", endAt(new Date())).limit(limit)
                                .get(),
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('items').doc(itemData[j].id).collection('labMeasure')
                                .where("insertedDate", ">=", startAt((new Date()).setDate((new Date()).getDate() - month))).where("insertedDate", "<=", endAt(new Date())).limit(limit)
                                .get(),
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[0].id).collection('items').doc(itemData[j].id).collection('automaticMeasure')
                                .where("insertedDate", ">=", startAt((new Date()).setDate((new Date()).getDate() - month))).where("insertedDate", "<=", endAt(new Date())).limit(limit)
                                .get()
                        ])
                            .then((res) => {
                                if (res[0].docs.length > 0) {
                                    pData[0].fieldMeasureIds.push(res[0].docs[res[0].docs.length - 1]);
                                    res[0].docs.forEach((doc) => {
                                        let fData = doc.data();
                                        fData.id = doc.id;
                                        fData.processId = pData[0].id;
                                        fData.itemsId = itemData[j].id;
                                        pData[0].items[j].fieldMeasure.push(fData)
                                    })
                                }
                                if (res[1].docs.length > 0) {
                                    pData[0].labMeasureIds.push(res[1].docs[res[1].docs.length - 1]);
                                    res[1].docs.forEach((doc) => {
                                        let lData = doc.data();
                                        lData.id = doc.id;
                                        lData.processId = pData[0].id;
                                        lData.itemsId = itemData[j].id;
                                        pData[0].items[j].labMeasure.push(lData);
                                    })
                                }
                                if (res[2].docs.length > 0) {
                                    pData[0].automaticMeasureIds.push(res[2].docs[res[2].docs.length - 1]);
                                    res[2].docs.forEach((doc) => {
                                        let aData = doc.data();
                                        aData.id = doc.id;
                                        aData.processId = pData[0].id;
                                        aData.itemsId = itemData[j].id;
                                        pData[0].items[j].automaticMeasure.push(aData)
                                    })
                                    //}
                                }
                            })
                    }
                }
            }
            setValue(`fieldUpdate`, {})
            setValue(`labUpdate`, {})
            setValue(`automaticUpdate`, {})
            setValue(`id`, data.id)
            setValue(`process`, pData);
            //console.log('tData', tData);
            setLoading(false);
            setLoaderProcess(false);
        } catch (e) {
            console.log('error -> ', e);
            setLoading(false);
            setLoaderProcess(false);
        }
    }
    const {register, handleSubmit, control, getValues, setValue, reset,} = useForm({
        // defaultValues: {}; you can populate the fields by this attribute
        /*defaultValues: useEffect(async () => {
           await loadData();
        }, [reloader,searchChart])*/
        //defaultValues: async ()=> loadData(searchChart)
    });
    const {fields} = useFieldArray({
        control,
        name: "process",
    })

    const selectType = (type) => {
        setWhichIsClicked(type);
        type !== 'preview' && setWhichIsClickedPrev(type);
        switch (type) {
            case 'field':
                setTitle('現地測定値　データ入力');
                break;
            case 'lab':
                setTitle('ラボ測定値　データ入力');
                break;
            case 'automatic':
                setTitle('自動測定値　データ入力');
                break;
            case 'preview':
                setTitle('測定データプレビュー');
                break;
            default:
                setTitle('測定データ');
                break;
        }
        setInsertClicked(type === 'preview' ? false : true);
        window.scrollTo(0, 0);
    }

    /*const goBack = () => {
        history.goBack();
    }*/
    const addValueRows = async (type) => {
        setAppendItem({value: '', insertedDate: insertionDate, edit: false});
        setAppendComment({comment: '', insertedDate: insertionDate, edit: false});
        setReloaderMeasurement(!reloaderMeasurement)
    }

    const dateHandler = (date) => {
        /*toast.success("カートへの追加が完了しました。", {
            position: toast.POSITION.TOP_RIGHT
        });*/
        setInsertionDate(date);
    }

    const submitHandler = () => {
        setRemoveMeasure([]);
        setInsertClicked(false);
        setWhichIsClicked('none');
        setTitle('測定データ');
        window.scrollTo(0, 0);
    }

    const cancelHandler = () => {
        // console.log('click cancel');
        setReloader(!reloader);
        // if (whichIsClicked === 'none') {
        //     props.cProps.history.push("/measurement")
        // } else {
        setRemoveMeasure([]);
        setInsertClicked(false);
        setWhichIsClicked('none');
        setTitle('測定データ');
        // }
        //reset(dataDetail);
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }

    const cancelHandlerPreview = () => {
        setInsertClicked(true);
        setWhichIsClicked(whichIsClickedPrev);
        switch (whichIsClickedPrev) {
            case 'field':
                setTitle('現地測定値　データ入力');
                break;
            case 'lab':
                setTitle('ラボ測定値　データ入力');
                break;
            case 'automatic':
                setTitle('自動測定値　データ入力');
                break;
            case 'preview':
                setTitle('測定データプレビュー');
                break;
            default:
                setTitle('測定データ');
                break;
        }
        window.scrollTo(0, 0);
    }


    const submitData = async (data) => {
        try {
            setLoading(true);
            window.scrollTo(0, 0);
            const newData = {...data};
            newData[`${whichIsClicked}Update`].user = props.user.id;
            newData[`${whichIsClicked}Update`].last_name = props.user.last_name;
            newData[`${whichIsClicked}Update`].first_name = props.user.first_name;
            newData[`${whichIsClicked}Update`].org = props.user.organization;
            newData[`${whichIsClicked}Update`].org_name = props.userOrg.display_name;
            newData[`${whichIsClicked}Update`].updated_date = new Date();
            if (removeMeasure && removeMeasure.length > 0 && removeMeasure.filter(e => e.which === whichIsClicked).length > 0) {
                let measure = removeMeasure.filter(e => e.which === whichIsClicked);
                //console.log('measure', measure);
                for (let a = 0; a < measure.length; a++) {
                    if (measure[a].itemId !== undefined) {
                        await props.db.collection('measurement').doc(newData.id).collection('process').doc(measure[a].processId).collection('items').doc(measure[a].itemId).collection(`${whichIsClicked}Measure`).doc(measure[a].id).delete();
                    } else {
                        measure[a].id !== undefined && await props.db.collection('measurement').doc(newData.id).collection('process').doc(measure[a].processId).collection(`${whichIsClicked}Comments`).doc(measure[a].id).delete();
                    }
                }
            }
            //merge measures

            //await props.db.collection('measurement').doc(newData.id).update({fieldUpdate: newData.fieldUpdate});
            switch (whichIsClicked) {
                case 'field':
                    await props.db.collection('measurement').doc(newData.id).update({fieldUpdate: newData.fieldUpdate});
                    break;
                case 'lab':
                    await props.db.collection('measurement').doc(newData.id).update({labUpdate: newData.labUpdate});
                    break;
                case 'automatic':
                    await props.db.collection('measurement').doc(newData.id).update({automaticUpdate: newData.automaticUpdate});
                    break;
            }
            for (let i = 0; i < newData.process.length; i++) {
                //FieldComments merge
                for (let c = 0; c < newData.process[i][`${whichIsClicked}Comments`].length; c++) {
                    //console.log('newData.process[i].fieldComments[c]', newData.process[i][`${whichIsClicked}Comments`][c]);
                    if (newData.process[i][`${whichIsClicked}Comments`][c].id === undefined) {
                        let fieldComments = await props.db.collection('measurement').doc(newData.id).collection('process').doc(newData.process[i].id).collection(`${whichIsClicked}Comments`).doc();
                        await fieldComments.set({
                            edit: newData.process[i][`${whichIsClicked}Comments`][c].edit,
                            insertedDate: newData.process[i][`${whichIsClicked}Comments`][c].insertedDate,
                            comment: newData.process[i][`${whichIsClicked}Comments`][c].comment,
                        }, {merge: true});
                        newData.process[i][`${whichIsClicked}Comments`][c].id = fieldComments.id;
                        newData.process[i][`${whichIsClicked}Comments`][c].insertedDate = firebase.firestore.Timestamp.fromDate(newData.process[i][`${whichIsClicked}Comments`][c].insertedDate);
                    } else {
                        if (newData.process[i][`${whichIsClicked}Comments`][c].changed !== undefined) {
                            await props.db.collection('measurement').doc(data.id).collection('process').doc(newData.process[i].id).collection(`${whichIsClicked}Comments`).doc(newData.process[i][`${whichIsClicked}Comments`][c].id).update({
                                edit: newData.process[i][`${whichIsClicked}Comments`][c].edit,
                                insertedDate: newData.process[i][`${whichIsClicked}Comments`][c].insertedDate,
                                comment: newData.process[i][`${whichIsClicked}Comments`][c].comment,
                            });
                            delete newData.process[i][`${whichIsClicked}Comments`][c].changed;
                        }
                    }
                }
                //FieldMeasure merge
                for (let j = 0; j < newData.process[i].items.length; j++) {
                    for (let m = 0; m < newData.process[i].items[j][`${whichIsClicked}Measure`].length; m++) {
                        if (newData.process[i].items[j][`${whichIsClicked}Measure`][m].id === undefined) {
                            let fieldMeasure = await props.db.collection('measurement').doc(newData.id).collection('process').doc(newData.process[i].id).collection('items').doc(newData.process[i].items[j].id).collection(`${whichIsClicked}Measure`).doc();
                            await fieldMeasure.set({
                                edit: newData.process[i].items[j][`${whichIsClicked}Measure`][m].edit,
                                insertedDate: newData.process[i].items[j][`${whichIsClicked}Measure`][m].insertedDate,
                                value: newData.process[i].items[j][`${whichIsClicked}Measure`][m].value,
                            }, {merge: true});
                            newData.process[i].items[j][`${whichIsClicked}Measure`][m].id = fieldMeasure.id;
                            newData.process[i].items[j][`${whichIsClicked}Measure`][m].insertedDate = firebase.firestore.Timestamp.fromDate(newData.process[i].items[j][`${whichIsClicked}Measure`][m].insertedDate);
                            newData.process[i].items[j][`${whichIsClicked}Measure`][m].itemsId = newData.process[i].items[j].id;
                            newData.process[i].items[j][`${whichIsClicked}Measure`][m].processId = newData.process[i].id;
                        } else {
                            if (newData.process[i].items[j][`${whichIsClicked}Measure`][m].changed !== undefined) {
                                await props.db.collection('measurement').doc(data.id).collection('process').doc(newData.process[i].id).collection('items').doc(newData.process[i].items[j].id).collection(`${whichIsClicked}Measure`).doc(newData.process[i].items[j][`${whichIsClicked}Measure`][m].id).update({
                                    edit: newData.process[i].items[j][`${whichIsClicked}Measure`][m].edit,
                                    insertedDate: newData.process[i].items[j][`${whichIsClicked}Measure`][m].insertedDate,
                                    value: newData.process[i].items[j][`${whichIsClicked}Measure`][m].value,
                                });
                                delete newData.process[i].items[j][`${whichIsClicked}Measure`][m].changed;
                            }
                        }
                    }
                }
            }
            setValue(`process`, newData.process);
            submitHandler();
            //setDataDetail(newData)
            return setLoading(false)
            /* await props.db.collection('measurement').doc(data.id).update(data);*/
        } catch (e) {
            setLoading(false)
            console.log('error atta while updating', e);
            setLoading(false)
        }
    }

    const dateFormatter = (date) => {
        const format = 'YYYY/MM/DD HH:mm';
        if (moment(date).isValid()) {
            return moment(date).format(format);
        }
        return moment(date.toDate()).format(format);
    }
    let selectOption = <>
        <option value={1}>直近24時間</option>
        <option value={14}>直近2週間</option>
        <option value={30}>直近1か月</option>
        <option value={60}>直近2か月</option>
        <option value={90}>直近3か月</option>
        <option value={"selectDate"}>カスタム</option>
    </>;
    const handleSearchChart = async (e, month) => {
        setLoading(true)
        //let month = getValues(`process[${e}].select`);
        let processId = getValues(`process[${e}].id`);
        let fieldMeasureIds = [];
        let labMeasureIds = [];
        let automaticMeasureIds = [];
        let fieldCommentsIds = [];
        let labCommentsIds = [];
        let automaticCommentsIds = [];
        setValue(`process[${e}].fieldNotMore`, false);
        setValue(`process[${e}].labNotMore`, false);
        setValue(`process[${e}].automaticNotMore`, false);
        setEnableNext(true);
        let beginDate;
        let lastDate;
        switch (month) {
            case 'selectDate':
                beginDate = startAt(startDate);
                lastDate = endAt(endDate);
                break;
            default:
                beginDate = startAt((new Date()).setDate((new Date()).getDate() - month));
                lastDate = endAt(new Date());
                break;
        }
        await Promise.all([
            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('fieldComments')
                .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                .get(),
            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('labComments')
                .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                .get(),
            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('automaticComments')
                .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                .get()
        ])
            .then((res) => {
                if (res[0].docs.length > 0) {
                    let fieldCommentsData = [];
                    fieldCommentsIds.push(res[0].docs[res[0].docs.length - 1])
                    res[0].docs.forEach((doc) => {
                        let fcommentData = doc.data();
                        fcommentData.id = doc.id;
                        fcommentData.processId = processId;
                        fieldCommentsData.push(fcommentData)
                    })
                    setValue(`process[${e}].fieldComments`, fieldCommentsData);
                    if (res[0].docs.length < limit) setValue(`process[${e}].fieldNotMore`, true);
                } else {
                    setValue(`process[${e}].fieldNotMore`, true);
                    setValue(`process[${e}].fieldComments`, []);
                }
                if (res[1].docs.length > 0) {
                    let labCommentsData = [];
                    labCommentsIds.push(res[1].docs[res[1].docs.length - 1])
                    res[1].docs.forEach((doc) => {
                        let lcommentData = doc.data();
                        lcommentData.id = doc.id;
                        lcommentData.processId = processId;
                        labCommentsData.push(lcommentData)
                    })
                    setValue(`process[${e}].labComments`, labCommentsData);
                    if (res[1].docs.length < limit) setValue(`process[${e}].labNotMore`, true);
                } else {
                    setValue(`process[${e}].labNotMore`, true);
                    setValue(`process[${e}].labComments`, []);
                }
                if (res[2].docs.length > 0) {
                    let automaticCommentsData = [];
                    automaticCommentsIds.push(res[2].docs[res[2].docs.length - 1])
                    res[2].docs.forEach((doc) => {
                        let acommentData = doc.data();
                        acommentData.id = doc.id;
                        acommentData.processId = processId;
                        automaticCommentsData.push(acommentData);
                    })
                    setValue(`process[${e}].automaticComments`, automaticCommentsData);
                    if (res[2].docs.length < limit) setValue(`process[${e}].automaticNotMore`, true);
                } else {
                    setValue(`process[${e}].automaticNotMore`, true);
                    setValue(`process[${e}].automaticComments`, []);
                }
            })
        setValue(`process[${e}].fieldCommentsIds`, fieldCommentsIds);
        setValue(`process[${e}].labCommentsIds`, labCommentsIds);
        setValue(`process[${e}].automaticCommentsIds`, automaticCommentsIds);
        //}
        if (getValues(`process[${e}].itemsIds`).length > 0) {
            for (let i = 0; i < getValues(`process[${e}].itemsIds`).length; i++) {
                let itemId = getValues(`process[${e}].itemsIds[${i}]`);
                await Promise.all([
                    props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemId).collection('fieldMeasure')
                        .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                        //.orderBy('insertedDate', 'asc')
                        .get(),
                    props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemId).collection('labMeasure')
                        .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                        //.orderBy('insertedDate', 'asc')
                        .get(),
                    props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemId).collection('automaticMeasure')
                        .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                        //.orderBy('insertedDate', 'asc')
                        .get()
                ])
                    .then((res) => {
                        if (res[0].docs.length > 0) {
                            let fieldMeasure = [];
                            fieldMeasureIds.push(res[0].docs[res[0].docs.length - 1]);
                            res[0].docs.forEach((doc) => {
                                //let doc = res[0].docs[index];
                                let fData = doc.data();
                                fData.id = doc.id;
                                fData.processId = processId;
                                fData.itemsId = itemId;
                                fieldMeasure.push(fData);
                            })
                            setValue(`process[${e}].items[${i}].fieldMeasure`, fieldMeasure);
                        } else {
                            setValue(`process[${e}].fieldNotMore`, true);
                            setValue(`process[${e}].items[${i}].fieldMeasure`, []);
                        }
                        if (res[1].docs.length > 0) {
                            let labMeasure = [];
                            labMeasureIds.push(res[1].docs[res[1].docs.length - 1]);
                            res[1].docs.forEach((doc) => {
                                let lData = doc.data();
                                lData.id = doc.id;
                                lData.processId = processId;
                                lData.itemsId = itemId;
                                labMeasure.push(lData)
                            })
                            setValue(`process[${e}].items[${i}].labMeasure`, labMeasure);
                        } else {
                            setValue(`process[${e}].labNotMore`, true);
                            setValue(`process[${e}].items[${i}].labMeasure`, []);
                        }
                        if (res[2].docs.length > 0) {
                            let automaticMeasure = [];
                            automaticMeasureIds.push(res[2].docs[res[2].docs.length - 1]);
                            res[2].docs.forEach((doc) => {
                                let aData = doc.data();
                                aData.id = doc.id;
                                aData.processId = processId;
                                aData.itemsId = itemId;
                                automaticMeasure.push(aData);
                            })
                            setValue(`process[${e}].items[${i}].automaticMeasure`, automaticMeasure);
                        } else {
                            setValue(`process[${e}].automaticNotMore`, true);
                            setValue(`process[${e}].items[${i}].automaticMeasure`, []);
                        }
                    })
                setValue(`process[${e}].fieldMeasureIds`, fieldMeasureIds);
                setValue(`process[${e}].labMeasureIds`, labMeasureIds);
                setValue(`process[${e}].automaticMeasureIds`, automaticMeasureIds);
            }
        }
        setEnableNext(false);
        return setLoading(false);
    }
    const nextReloadData = async (e) => {
        if (getValues(`process[${e.processIndex}][${e.which}NotMore]`)) return;
        try {
            setLoading(true)
            setEnableNext(true);
            let month = getValues(`process[${e.processIndex}].select`);
            let id = getValues(`id`);
            let processId = e.processId;
            let measureIds = [];
            let commentIds = [];
            let lastIndexComment = getValues(`process[${e.processIndex}][${e.which}Comments]`).length;
            let beginDate;
            let lastDate;
            switch (searchChart) {
                case 'selectDate':
                    beginDate = startAt(startDate);
                    lastDate = endAt(endDate);
                    break;
                default:
                    beginDate = startAt((new Date()).setDate((new Date()).getDate() - month));
                    lastDate = endAt(new Date());
                    break;
            }
            if (getValues(`process[${e.processIndex}].itemsIds`).length > 0) {
                for (let i = 0; i < getValues(`process[${e.processIndex}].itemsIds`).length; i++) {
                    if (getValues(`process[${e.processIndex}][${e.which}MeasureIds[${i}]`) !== undefined) {
                        let itemId = getValues(`process[${e.processIndex}].itemsIds[${i}]`);
                        let lastIndex = getValues(`process[${e.processIndex}].items[${i}][${e.which}Measure]`).length
                        let measure = await props.db.collection('measurement').doc(id).collection('process').doc(processId).collection('items').doc(itemId).collection(`${e.which}Measure`)//fieldMeasureIds[${i}]
                            .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                            .startAfter(getValues(`process[${e.processIndex}][${e.which}MeasureIds[${i}]`)).limit(limit)
                            //.orderBy('insertedDate', 'asc')
                            .get();
                        if (measure.docs.length > 0) {
                            measureIds.push(measure.docs[measure.docs.length - 1]);
                            measure.docs.forEach((doc, index) => {
                                let fData = doc.data();
                                fData.id = doc.id;
                                fData.processId = processId;
                                fData.itemsId = itemId;
                                setValue(`process[${e.processIndex}].items[${i}][${e.which}Measure[${lastIndex + index}]`, fData);
                            })
                        } else {
                            setValue(`process[${e.processIndex}][${e.which}NotMore]`, true);
                            setLoading(false);
                        }
                    }
                }
                if (getValues(`process[${e.processIndex}][${e.which}CommentsIds[0]`) !== undefined) {
                    //Comments
                    let comments = await props.db.collection('measurement').doc(id).collection('process').doc(processId).collection(`${e.which}Comments`)
                        .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                        .startAfter(getValues(`process[${e.processIndex}][${e.which}CommentsIds[0]`)).limit(limit)
                        //.orderBy('insertedDate', 'asc')
                        .get();
                    if (comments.docs.length > 0) {
                        commentIds.push(comments.docs[comments.docs.length - 1]);
                        comments.docs.forEach((doc, c) => {
                            let fcommentData = doc.data();
                            fcommentData.id = doc.id;
                            fcommentData.processId = processId;
                            setValue(`process[${e.processIndex}][${e.which}Comments[${lastIndexComment + c}]`, fcommentData);
                        })
                    } else {
                        setValue(`process[${e.processIndex}][${e.which}NotMore]`, true);
                        setLoading(false);
                    }
                }
                setValue(`process[${e.processIndex}][${e.which}MeasureIds]`, measureIds);
                setValue(`process[${e.processIndex}][${e.which}CommentsIds]`, commentIds);
            }
            setEnableNext(false);
            return setLoading(false);
        } catch (e) {
            console.log('error', e);
        }
    }
    const nextReloadProcessData = async (e, processId) => {
        if (getValues(`process[${e}].fieldNotMore`) && getValues(`process[${e}].labNotMore`) && getValues(`process[${e}].automaticNotMore`)) return;
        try {
            setLoading(true)
            setEnableNext(true);
            let month = getValues(`process[${e}].select`);
            let id = getValues(`id`);
            let measureIds = [];
            let commentIds = [];
            let whichMeasure = ['field', 'lab', 'automatic'];
            let beginDate;
            let lastDate;
            switch (searchChart) {
                case 'selectDate':
                    beginDate = startAt(startDate);
                    lastDate = endAt(endDate);
                    break;
                default:
                    beginDate = startAt((new Date()).setDate((new Date()).getDate() - month));
                    lastDate = endAt(new Date());
                    break;
            }
            for (let which = 0; which < whichMeasure.length; which++) {
                let lastIndexComment = getValues(`process[${e}][${whichMeasure[which]}Comments]`).length;
                if (getValues(`process[${e}].itemsIds`).length > 0) {
                    try {
                        for (let i = 0; i < getValues(`process[${e}].itemsIds`).length; i++) {
                            if (getValues(`process[${e}][${whichMeasure[which]}MeasureIds[${i}]`) !== undefined) {
                                let itemId = getValues(`process[${e}].itemsIds[${i}]`);
                                let lastIndex = getValues(`process[${e}].items[${i}][${whichMeasure[which]}Measure]`).length
                                let measure = await props.db.collection('measurement').doc(id).collection('process').doc(processId).collection('items').doc(itemId).collection(`${whichMeasure[which]}Measure`)
                                    .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                                    .startAfter(getValues(`process[${e}][${whichMeasure[which]}MeasureIds[${i}]`)).limit(limit)
                                    //.orderBy('insertedDate', 'asc')
                                    .get();
                                if (measure.docs.length > 0) {
                                    measureIds.push(measure.docs[measure.docs.length - 1]);
                                    measure.docs.forEach((doc, index) => {
                                        let fData = doc.data();
                                        fData.id = doc.id;
                                        fData.processId = processId;
                                        fData.itemsId = itemId;
                                        setValue(`process[${e}].items[${i}][${whichMeasure[which]}Measure[${lastIndex + index}]`, fData);
                                    })
                                } else {
                                    setValue(`process[${e.processIndex}][${whichMeasure[which]}NotMore]`, true);
                                    setLoading(false);
                                }
                            }
                        }
                        //Comments
                        if (getValues(`process[${e}][${whichMeasure[which]}CommentsIds[0]`) !== undefined) {
                            let comments = await props.db.collection('measurement').doc(id).collection('process').doc(processId).collection(`${whichMeasure[which]}Comments`)
                                .where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).limit(limit)
                                .startAfter(getValues(`process[${e}][${whichMeasure[which]}CommentsIds[0]`)).limit(limit)
                                //.orderBy('insertedDate', 'asc')
                                .get();
                            if (comments.docs.length > 0) {
                                commentIds.push(comments.docs[comments.docs.length - 1]);
                                comments.docs.forEach((doc, c) => {
                                    let fcommentData = doc.data();
                                    fcommentData.id = doc.id;
                                    fcommentData.processId = processId;
                                    setValue(`process[${e}][${whichMeasure[which]}Comments[${lastIndexComment + c}]`, fcommentData);
                                })
                            } else {
                                setValue(`process[${e}][${whichMeasure[which]}NotMore]`, true);
                                setLoading(false);
                            }
                        }

                        setValue(`process[${e}][${whichMeasure[which]}MeasureIds]`, measureIds);
                        setValue(`process[${e}][${whichMeasure[which]}CommentsIds]`, commentIds);
                    } catch (e) {
                        console.log(e, 'error')
                    }
                }
            }
            setEnableNext(false);
            return setLoading(false);
        } catch (e) {
            console.log('error', e);
        }
    }
    //console.log('processData', processData);
    const handleSelectProcess = async (e) => {
        setProcessId(e.target.value);
        setSearChart(90);
        setStartDate(null);
        setEndDate(null);
        e.target.value !== "" && await loadProcessData(90, e.target.value);

    }
    let selectOptionExcel = <>
        <option value={'choose'}>選択してください</option>
        <option value={"selectDateExcel"}>CSVダウンロード</option>
    </>;
    const formatDatetime = (datetime) => {
        const dateStr = new Date(datetime).toLocaleDateString(
            "ja-JP"
        );
        const timeStr = new Date(datetime).toLocaleTimeString();
        return `${dateStr} ${timeStr}`;
    }
    const handleToleranceColorExcel = (value, lvlMin, lvlMax, rangeSettings) => {
        let color = '';
        switch (rangeSettings) {
            case '上限下限共':
                color = (value * 1 >= lvlMin * 1 && value * 1 <= lvlMax * 1 ? '' : 'FF0000');
                break;
            case '下限のみ':
                color = (value * 1 >= lvlMin * 1 ? '' : 'FF0000');
                break;
            case '上限のみ':
                color = (value * 1 <= lvlMax * 1 ? '' : 'FF0000');
                break;
            default:
                color = (value * 1 >= lvlMin * 1 && value * 1 <= lvlMax * 1 ? '' : 'FF0000');
                break;
        }
        return color;
    }
    const exportDataXlsx = async () => {
        try {
            setLoaderXlsx(true);
            let processId = getValues(`process[0].id`);
            let beginDate;
            let lastDate;
            if(selectedTypeXlsx!=='choose') {
                beginDate = startAt(startDateExcel);
                lastDate = endAt(endDateExcel);
            }
            let pData = [];
            pData = [{
                items: [],
            }];

            let items = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').orderBy('index', 'asc').get();
            let itemData = [];
            if (items.docs.length > 0) {
                for (let i1 = 0; i1 < items.docs.length; i1++) {
                    let doc = items.docs[i1];
                    let data1 = doc.data();
                    data1.id = doc.id;
                    data1.processId = processId;
                    data1.fieldMeasure = [];
                    data1.labMeasure = [];
                    data1.automaticMeasure = [];
                    itemData.push(data1)
                }
            }
            if (itemData && itemData.length > 0) {
                for (let j = 0; j < itemData.length; j++) {
                    pData[0].items.push(itemData[j]);
                    if(['none', 'preview'].includes(whichIsClicked)){
                        await Promise.all([
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemData[j].id).collection('fieldMeasure').where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).orderBy('insertedDate', 'asc').get(),
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemData[j].id).collection('labMeasure').where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).orderBy('insertedDate', 'asc').get(),
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemData[j].id).collection('automaticMeasure').where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).orderBy('insertedDate', 'asc').get()
                        ])
                            .then((res) => {
                                if (res[0].docs.length > 0) {
                                    let fieldMeasure = [];
                                    res[0].docs.forEach((doc) => {
                                        //let doc = res[0].docs[index];
                                        let fData = doc.data();
                                        fData.id = doc.id;
                                        fData.processId = processId;
                                        fData.itemsId = itemData[j].id;
                                        fieldMeasure.push(fData);
                                    })
                                    pData[0].items[j].fieldMeasure = fieldMeasure
                                }
                                if (res[1].docs.length > 0) {
                                    let labMeasure = [];
                                    res[1].docs.forEach((doc) => {
                                        let lData = doc.data();
                                        lData.id = doc.id;
                                        lData.processId = processId;
                                        lData.itemsId = itemData[j].id;
                                        labMeasure.push(lData)
                                    })
                                    pData[0].items[j].labMeasure = labMeasure
                                }
                                if (res[2].docs.length > 0) {
                                    let automaticMeasure = [];
                                    res[2].docs.forEach((doc) => {
                                        let aData = doc.data();
                                        aData.id = doc.id;
                                        aData.processId = processId;
                                        aData.itemsId = itemData[j].id;
                                        automaticMeasure.push(aData);
                                    })
                                    pData[0].items[j].automaticMeasure = automaticMeasure
                                }
                            })
                    }
                    else if(whichIsClicked === 'field') {
                        await Promise.all([
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemData[j].id).collection('fieldMeasure').where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).orderBy('insertedDate', 'asc').get(),
                        ])
                            .then((res) => {
                                if (res[0].docs.length > 0) {
                                    let fieldMeasure = [];
                                    res[0].docs.forEach((doc) => {
                                        //let doc = res[0].docs[index];
                                        let fData = doc.data();
                                        fData.id = doc.id;
                                        fData.processId = processId;
                                        fData.itemsId = itemData[j].id;
                                        fieldMeasure.push(fData);
                                    })
                                    pData[0].items[j].fieldMeasure = fieldMeasure
                                }
                            })
                    }
                    else if(whichIsClicked === 'lab') {
                        await Promise.all([
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemData[j].id).collection('labMeasure').where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).orderBy('insertedDate', 'asc').get()
                        ])
                            .then((res) => {
                                if (res[0].docs.length > 0) {
                                    let labMeasure = [];
                                    res[0].docs.forEach((doc) => {
                                        let lData = doc.data();
                                        lData.id = doc.id;
                                        lData.processId = processId;
                                        lData.itemsId = itemData[j].id;
                                        labMeasure.push(lData)
                                    })
                                    pData[0].items[j].labMeasure = labMeasure
                                }
                            })
                    }
                    else if(whichIsClicked === 'automatic') {
                        await Promise.all([
                            props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(processId).collection('items').doc(itemData[j].id).collection('automaticMeasure').where("insertedDate", ">=", beginDate).where("insertedDate", "<=", lastDate).orderBy('insertedDate', 'asc').get()
                        ])
                            .then((res) => {
                                if (res[0].docs.length > 0) {
                                    let automaticMeasure = [];
                                    res[0].docs.forEach((doc) => {
                                        let aData = doc.data();
                                        aData.id = doc.id;
                                        aData.processId = processId;
                                        aData.itemsId = itemData[j].id;
                                        automaticMeasure.push(aData);
                                    })
                                    pData[0].items[j].automaticMeasure = automaticMeasure
                                }
                            })
                    }
                }
            }
            //console.log('pData', pData);
            if(pData && pData[0].items.length > 0) {
                const workbook = new Excel.Workbook();
                //['none', 'preview'].includes(whichIsClicked)
                const worksheet = workbook.addWorksheet('FieldMeasure');
                const worksheet2 = workbook.addWorksheet('labMeasure');
                const worksheet3 = workbook.addWorksheet('autoMeasure');

                //field
                const headerRow = worksheet.getRow(1);
                headerRow.width = 60;
                const columnFromIndex = 3;

                const headerRow2 = worksheet2.getRow(1);
                headerRow2.width = 60;

                const headerRow3 = worksheet3.getRow(1);
                headerRow3.width = 60;

                //const columnToIndex = columnFromIndex + 3;
                headerRow.getCell(1).value = '管理項目';
                headerRow.getCell(1).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};
                headerRow.getCell(2).value = '許容範囲';
                headerRow.getCell(2).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};

                headerRow2.getCell(1).value = '管理項目';
                headerRow2.getCell(1).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};
                headerRow2.getCell(2).value = '許容範囲';
                headerRow2.getCell(2).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};

                headerRow3.getCell(1).value = '管理項目';
                headerRow3.getCell(1).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};
                headerRow3.getCell(2).value = '許容範囲';
                headerRow3.getCell(2).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};

                pData[0].items[0].fieldMeasure.forEach((vr, vr_x) => {
                        headerRow.getCell(columnFromIndex + vr_x).alignment = {
                            horizontal: 'left',
                            vertical: 'middle',
                            wrapText: true
                        }
                        headerRow.getCell(columnFromIndex + vr_x).value = formatDatetime(new Date(moment(vr.id === undefined ? vr.insertedDate : vr.insertedDate.toDate())))
                    }
                )

                pData[0].items[0].labMeasure.forEach((vr, vr_x) => {
                        headerRow2.getCell(columnFromIndex + vr_x).alignment = {
                            horizontal: 'left',
                            vertical: 'middle',
                            wrapText: true
                        }
                        headerRow2.getCell(columnFromIndex + vr_x).value = formatDatetime(new Date(moment(vr.id === undefined ? vr.insertedDate : vr.insertedDate.toDate())))
                    }
                )

                pData[0].items[0].automaticMeasure.forEach((vr, vr_x) => {
                        headerRow3.getCell(columnFromIndex + vr_x).alignment = {
                            horizontal: 'left',
                            vertical: 'middle',
                            wrapText: true
                        }
                        headerRow3.getCell(columnFromIndex + vr_x).value = formatDatetime(new Date(moment(vr.id === undefined ? vr.insertedDate : vr.insertedDate.toDate())))
                    }
                )

                pData[0].items.forEach((item, item_index) => {
                    const row = worksheet.getRow(2 + item_index);
                    row.getCell(1).value = item.item_name;
                    row.getCell(2).value = `${item.tolerance_lvl_min}~${item.tolerance_lvl_max} ${item.unit}`;
                    item.fieldMeasure.forEach((vr, vr_x) => {
                            //color: handleToleranceColor(vl.value,item.tolerance_lvl_min,item.tolerance_lvl_max,item.range_settings)
                            row.getCell(columnFromIndex + vr_x).alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                                wrapText: true
                            };
                            row.getCell(columnFromIndex + vr_x).font = {color: {argb: handleToleranceColorExcel(vr.value, item.tolerance_lvl_min, item.tolerance_lvl_max, item.range_settings)}};
                            //row.getCell(columnFromIndex + vr_x).numFmt = '#,##0.00';
                            row.getCell(columnFromIndex + vr_x).value = !isNaN(parseFloat(vr.value)) ? parseFloat(vr.value) : '';
                        }
                    )

                    const row2 = worksheet2.getRow(2 + item_index);
                    row2.getCell(1).value = item.item_name;
                    row2.getCell(2).value = `${item.tolerance_lvl_min}~${item.tolerance_lvl_max} ${item.unit}`;
                    item.labMeasure.forEach((vr, vr_x) => {
                            //color: handleToleranceColor(vl.value,item.tolerance_lvl_min,item.tolerance_lvl_max,item.range_settings)
                            row2.getCell(columnFromIndex + vr_x).alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                                wrapText: true
                            };
                            row2.getCell(columnFromIndex + vr_x).font = {color: {argb: handleToleranceColorExcel(vr.value, item.tolerance_lvl_min, item.tolerance_lvl_max, item.range_settings)}};
                            //row.getCell(columnFromIndex + vr_x).numFmt = '#,##0.00';
                            row2.getCell(columnFromIndex + vr_x).value = !isNaN(parseFloat(vr.value)) ? parseFloat(vr.value) : '';
                        }
                    )

                    const row3 = worksheet3.getRow(2 + item_index);
                    row3.getCell(1).value = item.item_name;
                    row3.getCell(2).value = `${item.tolerance_lvl_min}~${item.tolerance_lvl_max} ${item.unit}`;
                    item.automaticMeasure.forEach((vr, vr_x) => {
                            //color: handleToleranceColor(vl.value,item.tolerance_lvl_min,item.tolerance_lvl_max,item.range_settings)
                            row3.getCell(columnFromIndex + vr_x).alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                                wrapText: true
                            };
                            row3.getCell(columnFromIndex + vr_x).font = {color: {argb: handleToleranceColorExcel(vr.value, item.tolerance_lvl_min, item.tolerance_lvl_max, item.range_settings)}};
                            //row.getCell(columnFromIndex + vr_x).numFmt = '#,##0.00';
                            row3.getCell(columnFromIndex + vr_x).value = !isNaN(parseFloat(vr.value)) ? parseFloat(vr.value) : '';
                        }
                    )

                })

                switch (whichIsClicked) {
                    case 'field': workbook.removeWorksheet('labMeasure'); workbook.removeWorksheet('autoMeasure'); break;
                    case 'lab': workbook.removeWorksheet('FieldMeasure'); workbook.removeWorksheet('autoMeasure'); break;
                    case 'automatic': workbook.removeWorksheet('FieldMeasure'); workbook.removeWorksheet('labMeasure'); break;
                    default: break;
                }
                workbook.xlsx.writeBuffer().then(buffer => {
                    const blob = new Blob([buffer]);
                    saveAs(blob, `${getValues(`process[0].process_name`)}-${['none', 'preview'].includes(whichIsClicked) ? 'All' : whichIsClicked}Measure - ${new moment().format('YYYY-MM-DD_HHmmss')}.xlsx`)
                })
            }
            return setLoaderXlsx(false);
        } catch (e) {
            console.log('e -> ', e.message);
            //alert('エラー');
        }
    }
    return (
        <div>
            {data && visibleUrl &&
            <div>
                <form onSubmit={handleSubmit(submitData)}>
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20,flex:1,justifyContent:'space-between'}}>
                        <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: '10px',flex:0.5}}>
                            <h1>{title}</h1>
                            <span>対象事業所：{data.organization_name}</span>
                            <span>登録事業所：{data.fromOrganizationName}</span>
                            <span>開始年月日： {data && data.start_date && moment(data.start_date.toDate()).format('YYYY/MM/DD')}</span>
                            <span>名称 : {data.name} </span>
                            <div style={{
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span>工程：</span>
                                <select className="browser-default custom-select" onChange={handleSelectProcess}
                                        style={{width: 250}}>
                                    <option value="">選択してください</option>
                                    {processData.map((item, index) => (
                                        <option key={index} value={item.id}>{item.process_name}</option>
                                    ))}
                                </select>
                            </div>
                            {insertClicked && <div style={{display: 'flex', alignItems: 'center'}}>
                                追加測定日：<DatePicker id="datePicker" className={'form-control'} maxDate={new Date()}
                                                       selected={insertionDate} autoComplete={'off'}
                                /*minDate={startAt((new Date()).setMonth((new Date()).getMonth() - searchChart))}
                                maxDate={endAt(new Date())}*/
                                                       showTimeInput timeInputLabel="Time:"
                                                       dateFormat="yyyy/MM/dd HH:mm"
                                                       onChange={(date) => dateHandler(date)}/>
                                <MDBBtn size={'md'} onClick={async () => addValueRows(whichIsClicked)}>確定</MDBBtn>
                            </div>}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '.2',
                            justifyContent: 'space-between', textAlign: 'end',alignItems:'flex-end',flex:0.5

                        }}>
                            <div style={{
                                //flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                height: 46,
                                alignItems: "flex-start"
                            }}>
                                <span style={{textAlign: 'right'}}>共有設定中：</span>
                                <div style={{}}>
                                    {data && data.measureShare && data.measureShare.length > 0 ? data.measureShare.map((item, index) => (
                                        <div key={index}>{item.name}</div>
                                    )) : ''}
                                </div>
                            </div>
                            {!loaderProcess && <div style={{display:"flex",flexDirection:'row',alignItems:'center'}} hidden={processId !== "" ? false : true}>
                                {/*<select className="browser-default custom-select" onChange={(e)=> {setSelectedTypeXlsx(e.target.value); if(e.target.value==='choose'){setStartDateExcel(null);setEndDateExcel(null)} }}
                                        style={{width: 120,marginRight:10,height:38}}>
                                    {selectOptionExcel}
                                </select>*/}
                                <div style={{marginRight:10,color:'#0c0c0c',fontWeight:'500'}}>ＣＳＶダウンロード</div>
                                {
                                    selectedTypeXlsx !== "choose" && <div className="form-check form-check-inline">
                                        <div style={{width: 115}}>
                                            <DatePicker id="startDate" className={'form-control'}
                                                        maxDate={endDateExcel ? endDateExcel : new Date()}
                                                        selected={startDateExcel} placeholderText={'開始日選択'}
                                                        dateFormat="yyyy/MM/dd" autoComplete={'off'}
                                                        onChange={(date) => {
                                                            setStartDateExcel(date);
                                                            setEndDateExcel(null)
                                                        }}
                                            /></div>
                                        {
                                            startDateExcel && <div style={{marginLeft: 10}}>~</div>
                                        }
                                        {
                                            startDateExcel && <div style={{marginLeft: 10, width: 115}}>
                                                <DatePicker id="endDate" className={'form-control'} minDate={startDateExcel}
                                                            maxDate={new Date()} placeholderText={'終了日選択'}
                                                            selected={endDateExcel} autoComplete={'off'}
                                                            dateFormat="yyyy/MM/dd"
                                                            onChange={(date) => setEndDateExcel(date)}/></div>
                                        }
                                    </div>
                                }
                                <MDBBtn onClick={(e)=>{exportDataXlsx()}} style={{cursor:'pointer',height:38,width:50,alignItems:'center',padding:0,marginLeft:-2}} color="default" disabled={(startDateExcel && endDateExcel) === null ? true : loaderXlsx  }>{loaderXlsx ? <MDBSpinner className='visually-hidden' small/> : <MDBIcon icon="download" size={'lg'}/>}</MDBBtn>
                               {/* <MDBIcon icon="download" size={'lg'} onClick={(e)=>{exportDataXlsx()}} style={{cursor:'pointer'}} disabled={'false'}/>*/}
                            </div>}
                            {
                                !loaderProcess && <div hidden={processId !== "" ? false : true}>
                                    <MDBBtn hidden={whichIsClicked === 'field' || whichIsClicked === 'preview'}
                                            color={''}
                                            className={'measureBlueBtn'}
                                            onClick={() => selectType('field')}>現地測定値入力</MDBBtn>
                                    <MDBBtn hidden={whichIsClicked === 'lab' || whichIsClicked === 'preview'}
                                            className={'measureRedBtn'} onClick={() => selectType('lab')}
                                            color="danger">ラボ測定値入力</MDBBtn>
                                    <MDBBtn hidden={whichIsClicked === 'automatic' || whichIsClicked === 'preview'}
                                            className={'measureOrangeBtn'} onClick={() => selectType('automatic')}
                                            color="warning">自動測定値入力</MDBBtn>
                                </div>
                            }
                        </div>
                    </div>
                    {loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner/></div>}
                    {!loaderProcess && fields.map((item, process_index) => (
                        <div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row', justifyContent: 'space-between',
                                    padding: '0px',
                                    background: '#545454',
                                    color: '#FFF', alignItems: 'center', flex: 1
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column', marginLeft: 5//,minWidth:300
                                    }}>
                                    <span
                                        style={{fontWeight: 'bold'}}>工程{item.index + 1} : {item.process_name}</span>
                                        <span>分析商品名：{item.analysis_product_name}</span>
                                    </div>
                                    <div style={{flexDirection: 'row', justifyContent: 'flex-end', display: 'flex'}}>
                                        <div className="form-check form-check-inline">
                                            <select key={process_index} className="browser-default custom-select"
                                                    {...register(`process[${process_index}].select`)}
                                                    style={{height: 38, width: 125}}
                                                    onChange={(e) => {
                                                        setValue(`process[${process_index}].select`, e.target.value);
                                                        setSearChart(e.target.value);
                                                        if (e.target.value !== "selectDate") {
                                                            setStartDate(null);
                                                            setEndDate(null);
                                                        }
                                                        if (e.target.value === "selectDate") {
                                                            setEnableNext(true)
                                                        }
                                                        e.target.value !== "selectDate" && handleSearchChart(process_index, e.target.value)
                                                    }}>
                                                {selectOption}
                                            </select>
                                        </div>
                                        {
                                            searchChart === "selectDate" &&
                                            <div className="form-check form-check-inline">
                                                <div style={{width: 125}}>
                                                    <DatePicker id="startDate" className={'form-control'}
                                                                maxDate={endDate ? endDate : new Date()}
                                                                selected={startDate} placeholderText={'開始日選択'}
                                                                dateFormat="yyyy/MM/dd" isClearable autoComplete={'off'}
                                                                onChange={(date) => {
                                                                    setStartDate(date);
                                                                    setEndDate(null)
                                                                }}
                                                    /></div>
                                                {
                                                    startDate && <div style={{marginLeft: 10}}>~</div>
                                                }
                                                {
                                                    startDate && <div style={{marginLeft: 10, width: 125}}>
                                                        <DatePicker id="endDate" className={'form-control'}
                                                                    minDate={startDate}
                                                                    maxDate={((new Date(startDate)).setMonth((new Date(startDate)).getMonth() + 3)) > new Date() ? new Date() : (new Date(startDate)).setMonth((new Date(startDate)).getMonth() + 3)}
                                                                    placeholderText={'終了日選択'}
                                                                    selected={endDate} isClearable autoComplete={'off'}
                                                                    dateFormat="yyyy/MM/dd"
                                                                    onChange={(date) => setEndDate(date)}/></div>
                                                }
                                            </div>
                                        }
                                        <MDBBtn className="form-check form-check-inline"
                                                style={{paddingTop: 10, paddingBottom: 10, width: 120}}
                                                disabled={enableNext || (getValues(`process[${process_index}].fieldNotMore`) && getValues(`process[${process_index}].labNotMore`) && getValues(`process[${process_index}].automaticNotMore`))}
                                                rounded color="white" size={'md'}
                                                onClick={() => nextReloadProcessData(process_index, getValues(`process[${process_index}].id`))}><span
                                            style={{fontSize: 14}}>次の{limit}件</span></MDBBtn>
                                    </div>
                            </div>
                            <DrawMeasureChart nestIndex={process_index} {...{control, register}}
                                              getValues={getValues} which={whichIsClicked}/>
                            <div style={{padding: '20px'}}>
                                {(['none', 'preview'].includes(whichIsClicked) || whichIsClicked === 'field') &&
                                    <Measurements appendItem={appendItem} appendComment={appendComment}
                                                  reloader={reloaderMeasurement} searchChart={searchChart}
                                                  editClicked={insertClicked} nestIndex={process_index} {...{
                                        control,
                                        register
                                    }} getValues={getValues} setValue={setValue} dateFormatter={dateFormatter}
                                                  db={props.db}
                                                  process_index={process_index} which="field" title={title1}
                                                  color="#2E75B6" deleteMeasure={(e) => {
                                        removeMeasure.push(e);
                                    }} nextData={(e) => {
                                        nextReloadData(e)
                                    }} enabledNext={enableNext} limit={limit}
                                    />}
                                {(['none', 'preview'].includes(whichIsClicked) || whichIsClicked === 'lab') &&
                                    <Measurements appendItem={appendItem} appendComment={appendComment}
                                                  reloader={reloaderMeasurement} //searchChart={searchChart}
                                                  editClicked={insertClicked} dateFormatter={dateFormatter}
                                                  nestIndex={process_index} {...{control, register}}
                                                  getValues={getValues} setValue={setValue}
                                                  dateFormatter={dateFormatter} db={props.db}
                                                  process_index={process_index} which="lab" title={title2}
                                                  deleteMeasure={(e) => {
                                                      removeMeasure.push(e);
                                                  }} nextData={(e) => {
                                        nextReloadData(e)
                                    }} enabledNext={enableNext} limit={limit}
                                                  color="#B71C1C"/>}
                                {(['none', 'preview'].includes(whichIsClicked) || whichIsClicked === 'automatic') &&
                                    <Measurements appendItem={appendItem} appendComment={appendComment}
                                                  reloader={reloaderMeasurement} //searchChart={searchChart}
                                                  editClicked={insertClicked} dateFormatter={dateFormatter}
                                                  nestIndex={process_index} {...{control, register}}
                                                  getValues={getValues} setValue={setValue}
                                                  dateFormatter={dateFormatter} db={props.db}
                                                  process_index={process_index} which="automatic" title={title3}
                                                  deleteMeasure={(e) => {
                                                      removeMeasure.push(e);
                                                  }} nextData={(e) => {
                                        nextReloadData(e)
                                    }} enabledNext={enableNext} limit={limit}
                                                  color="#FB8804"/>}
                            </div>
                        </div>


                    ))}
                    {insertClicked && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 20,
                        flexDirection: 'column'
                    }}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'}
                                    onClick={cancelHandler} style={{width: 200}}>キャンセル</MDBBtn>
                            <MDBBtn style={{width: 200}} onClick={() => selectType('preview')}>プレビュー</MDBBtn>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <MDBBtn type="submit" style={{width: 200}}>登録</MDBBtn>
                        </div>
                    </div>
                    }
                    {!insertClicked && !loading && processId !== "" &&
                        <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
                            <MDBBtn hidden={whichIsClicked === 'preview'} onClick={event => {props.cProps.history.push('/measurement');}}
                                    style={{width: 200}}>一覧へ戻る</MDBBtn>
                            <MDBBtn hidden={whichIsClicked === 'preview' ? false : true} size="sm4"
                                    onClick={cancelHandlerPreview} style={{width: 200}}>閉じる</MDBBtn>
                            <MDBBtn hidden={whichIsClicked === 'field' || whichIsClicked === 'preview'}
                                    color={''} className={'measureBlueBtn'} style={{width: 200}}
                                    onClick={() => selectType('field')}>現地測定値入力</MDBBtn>
                            <MDBBtn hidden={whichIsClicked === 'lab' || whichIsClicked === 'preview'}
                                    className={'measureRedBtn'} style={{width: 200}}
                                    onClick={() => selectType('lab')} color="danger">ラボ測定値入力</MDBBtn>
                        </div>}
                </form>
            </div>
            }
        </div>
    )
}

export default PreviewMeasurement;