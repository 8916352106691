import React, { Component } from 'react';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { MDBChip, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, toast } from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"
import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "./Cart.css";
import OtasukeCartAllSelect from "../CartSelect/OtasukeCartAllSelect";
import OtasukeCartAllSelectOtasuke from "../CartSelect/OtasukeCartAllSelectOtasuke";

class OtasukeCartDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      otasukeCart: {},
      // cart: {},
      deleteModal: false,
      deleteId: null,
      deleteTitle: null,
      modal: false,
      modalSave: false,
      carriage: 0,
      cartselect: false,
      lastDeliveryAddress: {},
      reachMax: false,
      loading: true
    }
  }


  getCart = async () => {
    let iCollection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      console.log('manu', this.props.userOrg.id, this.props.org.id)
      iCollection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      iCollection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      iCollection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      iCollection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && iCollection){
      let urigawa = false;
      if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
        urigawa = true;
      }
      if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
        urigawa = true;
      }
      // console.log(urigawa)

      let collection = 'address_book_buy'

      if (urigawa) {
        collection = 'address_book_sell';
      }

      let ab = this.props.db.collection(collection).doc(this.props.user.id)
              .collection('address_book').doc(this.props.org.id)
              .collection('address_book')
              .orderBy('dueDate','desc')
              .get()

      let ochRef = this.props.db.collection('otasuke_carts_history').doc(this.props.id);
      let och = ochRef.get();

      let response = await Promise.all([ab, och]);

      let abDoc = response[0];
      let ochDoc = response[1];

      let reachMax = true;
      if (abDoc.docs.length < 50) {
        reachMax = false;
      }

      this.setState({ reachMax: reachMax })

      let ochData = ochDoc.data();

      // 運賃計算
      let total=0;
      if(ochData && ochData.cart && ochData.cart.items && ochData.cart.items.length){
        let items = ochData.cart.items;

        // if (!urigawa) {
          // let withDeletedItem = false;
        delete ochData.cart['deletedItem'];
          await Promise.all(items.map(async (item) => {
            if (items) {
              let itemSell = await iCollection.doc(item.id).get();

              let itemSellData = itemSell.data();
              delete item['deletedItem'];
              delete item['deletedText'];

              if (!itemSellData) {
                ochData.cart.deletedItem = true;
                item.deletedItem = true;
                if (!urigawa) {
                  item.deletedText = '販売終了';
                } else {
                  item.deletedText = '販売終了';
                }
              } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                ochData.cart.deletedItem = true;
                item.deletedItem = true;
                if (!urigawa) {
                  item.deletedText = '要見積';
                } else {
                  item.deletedText = '要見積';
                }
              } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                ochData.cart.deletedItem = true;
                item.deletedItem = true;
                if (!urigawa) {
                  item.deletedText = '要見積';
                } else {
                  item.deletedText = '要見積';
                }
              } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                let expiration_date = itemSellData['price'][0]['expiration_date'];
                let expDate = expiration_date.toDate();

                if ((expDate.getTime() - this.props.dateNow) < 0) {
                  ochData.cart.deletedItem = true;
                  item.deletedItem = true;
                  if (!urigawa) {
                    item.deletedText = '要見積';
                  } else {
                    item.deletedText = '要見積';
                  }
                } else {
                  // console.log(!urigawa)
                  if (!urigawa) {
                    item.price = itemSellData['price'][0]['price'];
                  }
                }
              } else {
                if (!urigawa) {
                  item.price = itemSellData['price'][0]['price'];
                }
              }
              // if (itemSellData && itemSellData['price']) {
              //   item.price = itemSellData['price'][0]['price'];
              //   total += item.count * item.price;
              // } else {
              //   // withDeletedItem = true;
              //   ochData.cart.items = ochData.cart.items.filter((obj) => obj.id !== item.id);
              // }
            }
          }));

          // if (withDeletedItem) {
          //   ochRef.set(ochData, {merge: true});
          // }
        // }


        // items.forEach((item, index) => {
        //   total += item.count * item.price;
        // });
      }

      this.setState({otasukeCart: ochData})

      let carriage_free = 0;
      let carriage = 0;
      if(this.props.org.maker && this.props.userOrg && this.props.userOrg.sellers){
        // メーカーU -> 販社
        this.props.userOrg.sellers.map((seller) => {
          if(seller.organization == this.props.org.id){
            carriage_free = seller.carriage_free ? seller.carriage_free : 0;
            carriage = seller.carriage ? seller.carriage : 0;
          }
        })
      }
      if(this.props.org.reseller && this.props.userOrg){
        // 販社U -> 購入U
        carriage_free = this.props.userOrg.carriage_free ? this.props.userOrg.carriage_free : 0;
        carriage = this.props.userOrg.carriage ? this.props.userOrg.carriage : 0;
      }
      let postage = total < carriage_free ? carriage : 0 ;
      this.setState({carriage: postage})








      // this.props.db.collection(collection).doc(this.props.user.id)
      //     .collection('address_book').doc(this.props.org.id)
      //     .collection('address_book')
      //     .orderBy('dueDate','desc')
      //     .get()
      //     .then((snapshot) => {
      //       // console.log(snapshot.docs.length)
      //       let reachMax = true;
      //       if (snapshot.docs.length < 50) {
      //         reachMax = false;
      //       }
      //       this.setState({
      //         reachMax: reachMax
      //       })
      //     });

      // this.props.db.collection('otasuke_carts_history').doc(this.props.id) //this.props.user.id
      // .get()
      // .then((doc) => {
      //   if(doc.exists) {
      //     const data = doc.data();
      //     this.setState({otasukeCart: data})
      //
      //     // 運賃計算
      //     let total=0;
      //     if(this.state.otasukeCart && this.state.otasukeCart.cart && this.state.otasukeCart.cart.items && this.state.otasukeCart.cart.items.length){
      //       let items = this.state.otasukeCart.cart.items;
      //       items.forEach((item, index) => {
      //         total += item.count * item.price;
      //       });
      //     }
      //     let carriage_free = 0;
      //     let carriage = 0;
      //     if(this.props.org.maker && this.props.userOrg && this.props.userOrg.sellers){
      //       // メーカーU -> 販社
      //       this.props.userOrg.sellers.map((seller) => {
      //         if(seller.organization == this.props.org.id){
      //           carriage_free = seller.carriage_free ? seller.carriage_free : 0;
      //           carriage = seller.carriage ? seller.carriage : 0;
      //         }
      //       })
      //     }
      //     if(this.props.org.reseller && this.props.userOrg){
      //       // 販社U -> 購入U
      //       carriage_free = this.props.userOrg.carriage_free ? this.props.userOrg.carriage_free : 0;
      //       carriage = this.props.userOrg.carriage ? this.props.userOrg.carriage : 0;
      //     }
      //     let postage = total < carriage_free ? carriage : 0 ;
      //     this.setState({carriage: postage})
      //
      //   }
      // })
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.org !== prevProps.org || this.props.userOrg !== prevProps.userOrg) {
      this.getCart();
    }

    // let cart = 0;
    // cart = this.props.id - 1;
    // if(isNaN(cart)){
    //   cart=0;
    // }
    //
    // if (this.state.cartNo != cart){
    //   this.setState({cartNo: cart});
    // }
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.getCart();
  }

  addToDeliveryAddress = (deliveryAddress) => {
    deliveryAddress.otasukeHistoryId = this.props.id
    this.props.cProps.history.push('/deliveryAddressAdd', deliveryAddress)
  }

  deleteHelpCart = (e) => {
    let dId = e && e.currentTarget.dataset.id;
    let dTitle = e && e.currentTarget.dataset.title;
    this.setState({
      deleteId: dId,
      deleteTitle: dTitle,
      deleteModal: true
    })
  }

  addCart = () => {
    let self = this;
    // console.log('item', this.state.otasukeCart.cart);
    this.setState({selectedItem: this.state.otasukeCart.cart}, () => {
      self.setState({cartselect: true});
    });
  }

  modalDelClose = (e) => {
    let dId = e && e.currentTarget.dataset.id;

    if (dId) {
      console.log('delete', dId)
      let self = this;

      let otasukeCartHistoryRef = this.props.db.collection('otasuke_carts_history').doc(dId);
      otasukeCartHistoryRef.set({
        sendHelpCart: 2,
      }, { merge: true })
          .then(function () {
            console.log('success set otasukeCartHistory')
            self.props.cProps.history.push('/otasuke/history/carts')
          })
          .catch(function (error) {
            console.error("Error set otasukeCartHistory: ", error);
          });
    }

    this.setState({
      deleteModal: false,
      deleteId: null,
      deleteTitle: null
    })
  }

  decrease = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("decrease:");
    console.log(itemId);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        if(cart[index]['count']>0){
          cart[index]['count']--;
        }
      }
    });

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    //this.getCart();
    this.setState({
      carts: carts,
    })
  }

  description = (e) => {
    // console.log('ee',e);
    let value = e.currentTarget.value;
    const itemId = e.currentTarget.parentNode.dataset.id;

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['description'] = value;
      }
    });

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
        .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    //this.getCart();
    this.setState({
      carts: carts,
    })
  }

  increase = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("increase:");
    console.log(itemId);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];

    console.log("carts", carts, cartBase)
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count']++;
      }
    });

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    //this.getCart();
    this.setState({
      carts: carts,
    })
  }

  updateNum = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNum:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    console.log("Number");
    this.setState({
      numupdate: true,
    })

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });
  }

  updateNumSave = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNumSave:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    let carts = {};
    Object.assign(carts, this.state.carts);

    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    //this.getCart();
    this.setState({
      carts: carts,
    })
  }


  dellCart = (e) => {
    const itemId = e.currentTarget.dataset.id;
    console.log("dellCart:");
    console.log(itemId);

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    let newCart = [];
    cart.forEach((item, index) => {
      if(index != itemId){
        newCart.push(cart[index]);
      }
    });
    carts[this.state.cartNo].items = newCart;

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then((doc) => {
          this.getCart();
        });
  }

  handleOrderNoChange = (e) => {
    this.updateCartData("orderNo",e.currentTarget.value);
  }

  handleCartNameChange = (e) => {
    console.log("cartName",e.currentTarget.value)
    this.updateCartData("cartName", e.currentTarget.value)
  }

  handleToUserChange = (e) => {
    if (e.currentTarget.selectedIndex > 0) {
      this.updateCartData("toUserName",e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name);
      this.updateCartData("toUser",e.currentTarget.value);
    } else {
      this.updateCartData("toUserName",'');
      this.updateCartData("toUser",'');
    }
  }
  handleOrderNoukiChange = (e) => {
    this.updateCartData("orderNouki",e.currentTarget.value);
  }
  handleOrderNoukiDayChange = (e) => {
    this.updateCartData("orderNouki","希望");
    this.updateCartData("orderNoukiDay",e.currentTarget.value);
  }
  handleOrderNoukiDateChange = (e) => {
    this.updateCartData('orderNouki', '日付指定');
    let date = firebase.firestore.Timestamp.fromDate(e);
    this.updateCartData('orderNoukiDate', date);
  }
  handleOrderBasyoChange = (e) => {
    this.updateCartData("orderBasyo",e.currentTarget.value);
  }
  handleOrderZipcodeChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderZipcode",e.currentTarget.value);
  }
  handleOrderAddr1Change = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderAddr1",e.currentTarget.value);
  }
  handleOrderAddr2Change = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderAddr2",e.currentTarget.value);
  }
  handleOrderJigyosyoChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderJigyosyo",e.currentTarget.value);
  }
  handleOrderTantoChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderTanto",e.currentTarget.value);
  }
  handleOrderPhoneChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderPhone",e.currentTarget.value);
  }
  handleOrderBikouChange = (e) => {
    this.updateCartData("orderBikou",e.currentTarget.value);
  }

  sendOtasukeCart = (e) => {
    e.preventDefault();
    this.setState({
      modal: true,
    })
  }

  sendSaveCart = (e) => {
    e.preventDefault();
    this.setState({
      modalSave: true,
    })
  }

  saveCart = (e) => {
    // const itemId = e.currentTarget.parentNode.dataset.id;
    // console.log("cartName:", itemId, itemName);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    console.log(carts, cartBase)
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
        sendHelpCart: 0
      };
      carts[this.state.cartNo] = cartBase;
    }

    // console.log("carts", carts)

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
        .collection('otasuke_carts').doc(this.props.org.id);

    let self = this;
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then(function () {
          self.getCart();
        })
        .catch(function (error) {
          console.error("Error set document: ", error);
        });



    // //this.getCart();
    this.setState({
      modalSave: false,
    })
  }

  modalSaveClose = (e) => {
    this.setState({
      modalSave: false,
    })
  }

  modalClose = (e) => {
    this.setState({
      modal: false,
    })

    let cartId = e && e.currentTarget.dataset.id;
    if(cartId){
      console.log("request");

      let carts = this.state.carts;

      let cartBase = {
        items: [],
        orderNo: '',
        toUser: '',
        toUserName: '',
        orderNouki: '最短',
        orderNoukiDay: '',
        orderNoukiDate: '',
        orderBasyo: '登録住所',
        orderZipcode: '',
        orderAddr1: '',
        orderAddr2: '',
        orderJigyosyo: '',
        orderTanto: '',
        orderPhone: '',
        orderBikou: '',
        requested: false,
        carriage: this.state.carriage,
      };
      if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items){
        Object.assign(cartBase, this.state.carts[this.state.cartNo]);

        if (cartBase.orderNouki === '日付指定' && !cartBase.orderNoukiDate) {
          toast.warn('日付を選択してください', {
            closeButton: true
          });
          return;
        }

        let sendHelpCartDate = firebase.firestore.Timestamp.fromDate(new Date());

        // cartBase.requested = false;
        cartBase.sendHelpCart = 1;
        cartBase.sendHelpCartUser = this.props.user.id;
        cartBase.sendHelpCartUserName = this.props.user.last_name + " " + this.props.user.first_name;
        cartBase.sendHelpCartDate = sendHelpCartDate;
        carts[this.state.cartNo] = cartBase;

        let otasukeCartsHistoryRef = this.props.db.collection('otasuke_carts_history').doc();
        let dueDate = new Date();
        let created = firebase.firestore.Timestamp.fromDate(new Date());

        let setWithMergeOrder = otasukeCartsHistoryRef.set({
          cart: cartBase,
          cartNo: this.state.cartNo,
          toUser: cartBase.toUser,
          toUserName: cartBase.toUserName,
          toOrganization: this.props.org.id,
          fromUser: this.props.user.id,
          fromUserName: this.props.user.last_name + " " + this.props.user.first_name,
          fromOrganization: this.props.user.organization,
          created: created,
          dueDate: dueDate.getTime(),
          latestUpdate: created,
          sortDate: created,
          wait: {
            [this.props.user.id]: true,
          },
          requested: true,
          rejected: false,
          newReject: false,
          newApply: false,
          readSeller: false,
          // readBuyer: false,
          sellerConfirm: false,
          sendHelpCart: 1,
          sendHelpCartUser: this.props.user.id,
          sendHelpCartUserName: this.props.user.last_name + " " + this.props.user.first_name,
          sendHelpCartDate: sendHelpCartDate
        }, { merge: true });

        delete carts[this.state.cartNo]['deletedItem'];
        delete carts[this.state.cartNo]['deletedText'];

        let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
          .collection('otasuke_carts').doc(this.props.org.id);

        let setWithMerge = cartRef.set({
          [this.state.cartNo]: carts[this.state.cartNo],
          update: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true });

        this.getCart();
      }
    }

  }

  showHideCart = (val, cartId) => {
    console.log(val, cartId)
    this.setState({cartselect: val});
    if (cartId*1) {
      // let userType = 'seller';
      if (this.props.userOrg && this.props.userOrg.buyer || this.props.userOrg && this.props.userOrg.reseller && this.props.org.maker) {
        // userType = 'buyer';
        this.props.cProps.history.push('/cart/'+cartId*1)
      } else {
        this.props.cProps.history.push('/otasuke/cart/'+cartId*1)
      }


    }

  }

  updateCartData = (key, val) => {
    let cartData = {};
    if(this.state.carts[this.state.cartNo]){
      cartData = this.state.carts[this.state.cartNo];
    }
    if(cartData.requested) {
      return;
    }

    cartData[key] = val;
    let carts = this.state.carts;
    carts[this.state.cartNo] = cartData;
    if (!cartData.items) {
      cartData.items = [];
      cartData.created = firebase.firestore.Timestamp.fromDate(new Date());
      cartData.rejected = false;
      cartData.creatorUser = this.props.user.id;
      cartData.creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;
    }

    // console.log(carts, cartData, this.state.cartNo, key, val)

    this.setState({
      carts: carts
    });
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let userType = 'seller';
    let dateLabel = '送信日時';
    let title = 'お助けカート送信履歴詳細';
    if (this.props.userOrg && this.props.userOrg.buyer || this.props.userOrg && this.props.userOrg.reseller && this.props.org.maker) {
      userType = 'buyer';
      dateLabel = '受信日時';
      title = 'お助けカート';
    }

    // console.log("userType:", userType)
    let cart = [];
    let total = 0;
    let postage = 0;
    let taxes = {};
    let taxTotal = 0;

    let cartData = {
      items: [],
      cartName: '',
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderNoukiDate: '',
      orderBasyo: '登録住所',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      requested: false,
    };
    if(this.state.otasukeCart && this.state.otasukeCart.cart && this.state.otasukeCart.cart.items && this.state.otasukeCart.cart.items.length){

      function n(n){
        return n > 9 ? "" + n: "0" + n;
      }

      Object.assign(cartData, this.state.otasukeCart.cart);
      let items = this.state.otasukeCart.cart.items;
      items.forEach((item, index) => {
        let  date = item.update.toDate().getFullYear() + '/'
            + n(item.update.toDate().getMonth() + 1) + '/'
            + n(item.update.toDate().getDate())+ ' '
            + n(item.update.toDate().getHours()) + ':'
            + n(item.update.toDate().getMinutes());

        let priceText = <><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</>;
        if (item.deletedItem) {
          priceText = <><span style={{color: 'red'}}>{item.deletedText}</span></>
        }

              cart.push({
                date: date,
                code: item.code,
                name: <Link to={"/items/" + item.id}>{item.name}</Link>,
                maker: item.maker,
                count:
                <div>
                  <div style={{textAlign: 'right', width: '8rem'}}>{priceText}</div>
                  <div className="def-number-input number-input" data-id={index}>
                    <button onClick={this.decrease} disabled={true} className="minus">－</button>
                    <input
                        className="quantity"
                        type="number"
                        value={item.count}
                        disabled={true}
                        onChange={this.updateNum}
                        onBlur={this.updateNumSave}
                    />
                    <button onClick={this.increase} disabled={true} className="plus">＋</button>
                  </div>
                </div>
                ,
                price: <div style={{textAlign: 'right'}}>{!item.deletedItem && (item.count * item.price).toLocaleString() + '円'}</div>,
                // ToDo: Songodson data garahgui bgaag zasah
                description:<div data-id={index}>
                  <textarea
                      className="form-control"
                      rows="2"
                      onChange={this.description}
                      disabled={true}
                      value={item.description || ''}
                  />
                </div>,
                btn: <MDBBtn size="sm4" className="text-nowrap"
                        onClick={this.dellCart}
                        data-id={index}
                        disabled={true}
                      >削除</MDBBtn>,
              })

        if (!item.deletedItem) {
          if (taxes[item.tax]) {
            taxes[item.tax] += (item.count * item.price * item.tax);
          } else {
            taxes[item.tax] = (item.count * item.price * item.tax);
          }
          taxTotal += parseInt(item.count * item.price * item.tax, 10);
          total += item.count * item.price;
        }

      });
      postage = this.state.carriage;
      total += postage;
    }

    const isEnable = (cartData.toUser !== '')
    && (this.state.orderNouki !== '')
    && (this.state.orderBasyo !== '');

    const data = {
      columns: [
        {
          label: '年月日',
          field: 'date',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品コード',
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: 'price,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '備考',
          field: 'description,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '削除',
          field: 'delete',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      //rows: this.state.dbItems

      rows: cart
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }



    let receivedDate = '';
    if (this.state.otasukeCart.created) {
      receivedDate = this.state.otasukeCart.created.toDate().getFullYear() + '/'
          + n(this.state.otasukeCart.created.toDate().getMonth() + 1) + '/'
          + n(this.state.otasukeCart.created.toDate().getDate()) + ' '
          + n(this.state.otasukeCart.created.toDate().getHours()) + ':'
          + n(this.state.otasukeCart.created.toDate().getMinutes());
    }

    let deliveryAddress = {};

    if (cartData.orderBasyo === 'アドレス帳から選択する' || cartData.orderBasyo === '前回の納品先') {
      deliveryAddress = cartData.deliveryAddress || {};
    } else  if (!(
        cartData.orderPropertyName === '' &&
        cartData.orderZipcode === '' &&
        cartData.orderAddr1 === '' &&
        cartData.orderAddr2 === '' &&
        cartData.orderJigyosyo === '' &&
        cartData.orderTanto === '' &&
        cartData.orderPhone === ''
    )) {
      deliveryAddress = {
        propertyName: cartData.orderPropertyName,
        postCode: cartData.orderZipcode,
        address1: cartData.orderAddr1,
        address2: cartData.orderAddr2,
        officeName: cartData.orderJigyosyo,
        personInCharge: cartData.orderTanto,
        phoneNumber: cartData.orderPhone
      }
    }

    if (cartData.orderBasyo === '納品先を直接入力する') {
      deliveryAddress = {
        propertyName: cartData.orderPropertyName,
        postCode: cartData.orderZipcode,
        address1: cartData.orderAddr1,
        address2: cartData.orderAddr2,
        officeName: cartData.orderJigyosyo,
        personInCharge: cartData.orderTanto,
        phoneNumber: cartData.orderPhone
      }
    }

    let disable = cartData.deletedItem;

    // console.log(cartData, Object.keys(deliveryAddress).length, deliveryAddress)

    return (
      <>
        <h2><MDBIcon icon="shopping-cart" className="mr-3" />{title}</h2>

        <div className="form-group" style={{display: "-webkit-inline-box", margin: "25px 0"}}>
          <label htmlFor="cartName" className="col-form-label">カート名称: {cartData.cartName}</label>
        </div>

        <MDBTable btn responsive>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{total.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map((value, index) => {
              return <tr key={index}>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        { cart.length>0 &&
        <div>

          <div className="form-group row">
            <label htmlFor="orderNo" className="col-sm-2 col-form-label">発注番号</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="orderNo"
                placeholder="発注番号"
                disabled={true}
                onChange={this.handleOrderNoChange}
                value={cartData.orderNo}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="orderTo" className="col-sm-2 col-form-label">お助けカート送信先</label>
            <div className="col-sm-10">
              <input
                  type="text"
                  className="form-control"
                  id="orderTo"
                  placeholder="選択してください"
                  disabled={true}
                  // onChange={this.handleOrderNoChange}
                  value={cartData.toUserName}
              />
              {/*<select */}
              {/*  className="browser-default custom-select"*/}
              {/*  id="billToUser"*/}
              {/*  disabled={true}*/}
              {/*  onChange={this.handleToUserChange}*/}
              {/*  value={cartData.toUser}*/}
              {/*  >*/}
              {/*  <option>選択してください</option>*/}
              {/*  {this.props.org && this.props.org.users &&*/}
              {/*    this.props.org.users.map((user) => */}
              {/*      <option key={user.user} value={user.user} data-name={user.name}>{user.name}</option>*/}
              {/*    )*/}
              {/*  }*/}
              {/*</select>*/}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">希望納期</label>
            <div className="col-sm-10">
            <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderNouki"
                  id="orderNouki1"
                  value="最短"
                  disabled={true}
                  onChange={this.handleOrderNoukiChange}
                  checked={(cartData.orderNouki=='最短' || !cartData.orderNouki) ? true : false}

                />
                <label className="form-check-label" htmlFor="orderNouki1">
                  最短
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderNouki"
                  id="orderNouki2"
                  value="希望"
                  disabled={true}
                  onChange={this.handleOrderNoukiChange}
                  checked={cartData.orderNouki=='希望' ? true : false}
                />
                <label className="form-check-label" htmlFor="orderNouki2" style={{height: 'auto'}}>
                  <div className="form-group form-inline">
                    <input
                    type="number"
                    className="form-control"
                    id="orderNoukiDay"
                    placeholder=""
                    onChange={this.handleOrderNoukiDayChange}
                    value={cartData.orderNoukiDay}
                    disabled={true}
                    />
                    日
                  </div>
                </label>
              </div>
              <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="orderNouki"
                    id="orderNouki3"
                    value="日付指定"
                    disabled={true}
                    onChange={this.handleOrderNoukiChange}
                    checked={cartData.orderNouki=='日付指定' ? true : false}
                />
                <label className="form-check-label" htmlFor="orderNouki3" style={{height: 'auto'}}>
                  <div className="form-group form-inline" >
                    日付指定
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        id="orderNoukiDate"
                        className="form-control"
                        selected={cartData.orderNoukiDate ? cartData.orderNoukiDate.toDate() : ''}
                        onChange={this.handleOrderNoukiDateChange}
                        autoComplete={'off'}
                        disabled={true}
                        locale="ja-JP"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">納品場所</label>
            <div className="col-sm-10">
              <div className={'form-check'} style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <div>
                  <input
                      className={'form-check-input'}
                      type={'radio'}
                      name={'orderBasyo'}
                      id={'orderBasyo1'}
                      // value={''}
                      disabled={true}
                      checked={(cartData.orderBasyo === 'アドレス帳から選択する' || cartData.orderBasyo === '前回の納品先' || cartData.orderBasyo === '納品先を直接入力する') ? true : false}
                  />
                  <label className={'form-check-label'} htmlFor={'orderBasyo1'}>
                    納品先
                  </label>
                </div>
                {Object.keys(deliveryAddress).length > 0 && <>
                    <div>
                      <div>物件名：{deliveryAddress.propertyName}</div>
                      <div>{deliveryAddress.postCode}</div>
                      <div>{deliveryAddress.address1} {deliveryAddress.address2} {deliveryAddress.officeName}</div>
                      <div>受取担当者：{deliveryAddress.personInCharge}</div>
                      <div>電話番号：{deliveryAddress.phoneNumber}</div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <div style={{width: 262}}>
                        <MDBBtn className="btn-sm"
                          color=""
                          onClick={(e) => this.addToDeliveryAddress(deliveryAddress)}
                          disabled={this.state.reachMax}
                          style={{width: 250, backgroundColor: '#2D486A', color: '#ffffff'}}>
                            この納品場所をアドレス帳に追加する
                        </MDBBtn>
                        {this.state.reachMax &&
                          <div style={{color: '#ff3547', fontSize: '0.64rem', margin: 6}}>
                            保存可能な件数は５０件です。 いずれかを削除するか編集してご利用下さい。
                          </div>
                        }

                      </div>
                    </div>
                  </>
                }

              </div>
              <div className={'form-check'} style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <div>
                  <input
                      className={'form-check-input'}
                      type={'radio'}
                      name={'orderBasyo'}
                      id={'orderBasyo2'}
                      disabled={true}
                      checked={cartData.orderBasyo === '納品先を指定しない' ? true : false}
                  />
                  <label className={'form-check-label'} htmlFor={'orderBasyo2'}>
                    納品先は指定されていません
                  </label>
                  {(userType === 'buyer' && cartData.orderBasyo === '納品先を指定しない') &&
                      <label className={'form-check-label'} style={{marginLeft: 50, color: '#FF3547'}}>
                        カートに入れてから指定してください。
                      </label>
                  }

                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="orderBikou" className="col-sm-2 col-form-label">備考</label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                id="orderBikou"
                rows="3"
                disabled={true}
                onChange={this.handleOrderBikouChange}
                value={cartData.orderBikou}
          />
            </div>
          </div>

          <div className={"form-group row"}>
            <label style={{color: '#707070'}} className={"col-sm-5"}>
              カートID：{this.props.id}
              <br/>
              {dateLabel}：{receivedDate}
            </label>
          </div>


        </div>
        }

        {/*<MDBBtn*/}
        {/*    className="btn-block"*/}
        {/*    color="success"*/}
        {/*    // disabled={cartData.sendHelpCart}*/}
        {/*    onClick={this.sendOtasukeCart}*/}
        {/*>このお助けカートを送信する</MDBBtn>*/}

        {
          userType === 'buyer' ?
              <div style={{textAlign: 'center'}}>
                <MDBBtn
                    className="text-nowrap custom-button-grey"
                    color=""
                    style={{width: 200}}
                    data-id={this.props.id}
                    data-title={this.state.otasukeCart && this.state.otasukeCart.cart && this.state.otasukeCart.cart.cartName}
                    onClick={this.deleteHelpCart}
                >削除する</MDBBtn>
                <MDBBtn
                    className="text-nowrap"
                    color="success"
                    style={{width: 200}}
                    onClick={this.addCart}
                    disabled={disable}
                >カートに追加する</MDBBtn>
              </div>
              :
              <div style={{textAlign: 'center'}}>
                <MDBBtn
                    className="btn-block"
                    color="success"
                    // style={{width: 200}}
                    onClick={this.addCart}
                    disabled={disable}
                >この履歴を使ってお助けカートを作成する</MDBBtn>
              </div>
        }



        {
          userType === 'buyer' ?
              <OtasukeCartAllSelect isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
              :
              <OtasukeCartAllSelectOtasuke isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
        }

        <MDBModal isOpen={this.state.deleteModal} toggle={this.modalDelClose}>
          <MDBModalHeader toggle={this.modalDelClose}>お助けカート削除</MDBModalHeader>
          <MDBModalBody>
            お助けカート「{this.state.deleteTitle}」を削除します。 よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDelClose} color={'secondary'} size={'sm'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalDelClose} color={'danger'} size={'sm'} data-id={this.state.deleteId}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default OtasukeCartDetail;
