import React from 'react';


class ItemImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: undefined,
    }
  }

  getItemImage = () => {
    if (this.props.src) {
      try {
        let gsReference = this.props.storage.refFromURL(this.props.src)
            .getDownloadURL()
            .then((url) => {
              this.setState({image: url})
            })
      } catch (e) {
        // console.log(e, this.props.src)
        this.setState({image: undefined})
      }
    }
  }

  componentDidMount() {
    this.getItemImage();
  }

  render() {
    if(this.state.image){
      return (
        <img src={this.state.image} alt={this.props.alt} className={this.props.className} style={this.props.style} />
      )
    }else{
      return (<></>)
    }
  }
}
export default ItemImage;