import React, { Component } from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

class SyukkaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.isOpen,
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
    this.props.closeHandler();
  }
  
  componentDidUpdate = () => {
    if (this.state.modal != this.props.isOpen){
      this.setState({
        modal: this.props.isOpen,
      });
    }
  }


  render() {
    
    return (
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>出荷日・送り状番号</MDBModalHeader>
      <MDBModalBody>
      出荷日・送り状番号を送信しました。
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn 
          className="btn-lg btn-block"
          onClick={this.toggle}
        >確　認</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
      
  
    );
  }
}

export default SyukkaModal;