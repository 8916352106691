import React from "react";
import { MDBSpinner, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBIcon} from 'mdbreact';
import { BrowserRouter, Route, Link } from 'react-router-dom'
import firebase from "../../firebase";

class OtasukeCartList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      carts: [],
      last: null,
      reportPerPage: 12,
      modal: false,
      deleteCartID: null,
      deleteCartName: null
    }
  }

  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && collection){
      let ocRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id).collection('otasuke_carts').doc(this.props.org.id);
      let oc = await ocRef.get();
      if (oc.exists) {
        let ocData = oc.data();

        // let withDeletedItem = false;
        for (let i=0; i<5; i++) {
          let ocCart = ocData[i];

          if (ocCart && this.props.userOrg) {
            let items = ocCart.items;
            if (items) {
              delete ocCart['deletedItem'];
              delete ocCart['deletedText'];
              await Promise.all(items.map(async (item) => {
                let itemSell = await collection.doc(item.id).get();
                let itemSellData = itemSell.data();

                delete item['deletedItem'];
                delete item['deletedText'];

                if (!itemSellData) {
                  ocCart.deletedItem = true;
                  ocCart.deletedText = '販売終了商品が含まれています';
                  item.deletedItem = true;
                  item.deletedText = '販売終了商品が含まれています';
                } else if (!itemSellData['price'] || itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                  ocCart.deletedItem = true;
                  ocCart.deletedText = '要見積商品が含まれています';
                  item.deletedItem = true;
                  item.deletedText = '要見積商品が含まれています';
                } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                  ocCart.deletedItem = true;
                  ocCart.deletedText = '要見積商品が含まれています';
                  item.deletedItem = true;
                  item.deletedText = '要見積商品が含まれています';
                } else if (itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                  let expiration_date = itemSellData['price'][0]['expiration_date'];
                  let expDate = expiration_date.toDate();

                  if ((expDate.getTime() - this.props.dateNow) < 0) {
                    ocCart.deletedItem = true;
                    ocCart.deletedText = '価格有効期限切れ要見積が含まれています';
                    item.deletedItem = true;
                    item.deletedText = '価格有効期限切れ要見積が含まれています';
                  } else {
                    item.price = itemSellData['price'][0]['price'];
                  }
                } else {
                  item.price = itemSellData['price'][0]['price'];
                }

                // 要見積商品が含まれています
                // if (itemSellData && itemSellData['price']) {
                //   item.price = itemSellData['price'][0]['price'];
                // } else {
                //   withDeletedItem = true;
                //   ocCart.items = ocCart.items.filter((obj) => obj.id !== item.id);
                // }
              }));
            }
          }
        }

        // if (withDeletedItem) {
        //   ocRef.set(ocData, {merge: true});
        // }

        this.setState({carts: ocData})
      }

      // this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      //     .collection('otasuke_carts').doc(this.props.org.id)
      //     .get()
      //     .then((doc) => {
      //       if(doc.exists) {
      //         let data = doc.data();
      //         this.setState({carts: data})
      //       }
      //     })
    }
  }

  componentDidMount() {
    //this.getCarts();
    this.getCarts(this.props);
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  // componentWillReceiveProps(nextProps) {
  //   this.getCarts(nextProps);
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.org !== prevProps.org || this.props.userOrg !== prevProps.userOrg) {
      this.getCarts();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    console.log("LAST:" , this.state.last);
    this.getCarts(this.props);
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);
  }

  delete = (e) => {
    console.log('delete', e.currentTarget.dataset.id);
    let id = e.currentTarget.dataset.id;
    let title = e.currentTarget.dataset.title;
    this.setState({
      modal: true,
      deleteCartID: id,
      deleteCartName: title
    })
  }

  modalClose = (e) => {
    // console.log(e)
    let deleteCartId = e && e.currentTarget.dataset.id;
    if(deleteCartId){
      let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
          .collection('otasuke_carts').doc(this.props.org.id);
      let setWithMerge = cartRef.set({
        [deleteCartId]: {},
        update: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })
          .then((doc) => {
            this.setState({
              modal: false,
              deleteCartID: null,
              deleteCartName: null
            }, () => {
              this.getCarts();
            });
          });
    } else {
      this.setState({
        modal: false,
        deleteCartID: null,
        deleteCartName: null
      })
    }
  }


  render() {
    if (! this.props.org ) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let carts = [];
    for(let i=0; i<5; i++){
      if(this.state.carts[i] && this.state.carts[i].items){ // && this.state.carts[i].items.length > 0
        let items = this.state.carts[i].items;
        let cartName = this.state.carts[i].cartName || '名称未設定';
        let creatorName = this.state.carts[i].creatorUserName || '-'
        let date = '-';
        let time = '-';
        let amount = 0;
        let name= '';
        let recipient = this.state.carts[i].toUserName || '-';
        let deletedItem = this.state.carts[i].deletedItem;
        let deletedText = this.state.carts[i].deletedText;
        let d = [];
        let deletedDescription = '';

        if (this.state.carts[i].latestUpdate) {
          date = this.state.carts[i].latestUpdate.toDate().getFullYear() + '/'
              + n(this.state.carts[i].latestUpdate.toDate().getMonth() + 1) + '/'
              + n(this.state.carts[i].latestUpdate.toDate().getDate());
              time =  n(this.state.carts[i].latestUpdate.toDate().getHours()) + ':'
              + n(this.state.carts[i].latestUpdate.toDate().getMinutes());
        }

        // console.log(this.state.carts[i])

        items.forEach((item, index) => {
          if (!item.deletedItem) {
            amount += (item.price*item.count);
          } else {
            if (!d.includes(item.deletedText)) {
              d.push(item.deletedText)
              deletedDescription = <>{deletedDescription}<span style={{color: 'red'}}>{item.deletedText}</span><br /></>
            }
          }
          if(name != ''){
            name = <>{name}{item.name}<br /></>;
          }else{
            name = <>{item.name}<br /></>;
          }
        });

        if (deletedItem) {
          name = <>{name}{deletedDescription}</>
        }

        carts.push({
          'カート番号': <>{i+1}</>,
          '保存日時': <span>{date}<br/>{time}</span>,
          '送信先': recipient,
          'お助けカート名称': cartName,
          '内容': name,
          '金額': <div style={{textAlign: 'right'}}>{amount > 0 && amount.toLocaleString()}</div>,
          '詳細': <div style={{textAlign: 'center'}}>
            <Link to={"/otasuke/cart/" + (i+1)}>
              <MDBBtn rounded size="sm" color={''} className={'custom-button-lightgreen'}>表示</MDBBtn>
            </Link>
          </div>,
          '削除': <div style={{textAlign: 'center'}}>
            <MDBBtn rounded flat size="sm"
                    data-id={i}
                    data-title={cartName}
                    onClick={this.delete}>
              <MDBIcon
                  icon="trash"
                  size={'2x'}
                  className="custom-text-grey"/>
            </MDBBtn>
          </div>
        })
      }else{
        carts.push({
          'カート番号': <>{i+1}</>,
          '保存日時': '-',
          '送信先': '-',
          'お助けカート名称': '-',
          '内容': '空です',
          '金額': <div style={{textAlign: 'right'}}>-</div>,
          '詳細': <div style={{textAlign: 'center'}}>-</div>,
          '削除': '',
        })
      }
    }

    const data = {
      columns: [
        {
          label: 'カート番号',
          field: 'カート番号',
          sort: 'asc',
          // width: 80,
          minimal: 'sm'
        },
        {
          label: '保存日時',
          field: '保存日時',
          sort: 'disabled',
          // width: 370,
          minimal: 'sm'
        },
        {
          label: '送信先',
          field: '送信先',
          sort: 'disabled',
          width: 100,
          minimal: 'sm'
        },
        {
          label: 'お助けカート名称',
          field: 'お助けカート名称',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '内容',
          field: '内容',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: '金額',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>詳細</div>,
          field: '詳細',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>削除</div>,
          field: '削除',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        }
      ],
      rows: carts
    }


    return (
      <>
        <h2>お助けカート一覧</h2>
        <MDBTable responsive btn small>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>お助けカート削除</MDBModalHeader>
          <MDBModalBody>
            お助けカート「{this.state.deleteCartName}」を削除します。 よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalClose} color="danger" data-id={this.state.deleteCartID} >OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

      </>
    )

  }
}

export default OtasukeCartList;
