import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact';

const ItemCard = ({title,price,description,image}) => {
  const style = {marginBottom: 10};
  return (
    <MDBCol md="4" style={style}>
      <MDBCard style={{ height: "100%" }}>
        <MDBCardImage className="img-fluid" src={image} waves />
        <MDBCardBody>
          <MDBCardTitle>{title}</MDBCardTitle>
          <MDBCardText>
            {description}
          </MDBCardText>
          <MDBCardText>
            参考価格 {price}円
          </MDBCardText>
          <MDBBtn href="#">カートに入れる</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  )
}
ItemCard.defaultProps = {
  title: "商品名",
  price: 0,
  description:"商品説明",
  image:"/sample.jpg"
}
export default ItemCard;