import React, { Component } from 'react';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"

class CartSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.isOpen,
      carts: [],
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  
  componentDidUpdate = () => {
    if (this.state.modal != this.props.isOpen){
      this.setState({
        modal: this.props.isOpen,
      });
    }
  }

  getCarts = () => {
    if(this.props.org){
    this.props.db.collection('carts').doc(this.props.user.id)
      .collection('carts').doc(this.props.org.id)
      .get()
      .then((doc) => {
        if(doc.exists) {
          let data = doc.data();
          this.setState({carts: data})
        }
      })
    }
  }

  componentDidMount() {
    this.getCarts();
  }

  addCart = (e) => {
    const cartId = e.currentTarget.dataset.id;

    let carts = this.state.carts;
    if(!carts[cartId]){
      carts[cartId] = {items: []}
    }

    let cart = carts[cartId].items;
    if(!cart){
      cart=[];
    }
    console.log(carts);
    cart.push({
      name: this.props.data['NS商品名（売上） 品名&色&容量'] ? this.props.data['NS商品名（売上） 品名&色&容量'] : '',
      maker: this.props.data['メーカー名'] ? this.props.data['メーカー名'] : '',
      code: this.props.data['NS商品C（自社_仕入先C&自社_品種C&自社_品名&自社_色&自社_容量）'] ? this.props.data['NS商品C（自社_仕入先C&自社_品種C&自社_品名&自社_色&自社_容量）'] : '',
      price: this.props.data['売単価'] ? this.props.data['売単価'] : 0,
      id: this.props.data.id,
      count:1,
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    carts[cartId].items = cart;
    carts[cartId].rejected = false;

    let cartRef = this.props.db.collection('carts').doc(this.props.user.id)
      .collection('carts').doc(this.props.org.id);

    let setWithMerge = cartRef.set({
      [cartId]: carts[cartId],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    this.toggle();
  }

  render() {
    let cart = [];
    for(let i=0; i<5; i++){
      if(this.state.carts[i] && this.state.carts[i].items && this.state.carts[i].items.length > 0){
        let items = this.state.carts[i].items;
        let date = '---';
        let amount = 0;
        let name=<></>;
        items.forEach((item, index) => {
          amount += item.price;
          if(name != ''){
            name = <>{name}{item.name}<br /></>;
          }else{
            name = <>{item.name}<br /></>;
          }
          date = item.update.toDate().getFullYear() + '年'
               + (item.update.toDate().getMonth() + 1) + '月'
               + item.update.toDate().getDate() + '日';
          });

        cart.push({
          'no': <><MDBIcon icon="shopping-cart" size="2x" className="indigo-text mr-3" />カート{i+1}</>,
          '商品名': name,
          '金額': amount.toLocaleString(),
          'date': date,
          btn: (this.state.carts[i].requested ? 
                <MDBBtn size="sm4" className="text-nowrap"
                onClick={this.addCart}
                disabled={true}
                color="warning"
                data-id={i}
                >承認待</MDBBtn>
                :
                <MDBBtn size="sm4" className="text-nowrap"
                  onClick={this.addCart}
                  data-id={i}
                >選択</MDBBtn>),
        })
      }else{
        cart.push({
          'no': <><MDBIcon icon="shopping-cart" size="2x" className="indigo-text mr-3" />カート{i+1}</>,
          '商品名': 'カートは空です',
          '金額': '---',
          'date': '---',
          btn: <MDBBtn size="sm4" className="text-nowrap"
                  onClick={this.addCart}
                  data-id={i}
                >選択</MDBBtn>,
        })
      }
    }

    const data = {
      columns: [
        {
          label: 'カート番号',
          field: 'no',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: '商品名',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '金額',
          field: '金額',
          sort: 'amount',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '保存日時',
          field: 'date',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '選択',
          field: 'btn',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      //rows: this.state.dbItems
      
      rows: cart
    }
    
    
    return (
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>カート選択</MDBModalHeader>
      <MDBModalBody>
        カートを選択してください
        <MDBTable btn responsive>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn 
          className="btn-lg btn-block"
          color="secondary"
          onClick={this.toggle}
        >キャンセル</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    
    
  
    );
  }
}

export default CartSelect;