import React from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer
} from 'mdbreact';
import FILELink from "../Item/FILELink";
import firebase from "../../firebase";
import "./SDSManagement.css";
import DatePicker from "react-datepicker";

class SDSManagementHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      dbSDSs: [],
      deleteModal: false,
      selectHistory: {},
      selectHistoryDate: null,
      selectEditHistoryDate: null,
      dateInput: {},
      editModal: false
    }
  }

  getSDSs = (props) => {
    // console.log("###### getSDSs ########", props)

    if (this.state.loading) {
      return;
    }

    this.setState({loading: true});

    this.props.db.collection('sds_management').doc(this.props.id).get()
    .then((snapshot) => {
      let data = snapshot.data();
      this.setState({
        data: data,
        loading: false
      })
    }).catch((e) => {
      this.setState({
        data: {},
        loading: false
      })
    });

  }

  componentDidMount() {
    this.getSDSs(this.props);
    // console.log('this.props.id', this.props.id)
  }
  componentWillReceiveProps(nextProps) {
    this.getSDSs(nextProps);
  }

  cancelEditHistory = (date, sdsHistory) => {
    this.setState({
      selectEditHistoryDate: null,
      dateInput: {},
      editModal: false
    })
  }

  saveEditHistory = (date, sdsHistory) => {
    console.log(date, sdsHistory);

    this.setState({
      editModal: true
    })
  }

  editModalClose = (e) => {
    this.setState({
      editModal: false
    })
    let confirm = e && e.currentTarget.dataset.confirm;

    if (confirm) {
      console.log('confirm')

      let history = this.state.data.history;
      let lastHistory = history[this.state.selectEditHistoryDate].slice(-1)[0];

      let di = this.state.dateInput[this.state.selectEditHistoryDate];

      lastHistory.checkDate = di['checkDate'] ? firebase.firestore.Timestamp.fromDate(di['checkDate']) : null;
      lastHistory.checker = this.props.user.id;
      lastHistory.checkerUserName = this.props.user.last_name + ' ' + this.props.user.first_name;
      lastHistory.revisionDate = di['revisionDate'] ? firebase.firestore.Timestamp.fromDate(di['revisionDate']) : null;

      let sdsManagementRef = this.props.db.collection('sds_management').doc(this.props.id);
      sdsManagementRef.set({
        history: history
      }, {merge: true})

      this.setState({
        selectEditHistoryDate: null,
        dateInput: {},
        editModal: false
      })
    }
  }

  editHistory = (date, sdsHistory) => {
    console.log('edit',sdsHistory, date)

    let dateInput = {};
    dateInput[date] = {};
    if (sdsHistory.checkDate) {
      dateInput[date]['checkDate'] = sdsHistory.checkDate.toDate();
    }
    if (sdsHistory.revisionDate) {
      dateInput[date]['revisionDate'] = sdsHistory.revisionDate.toDate();
    }
    // dateInput[date]['checkDate'] = sdsHistory.checkDate.toDate();
    // dateInput[date]['revisionDate'] = sdsHistory.revisionDate.toDate();

    this.setState({
      selectEditHistoryDate: date,
      dateInput: dateInput
    })
  }

  deleteHistory = (date, sdsHistory) => {
    // console.log('delete',sdsHistory, date, this.state.data);

    this.setState({
      selectHistory: sdsHistory,
      selectHistoryDate: date,
      deleteModal: true
    })
  }

  deleteModalClose = (e) => {
    this.setState({
      deleteModal: false
    })
    let confirm = e && e.currentTarget.dataset.confirm;

    if (confirm) {
      let history = this.state.data.history;
      let preferredDate = this.state.data.preferredDate;
      history[this.state.selectHistoryDate] = history[this.state.selectHistoryDate].slice(0, -1);

      let lastCheckDateTimestamp = 0;
      let lastCheckDate = null;
      let lastCheckUser = null;
      let lastCheckUserName = null;
      let revisionDate = null;

      //TODO: check status
      let dateNow = firebase.firestore.Timestamp.now().toDate();
      let yearNow = dateNow.getFullYear();
      let monthNow = dateNow.getMonth()+1;
      let dayNow = dateNow.getDate();

      const date1 = new Date(yearNow+'/'+monthNow+'/'+dayNow);
      const date2 = new Date(yearNow+'/'+(monthNow+1)+'/1');
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      let status = 'normal';
      Object.keys(preferredDate).forEach(v => {
        if (preferredDate[v]) {
          console.log('preferredDate', v)
          if (monthNow+1 === Number(v) && diffDays <= 7 && 0 <= diffDays && !history[yearNow+'/'+v]) {
            status = 'warning';
          }
          if (monthNow >= v && (!history[yearNow+'/'+v] || history[yearNow+'/'+v] && history[yearNow+'/'+v].length === 0)) {
            status = 'alert';
          }
        }
      })
      // console.log('status', status)

      Object.keys(history).forEach((h) => {
        if (history[h] && history[h].slice(-1)[0] && (history[h].slice(-1)[0].checkDate && history[h].slice(-1)[0].checkDate.toDate().getTime() > lastCheckDateTimestamp)) {
          lastCheckDateTimestamp = history[h].slice(-1)[0].checkDate.toDate().getTime()

          lastCheckDate = history[h].slice(-1)[0].checkDate;
          lastCheckUser = history[h].slice(-1)[0].checker;
          lastCheckUserName = history[h].slice(-1)[0].checkerUserName;

          revisionDate = history[h].slice(-1)[0].revisionDate;
        }
      })

      let sdsManagementRef = this.props.db.collection('sds_management').doc(this.props.id);
      sdsManagementRef.set({
        history: history,
        lastCheckDate: lastCheckDate,
        lastCheckUser: lastCheckUser,
        lastCheckUserName: lastCheckUserName,
        revisionDate: revisionDate,
        status: status
      }, {merge: true})
    }
  }

  handleChange = (yearMonth, date, type) => {
    // console.log(date)
    let dateInput = this.state.dateInput
    let d = dateInput[yearMonth] || {};
    d[type] = date;
    dateInput[yearMonth] = d;
    console.log(dateInput)
    this.setState({
      dateInput: dateInput
    });
  }

  render() {
    if (! this.props.org ) {
      return (
          <>
          </>
      )
    }

    const historySDS = [];
    let history = this.state.data.history;

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    history && Object.keys(history).forEach((sdsHistory) => {
      let m = sdsHistory.split('/')[1]*1
      let sds = history[sdsHistory].slice(-1)[0];

      if (sds) {
        let checkDate = '';
        if (sds['checkDate']) {
          checkDate = sds['checkDate'].toDate().getFullYear() + '/'
              + n(sds['checkDate'].toDate().getMonth()+1) + '/'
              + n(sds['checkDate'].toDate().getDate()) + ' '
              + n(sds['checkDate'].toDate().getHours()) + ':'
              + n(sds['checkDate'].toDate().getMinutes());
        }

        // console.log('checkDate', checkDate);
        let checkerUserName = sds.checkerUserName || '';

        let revisionDate = '';
        if (sds['revisionDate']) {
          revisionDate = sds['revisionDate'].toDate().getFullYear() + '/'
              + n(sds['revisionDate'].toDate().getMonth()+1) + '/'
              + n(sds['revisionDate'].toDate().getDate());
        }

        if (this.state.selectEditHistoryDate === sdsHistory) {
          checkDate = <DatePicker
              dateFormat="yyyy/MM/dd HH:mm:ss"
              id="checkDate"
              className="form-control"
              showTimeInput
              selected={this.state.dateInput[sdsHistory] && this.state.dateInput[sdsHistory].checkDate}
              onChange={(v) => this.handleChange(sdsHistory, v, 'checkDate')}
              autoComplete={'off'}
              locale="ja-JP"
          />

          revisionDate = <DatePicker
              dateFormat="yyyy/MM/dd"
              id="revisionDate"
              className="form-control"
              selected={this.state.dateInput[sdsHistory] && this.state.dateInput[sdsHistory].revisionDate}
              onChange={(v) => this.handleChange(sdsHistory, v, 'revisionDate')}
              autoComplete={'off'}
              locale="ja-JP"
          />
        }

        console.log('sds.preferredMonth', sds.preferredMonth)

        let data = {
          'SDSチェック希望月': sds.preferredMonth,
          'SDSチェック実施日時': checkDate,
          'チェック実施者': checkerUserName,
          'SDS改定日': revisionDate,
          // m: m
        };

        if (this.props.user && this.props.user.admin) {
          if (this.state.selectEditHistoryDate === sdsHistory) {
            data.action = <div style={{width: 155}}>
              <MDBBtn size="sm4" color={'secondary'} style={{color: 'black', padding: '0.26em 1.5em', borderRadius: 15}} onClick={() => this.cancelEditHistory(sdsHistory, sds)} >戻る</MDBBtn>
              <MDBBtn size="sm4" color={'default'} style={{color: 'black', padding: '0.26em 1.5em', borderRadius: 15}} onClick={() => this.saveEditHistory(sdsHistory, sds)} disabled={!(this.state.dateInput[sdsHistory].checkDate && this.state.dateInput[sdsHistory].revisionDate)} >保存</MDBBtn>
            </div>
          } else {
            data.action = <div style={{width: 155}}>
              <MDBBtn size="sm4" color={''} style={{color: 'black', padding: '0.26em 1.5em', borderRadius: 15}} onClick={() => this.editHistory(sdsHistory, sds)} >編集</MDBBtn>
              <MDBBtn size="sm4" color={'danger'} style={{color: 'black', padding: '0.26em 1.5em', borderRadius: 15}} onClick={() => this.deleteHistory(sdsHistory, sds)} >削除</MDBBtn>
            </div>
          }
        }

        historySDS.push(data);
      }
    })

    // historySDS.sort((a, b) => {
    //   let aDate = a['SDSチェック希望月'];
    //   let aYear = aDate.split('/')[0];
    //
    //   let bDate = b['SDSチェック希望月'];
    //   let bYear = bDate.split('/')[0];
    //
    //   let ay = aYear*1;
    //   let by = bYear*1;
    //   return ay - by;
    // });
    //
    // historySDS.sort((a, b) => {
    //   let aDate = a['SDSチェック希望月'];
    //   let aMonth = aDate.split('/')[1]
    //
    //   let bDate = b['SDSチェック希望月'];
    //   let bMonth = bDate.split('/')[1]
    //
    //   let am = aMonth*1;
    //   let bm = bMonth*1;
    //   return am - bm;
    // });

    historySDS.sort((a,b) => {
      let aDate = 1;
      let bDate = 1;
      try {
        aDate = new Date(a['SDSチェック希望月']).getTime();
        bDate = new Date(b['SDSチェック希望月']).getTime();
      } catch (e) {
        console.log('e', e)
      }

      return aDate-bDate
    })

    let columns = [
      {
        label: 'SDSチェック希望月',
        field: 'SDSチェック希望月',
        sort: 'asc',
        // width: 50,
        minimal: 'sm'
      },
      {
        label: 'SDSチェック実施日時',
        field: 'SDSチェック実施日時',
        sort: 'asc',
        // width: 80,
        minimal: 'sm'
      },
      {
        label: 'チェック実施者',
        field: 'チェック実施者',
        sort: 'asc',
        // width: 80,
        minimal: 'sm'
      },
      {
        label: 'SDS改定日',
        field: 'SDS改定日',
        sort: 'asc',
        // width: 80,
        minimal: 'sm'
      }
    ];

    if (this.props.user && this.props.user.admin) {
      columns.push({
        label: <div style={{width: 160}} />,
        field: 'action',
        sort: 'asc',
        width: 160,
        // minimal: 'sm'
      },)
    }

    const data = {
      columns: columns,
      rows: historySDS
    }

    let sds = this.state.data;
    let sdsBtn = [];
    let title = '';
    let applicationUserName = '';
    let applicationDate = '';
    let receptionUserName = '';
    let receptionDate = '';
    let sdsMonths = '';

    Object.keys(sds.preferredDate || {}).forEach(value => {
      if (sds.preferredDate[value]) {
        if (sdsMonths) {
          sdsMonths += ' / '
        }
        sdsMonths += (value + '月');
      }
    })

    if (sds.item) {
      for(let i in sds['item']['files']) {
        let e = sds['item']['files'][i];
        if (e && e.name && (e.name.toLowerCase().indexOf("ｓｄｓ") !== -1 || e.name.toLowerCase().indexOf("sds") !== -1)) {
          sdsBtn.push(<FILELink src={e['file']} storage={this.props.storage} target="_blank" style={{display: 'flex', flex: 1}}>
            <MDBBtn size="sm4" color={''} style={{color: 'black', padding: '0.26em 1.5em', borderRadius: 15}} >SDS</MDBBtn>
          </FILELink>)
        }
      }

      title = sds['item'] && sds['item']['P4CLOUD商品名'];
      applicationUserName = sds['applicationUserName'] || '';

      if (sds['created']) {
        applicationDate = sds['created'].toDate().getFullYear() + '/'
            + n(sds['created'].toDate().getMonth()+1) + '/'
            + n(sds['created'].toDate().getDate()) + '';
      }

      receptionUserName = sds['receptionUserName'] || '';

      if (sds['receptionDate']) {
        receptionDate = sds['receptionDate'].toDate().getFullYear() + '/'
            + n(sds['receptionDate'].toDate().getMonth()+1) + '/'
            + n(sds['receptionDate'].toDate().getDate()) + '';
      }
    }

    return (
      <MDBContainer>
        <MDBRow>
          <h2>SDS管理　実施履歴</h2>
        </MDBRow>
        <br/>
        <MDBRow style={{marginBottom: 5}}>
          <MDBCol lg="2" md="3" sm="4" style={{background: '#E0E0E0', padding: 5}}>
            商品名
          </MDBCol>
          <MDBCol lg="10" md="9" sd="8" style={{padding: 5}}>
            {title} <div style={{display: 'inline-flex'}}>{sdsBtn}</div>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{marginBottom: 5}}>
          <MDBCol lg="2" md="3" sm="4" style={{background: '#E0E0E0', padding: 5}}>
            申込日
          </MDBCol>
          <MDBCol lg="10" md="9" sd="8" style={{padding: 5}}>
            {applicationDate}
          </MDBCol>
        </MDBRow>

        <MDBRow style={{marginBottom: 5}}>
          <MDBCol lg="2" md="3" sm="4" style={{background: '#E0E0E0', padding: 5}}>
            申込者
          </MDBCol>
          <MDBCol lg="10" md="9" sd="8" style={{padding: 5}}>
            {applicationUserName}
          </MDBCol>
        </MDBRow>

        <MDBRow style={{marginBottom: 5}}>
          <MDBCol lg="2" md="3" sm="4" style={{background: '#E0E0E0', padding: 5}}>
            受付日
          </MDBCol>
          <MDBCol lg="10" md="9" sd="8" style={{padding: 5}}>
            {receptionDate}
          </MDBCol>
        </MDBRow>

        <MDBRow style={{marginBottom: 5}}>
          <MDBCol lg="2" md="3" sm="4" style={{background: '#E0E0E0', padding: 5}}>
            受付者
          </MDBCol>
          <MDBCol lg="10" md="9" sd="8" style={{padding: 5}}>
            {receptionUserName}
          </MDBCol>
        </MDBRow>
        <MDBRow style={{marginBottom: 5}}>
          <MDBCol lg="2" md="3" sm="4" style={{background: '#E0E0E0', padding: 5}}>
            チェック希望月
          </MDBCol>
          <MDBCol lg="10" md="9" sd="8" style={{padding: 5}}>
            {sdsMonths}
          </MDBCol>
        </MDBRow>
        <br/>
        <MDBRow>
          <span>定期チェック実施履歴</span>
        </MDBRow>
        <MDBRow>
          <MDBTable responsive btn small>
            <MDBTableHead color="primary-color" textWhite columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
        </MDBRow>

        <MDBModal isOpen={this.state.deleteModal} toggle={this.deleteModalClose}>
          <MDBModalHeader toggle={this.deleteModalClose}>SDS履歴を削除する</MDBModalHeader>
          <MDBModalBody>
            SDS履歴の削除しますか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.deleteModalClose} color="secondary" >キャンセル</MDBBtn>
            <MDBBtn onClick={this.deleteModalClose} color="default" data-confirm={true} >確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.editModal} toggle={this.editModalClose}>
          <MDBModalHeader toggle={this.editModalClose}>SDS編集確認</MDBModalHeader>
          <MDBModalBody>
            SDS編集履歴確認？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.editModalClose} color="secondary" >キャンセル</MDBBtn>
            <MDBBtn onClick={this.editModalClose} color="default" data-confirm={true} >確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
      </MDBContainer>
    )

  }
}

export default SDSManagementHistory;
