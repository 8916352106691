import React from "react";
import {Link} from 'react-router-dom'
import {
    MDBSpinner,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from "mdbreact";

class DeliveryAddress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dbDeliveryAddresses: [],
            last: null,
            dePerPage: 12,
            data: {},
            delModal: false,

            cn: null,
            ct: null
        }
    }

    componentDidMount() {
        console.log('componentDidMount')

        let cn = new URLSearchParams(this.props.search).get("cn");
        let ct = new URLSearchParams(this.props.search).get("ct");
        // console.log(cn)
        this.setState({
            cn: cn,
            ct: ct
        }, () => {
            this.getDeliveryAddress();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('componentWillReceiveProps')

        let cn = new URLSearchParams(nextProps.search).get("cn");
        let ct = new URLSearchParams(nextProps.search).get("ct");
        this.setState({
            cn: cn,
            ct: ct
        }, () => {
            this.getDeliveryAddress();
        })
    }

    onReachEnd = () => {
        console.log('onReachEnd')
    }

    getDeliveryAddress = () => {
        if(this.props.org){
            let urigawa = false;
            if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
                urigawa = true;
            }
            if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
                urigawa = true;
            }

            let collection = 'address_book_buy'
            if (urigawa) {
                collection = 'address_book_sell';
            }

            // console.log(this.props.id)

            this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book').doc(this.props.id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data();
                        data.id = doc.id;
                        // console.log('data',data)

                        this.setState({data: data})
                    }
                })
        }
    }

    delModalClose = (e) => {
        this.setState({
            delModal: false
        })
        let daId = e && e.currentTarget.dataset.id;
        console.log(daId)

        if (daId) {
            let urigawa = false;
            if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
                urigawa = true;
            }
            if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
                urigawa = true;
            }

            let collection = 'address_book_buy'
            if (urigawa) {
                collection = 'address_book_sell';
            }

            let addressBookBuyRef = this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book').doc(daId);
            addressBookBuyRef.delete();
            this.props.cProps.history.push('/deliveryAddress' + (this.state.cn ? '?cn='+this.state.cn : ''))
        }
    }

    delDeliveryAddress = (e) => {
        let daId = e && e.currentTarget.dataset.id;
        this.setState({
            delModal: true,
            delId: daId
        })
    }

    render() {
        if (!this.props.org) {
            return (
                <>
                    取引先を選択してください。
                </>
            )
        }

        console.log(this.state.data)

        return (
            <>
                <h2>納品場所詳細</h2>
                <MDBCard style={{marginTop: 20}}>
                    <MDBCardBody className="companyInfo">
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>物件名</label>
                                <label style={{flex: 1}}>{this.state.data.propertyName}</label>
                            </div>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>郵便番号</label>
                                <label style={{flex: 1}}>{this.state.data.postCode}</label>
                            </div>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>住所1（都道府県・市区町村)</label>
                                <label style={{flex: 1}}>{this.state.data.address1}</label>
                            </div>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>住所2（番地・建物名)</label>
                                <label style={{flex: 1}}>{this.state.data.address2}</label>
                            </div>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>事業所名</label>
                                <label style={{flex: 1}}>{this.state.data.officeName}</label>
                            </div>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>担当者名</label>
                                <label style={{flex: 1}}>{this.state.data.personInCharge}</label>
                            </div>
                            <div style={{margin: 10}}>
                                <label style={{width: 250}}>電話番号</label>
                                <label style={{flex: 1}}>{this.state.data.phoneNumber}</label>
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>

                <div style={{display: 'flex', columnGap: 20, justifyContent: 'center', padding: 50}}>
                    <Link to={"/deliveryAddress" + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : '')}>
                        <MDBBtn
                            className="btn-lg btn-block"
                            color=""
                            style={{width: 200, color: '#585858'}}
                            // disabled={!isEnable}
                        >戻る</MDBBtn>
                    </Link>
                    <Link to={'/deliveryAddressEdit/'+this.state.data.id + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : '')}>
                        <MDBBtn
                            className="btn-lg btn-block"
                            color=""
                            style={{width: 200, backgroundColor: '#2D486A', color: '#ffffff'}}
                            // disabled={!isEnable}
                        >編集</MDBBtn>
                    </Link>

                    <MDBBtn
                        className="btn-lg btn-block"
                        color=""
                        style={{width: 200, backgroundColor: '#FF3547', color: '#ffffff'}}
                        // disabled={!isEnable}
                        data-id={this.state.data.id}
                        onClick={this.delDeliveryAddress}
                    >削除</MDBBtn>
                </div>


                {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

                <MDBModal isOpen={this.state.delModal} toggle={this.delModalClose}>
                    <MDBModalHeader toggle={this.delModalClose}>納品場所削除</MDBModalHeader>
                    <MDBModalBody>
                        この納品場所を削除します。よろしいですか？ <br/><br/>
                        ※この納品場所を削除しても、この納品場所への注文はキャンセルされません。
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.delModalClose} color="secondary">キャンセル</MDBBtn>
                        <MDBBtn onClick={this.delModalClose} color={''} style={{backgroundColor: '#FF3547', color: '#ffffff'}} data-id={this.state.delId}>削除</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}

export default DeliveryAddress;