import React, {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import moment from "moment";

const DrawMeasureChart = (props) => {
    const [seriesColor, setSeriesColor] = useState({
        0: { color: '#4285F4', lineWidth: 2,spanGaps:true,type:'line',targetAxisIndex: 0, },
        1: { color: '#EA4335', lineWidth: 2 ,spanGaps:true,type:'line',ignoreEmptyValue: true},
        2: { color: '#FBBC04', lineWidth: 2,spanGaps:true,type:'line',targetAxisIndex: 2},
        3: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 ,spanGaps:true,type:'line',targetAxisIndex: 3},
        4: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 ,spanGaps:true,type:'line',targetAxisIndex: 4},
    })
    const formatBegin = (item) => {
        // Extract data
        const fieldData = getDataArray(item.fieldMeasure, 'field');
        const labData = getDataArray(item.labMeasure, 'lab');
        const automaticData = getDataArray(item.automaticMeasure, 'automatic');
        let result;
       //const mergedData;
        // Merge data
        switch (props.which) {
            case 'none':
                result =  mergeData(fieldData, labData , automaticData, item.tolerance_lvl_max, item.tolerance_lvl_min,item.range_settings);
                break;
            case 'preview':
                result =   mergeData(fieldData, labData , automaticData, item.tolerance_lvl_max, item.tolerance_lvl_min,item.range_settings);
                break;
            case 'field' :
                result =  mergeDataField(fieldData, item.tolerance_lvl_max, item.tolerance_lvl_min,item.range_settings);
                break;
            case 'lab' :
                result =  mergeDataLab(labData, item.tolerance_lvl_max, item.tolerance_lvl_min,item.range_settings);
                break;
            case 'automatic':
                result =  mergeDataAuto(automaticData, item.tolerance_lvl_max, item.tolerance_lvl_min,item.range_settings);
                break;
        }
        return result;

    }

    useEffect(() => {
        switch (props.which) {
            case 'none':
                setSeriesColor({
                    0: { color: '#4285F4', lineWidth: 2,spanGaps:true,type:'line'},
                    1: { color: '#EA4335', lineWidth: 2,spanGaps:true,type:'line',ignoreEmptyValue: true},
                    2: { color: '#FBBC04', lineWidth: 2,spanGaps:true,type:'line'},
                    3: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1,spanGaps:true,type:'line'},
                    4: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1,spanGaps:true,type:'line'},
                });
                break;
            case 'field' :
                setSeriesColor({
                    0: { color: '#4285F4', lineWidth: 2 },
                    1: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                    2: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                });
                break;
            case 'lab' :
                setSeriesColor({
                    0: { color: '#EA4335', lineWidth: 2 },
                    1: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                    2: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                });
                break;
            case 'automatic':
                setSeriesColor({
                    0: { color: '#FBBC04', lineWidth: 2 },
                    1: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                    2: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                });
                break;
            default:
                setSeriesColor({
                    0: { color: '#4285F4', lineWidth: 2 },
                    1: { color: '#EA4335', lineWidth: 2 },
                    2: { color: '#FBBC04', lineWidth: 2 },
                    3: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                    4: { color: '#B71C1C', visibleInLegend: false, lineWidth: 1 },
                });
                break;
        }
    }, [props.which])

    const dateFormatter = (date) => {
        const format = 'YYYY/MM/DD HH:mm';
        if (moment(date).isValid()) {
            return moment(date).format(format);
        }
        return moment(date.toDate()).format(format);
    }
    // Function to convert data array to the desired format
    // Define your custom comparator function to sort by datetime
    const compareDates = (a, b)=> {
        const dateA = a[0] === "insertedDate" ? '1900/00/00 00:00' : a[0];
        const dateB = b[0] === "insertedDate" ? '1900/00/00 00:00' : b[0];

        if (dateA < dateB) {
            return -1;
        }
        if (dateA > dateB) {
            return 1;
        }
        return 0;
    }

    const getDataArray = (measurements, label) => {
        const dataArray = [['1900/00/00 00:00', label]];
        measurements.forEach(measurement => {
            if(moment(measurement.id ===undefined ? new Date() : measurement.insertedDate.toDate()).format('YYYY/MM/DD HH:mm')) {
                const date = dateFormatter(measurement.insertedDate)
                /*new Date(
                measurement.insertedDate.seconds * 1000 +
                measurement.insertedDate.nanoseconds / 1e6
            );*/
                const dateString = date;
                //`${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;

                dataArray.push([dateString, parseFloat(measurement.value)]);
            }

        });
        // Sort the array using the comparator function
        dataArray.sort(compareDates);
        return dataArray;
    }

    // Function to merge data arrays none all
    const mergeData = (fieldData, labData, automaticData, mx, mn,range) => {
        const mergedData = [['insertedDate', {label: '現地測定値', type: 'number'},  {label: 'ラボ測定値', type: 'number'},  {label: '自動測定値', type: 'number'}, {label: '以下', type: 'number'},{label: '以上', type: 'number'}]];
        const allDates = new Set([
            ...fieldData.slice(1).map(row => row[0]),
            ...labData.slice(1).map(row => row[0]),
            ...automaticData.slice(1).map(row => row[0])
        ]);
        let max = null;
        let min = null;
        switch (range) {
            case '上限下限共':
                max = mx * 1;
                min = mn * 1;
                break;
            case '下限のみ':
                max = null;
                min = mn * 1;
                break;
            case '上限のみ':
                max = mx * 1;
                min = null;
                break;
            default:
                max = mx * 1;
                min = mn * 1;
                break;
        }
        allDates.forEach(date => {
            const fieldValue = getFieldDataForDate(date, fieldData);
            const labValue = getFieldDataForDate(date, labData);
            const automaticValue = getFieldDataForDate(date, automaticData);
            if(!isNaN(fieldValue) && !isNaN(labValue) && !isNaN(automaticValue) ) {
                mergedData.push([date,fieldValue,labValue,automaticValue, max, min]);
            }

        });
        mergedData && mergedData.length>0 && mergedData.sort(compareDates);
          mergedData.forEach(data => {
              data[0]=data[0].substring(5,16)
          });
        return mergedData;
    }

    const mergeDataField = (fieldData, mx, mn,range) => {
        const mergedData = [['insertedDate', {label: '現地測定値', type: 'number'}, {label: '以下', type: 'number'},{label: '以上', type: 'number'}]];
        const allDates = new Set([
            ...fieldData.slice(1).map(row => row[0]),
        ]);

        let max = null;
        let min = null;
        switch (range) {
            case '上限下限共':
                max = mx * 1;
                min = mn * 1;
                break;
            case '下限のみ':
                max = null;
                min = mn * 1;
                break;
            case '上限のみ':
                max = mx * 1;
                min = null;
                break;
            default:
                max = mx * 1;
                min = mn * 1;
                break;
        }

        allDates.forEach(date => {
            const fieldValue = getFieldDataForDate(date, fieldData);
            !isNaN(fieldValue) && mergedData.push([date.substring(5,16), fieldValue, max, min]);
        });

        return mergedData;
    }
    const mergeDataLab = (labData, mx, mn,range) => {
        const mergedData = [['insertedDate', {label: 'ラボ測定値', type: 'number'}, {label: '以下', type: 'number'},{label: '以上', type: 'number'}]];
        const allDates = new Set([
            ...labData.slice(1).map(row => row[0]),
        ]);
        let max = null;
        let min = null;
        switch (range) {
            case '上限下限共':
                max = mx * 1;
                min = mn * 1;
                break;
            case '下限のみ':
                max = null;
                min = mn * 1;
                break;
            case '上限のみ':
                max = mx * 1;
                min = null;
                break;
            default:
                max = mx * 1;
                min = mn * 1;
                break;
        }
        allDates.forEach(date => {
            const labValue = getFieldDataForDate(date, labData);
            !isNaN(labValue) && mergedData.push([date.substring(5,16), labValue,max, min]);
        });
        //console.log('mergedDatalab', mergedData);
        return mergedData;
    }
    const mergeDataAuto = (automaticData, mx, mn,range) => {
        const mergedData =[['insertedDate', {label: 'ラボ測定値', type: 'number'}, {label: '以下', type: 'number'},{label: '以上', type: 'number'}]];
        const allDates = new Set([
            ...automaticData.slice(1).map(row => row[0]),
        ]);
        let max = null;
        let min = null;
        switch (range) {
            case '上限下限共':
                max = mx * 1;
                min = mn * 1;
                break;
            case '下限のみ':
                max = null;
                min = mn * 1;
                break;
            case '上限のみ':
                max = mx * 1;
                min = null;
                break;
            default:
                max = mx * 1;
                min = mn * 1;
                break;
        }
        allDates.forEach(date => {
            const automaticValue = getFieldDataForDate(date, automaticData);
            !isNaN(automaticValue) && mergedData.push([date.substring(5,16), automaticValue,max, min]);
        });

        return mergedData;
    }
    // Function to get field data for a specific date
    const getFieldDataForDate = (date, dataArray) => {
        const valuesForDate = dataArray.filter(row => row[0] === date);
        const sum = valuesForDate.reduce((acc, row) => acc + row[1], null);
        return sum;
    }
    return (
        props.getValues(`process[${props.nestIndex}].items`).map((im, x) => (
            parseInt(im.graph_display) && (props.which === 'none' ? (im.fieldMeasure && im.fieldMeasure.length > 0 || im.labMeasure && im.labMeasure.length > 0 || im.automaticMeasure && im.automaticMeasure.length > 0) :
                    (props.which === 'automatic' ? (im.automaticMeasure && im.automaticMeasure.length > 0) : (props.which === 'lab' ? (im.labMeasure && im.labMeasure.length > 0) : (im.fieldMeasure && im.fieldMeasure.length > 0)))
            )  ?
            <Chart
                key={x}
                width="100%"
                height="480px"
                chartType="LineChart"
                data={formatBegin(im)}
                options={{
                    interpolateNulls: true,
                    responsive: true,
                    tension: 0,
                    //title: '',
                    legend: {
                        display: true,
                        position: "top",
                        alignment: "center",
                    },
                    vAxes: {
                        0: { title: im.item_name,titleTextStyle : {
                            italic : false,
                        },}
                    },
                    hAxis: {
                        title: "採取日",titleTextStyle : {
                            italic : false
                        },
                        textStyle : {
                            fontSize: 10 // or the number you want
                        }
                    },
                    lang:{
                        noData: 'no data!'
                    },
                    noData: {
                        position: {
                            "x": 0,
                            "y": 0,
                            "align": "center",
                            "verticalAlign": "middle"
                        }
                    },
                    series : seriesColor
                }}
            /> : <div></div>
        ))
    )
}

export default DrawMeasureChart
