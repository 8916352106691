import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
    MDBCollapse
} from "mdbreact";

class SupportUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredList: []
    }
  }

  componentDidMount() {
    // console.log(this.props.search)
    // console.log(this.props.data[this.props.search])
    console.log(this.props.data.search)

    let filteredDatas = this.props.data.search.filter(data => {
      return (data.text.toLowerCase().includes(this.props.search) || data.title.toLowerCase().includes(this.props.search))
    })

    console.log('filtered datas', filteredDatas)

    this.setState({
      filteredList: filteredDatas
    })
  }

  render() {
    return (
      <>
        <MDBContainer>
          <MDBRow style={{height: 100, background: '#2e75b6', color: '#ffffff', alignItems: 'center', paddingLeft: 20, paddingRight: 20, justifyContent: 'space-between'}}>
            <Link to={'/support'} style={{color: '#ffffff'}}>
              <img src="/logo.png" className="img-fluid" alt="HOME" style={{height: 22, marginRight: 10}} />
              サポートサイト
            </Link>
            <div>
              <Link to={'/support#operation'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>基本的な操作</Link>
              <Link to={'/support#faq'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>よくある質問</Link>
              <Link to={'/support#information'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>お役立ち情報</Link>
            </div>
          </MDBRow>
          <MDBRow>
            <div style={{padding: '10px 20px'}}>
              {
                this.props.data.categoryBreadcrumbArray['update'].map((v, i) => {
                  return <><Link to={'/support'}>{v}</Link> » </>
                })
              }
              {this.props.data.categoryTitle['update']}
            </div>
          </MDBRow>
          <MDBRow style={{display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5'}}>
            <div style={{display: 'grid', columnGap: 20, rowGap: 20, backgroundColor: '#ffffff', margin: 50, padding: 40}}>
              <h3>アップデート情報</h3>
              {this.props.data['update-list'].map((v) => {
                return <div style={{padding: 30, background: '#f6f6f6', display: 'flex', flexDirection: 'column'}}>
                  <Link to={v.link} style={{color: '#000000', marginBottom: 20}}>{v.shortData}</Link>
                  <span style={{color: '#4b4f58'}}>{v.shortDataDate}</span>
                  {/*TODO: read more button*/}
                  {/*<div>*/}
                  {/*  <Link to={v.link} style={{fontWeight: 400, lineHeight: 1, letterSpacing: 5, fontSize: '1.13rem', borderRadius: 50, backgroundColor: '#1e92e0', padding: 'calc(0.567em + 2px) calc(1.233em + 2px)', color: '#ffffff'}}>Read More</Link>*/}
                  {/*</div>*/}
                </div>
              })}
            </div>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default SupportUpdate;

