import React from "../../../node_modules/react";
import { BrowserRouter, Route, Link } from 'react-router-dom'
import { MDBDataTable, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import ItemCardStock from '../../components/Item/ItemDetailStock';
import ItemCard from '../../components/Item/ItemDetail';

class ItemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      changed: false,
      item_stock: false,
    }
  }


  getItem = () => {

    let item = this.props.db.collection('items_sell');
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
      item = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items').doc(this.props.id);
      this.setState({item_stock: true})
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
      item = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items').doc(this.props.id);
      this.setState({item_stock: true})
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      item = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items').doc(this.props.id);
      this.setState({item_stock: false})
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      item = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items').doc(this.props.id)
      this.setState({item_stock: false})
    }


    item
      .get()
      .then((doc) => {
        if(doc.exists) {
          let data = doc.data();
          data.id = doc.id;
          this.setState({item: data})
        }
      })
  }

  componentDidMount() {
    this.getItem();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.org !== nextProps.org) {
      this.setState({changed: true})
    }
    if (this.props.userOrg !== nextProps.userOrg) {
      this.setState({changed: true})
    }
  }

  componentDidUpdate() {
    if(this.state.changed){
      this.setState({changed: false})
      this.getItem();
    }
  }


  render() {
    //var path = this.props.params.splat;
    console.log(this.props);
    return (
      <>
      {this.state.item && this.state.item_stock &&
        <ItemCard data={this.state.item} storage={this.props.storage} db={this.props.db} user={this.props.user} userOrg={this.props.userOrg} org={this.props.org} dateNow={this.props.dateNow} />
      }
      {this.state.item && ! this.state.item_stock &&
        <ItemCard data={this.state.item} storage={this.props.storage} db={this.props.db} user={this.props.user} userOrg={this.props.userOrg} org={this.props.org} dateNow={this.props.dateNow} />
      }
      </>
    )

  }
}

export default ItemDetail;
