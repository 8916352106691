import React from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBChip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon, MDBRow, MDBCol, MDBInput
} from 'mdbreact';
import StorageLink from './StorageLink'
import firebase from "../../firebase";
import DatePicker from "react-datepicker";
import "./Bills.css"
import { saveAs } from 'file-saver';
import Typesense from "typesense";
import JSZip from "jszip";
const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);

class BillsTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbBills: [],
      last: null,
      billsPerPage: 12,
      applyModal: false,
      waitModal: false,
      deleteModal: false,
      searchwordA: '',
      algoriaPage: 1,
      algolia: false,
      sortType: 'desc',
      billsType: '1',
      sortMainType: 'startDate',
      billingDateStart: null,
      billingDateEnd: null,
      createdDateStart: null,
      createdDateEnd: null,
      sentDateStart: null,
      sentDateEnd: null,
      confirmDateStart: null,
      confirmDateEnd: null,
      filterType: 'number',
      searchAmount: '',
      searchAmountEnd: '',
      sellerUsers: [],
      buyerUsers: [],
      selectedSellerUser: '',
      selectedBuyerUser: '',
      notMore: false,
      searchNumberId: '',
      searchTitle: '',
      selectedBillList: [],
      downloading: false,
      downloadingAll: false,
    }
  }

  getBillsA = async (props, newOrg, word) => {
    if (this.state.loading) {
      return;
    }

    if (this.state.notMore) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    let urigawa = false;
    let indexName = 'P4Bill';

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }

    console.log(fromOrg, toOrg)

    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let tempResults = [];

    this.setState({loading: true, algolia: true});
    let filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
    let query_by = '*';
    let q = '*'
    let infix = 'off'
    if (!urigawa) {
      filters += ` && deleted:false`
    } else {
      switch (this.state.billsType) {
        case '1':
          filters += ` && deleted:false`;
          break;
        case '2':
          filters += ` && deleted:true`;
          break;
      }
    }
    if (this.state.filterType === 'billingDate' && this.state.billingDateStart && this.state.billingDateEnd) {
      let startTimestamp = new Date(this.state.billingDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.billingDateEnd).setHours(23,59,59)/1000;
      //console.log('billingDate search', startTimestamp, endTimestamp);
      //filters += ` && billingDate._seconds >= ${startTimestamp} && billingDate._seconds <= ${endTimestamp}`;
      filters += ` && billingDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }

    if (this.state.filterType === 'createdDate' && this.state.createdDateStart && this.state.createdDateEnd) {
      let startTimestamp = new Date(this.state.createdDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.createdDateEnd).setHours(23,59,59)/1000;
      //console.log('createdDate search', startTimestamp, endTimestamp);
      //filters += ` && date._seconds: >= ${startTimestamp} && date._seconds: <= ${endTimestamp}`;
      filters += ` && date._seconds:[${startTimestamp}..${endTimestamp}]`;
    }

    if (this.state.filterType === 'confirmDate' && this.state.confirmDateStart && this.state.confirmDateEnd) {
      let startTimestamp = new Date(this.state.confirmDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.confirmDateEnd).setHours(23,59,59)/1000;
      console.log('confirmDate search', startTimestamp, endTimestamp);
      //filters += ` && confirmDate._seconds >= ${startTimestamp} && confirmDate._seconds <= ${endTimestamp}`;
      filters += ` && confirmDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }

    if (this.state.filterType === 'sentDate' && this.state.sentDateStart && this.state.sentDateEnd) {
      let startTimestamp = new Date(this.state.sentDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.sentDateEnd).setHours(23,59,59)/1000;
      //console.log('sentDate search', startTimestamp, endTimestamp);
      //filters += ` && created._seconds >= ${startTimestamp} && created._seconds <= ${endTimestamp}`;
      filters += ` && created._seconds:[${startTimestamp}..${endTimestamp}]`;
    }

    if (this.state.filterType === 'amount' && this.state.searchAmount && this.state.searchAmountEnd) {
      filters += ` && totalAmount:[${this.state.searchAmount}..${this.state.searchAmountEnd}]`;
    }
    if (this.state.filterType === 'number' && this.state.searchNumberId) {
      //filters += ` && number:'${this.state.searchNumberId}'`;
      q = `${this.state.searchNumberId}`
      query_by = 'number';
      infix = 'always'
    }
    if (this.state.filterType === 'title' && this.state.searchTitle) {
      //filters += ` && title:${this.state.searchTitle}`;
      q = `${this.state.searchTitle}`
      query_by = 'title';
      infix = 'always'
    }
    if (this.state.filterType === 'id' && this.state.searchNumberId) {
      //filters += ` && objectID:${this.state.searchNumberId}`;
      q = `${this.state.searchNumberId}`
      query_by = 'objectID';
      infix = 'always'
    }
    if (this.state.filterType === 'sender' && this.state.selectedSellerUser) {
      filters += ` && fromUser:${this.state.selectedSellerUser}`;
    }

    if (this.state.filterType === 'receiver' && this.state.selectedBuyerUser) {
      filters += ` && toUser:${this.state.selectedBuyerUser}`;
    }
    let orderBy = 'dueDate';
    if (this.state.sortMainType === 'billingDate') {
      orderBy = 'billingDueDate';
    }
    let searchParameters = {
      'q'         : q,
      'query_by'  : query_by,
      'infix'     : infix,
      'filter_by' : filters,
      'sort_by'   : `${orderBy}:${this.state.sortType}`,//this.state.sortType === 'desc' ? 'dueDate:desc' : 'dueDate:asc',
      'page'      : this.state.algoriaPage,
      'per_page'  : this.state.billsPerPage
    }
    await typesense.collections('bills').documents().search(searchParameters).then((docs) => {
      //console.log('datadata', docs);
      //docs.page=this.state.algoriaPage,
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })
    });
    let nextPage = this.state.algoriaPage + 1;
    let bills = this.state.dbBills;
    if (newOrg) {
      bills = [];
    }

    let notMore = false;
    if (tempResults.length === 0) {
      notMore = true
    }

    let newResults = bills.concat(tempResults);

    this.setState({
      algoriaPage: nextPage,
      dbBills: newResults,
      loading: false,
      notMore: notMore
    });
  }

  getBills = (props, newOrg) => {
    // console.log("###### getBills ########", props)

    if (this.state.loading) {
      return;
    }

    if (this.state.notMore) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    let urigawa = false;

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let first;
    this.setState({loading: true, algolia: false});

    let orderBy = 'dueDate';
    if (this.state.sortMainType === 'billingDate') {
      orderBy = 'billingDueDate';
    }

    // console.log('this.state.billsType', this.state.billsType)

    if (urigawa) {
      switch (this.state.billsType) {
        case '1':
          if (this.state.last) {
            first = this.props.db.collection('bills')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where(orderBy, ">", 0)
                .where('deleted', '==', false)
                .orderBy(orderBy,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.billsPerPage);
          } else {
            first = this.props.db.collection('bills')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where(orderBy, ">", 0)
                .where('deleted', '==', false)
                .orderBy(orderBy,this.state.sortType)
                .limit(this.state.billsPerPage);
          }
          break;
        case '2':
          if (this.state.last) {
            first = this.props.db.collection('bills')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where(orderBy, ">", 0)
                .where('deleted', '==', true)
                .orderBy(orderBy,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.billsPerPage);
          } else {
            first = this.props.db.collection('bills')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where(orderBy, ">", 0)
                .where('deleted', '==', true)
                .orderBy(orderBy,this.state.sortType)
                .limit(this.state.billsPerPage);
          }
          break;
        default:
          if (this.state.last) {
            first = this.props.db.collection('bills')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where(orderBy, ">", 0)
                .orderBy(orderBy,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.billsPerPage);
          } else {
            first = this.props.db.collection('bills')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where(orderBy, ">", 0)
                .orderBy(orderBy,this.state.sortType)
                .limit(this.state.billsPerPage);
          }
          break;
      }
    } else {
      if(this.state.last){
        first = this.props.db.collection('bills')
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .where("deleted", "==", false)
            .where(orderBy, ">", 0)
            .orderBy(orderBy,this.state.sortType)
            .startAfter(this.state.last)
            .limit(this.state.billsPerPage);
      }else{
        first = this.props.db.collection("bills")
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .where("deleted", "==", false)
            .where(orderBy, ">", 0)
            .orderBy(orderBy,this.state.sortType)
            .limit(this.state.billsPerPage);
      }
    }

    // console.log('first1', first)
    //
    //   first = this.props.db.collection('bills')
    //
    // if (this.state.billingDateStart && this.state.billingDateEnd) {
    //   let billingDateStart = new Date(this.state.billingDateStart);
    //   let billingDateEnd = new Date(this.state.billingDateEnd);
    //   console.log(billingDateStart, billingDateEnd)
    //   first = this.props.db.collection('bills')
    //       .where("fromOrganization", "==", fromOrg)
    //       .where("toOrganization", "==", toOrg)
    //       .where('billingDate', '>=', billingDateStart)
    //       .where('billingDate', '<=', billingDateEnd)
    //       .where(orderBy, ">", 0)
    //       .where('deleted', '==', false)
    //       // .orderBy(orderBy,this.state.sortType)
    //       .limit(this.state.billsPerPage);
    // }
    //
    // console.log('first', first)

    let paginate = first.get()
    .then((snapshot) => {
      this.setState({loading: false})
      if(snapshot.docs.length > 0) {
        let last = snapshot.docs[snapshot.docs.length - 1];

        this.setState({last: last})

        let bills = this.state.dbBills;

        if (newOrg) {
          bills = [];
        }

        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          bills.push(data)
        })
        this.setState({dbBills: bills}, () => {
          let wrapper1 = document.getElementById('wrapper1');
          let div1 = document.getElementById('div1');
          let wrapper2 = document.getElementsByClassName('table-responsive')[0];
          let div2 = document.getElementById('div2');

          if (wrapper1 && div1 && wrapper2 && div2) {
            div1.style.width = div2.offsetWidth+'px';

            wrapper1.onscroll = function() {
              wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
              wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
          }
        })
      }else{
        this.setState({notMore: true, loading: false});
        if (newOrg === true) {
          this.setState({dbBills: []})
        }
      }

    });

  }

  updateBills = async () => {
    console.log('update bills');
    let bills = this.props.db.collection('bills');
    let billsGet = await bills.get();
    let billsDocs = billsGet.docs;
    console.log('bills length', billsDocs.length);

    await Promise.all(billsDocs.map(async (value) => {
      try {
        let data = value.data();

        //TODO: dueDate update xiix
        // if (data['date'] && (data['date'].toDate().getTime() !== data['dueDate'])) {
        //   let timestamp = data['date'].toDate().getTime();
        //   data['dueDate'] = timestamp;
        //   console.log('date updated bills', data['date'].toDate().getTime(), data['dueDate'])
        //   await this.props.db.collection('bills').doc(value.id).set(data, {merge: true})
        // }

        if (!data['billingDueDate']) {
          data['billingDueDate'] = 0;
          console.log('date updated bills', data['billingDueDate'])
          await this.props.db.collection('bills').doc(value.id).set(data, {merge: true})
        }
      } catch (e) {
        console.log('error', e)
      }
    }));

    console.log('billing update done');
  }

  componentDidMount() {
    //this.getBills();
    // this.updateBills()
    this.getBills(this.props, true);
    this.getOrganizationUsers(this.props);
    this.props.loadBadge();
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({last: null, dbBills: []}, () => {
      this.getBills(nextProps, true);
      // this.getBillsA(nextProps, true, 'test22')
      this.props.loadBadge();
    })

    this.getOrganizationUsers(nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  getOrganizationUsers = (props) => {
    let sellerUsers = [];
    let buyerUsers = [];

    // console.log('props', props);

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      // console.log('seller', props);
      sellerUsers = props.userOrg.users;
      buyerUsers = props.org.users;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      // console.log('seller', props);
      sellerUsers = props.userOrg.users;
      buyerUsers = props.org.users;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      // console.log('buyer', props);
      sellerUsers = props.org.users;
      buyerUsers = props.userOrg.users;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      // console.log('buyer', props);
      sellerUsers = props.org.users;
      buyerUsers = props.userOrg.users;
    }

    if (sellerUsers.length > 0 && buyerUsers.length > 0) {
      // console.log(sellerUsers, buyerUsers);
      this.setState({
        sellerUsers,
        buyerUsers
      })
    }
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    console.log("LAST:" , this.state.last);
    console.log(this.state.algolia)
    if(this.state.algolia){
      this.getBillsA(this.props, false, this.state.searchwordA)
    } else {
      this.getBills(this.props, false);
    }

  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);

    let esId = e.currentTarget.dataset.id;
    let urigawa = e.currentTarget.dataset.urigawa;
    let index = e.currentTarget.dataset.index;
    urigawa = urigawa === 'true'

    let item = this.state.dbBills[index];

    let billSet = {
      visited: {[this.props.user.id]: true},
    }
    if (!urigawa) {
      let date = firebase.firestore.Timestamp.fromDate(new Date())

      if (!item.confirmDate && !(item['visited'] && item['visited'][item['toUser']])) { //&& item['toUser'] === this.props.user.id
        billSet.confirmDate = date
        billSet.confirmUser = this.props.user.id;
        billSet.confirmUserName = this.props.user.last_name + this.props.user.first_name;
        this.dbBillsUpdate(esId,"confirmDate",date);
        this.dbBillsUpdate(esId,"confirmUserName",this.props.user.last_name + this.props.user.first_name);
      }

      if (item.readBuyer === false) {
        billSet.readBuyer = true;
        this.dbBillsUpdate(esId,"readBuyer",true);

        this.props.readBill(1)
      }

    } else {
      console.log(item.readSeller)
      if (item.readSeller === false) {
        billSet.readSeller = true;
        this.dbBillsUpdate(esId,"readSeller",true);

        this.props.readBill(1)
      }

    }



    let billsRef = this.props.db.collection('bills').doc(esId);
    billsRef.set(billSet, { merge: true });
    this.dbBillsUpdate(esId,"visited",{[this.props.user.id]:true});

  }

  applyFile = (e) => {
    console.log("applyFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      applyModal: true,
      applyId: esId,
      applyTitle: esTitle,
    })
  }
  applyClose = (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
      applyTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let billsRef = this.props.db.collection('bills').doc(esId);
      billsRef.set({
        apply: {[this.props.user.id]: true},
      }, { merge: true });
      this.dbBillsUpdate(esId,"apply",{key:1});
    }

  }

   delete = (e) => {
     let esId = e.currentTarget.dataset.id;
     let esTitle = e.currentTarget.dataset.title;

     this.setState({
       deleteModal: true,
       deleteId: esId,
       deleteTitle: esTitle,
     })
   }

  deleteClose = (e) => {
    this.setState({
      deleteModal: false,
      deleteId: null,
      deleteTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let date = firebase.firestore.Timestamp.fromDate(new Date())

      let billsRef = this.props.db.collection('bills').doc(esId);
      billsRef.set({
        deleted: true,
        deletedUser: this.props.user.id,
        deletedUserName: this.props.user.last_name + this.props.user.first_name,
        deletedDate: date
      }, { merge: true });
      this.dbBillsUpdate(esId,"deleted",{key:1});
    }
  }

  waitFile = (e) => {
    console.log("waitFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      waitModal: true,
      waitId: esId,
      waitTitle: esTitle,
    })
  }
  waitClose = (e) => {
    this.setState({
      waitModal: false,
      waitId: null,
      waitTitle: null,
    })

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let billsRef = this.props.db.collection('bills').doc(esId);
      billsRef.set({
        wait: {[this.props.user.id]: true},
      }, { merge: true });
      this.dbBillsUpdate(esId,"wait",{key:1});
    }
  }

  dbBillsUpdate = (id,target,value) => {
    let dbBillsUpdated = this.state.dbBills.map((es)=>{
      if(es.id === id){
        if(target === 'visited') {
          let visited = es.visited;
          if(visited){
            es[target] = Object.assign(visited, value);
          } else {
            es[target] = value;
          }
        }else{
          es[target] = value;
        }
      }
      return es;
    })
    this.setState({
      dbBills: dbBillsUpdated,
    })
  }

  handleSearchwordAChange = (e) => {
    this.setState({
      searchwordA: e.currentTarget.value,
    });
  }

  handleSearchAmountChange = (e) => {
    this.setState({
      searchAmount: e.currentTarget.value
    })
  }
  handleSearchAmountEndChange = (e) => {
    this.setState({
      searchAmountEnd: e.currentTarget.value
    })
  }
  handleSearchNumberIdChange = (e) => {
    this.setState({
      searchNumberId: e.currentTarget.value
    })
  }

  handleSearchTitleChange = (e) => {
    this.setState({
      searchTitle: e.currentTarget.value
    })
  }

  handleSearchwordAKeyDown = (e) => {
    let val = this.state.searchwordA;
    if (e.key === 'Enter') {
      this.setState({
        dbBills: [],
        notMore: false,
        last: null,
        algoriaPage: 1,
      }, ()=>{
        if (val) {
          this.getBillsA(this.props, true, val);
        } else {
          this.getBills(this.props, true)
        }

      });
    }
  }

  sortChange = (val) => {
    this.setState({last: null, notMore: false, sortType: val, dbBills: [], algoriaPage: 1,selectedBillList: []}, () => {
      if (this.state.algolia) {
        this.getBillsA(this.props, true, this.state.searchwordA);
      } else {
        this.getBills(this.props, true);
      }
    })

    // this.setState({last: null, notMore: false, sortType: val}, () => {
    //   this.getOrders(this.props, true);
    // })
  }

  selectSortMainType = (e) => {
    this.setState({
      sortMainType: e.target.value || 'startDate',
      last: null,
      notMore: false,
      dbBills: [],
      algoriaPage: 1,selectedBillList: []
    }, () => {
      if (this.state.algolia) {
        this.getBillsA(this.props, true, this.state.searchwordA);
      } else {
        this.getBills(this.props, true);
      }
    });
  }

  selectSellerUser = (e) => {
    this.setState({
      selectedSellerUser: e.target.value || ''
    })
  }

  selectBuyerUser = (e) => {
    this.setState({
      selectedBuyerUser: e.target.value || ''
    })
  }

  selectBillsType = (e) => {
    this.setState({
      billsType: e.target.value || '1',
      last: null,
      notMore: false,
      dbBills: [],
      algoriaPage: 1
    }, () => {
      if (this.state.algolia) {
        this.getBillsA(this.props, true, this.state.searchwordA);
      } else {
        this.getBills(this.props, true);
      }
    });
  }

  clearFilter = () => {
    this.setState({
      billsType: '1',
      searchwordA: '',
      notMore: false,
      sortMainType: 'startDate',
      sortType: 'desc',
      last: null,
      dbBills: [],
      algolia: false,
      algoriaPage: 1,
      searchNumberId: '',
      searchTitle: '',
      billingDateStart: null,
      billingDateEnd: null,
      createdDateStart: null,
      createdDateEnd: null,
      sentDateStart: null,
      sentDateEnd: null,
      confirmDateStart: null,
      confirmDateEnd: null,
      filterType: 'number',
      searchAmount: '',
      searchAmountEnd: '',
      selectedSellerUser: '',
      selectedBuyerUser: '',
      selectedBillList: []
    }, () => {
      if (this.state.algolia) {
        this.getBillsA(this.props, true, this.state.searchwordA);
      } else {
        this.getBills(this.props, true);
      }
    });
  }

  handleBillingDateStartChange = date => {
    this.setState({
      billingDateStart: date
    })
  };

  handleBillingDateEndChange = date => {
    this.setState({
      billingDateEnd: date
    })
  };

  handleCreatedDateStartChange = date => {
    this.setState({
      createdDateStart: date
    })
  };

  handleCreatedDateEndChange = date => {
    this.setState({
      createdDateEnd: date
    })
  };

  handleSentDateStartChange = date => {
    this.setState({
      sentDateStart: date
    })
  };

  handleSentDateEndChange = date => {
    this.setState({
      sentDateEnd: date
    })
  };

  handleConfirmDateStartChange = date => {
    this.setState({
      confirmDateStart: date
    })
  };

  handleConfirmDateEndChange = date => {
    this.setState({
      confirmDateEnd: date
    })
  };

  billFilter = () => {
    console.log('bill filter');
    this.setState({
      algoriaPage: 1,
      notMore: false,
      algolia: true,
      last: null,
      dbBills: [],
      selectedBillList: []
    }, () => {
      this.getBillsA(this.props, true,this.state.filterType === 'title' ? this.state.searchTitle : null);
    })
  }

  filterTypeChange = (e) => {
    let value = e.target.value || 'billingDate';
    // console.log('filterType', value);
    let obj = {
      filterType: value
    };
    if (value === 'sender') {
      // console.log('sender', this.state.sellerUsers[0].user)
      obj.selectedSellerUser = this.state.sellerUsers[0].user;
    }

    if (value === 'receiver') {
      obj.selectedBuyerUser = this.state.buyerUsers[0].user;
    }
    this.setState(obj)
  }

  billIsSelected = (docId) => {
    let filtered = this.state.selectedBillList.filter(item => {
      if (item.objectID) {
        return item.objectID === docId
      } else {
        return item.id === docId
      }

    });
    return filtered.length > 0
  };

  billSelect = (selected, data) => {
    let selectedList;
    if (selected) {
      selectedList = [...this.state.selectedBillList, data];
    } else {
      selectedList = this.state.selectedBillList.filter( bill => {
        if (bill.objectID) {
          return bill.objectID !== data.objectID
        } else {
          return bill.id !== data.id
        }
      });
    }

    this.setState({
      selectedBillList: selectedList
    })
  }

  billSelectAll = (selected) => {
    let selectedList = [];
    if (selected === true) {
      this.state.dbBills.forEach((data,index) => {
        selectedList.push(data);
      });
      this.setState({
        selectedBillList: selectedList
      }, () => {
      })
    } else {
      this.setState({
        selectedBillList: selectedList
      });
    }
  };

  formatDate = (date = new Date())=> {
    const year = date.toLocaleString('jp', {year: 'numeric'});
    const month = date.toLocaleString('jp', {
      month: '2-digit',
    });
    const day = date.toLocaleString('jp', {day: '2-digit'});
    const hour = date.toLocaleString('jp', {hour: '2-digit'});
    const min = date.toLocaleString('jp', {minute: '2-digit'});
    const second = date.toLocaleString('jp', {second: '2-digit'});
    let dateFormatted = year + '-'+ month + '-' + day + ' ' + hour.substring(0,2) + '' +  (min * 1 < 10 ? '0'+'' + min : min) + '' + (second * 1 < 10 ? '0'+'' + second : second);
    return dateFormatted
  }

  downloadSelectedBills = async () => {
    //console.log('downloadSelectedDeliveryNotes',this.state.selectedDeliveryList);
    this.setState({downloading: true})

    const jszip = new JSZip();

    let promises = this.state.selectedBillList.map( async (bill) => {
      // console.log('selectedBillList', selectedBillList);
      if (bill && bill.file) {
        const fileBlob = await this.props.storage.ref(bill.file).getDownloadURL().then( (url) => {
          return fetch(url).then((response) => response.blob());
        })
        const fileName = bill.file.split('/').pop();
        jszip.file(fileName, fileBlob);
      }
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
    await promises;
    const blob = await jszip.generateAsync({ type: 'blob' });
    //console.log('jszip', jszip)
    let date = new Date();
    let dateFormatted = this.formatDate(date);
    saveAs(blob, 'Bill_'+dateFormatted+'.zip');
    this.setState({downloading: false})
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
    || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
      || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    if(! kaigawa && ! urigawa){
      return (
        <>
        </>
      )
    }

    const bills = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    this.state.dbBills.forEach((bill,index) => {
      let created, date, billingDate;
      let confirmDate = '';
      // console.log('algolia', this.state.algolia)
      if (this.state.algolia) {
        let cDate = new Date(bill['created']._seconds * 1000 + bill['created']._nanoseconds/1000000);
        created = cDate.getFullYear() + '/'
            + n(cDate.getMonth()+1) + '/'
            + n(cDate.getDate()) + ' '
            + n(cDate.getHours()) + ":"
            + n(cDate.getMinutes());

        let dDate = new Date(bill['date']._seconds * 1000 + bill['date']._nanoseconds/1000000);
        date = dDate.getFullYear() + '/'
            + n(dDate.getMonth()+1) + '/'
            + n(dDate.getDate());

        if (bill['billingDate']) {
          let bDate = new Date(bill['billingDate']._seconds * 1000 + bill['billingDate']._nanoseconds/1000000);
          billingDate = bDate.getFullYear() + '/'
              + n(bDate.getMonth()+1) + '/'
              + n(bDate.getDate());
        }

        if (bill['confirmDate']) {
          let coDate = new Date(bill['confirmDate']._seconds * 1000 + bill['confirmDate']._nanoseconds/1000000);
          confirmDate = coDate.getFullYear() + '/'
              + n(coDate.getMonth()+1) + '/'
              + n(coDate.getDate()) + ' '
              + n(coDate.getHours()) + ":"
              + n(coDate.getMinutes());
        }
      } else {
        created = bill['created'].toDate().getFullYear() + '/'
            + n(bill['created'].toDate().getMonth()+1) + '/'
            + n(bill['created'].toDate().getDate()) + ' '
            + n(bill['created'].toDate().getHours()) + ":"
            + n(bill['created'].toDate().getMinutes());

        date = bill['date'].toDate().getFullYear() + '/'
            + n(bill['date'].toDate().getMonth()+1) + '/'
            + n(bill['date'].toDate().getDate());

        if (bill['billingDate']) {
          billingDate = bill['billingDate'].toDate().getFullYear() + '/'
              + n(bill['billingDate'].toDate().getMonth()+1) + '/'
              + n(bill['billingDate'].toDate().getDate());
        }

        if (bill['confirmDate']) {
          confirmDate = bill['confirmDate'].toDate().getFullYear() + '/'
              + n(bill['confirmDate'].toDate().getMonth()+1) + '/'
              + n(bill['confirmDate'].toDate().getDate()) + ' '
              + n(bill['confirmDate'].toDate().getHours()) + ":"
              + n(bill['confirmDate'].toDate().getMinutes());
        }
      }
      // console.log(new Date(bill['created']._seconds * 1000 + bill['created']._nanoseconds/1000000))

      let dueDateFormat = new Date(bill['dueDate']);
      const dueDate = dueDateFormat.getFullYear() + '/'
          + n(dueDateFormat.getMonth()+1) + '/'
          + n(dueDateFormat.getDate()) + ' '
          + n(dueDateFormat.getHours()) + ":"
          + n(dueDateFormat.getMinutes());

      const endDate = bill['endDate']
        ? bill['endDate'].toDate().getFullYear() + '/'
          + (bill['endDate'].toDate().getMonth()+1) + '/'
          + bill['endDate'].toDate().getDate() + ''
        : '';

      let visited = '';
      let apply = '';
      let toVisited = '';
      if(urigawa) {
        if(bill['visited'] && bill['visited'][bill['toUser']] || bill['confirmDate']) {
          visited = <>{bill['confirmUserName'] && <><span className="text-nowrap">{bill['confirmUserName']}</span><br/></>}<MDBChip className="text-nowrap disabled">確認済</MDBChip></>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }
        if(bill['apply'] && Object.keys(bill['apply']).length > 0) {
          apply = <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">受注済み</MDBChip>;
        } else if(bill['wait'] && Object.keys(bill['wait']).length > 0) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">未受注</MDBChip>;
        }
      }

      if ( kaigawa ){
        if(this.props.user.authorizer){
          if(bill['visited'] && bill['visited'][bill['toUser']]) {
            toVisited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
          } else {
            toVisited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
          }
        }


        if(bill['visited'] && bill['visited'][this.props.user.id] || bill['confirmDate']) {
          visited = <>{bill['confirmUserName'] && <><span className="text-nowrap">{bill['confirmUserName']}</span><br/></>}<MDBChip className="text-nowrap disabled">確認済</MDBChip></>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }

        if(bill['apply'] && Object.keys(bill['apply']).length > 0) {
          apply = <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">承認発注済み</MDBChip>;
        } else if(bill['wait'] && Object.keys(bill['wait']).length > 0) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">承認待ち</MDBChip>;
        }
      }

      // console.log(urigawa, bill['readSeller'], kaigawa, bill['readBuyer'])

      let bold;
      if (urigawa) {
        bold = bill['readSeller'] === false
      }

      if (kaigawa) {
        bold = bill['readBuyer'] === false
      }
      let billId = bill.objectID ? bill.objectID : bill.id
      let checked = this.billIsSelected(billId);
      let data = {
        'check':<div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} id={"checkbox"+index} onChange={(e) => this.billSelect(e.target.checked, bill)} /> </div>,
        '請求番号':<span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{bill['number']}<br/>{bill['id'] || bill['objectID']}</span>,
        '送信者':<span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{bill['fromUserName']}<br/>{created}</span>,
        '請求受取者': <span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{bill['toUserName']}</span>, //{toVisited}
        '確認日時': <span>{visited}<br/>{confirmDate}</span>,
        '発行日': <span style={{fontWeight: bold ? 'bold' : ''}} className={'text-nowrap'}>{billingDate && <>{billingDate}<br/></>}{date}</span>,
        '請求書タイトル': <div style={{fontWeight: bold ? 'bold' : ''}}>{bill['title']}<><br/>{(bill['totalAmount'] === 0 || bill['totalAmount']) && bill['totalAmount'].toLocaleString()+"円"}</></div>,
        '詳細': <StorageLink src={bill['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={bill.id} data-urigawa={urigawa} data-index={index} onClick={this.openFile} >表示</MDBChip></StorageLink>,
      };

      if (urigawa) {
        if (bill.deleted) {
          data['確認'] = <div style={{textAlign: 'center'}}>
            <MDBChip className="text-nowrap disabled custom-button-gray" style={{margin: '0 auto'}}>削除済</MDBChip>
          </div>
        } else {
          data['確認'] = <div style={{textAlign: 'center'}}>
            <MDBBtn rounded flat size="sm"
                    data-id={bill.id}
                    data-title={bill['title']}
                    onClick={this.delete}
            >
              <MDBIcon
                  icon="trash"
                  size={'2x'}
                  className="custom-text-grey"/>
            </MDBBtn>
          </div>
        }

      }
      /*
      if ( kaigawa ){
        data['操作'] = ''
        if(this.props.user.authorizer){
          if(bill['apply'] && Object.keys(bill['apply']).length > 0) {
          } else {
            data['操作'] = <MDBChip bgColor="default-color" text="white" data-id={bill.id} data-title={bill.title} onClick={this.applyFile}>承認</MDBChip>
          }
        }else{
          if( (bill['wait'] && Object.keys(bill['wait']).length > 0)
            || (bill['apply'] && Object.keys(bill['apply']).length > 0)
          ) {
          } else {
            data['操作'] = <MDBChip bgColor="default-color" text="white" data-id={bill.id} data-title={bill.title} onClick={this.waitFile}>承認依頼</MDBChip>
          }
        }
      }
      */
      bills.push(data);

    });

    const data = {
      columns: [
        {
          label: <div style={{width: 50}}><MDBInput label=" " type="checkbox" id="checkboxAll" onChange={(e) => this.billSelectAll(e.target.checked)} /></div>,
          field: 'check',
          sort: 'asc',
          width: 50
        },
        {
          label: <div><row>請求番号</row><br/><row>請求書ID</row></div>,
          field: '請求番号',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>送信者</row><br/><row>請求書送信日</row></div>,
          field: '送信者',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },

        //{
        //  label: '有効期限',
        //  field: '有効期限',
        //  sort: 'asc',
        //  width: 80,
        //  minimal: 'sm'
        //},
        {
          label: '請求受取者',
          field: '請求受取者',
          sort: 'asc',
          width: 200,
          minimal: 'sm'
        },
        {
          label: <div><row>請求書確認者</row><br/><row>請求書確認日</row></div>,
          field: '確認日時',
          sort: 'asc',
          width: 200,
          minimal: 'sm'
        },
        {
          label: <div><row>請求締日</row><br/><row>請求書作成日</row></div>,
          field: '発行日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>請求書タイトル</row><br/><row>税込合計金額（円）</row></div>,
          field: '請求書タイトル',
          sort: 'disabled',
          width: 370,
          minimal: 'sm'
        },
        {
          label: '詳細',
          field: '詳細',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        }
      ],
      rows: bills
    }


    if(urigawa) {
      data.columns.push({
        label: <div style={{textAlign: 'center'}}>削除</div>,
        field: '削除',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      });
    }

    let selectOption = <>
      <option value={1}>送信済一覧</option>
      <option value={2}>削除済一覧</option>
      <option value={3}>すべて</option>
    </>

    return (
      <>
      <h2>請求書一覧</h2>
        <div style={{display:'flex',flexDirection:'column',flex:1,justifyContent:'flex-start'}}>
          {/*<MDBCol md={'3'}/>*/}
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                <div style={{margin:0}}>
                  <select style={{height:'38px'}} className="browser-default custom-select" value={this.state.filterType} onChange={(e) => this.filterTypeChange(e)}>
                    <option value={'title'}>請求書タイトル</option>
                    <option value={'billingDate'}>請求締日</option>
                    <option value={'createdDate'}>請求書作成日</option>
                    <option value={'sentDate'}>請求書送信日</option>
                    <option value={'confirmDate'}>請求書確認日</option>
                    <option value={'amount'}>税込合計金額</option>
                    <option value={'sender'}>送信者</option>
                    <option value={'receiver'}>受取者</option>
                    <option value={'number'}>請求番号</option>
                    <option value={'id'}>請求書ID</option>
                  </select>
                </div>
                {this.state.filterType === 'billingDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.billingDateStart}
                            onChange={this.handleBillingDateStartChange}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.billingDateEnd}
                            onChange={this.handleBillingDateEndChange}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'createdDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.createdDateStart}
                            onChange={this.handleCreatedDateStartChange}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.createdDateEnd}
                            onChange={this.handleCreatedDateEndChange}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'sentDate' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.sentDateStart}
                            onChange={this.handleSentDateStartChange}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.sentDateEnd}
                            onChange={this.handleSentDateEndChange}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'confirmDate' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.confirmDateStart}
                            onChange={this.handleConfirmDateStartChange}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"billDate"}
                            className={"form-control"}
                            selected={this.state.confirmDateEnd}
                            onChange={this.handleConfirmDateEndChange}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'amount' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                    <div className="d-md-flex flex-row mt-0 align-items-center">
                      <input
                          className="form-control my-0 py-1"
                          type="number"
                          placeholder=""
                          aria-label=""
                          onChange={this.handleSearchAmountChange}
                          value={this.state.searchAmount}
                      />
                      <div className="p-2">〜</div>
                      <input
                          className="form-control my-0 py-1"
                          type="number"
                          placeholder=""
                          aria-label=""
                          onChange={this.handleSearchAmountEndChange}
                          value={this.state.searchAmountEnd}
                      />
                    </div>
                    </div>
                }
                {(this.state.filterType === 'title') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={this.handleSearchTitleChange}
                          value={this.state.searchTitle}
                      />
                    </div>
                }
                {(this.state.filterType === 'number' || this.state.filterType === 'id') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={this.handleSearchNumberIdChange}
                          value={this.state.searchNumberId}
                      />
                    </div>
                }
                {this.state.filterType === 'sender' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.selectedSellerUser} onChange={(e) => this.selectSellerUser(e)}>
                        {this.state.sellerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                {this.state.filterType === 'receiver' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.selectedBuyerUser} onChange={(e) => this.selectBuyerUser(e)}>
                        {this.state.buyerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                <div>
                  <MDBBtn
                      color="default"
                      type="submit"
                      style={{width: '90px', margin: 0, height: 38, padding: '0.64rem 1.14rem'}}
                      onClick={this.billFilter}
                  >検　索</MDBBtn>
                </div>
              </div>
              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{width: 150}}>*/}
              {/*    <input type="radio" className="form-check-input" id="filtertype2" name="filterTypeName"*/}
              {/*           checked={this.state.filterType === 'createdDate'} onClick={() => this.filterTypeChange('createdDate')}/>*/}
              {/*    <label className="form-check-label" htmlFor="filtertype2">請求書作成日</label>*/}
              {/*  </div>*/}
              {/*  <div style={{flex: 1, display: 'flex', alignItems: 'center', gap: 10}}>*/}
              {/*    <div className="d-md-flex flex-row mt-0 align-items-center">*/}
              {/*      <DatePicker*/}
              {/*          dateFormat={"yyyy/MM/dd"}*/}
              {/*          id={"billDate"}*/}
              {/*          className={"form-control"}*/}
              {/*          selected={this.state.createdDateStart}*/}
              {/*          onChange={this.handleCreatedDateStartChange}*/}
              {/*          locale={"ja-JP"}*/}
              {/*      />*/}
              {/*      <div className="p-2">〜</div>*/}
              {/*      <DatePicker*/}
              {/*          dateFormat={"yyyy/MM/dd"}*/}
              {/*          id={"billDate"}*/}
              {/*          className={"form-control"}*/}
              {/*          selected={this.state.createdDateEnd}*/}
              {/*          onChange={this.handleCreatedDateEndChange}*/}
              {/*          locale={"ja-JP"}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{width: 150}}>*/}
              {/*    <input type="radio" className="form-check-input" id="filtertype3" name="filterTypeName"*/}
              {/*           checked={this.state.filterType === 'sendDate'} onClick={() => this.filterTypeChange('sendDate')}/>*/}
              {/*    <label className="form-check-label" htmlFor="filtertype3">請求書送信日</label>*/}
              {/*  </div>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', gap: 10}}>*/}
              {/*    <div className="d-md-flex flex-row mt-0 align-items-center">*/}
              {/*      <DatePicker*/}
              {/*          dateFormat={"yyyy/MM/dd"}*/}
              {/*          id={"billDate"}*/}
              {/*          className={"form-control"}*/}
              {/*          selected={this.state.sentDateStart}*/}
              {/*          onChange={this.handleSentDateStartChange}*/}
              {/*          locale={"ja-JP"}*/}
              {/*      />*/}
              {/*      <div className="p-2">〜</div>*/}
              {/*      <DatePicker*/}
              {/*          dateFormat={"yyyy/MM/dd"}*/}
              {/*          id={"billDate"}*/}
              {/*          className={"form-control"}*/}
              {/*          selected={this.state.sentDateEnd}*/}
              {/*          onChange={this.handleSentDateEndChange}*/}
              {/*          locale={"ja-JP"}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{width: 150}}>*/}
              {/*    <input type="radio" className="form-check-input" id="filtertype4" name="filterTypeName"*/}
              {/*           checked={this.state.filterType === 'confirmDate'} onClick={() => this.filterTypeChange('confirmDate')}/>*/}
              {/*    <label className="form-check-label" htmlFor="filtertype4">請求書確認日</label>*/}
              {/*  </div>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', gap: 10}}>*/}
              {/*    <div className="d-md-flex flex-row mt-0 align-items-center">*/}
              {/*      <DatePicker*/}
              {/*          dateFormat={"yyyy/MM/dd"}*/}
              {/*          id={"billDate"}*/}
              {/*          className={"form-control"}*/}
              {/*          selected={this.state.confirmDateStart}*/}
              {/*          onChange={this.handleConfirmDateStartChange}*/}
              {/*          locale={"ja-JP"}*/}
              {/*      />*/}
              {/*      <div className="p-2">〜</div>*/}
              {/*      <DatePicker*/}
              {/*          dateFormat={"yyyy/MM/dd"}*/}
              {/*          id={"billDate"}*/}
              {/*          className={"form-control"}*/}
              {/*          selected={this.state.confirmDateEnd}*/}
              {/*          onChange={this.handleConfirmDateEndChange}*/}
              {/*          locale={"ja-JP"}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{width: 150}}>*/}
              {/*    <input type="radio" className="form-check-input" id="filtertype5" name="filterTypeName"*/}
              {/*           checked={this.state.filterType === 'amount'} onClick={() => this.filterTypeChange('amount')}/>*/}
              {/*    <label className="form-check-label" htmlFor="filtertype5">税込合計金額</label>*/}
              {/*  </div>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', gap: 10}}>*/}
              {/*    <input*/}
              {/*        className="form-control my-0 py-1"*/}
              {/*        type="text"*/}
              {/*        placeholder="文字列検索"*/}
              {/*        aria-label="文字列検索"*/}
              {/*        onChange={this.handleSearchAmountChange}*/}
              {/*        value={this.state.searchAmount}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{width: 150}}>*/}
              {/*    <input type="radio" className="form-check-input" id="filtertype6" name="filterTypeName"*/}
              {/*           checked={this.state.filterType === 'sender'} onClick={() => this.filterTypeChange('sender')}/>*/}
              {/*    <label className="form-check-label" htmlFor="filtertype6">送信者</label>*/}
              {/*  </div>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', gap: 10}}>*/}
              {/*    <select className="browser-default custom-select" value={this.state.sortMainType} onChange={(e) => this.selectSortMainType(e)}>*/}
              {/*      <option value={'startDate'}>発行日順で並べ替え</option>*/}
              {/*      <option value={'billingDate'}>請求日順で並べ替え</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{width: 150}}>*/}
              {/*    <input type="radio" className="form-check-input" id="filtertype7" name="filterTypeName"*/}
              {/*           checked={this.state.filterType === 'receiver'} onClick={() => this.filterTypeChange('receiver')}/>*/}
              {/*    <label className="form-check-label" htmlFor="filtertype7">受取者</label>*/}
              {/*  </div>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', gap: 10}}>*/}
              {/*    <select className="browser-default custom-select" value={this.state.sortMainType} onChange={(e) => this.selectSortMainType(e)}>*/}
              {/*      <option value={'startDate'}>発行日順で並べ替え</option>*/}
              {/*      <option value={'billingDate'}>請求日順で並べ替え</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', gap: 10, marginLeft: 150}}>*/}
              {/*    <MDBBtn*/}
              {/*        color="default"*/}
              {/*        type="submit"*/}
              {/*        style={{width: '100%', margin: 0}}*/}
              {/*        onClick={this.billFilter}*/}
              {/*    >検　索</MDBBtn>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>

       {/* <MDBRow>
          <MDBCol md="3">
            <div className="input-group md-form form-sm form-1 pl-0">
              <div className="input-group-prepend">
                <span className="input-group-text lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search" />
                </span>
              </div>
              <input
                  className="form-control my-0 py-1"
                  type="text"
                  placeholder="文字列検索"
                  aria-label="文字列検索"
                  onChange={this.handleSearchwordAChange}
                  onKeyDown={this.handleSearchwordAKeyDown}
                  value={this.state.searchwordA}
              />
            </div>
          </MDBCol>

        </MDBRow>*/}
          <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'space-between',alignItems:'center'}}>
          <div>
            {
              <MDBBtn size={'sm'} color="default" style={{padding: 10, height: 38, width: 200,margin:0}} disabled={this.state.selectedBillList.length > 0 ? this.state.downloading : true} onClick={() => this.downloadSelectedBills()}>
                {this.state.downloading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
                {!this.state.downloading && <MDBIcon icon="download" style={{marginRight: 10}} />}
                選択したPDF一括ダウンロード
              </MDBBtn>
            }
          </div>
        <div style={{alignItems: 'center',color: '#495057',display:'flex',justifyContent:'flex-end',flexDirection:'row'}}>
          <div style={{margin: 0,width:220}}>
              <select style={{height:'38px'}} className="browser-default custom-select" value={this.state.sortMainType} onChange={(e) => this.selectSortMainType(e)}>
                <option value={'startDate'}>請求書作成日で並べ替え</option>
                <option value={'billingDate'}>請求締日順で並べ替え</option>
              </select>
            </div>
            <div className="form-check form-check-inline" style={{marginLeft: 10}}>
              <input type="radio" className="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample"
                     checked={this.state.sortType === 'asc'} onClick={() => this.sortChange('asc')}/>
              <label className="form-check-label" htmlFor="materialInline1">昇順</label>
            </div>

            <div className="form-check form-check-inline">
              <input type="radio" className="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample"
                     checked={this.state.sortType === 'desc'} onClick={() => this.sortChange('desc')}/>
              <label className="form-check-label" htmlFor="materialInline2">降順</label>
            </div>

            {
                urigawa && <>
                  <div className="form-check form-check-inline" style={{marginLeft: 20}}>
                    表示
                  </div>

                  <div className="form-check form-check-inline">
                    <select className="browser-default custom-select" value={this.state.billsType} onChange={(e) => this.selectBillsType(e)}>
                      {selectOption}
                    </select>
                  </div>
                </>
            }

          <div>
              <MDBBtn
                  color="default" style={{width: '90px', height: 38, padding: '0.64rem 1.14rem',marginRight:0}} flat={true} outline={true} onClick={() => this.clearFilter()}>
                リセット
              </MDBBtn>
            </div>
          </div>
        </div>
        </div>
      <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
        <div id="div1" style={{height: 20}}>
        </div>
      </div>
      <MDBTable responsive btn small id={"div2"}>
        <MDBTableHead color="primary-color" textWhite columns={data.columns} />
        <MDBTableBody rows={data.rows} />
      </MDBTable>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

        <MDBModal isOpen={this.state.deleteModal} toggle={this.deleteClose}>
          <MDBModalHeader toggle={this.deleteClose}>請求書削除</MDBModalHeader>
          <MDBModalBody>
            請求書「{this.state.deleteTitle}」を削除します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.deleteClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.deleteClose} color="danger" data-id={this.state.deleteId}>削除</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
          <MDBModalHeader toggle={this.applyClose}>承認(発注確定)</MDBModalHeader>
          <MDBModalBody>
          {this.state.applyTitle} の請求書内容での発注を承認しますか？<br />
          承認すると発注が確定し、販売会社に通知されます。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.applyClose} color="danger" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.waitModal} toggle={this.waitClose}>
          <MDBModalHeader toggle={this.waitClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
            {this.state.waitTitle} の請求書から発注するために、上長に承認依頼をします。<br />
            よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.waitClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.waitClose} color="danger" data-id={this.state.waitId} >上長に承認依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

      </>
    )

  }
}

export default BillsTableList;
