import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { MDBChip, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, toast } from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"
import { isNumber } from 'util';

import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';

import "react-datepicker/dist/react-datepicker.css";

import "./Cart.css";

class OtasukeCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartNo: 0,
      carts: [],
      cartName: '',
      modal: false,
      modalConfirm: false,
      modalDelivery: false,
      modalDeliveryDirect: false,
      modalSave: false,
      modalDelete: false,
      carriage: 0,

      lastDeliveryAddress: {},
    }
  }


  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if (this.props.org && collection) {
      let ci = this.props.db.collection('carts_info').doc(this.props.user.id).collection('carts_info').doc(this.props.org.id).get();
      let ocRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id).collection('otasuke_carts').doc(this.props.org.id);
      let oc = ocRef.get();

      // console.log('purchaser', this.props.user.id, this.props.org.id)

      let response = await Promise.all([ci, oc]);

      let ciDoc = response[0];
      let ocDoc = response[1];

      let ciData = ciDoc.data();
      if (ciData) {
        let lastDeliveryAddress = ciData.lastDeliveryAddress || {};
        this.setState({
          lastDeliveryAddress: lastDeliveryAddress
        })
      }

      let ocData = ocDoc.data();

      if (!ocData) {
        return;
      }

      // let withDeletedItem = false;
      for (let i=0; i<5; i++) {
        let cart = ocData[i];

        if (cart && cart.items) {
          let items = cart.items;
          delete cart['deletedItem'];
          await Promise.all(items.map(async (item) => {
            let itemSell = await collection.doc(item.id).get();
            let itemSellData = itemSell.data();

            delete item['deletedItem'];
            delete item['deletedText'];

            if (!itemSellData) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '販売終了';
            } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '要見積';
            } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '要見積';
            } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
              let expiration_date = itemSellData['price'][0]['expiration_date'];
              let expDate = expiration_date.toDate();

              if ((expDate.getTime() - this.props.dateNow) < 0) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '要見積';
              } else {
                item.price = itemSellData['price'][0]['price'];
              }
            } else {
              item.price = itemSellData['price'][0]['price'];
            }
            // if (itemSellData && itemSellData['price']) {
            //   item.price = itemSellData['price'][0]['price'];
            // } else {
            //   withDeletedItem = true;
            //   cart.items = cart.items.filter((obj) => obj.id !== item.id);
            // }
          }));
        }
      }

      // if (withDeletedItem) {
      //   ocRef.set(ocData, {merge: true});
      // }
      this.setState({carts: ocData})

      let total=0;
      if(ocData[this.state.cartNo] && ocData[this.state.cartNo].items && ocData[this.state.cartNo].items.length){
        let items = ocData[this.state.cartNo].items;
        items.forEach((item, index) => {
          total += item.count * item.price;
        });
      }

      let carriage_free = 0;
      let carriage = 0;
      if(this.props.org.maker && this.props.userOrg && this.props.userOrg.sellers){
        // メーカーU -> 販社
        this.props.userOrg.sellers.map((seller) => {
          if(seller.organization == this.props.org.id){
            carriage_free = seller.carriage_free ? seller.carriage_free : 0;
            carriage = seller.carriage ? seller.carriage : 0;
          }
        })
      }

      if(this.props.org.reseller && this.props.userOrg){
        // 販社U -> 購入U
        carriage_free = this.props.userOrg.carriage_free ? this.props.userOrg.carriage_free : 0;
        carriage = this.props.userOrg.carriage ? this.props.userOrg.carriage : 0;
      }
      let postage = total < carriage_free ? carriage : 0 ;
      this.setState({carriage: postage})

    }
  }

  componentDidUpdate = (prevProps) => {
    // console.log(this.props.id, prevProps.id)
    if (this.props.org !== prevProps.org || this.props.userOrg !== prevProps.userOrg || this.props.id !== prevProps.id) {
      // console.log('load did update getCarts')
      this.getCarts();
    }

    let cart = 0;
    cart = this.props.id - 1;
    if(isNaN(cart)){
      cart=0;
    }

    if (this.state.cartNo != cart){
      this.setState({cartNo: cart});
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    console.log('load did mount getCarts')
    this.getCarts();
  }

  decrease = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("decrease:");
    console.log(itemId);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        if(cart[index]['count']>0){
          cart[index]['count']--;
        }
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  description = (e) => {
    // console.log('ee',e);
    let value = e.currentTarget.value;
    const itemId = e.currentTarget.parentNode.dataset.id;

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['description'] = value;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
        .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  increase = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("increase:");
    console.log(itemId);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];

    // console.log("carts", carts, cartBase)
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count']++;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  updateNum = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNum:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    console.log("Number");
    this.setState({
      numupdate: true,
    })

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });
  }

  updateNumSave = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNumSave:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    let carts = {};
    Object.assign(carts, this.state.carts);

    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }


  dellCart = (e) => {
    const itemId = e.currentTarget.dataset.id;
    // console.log("dellCart:");
    // console.log(itemId);

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    let newCart = [];
    cart.forEach((item, index) => {
      if(index != itemId){
        newCart.push(cart[index]);
      }
    });
    carts[this.state.cartNo].items = newCart;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
      .collection('otasuke_carts').doc(this.props.org.id);
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then((doc) => {
          this.getCarts();
        });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    this.setState({
      carts: carts,
    })
  }

  handleOrderNoChange = (e) => {
    this.updateCartData("orderNo",e.currentTarget.value);
    this.updateToDB('orderNo', e.currentTarget.value)
  }

  handleCartNameChange = (e) => {
    this.updateCartData("cartName", e.currentTarget.value)
    this.updateToDB('cartName', e.currentTarget.value)
  }

  handleToUserChange = (e) => {
    if (e.currentTarget.selectedIndex > 0) {
      this.updateCartData("toUserName",e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name);
      this.updateCartData("toUser",e.currentTarget.value);

      this.updateToDB('toUserName', e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name)
      this.updateToDB('toUser', e.currentTarget.value)
    } else {
      this.updateCartData("toUserName",'');
      this.updateCartData("toUser",'');

      this.updateToDB('toUserName', '');
      this.updateToDB('toUser', '');
    }
  }
  handleOrderNoukiChange = (e) => {
    this.updateCartData("orderNouki",e.currentTarget.value);
  }
  handleOrderNoukiDayChange = (e) => {
    this.updateCartData("orderNouki","希望");
    this.updateCartData("orderNoukiDay",e.currentTarget.value);
  }
  handleOrderNoukiDateChange = (e) => {
    this.updateCartData('orderNouki', '日付指定');
    let date = firebase.firestore.Timestamp.fromDate(e);
    this.updateCartData('orderNoukiDate', date);
  }
  handleOrderBasyoChange = (e) => {
    this.updateCartData("orderBasyo",e.currentTarget.value);
  }
  handleOrderPropertyNameChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderPropertyName",e.currentTarget.value);
  }
  handleOrderZipcodeChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderZipcode",e.currentTarget.value);
  }
  handleOrderAddr1Change = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderAddr1",e.currentTarget.value);
  }
  handleOrderAddr2Change = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderAddr2",e.currentTarget.value);
  }
  handleOrderJigyosyoChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderJigyosyo",e.currentTarget.value);
  }
  handleOrderTantoChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderTanto",e.currentTarget.value);
  }
  handleOrderPhoneChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderPhone",e.currentTarget.value);
  }
  handleOrderBikouChange = (e) => {
    this.updateCartData("orderBikou",e.currentTarget.value);
  }

  sendOtasukeCart = (e, cartData) => {
    e.preventDefault();

    if (cartData.orderBasyo === 'アドレス帳から選択する' && Object.keys(cartData.deliveryAddress || {}).length === 0) {
      this.setState({
        modalDelivery: true
      })
      return;
    }

    if (cartData.orderBasyo === '納品先を直接入力する' &&
        !(
            cartData.orderPropertyName !== '' &&
            cartData.orderZipcode !== '' &&
            cartData.orderAddr1 !== '' &&
            cartData.orderAddr2 !== '' &&
            cartData.orderJigyosyo !== '' &&
            cartData.orderTanto !== '' &&
            cartData.orderPhone !== '')) {
      this.setState({
        modalDeliveryDirect: true
      })
      return;
    }

    this.setState({
      modal: true,
    })
  }

  modalDeliveryClose = (e) => {
    this.setState({
      modalDelivery: false
    })
  }

  modalDeliveryDirectClose = (e) => {
    this.setState({
      modalDeliveryDirect: false
    })
  }

  sendSaveCart = (e) => {
    e.preventDefault();
    this.setState({
      modalSave: true,
    })
  }

  deleteOtasukeCart = (e) => {
    e.preventDefault();
    this.setState({
      modalDelete: true
    })
  }

  addProduct = (e) => {
    // "/items?cn="+ (this.state.cartNo)
    this.saveCart()
    this.props.cProps.history.push("/items?cn="+ (this.state.cartNo))
  }

  goDeliveryAddress = (e) => {
    // e.preventDefault();

    this.saveCart()
    this.props.cProps.history.push('/deliveryAddress?cn='+(this.state.cartNo)+'&ct=otasuke')
  }

  updateToDB = (field, val) => {
    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    // console.log(carts, cartBase)
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
        sendHelpCart: 0
      };
      carts[this.state.cartNo] = cartBase;
    }

    let date = firebase.firestore.Timestamp.fromDate(new Date());
    cartBase.latestUpdate = date;
    carts[this.state.cartNo].latestUpdate = date;

    cartBase[field] = val;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
        .collection('otasuke_carts').doc(this.props.org.id);

    let self = this;
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then(function () {
          // self.getCarts();
        })
        .catch(function (error) {
          console.error("Error set document: ", error);
        });

    let items = carts[self.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[self.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[self.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    self.setState({
      carts: carts,
    })
  }

  saveCart = (e) => {
    // const itemId = e.currentTarget.parentNode.dataset.id;
    // console.log("cartName:", itemId, itemName);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    // console.log(carts, cartBase)
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
        sendHelpCart: 0
      };
      carts[this.state.cartNo] = cartBase;
    }

    let date = firebase.firestore.Timestamp.fromDate(new Date());
    cartBase.latestUpdate = date;
    carts[this.state.cartNo].latestUpdate = date;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
        .collection('otasuke_carts').doc(this.props.org.id);

    let self = this;
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then(function () {
          self.getCarts();
        })
        .catch(function (error) {
          console.error("Error set document: ", error);
        });



    // //this.getCarts();
    this.setState({
      modalSave: false,
    })
  }

  modalSaveClose = (e) => {
    this.setState({
      modalSave: false,
    })
  }

  modalDeleteClose = (e) => {
    this.setState({
      modalDelete: false
    })

    let deleteCartId = e && e.currentTarget.dataset.id;
    if(deleteCartId){
      let self = this;
      let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
          .collection('otasuke_carts').doc(this.props.org.id);
      let setWithMerge = cartRef.set({
        [deleteCartId]: {},
        update: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })
          .then((doc) => {
            console.log('deleted')
            self.getCarts();
            // self.props.cProps.history.push('/otasuke/carts')
          });
    }
  }

  modalCloseConfirm = (e) => {
    this.setState({
      modalConfirm: false
    })
  }

  modalClose = (e) => {
    this.setState({
      modal: false,
    })

    let cartId = e && e.currentTarget.dataset.id;
    let discard = e && e.currentTarget.dataset.discard;
    if(cartId){
      // console.log("request");

      let carts = this.state.carts;

      let cartBase = {
        items: [],
        orderNo: '',
        toUser: '',
        toUserName: '',
        orderNouki: '最短',
        orderNoukiDay: '',
        orderNoukiDate: '',
        orderBasyo: '登録住所',
        orderPropertyName: '',
        orderZipcode: '',
        orderAddr1: '',
        orderAddr2: '',
        orderJigyosyo: '',
        orderTanto: '',
        orderPhone: '',
        orderBikou: '',
        requested: false,
        carriage: this.state.carriage,
      };
      if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items){
        Object.assign(cartBase, this.state.carts[this.state.cartNo]);

        if (cartBase.orderNouki === '日付指定' && !cartBase.orderNoukiDate) {
          toast.warn('日付を選択してください', {
            closeButton: true
          });
          return;
        }

        let sendHelpCartDate = firebase.firestore.Timestamp.fromDate(new Date());

        // cartBase.requested = false;
        cartBase.sendHelpCart = 1;
        cartBase.sendHelpCartUser = this.props.user.id;
        cartBase.sendHelpCartUserName = this.props.user.last_name + " " + this.props.user.first_name;
        cartBase.sendHelpCartDate = sendHelpCartDate;
        carts[this.state.cartNo] = cartBase;

        let otasukeCartsHistoryRef = this.props.db.collection('otasuke_carts_history').doc();
        let dueDate = new Date();
        let created = firebase.firestore.Timestamp.fromDate(new Date());

        let deliveryAddress = {};
        let noDeliveryAddress = false;

        if (cartBase.orderBasyo === '前回の納品先' && Object.keys(this.state.lastDeliveryAddress).length > 0) {
          let lastDeliveryAddress = this.state.lastDeliveryAddress;

          Object.assign(deliveryAddress, {
            propertyName: lastDeliveryAddress.propertyName,
            postCode: lastDeliveryAddress.postCode,
            address1: lastDeliveryAddress.address1,
            address2: lastDeliveryAddress.address2,
            officeName: lastDeliveryAddress.officeName,
            personInCharge: lastDeliveryAddress.personInCharge,
            phoneNumber: lastDeliveryAddress.phoneNumber
          });

          cartBase.deliveryAddress = {};
          cartBase.orderPropertyName = '';
          cartBase.orderZipcode = '';
          cartBase.orderAddr1 = '';
          cartBase.orderAddr2 = '';
          cartBase.orderJigyosyo = '';
          cartBase.orderTanto = '';
          cartBase.orderPhone = '';
          Object.assign(cartBase.deliveryAddress, deliveryAddress);

          // console.log(lastDeliveryAddress, deliveryAddress, cartBase.deliveryAddress)
        }

        if (cartBase.orderBasyo === 'アドレス帳から選択する') {
          Object.assign(deliveryAddress, cartBase.deliveryAddress)

          cartBase.orderPropertyName = '';
          cartBase.orderZipcode = '';
          cartBase.orderAddr1 = '';
          cartBase.orderAddr2 = '';
          cartBase.orderJigyosyo = '';
          cartBase.orderTanto = '';
          cartBase.orderPhone = '';
        }

        if (cartBase.orderBasyo === '納品先を直接入力する') {
          Object.assign(deliveryAddress, {
            propertyName: cartBase.orderPropertyName,
            postCode: cartBase.orderZipcode,
            address1: cartBase.orderAddr1,
            address2: cartBase.orderAddr2,
            officeName: cartBase.orderJigyosyo,
            personInCharge: cartBase.orderTanto,
            phoneNumber: cartBase.orderPhone
          })

          delete cartBase.deliveryAddress
        }

        if (cartBase.orderBasyo === '納品先を指定しない') {
          cartBase.orderPropertyName = '';
          cartBase.orderZipcode = '';
          cartBase.orderAddr1 = '';
          cartBase.orderAddr2 = '';
          cartBase.orderJigyosyo = '';
          cartBase.orderTanto = '';
          cartBase.orderPhone = '';

          delete cartBase.deliveryAddress

          noDeliveryAddress = true;
        }

        let setWithMergeOrder = otasukeCartsHistoryRef.set({
          cart: cartBase,
          cartNo: this.state.cartNo,
          toUser: cartBase.toUser,
          toUserName: cartBase.toUserName,
          toOrganization: this.props.org.id,
          fromUser: this.props.user.id,
          fromUserName: this.props.user.last_name + " " + this.props.user.first_name,
          fromOrganization: this.props.user.organization,
          created: created,
          dueDate: dueDate.getTime(),
          latestUpdate: created,
          sortDate: created,
          wait: {
            [this.props.user.id]: true,
          },
          requested: true,
          rejected: false,
          newReject: false,
          newApply: false,
          readSeller: false,
          sellerConfirm: false,
          sendHelpCart: 1,
          sendHelpCartUser: this.props.user.id,
          sendHelpCartUserName: this.props.user.last_name + " " + this.props.user.first_name,
          sendHelpCartDate: sendHelpCartDate,
          readBuyer: false
        }, { merge: true });


        let cartRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id)
          .collection('otasuke_carts').doc(this.props.org.id);

        delete carts[this.state.cartNo]['deletedItem'];
        delete carts[this.state.cartNo]['deletedText'];

        let oc = carts[this.state.cartNo]
        if (discard) {
          oc = {}
        }

        this.setState({
          modalConfirm: true
        })

        let setWithMerge = cartRef.set({
          [this.state.cartNo]: oc,
          update: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true })
            .then((doc) => {
              this.getCarts();
            });

        // console.log(noDeliveryAddress, deliveryAddress)

        if (!noDeliveryAddress) {
          let cartInfoRef = this.props.db.collection('carts_info').doc(this.props.user.id)
              .collection('carts_info').doc(this.props.org.id);

          let setCartsInfoWithMerge = cartInfoRef.set({
            lastDeliveryAddress: deliveryAddress,
            update: firebase.firestore.Timestamp.fromDate(new Date())
          }, { merge: true }).then((doc) => {
            this.getCarts();
          });
        }

      }
    }

  }

  updateCartData = (key, val) => {
    let cartData = {};
    if(this.state.carts[this.state.cartNo]){
      cartData = this.state.carts[this.state.cartNo];
    }
    if(cartData.requested) {
      return;
    }

    cartData[key] = val;
    let carts = this.state.carts;
    carts[this.state.cartNo] = cartData;
    if (!cartData.items) {
      cartData.items = [];
      cartData.created = firebase.firestore.Timestamp.fromDate(new Date());
      cartData.rejected = false;
      cartData.creatorUser = this.props.user.id;
      cartData.creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;
    }

    // console.log(carts, cartData, this.state.cartNo, key, val)

    this.setState({
      carts: carts
    });
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let cart = [];
    let total = 0;
    let postage = 0;
    let taxes = {};
    let taxTotal = 0;

    let cartData = {
      items: [],
      cartName: '',
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderNoukiDate: '',
      orderBasyo: '登録住所',
      orderPropertyName: '',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      requested: false,
    };

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items){ // && this.state.carts[this.state.cartNo].items.length

      Object.assign(cartData, this.state.carts[this.state.cartNo]);
      let items = this.state.carts[this.state.cartNo].items;
      items.forEach((item, index) => {
        // console.log('otasuke item', item)
        let date = item.update.toDate().getFullYear() + '/'
            + n(item.update.toDate().getMonth() + 1) + '/'
            + n(item.update.toDate().getDate())+ ' '
            + n(item.update.toDate().getHours()) + ':'
            + n(item.update.toDate().getMinutes());

        let priceText = <><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</>;
        if (item.deletedItem) {
          priceText = <><span style={{color: 'red'}}>{item.deletedText}</span></>
        }

              cart.push({
                date: date,
                code: item.code,
                name: <Link to={"/items/" + item.id}>{item.name}</Link>,
                maker: item.maker,
                count:
                <div>
                  <div style={{textAlign: 'right', width: '8rem'}}>{priceText}</div>
                  <div className="def-number-input number-input" data-id={index}>
                    <button onClick={this.decrease} className="minus">－</button>
                    <input
                        className="quantity"
                        type="number"
                        value={item.count}
                        onChange={this.updateNum}
                        onBlur={this.updateNumSave}
                    />
                    <button onClick={this.increase} className="plus">＋</button>
                  </div>
                </div>,
                price: <div style={{textAlign: 'right'}}>{!item.deletedItem && (item.count * item.price).toLocaleString() + '円'}</div>,
                // ToDo: Songodson data garahgui bgaag zasah
                description:<div data-id={index}>
                  <textarea
                      className="form-control"
                      rows="2"
                      onChange={this.description}
                      value={item.description || ''}
                  />
                </div>,
                btn: <MDBBtn size="sm4" className="text-nowrap"
                        onClick={this.dellCart}
                        data-id={index}
                      >削除</MDBBtn>,
              })

        if (!item.deletedItem) {
          if (taxes[item.tax]) {
            taxes[item.tax] += (item.count * item.price * item.tax);
          } else {
            taxes[item.tax] = (item.count * item.price * item.tax);
          }
          taxTotal += parseInt((item.count * item.price * item.tax), 10);
          total += item.count * item.price;
        }

      });
      postage = this.state.carriage;
      total += postage;
    }

    const isEnable = (cartData.toUser !== '')
    && (this.state.orderNouki !== '')
    && (this.state.orderBasyo !== '')
    ;

    const data = {
      columns: [
        {
          label: '年月日',
          field: 'date',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品コード',
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: 'price,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '備考',
          field: 'description,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '削除',
          field: 'delete',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      //rows: this.state.dbItems

      rows: cart
    }

    // console.log('data', data)

    // console.log(cartData.orderBasyo)
    // let disableSend = !(cart.length > 0) || !cartData.toUser || cartData.orderBasyo === '' || cartData.orderBasyo === '登録住所' || !cartData.cartName || cartData.deletedItem;
    // console.log(!(cart.length > 0), !cartData.toUser, cartData.deletedItem)
    let disableSend = !(cart.length > 0) || !cartData.toUser || cartData.deletedItem;
    let disable = true;
    let tempDisable = true;

    Object.keys(cartData).forEach(key => {
      if (key !== "orderNouki" && key !== "orderBasyo" && key !== "items" && key !== "created" && key !== "creatorUser" && key !== "creatorUserName") {
        // console.log(key, cartData[key]);
        if (cartData[key]) {
          tempDisable = false
        }
      }
    });

    disable = tempDisable === false ? false : !(cart.length > 0);

    let deliveryAddress = {};
    let lastDeliveryAddress = {};

    if (cartData.requested) {
      if (cartData.orderBasyo === 'アドレス帳から選択する') {
        deliveryAddress = cartData.deliveryAddress || {};
      }

      if (cartData.orderBasyo === '前回の納品先') {
        lastDeliveryAddress = cartData.deliveryAddress || {};
      }
    } else {
      deliveryAddress = cartData.deliveryAddress || {};
      lastDeliveryAddress = this.state.lastDeliveryAddress;
    }

    // console.log(cartData, disable, tempDisable, disableSend)

    return (
      <>
        <h2><MDBIcon icon="shopping-cart" className="mr-3" />お助けカート{this.state.cartNo + 1}</h2>

        <div className="form-group" style={{display: "-webkit-inline-box", margin: "25px 0"}}>
          <label htmlFor="cartName" className="col-form-label">カート名称</label>
          <input
              type="text"
              className="form-control"
              id="cartName"
              style={{height:'35px', width:'500px'}}
              // placeholder="発注番号"
              onChange={this.handleCartNameChange}
              value={cartData.cartName}
          />
        </div>

        <MDBTable btn responsive>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <div style={{textAlign: 'center'}}>
          {/*<Link to={"/items?cn="+ (this.state.cartNo)}>*/}
            <MDBBtn
                className="text-nowrap"
                onClick={this.addProduct}
            >商品を追加する</MDBBtn>
          {/*</Link>*/}
        </div>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{total.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map((value, index) => {
              return <tr key={index}>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        { cart.length>0 &&
        <div>

          <div className="form-group row">
            <label htmlFor="orderNo" className="col-sm-2 col-form-label">発注番号</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="orderNo"
                placeholder="発注番号"
                onChange={this.handleOrderNoChange}
                value={cartData.orderNo}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="orderTo" className="col-sm-2 col-form-label" style={{backgroundColor:'#58C8C5', color:'white', borderRadius: 5}}>お助けカート送信先</label>
            <div className="col-sm-10">
              <select
                className="browser-default custom-select"
                id="billToUser"
                onChange={this.handleToUserChange}
                value={cartData.toUser}
                >
                <option>選択してください</option>
                {this.props.org && this.props.org.users &&
                  this.props.org.users.map((user) =>
                    <option key={user.user} value={user.user} data-name={user.name}>{user.name}</option>
                  )
                }
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">希望納期</label>
            <div className="col-sm-10">
            <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderNouki"
                  id="orderNouki1"
                  value="最短"
                  onChange={this.handleOrderNoukiChange}
                  checked={(cartData.orderNouki=='最短' || !cartData.orderNouki) ? true : false}

                />
                <label className="form-check-label" htmlFor="orderNouki1">
                  最短
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderNouki"
                  id="orderNouki2"
                  value="希望"
                  onChange={this.handleOrderNoukiChange}
                  checked={cartData.orderNouki=='希望' ? true : false}
                />
                <label className="form-check-label" htmlFor="orderNouki2" style={{height: 'auto'}}>
                  <div className="form-group form-inline">
                    <input
                    type="number"
                    className="form-control"
                    id="orderNoukiDay"
                    placeholder=""
                    onChange={this.handleOrderNoukiDayChange}
                    value={cartData.orderNoukiDay}
                    />
                    日
                  </div>
                </label>
              </div>
              <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="orderNouki"
                    id="orderNouki3"
                    value="日付指定"
                    onChange={this.handleOrderNoukiChange}
                    checked={cartData.orderNouki=='日付指定' ? true : false}
                />
                <label className="form-check-label" htmlFor="orderNouki3" style={{height: 'auto'}}>
                  <div className="form-group form-inline" >
                    日付指定
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        id="orderNoukiDate"
                        className="form-control"
                        selected={cartData.orderNoukiDate ? cartData.orderNoukiDate.toDate() : ''}
                        onChange={this.handleOrderNoukiDateChange}
                        autoComplete={'off'}
                        locale="ja-JP"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">納品場所</label>
            <div className="col-sm-10">
              <div className="form-check" style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <div>
                  <input
                      className={'form-check-input'}
                      type={'radio'}
                      name={'orderBasyo'}
                      id={'orderBasyo1'}
                      value={'前回の納品先'}
                      onChange={this.handleOrderBasyoChange}
                      disabled={Object.keys(this.state.lastDeliveryAddress || {}).length === 0}
                      checked={cartData.orderBasyo=='前回の納品先' ? true : false}
                  />
                  <label className={'form-check-label'} htmlFor={'orderBasyo1'}>
                    前回お助けカートで指定した納品先（最終履歴）
                  </label>
                </div>
                {Object.keys(lastDeliveryAddress).length > 0 && <div style={{marginTop: 10}}>
                  <div>物件名：{lastDeliveryAddress.propertyName}</div>
                  <div>{lastDeliveryAddress.postCode}</div>
                  <div>{lastDeliveryAddress.address1} {lastDeliveryAddress.address2} {lastDeliveryAddress.officeName}</div>
                  <div>受取担当者：{lastDeliveryAddress.personInCharge}</div>
                  <div>電話番号：{lastDeliveryAddress.phoneNumber}</div>
                </div>}
              </div>
              <div className="form-check" style={{
                border: '1px solid #ced4da',
                borderRadius: 5,
                padding: 20,
                margin: '20px 0',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                  <input
                      className="form-check-input"
                      type="radio"
                      name="orderBasyo"
                      id="orderBasyo2"
                      value="アドレス帳から選択する"
                      onChange={this.handleOrderBasyoChange}
                      checked={(cartData.orderBasyo=='アドレス帳から選択する') ? true : false}
                  />
                  <label className="form-check-label" htmlFor="orderBasyo2">
                    アドレス帳から選択する
                  </label>
                  {cartData.requested ?
                      <MDBBtn className="btn-sm"
                              color=""
                              disabled={true}
                              style={{width: 200, backgroundColor: '#2D486A', color: '#ffffff'}}>
                        アドレス帳を開く
                      </MDBBtn>
                      :
                      // <Link to={'/deliveryAddress?cn='+(this.state.cartNo)+'&ct=otasuke'}>
                        <MDBBtn className="btn-sm"
                                color=""
                                onClick={this.goDeliveryAddress}
                                style={{width: 200, backgroundColor: '#2D486A', color: '#ffffff'}}>
                          アドレス帳を開く
                        </MDBBtn>
                      // </Link>
                  }

                </div>
                {Object.keys(deliveryAddress).length > 0 && <div>
                  <div>物件名：{deliveryAddress.propertyName}</div>
                  <div>{deliveryAddress.postCode}</div>
                  <div>{deliveryAddress.address1} {deliveryAddress.address2} {deliveryAddress.officeName}</div>
                  <div>受取担当者：{deliveryAddress.personInCharge}</div>
                  <div>電話番号：{deliveryAddress.phoneNumber}</div>
                </div>}

              </div>
              <div className="form-check" style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <input
                    className="form-check-input"
                    type="radio"
                    name="orderBasyo"
                    id="orderBasyo3"
                    value="納品先を直接入力する"
                    onChange={this.handleOrderBasyoChange}
                    checked={(cartData.orderBasyo=='納品先を直接入力する') ? true : false}
                />
                <label className="form-check-label" htmlFor="orderBasyo3">
                  納品先を直接入力する
                </label>
                <div className="form-group row">
                  <label htmlFor="orderPropertyName" className="col-sm-3 col-form-label">物件名</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderPropertyName"
                        onChange={this.handleOrderPropertyNameChange}
                        value={cartData.orderPropertyName}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderZipcode" className="col-sm-3 col-form-label">郵便番号</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderZipcode"
                        onChange={this.handleOrderZipcodeChange}
                        value={cartData.orderZipcode}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderAddr1" className="col-sm-3 col-form-label">住所１（都道府県・市区町村）</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderAddr1"
                        onChange={this.handleOrderAddr1Change}
                        value={cartData.orderAddr1}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderAddr2" className="col-sm-3 col-form-label">住所2（番地・建物名）</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderAddr2"
                        onChange={this.handleOrderAddr2Change}
                        value={cartData.orderAddr2}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderJigyosyo" className="col-sm-3 col-form-label">事業所名</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderJigyosyo"
                        onChange={this.handleOrderJigyosyoChange}
                        value={cartData.orderJigyosyo}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderTanto" className="col-sm-3 col-form-label">担当者名</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderTanto"
                        onChange={this.handleOrderTantoChange}
                        value={cartData.orderTanto}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderPhone" className="col-sm-3 col-form-label">電話番号</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderPhone"
                        onChange={this.handleOrderPhoneChange}
                        value={cartData.orderPhone}
                    />
                  </div>
                </div>
              </div>
              <div className="form-check" style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <div>
                  <input
                      className={'form-check-input'}
                      type={'radio'}
                      name={'orderBasyo'}
                      id={'orderBasyo4'}
                      value={'納品先を指定しない'}
                      onChange={this.handleOrderBasyoChange}
                      checked={cartData.orderBasyo=='納品先を指定しない' ? true : false}
                  />
                  <label className={'form-check-label'} htmlFor={'orderBasyo4'}>
                    納品先を指定しない
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="orderBikou" className="col-sm-2 col-form-label">備考</label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                id="orderBikou"
                rows="3"
                onChange={this.handleOrderBikouChange}
                value={cartData.orderBikou}
          />
            </div>
          </div>

          { cartData.rejected &&
          <div
            className="text-center p-3 mb-2 bg-warning text-dark"
          >却下（差戻）</div>
          }

        {/*  { cartData.requested ? */}
        {/*  <div*/}
        {/*    className="text-center p-3 mb-2 bg-warning text-dark"*/}
        {/*  >承認依頼中</div>*/}
        {/*:*/}
        {/*  <MDBBtn*/}
        {/*    className="btn-lg btn-block"*/}
        {/*    color="success"*/}
        {/*    disabled={!isEnable}*/}
        {/*    onClick={this.requestOrder}*/}
        {/*    >上長承認依頼</MDBBtn>*/}
        {/*  }*/}

        </div>
        }


        {/*{cart.length > 0 ? <MDBBtn*/}
        {/*    className="btn-block"*/}
        {/*    color="success"*/}
        {/*    onClick={this.sendOtasukeCart}*/}
        {/*>このお助けカートを送信する</MDBBtn>: <MDBBtn*/}
        {/*    className="btn-block"*/}
        {/*    color="success"*/}
        {/*    disabled={disable}*/}
        {/*    onClick={this.sendOtasukeCart}*/}
        {/*>このお助けカートを送信する</MDBBtn> }*/}
        <MDBBtn
            className="btn-block"
            color="success"
            disabled={disableSend}
            onClick={(e) => this.sendOtasukeCart(e, cartData)}
        >このお助けカートを送信する</MDBBtn>

        <div style={{textAlign: 'center'}}>
          <MDBBtn
              className="text-nowrap custom-button-red"
              color=""
              disabled={disable}
              onClick={this.deleteOtasukeCart}
          >削除する</MDBBtn>
          <MDBBtn
              className="text-nowrap"
              disabled={disable}
              onClick={this.sendSaveCart}
          >保存する</MDBBtn>
        </div>

        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>お助けカート送信</MDBModalHeader>
          <MDBModalBody>
            お助けカート「{cartData.cartName}」を相手先に送信します。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose} color={''} className={'custom-button-grey'} size={'sm'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalClose} color={'danger'} size={'sm'} data-id={this.state.cartNo} data-discard={true} >カート内容を破棄して送信</MDBBtn>
            <MDBBtn onClick={this.modalClose} size={'sm'} data-id={this.state.cartNo} >カート内容を保存して送信</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalConfirm} toggle={this.modalCloseConfirm}>
          <MDBModalHeader toggle={this.modalCloseConfirm}>お助けカート送信</MDBModalHeader>
          <MDBModalBody>
            送信しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalCloseConfirm} size={'sm'} data-id={this.state.cartNo} >確 認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalSave} toggle={this.modalSaveClose}>
          <MDBModalHeader toggle={this.modalSaveClose}>お助けカート保存</MDBModalHeader>
          <MDBModalBody>
            お助けカート「{cartData.cartName}」を保存します。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalSaveClose} color={''} className={'custom-button-blue'} size={'sm'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.saveCart} color={'danger'} size={'sm'}>ok</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalDelete} toggle={this.modalDeleteClose}>
          <MDBModalHeader toggle={this.modalDeleteClose}>お助けカート削除</MDBModalHeader>
          <MDBModalBody>
            お助けカート「{cartData.cartName}」を削除します。 よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDeleteClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalDeleteClose} color="danger" data-id={this.state.cartNo} >OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalDelivery} toggle={this.modalDeliveryClose}>
          <MDBModalHeader toggle={this.modalDeliveryClose}>納品先が指定されていません。</MDBModalHeader>
          <MDBModalBody>
            アドレス帳から納品先が指定されていません。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDeliveryClose} color="" style={{backgroundColor: '#2D486A', color: '#ffffff'}}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalDeliveryDirect} toggle={this.modalDeliveryDirectClose}>
          <MDBModalHeader toggle={this.modalDeliveryDirectClose}>未入力項目があります。</MDBModalHeader>
          <MDBModalBody>
            直接入力の場合は全項目を入力してから「上長承認依頼ボタン」をクリックしてください。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDeliveryDirectClose} color="" style={{backgroundColor: '#2D486A', color: '#ffffff'}}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default OtasukeCart;
