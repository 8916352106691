import React from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBContainer
} from 'mdbreact';
import firebase from "../../firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./SDSManagement.css";

class SDSManagementDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      dbSDSs: [],
      dateInput: {},
      confirmation: false,
      confirmModal: false,
      newHistory: {},
    }
  }

  getSDSs = (props) => {
    console.log("###### getSDSs ########", props)

    if (this.state.loading) {
      return;
    }

    this.setState({loading: true});

    this.props.db.collection('sds_management').doc(this.props.id).get()
    .then((snapshot) => {
      let data = snapshot.data();
      // console.log('snapshot', data)
      let newHistory = data.history || {};
      this.setState({
        data: data,
        newHistory: newHistory,
        loading: false
      })
    }).catch((e) => {
      this.setState({
        data: {},
        newHistory: {},
        loading: false
      })
    });

  }

  handleChange = (month, date, type) => {
    // console.log(date)
    let dateInput = this.state.dateInput
    let d = dateInput[month] || {};
    d[type] = date;
    dateInput[month] = d;
    this.setState({
      dateInput: dateInput
    });
  }

  confirmationDateInput = (isConfirm) => {
    if (isConfirm) {
      this.setState({
        confirmModal: true
      })

      let history = this.state.data.history || {};
      let preferredDate = this.state.data.preferredDate;

      let lastCheckDateTimestamp = 0;
      let lastCheckDate = null;
      let lastCheckUser = null;
      let lastCheckUserName = null;
      let revisionDate = null;



      //TODO: check status
      let dateNow = firebase.firestore.Timestamp.now().toDate();
      let yearNow = dateNow.getFullYear();
      let monthNow = dateNow.getMonth()+1;
      let dayNow = dateNow.getDate();

      const date1 = new Date(yearNow+'/'+monthNow+'/'+dayNow);
      let date2Year = yearNow;
      let date2Month = monthNow;

      if (monthNow === 12) {
        date2Year += 1;
        date2Month = 1;
      } else {
        date2Month += 1;
      }

      const date2 = new Date(date2Year+'/'+date2Month+'/1');
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      let year = firebase.firestore.Timestamp.now().toDate().getFullYear();
      // let history = this.state.data.history || {};
      let dateInput = this.state.dateInput;
      Object.keys(dateInput).forEach((key) => {
        let dates = dateInput[key] || {};

        if (yearNow !== date2Year && diffDays <= 7 && 0 <= diffDays && key*1 === 1) {
          year = date2Year;
        }

        if (!history[year+'/'+key]) {
          history[year+'/'+key] = [];
        }

        if (dates.checkDate && dates.revisionDate) {
          history[year+'/'+key].push({
            preferredMonth: year+'/'+key,
            checkDate: firebase.firestore.Timestamp.fromDate(dates.checkDate),
            checker: this.props.user.id,
            checkerUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
            revisionDate: firebase.firestore.Timestamp.fromDate(dates.revisionDate)
          })
        }
      });

      let status = 'normal';
      Object.keys(preferredDate).forEach(v => {
        if (preferredDate[v]) {
          console.log('preferredDate', v, history[yearNow+'/'+v])
          if (date2Month === Number(v) && diffDays <= 7 && 0 <= diffDays && !history[date2Year+'/'+v]) {
            status = 'warning';
          }
          if (monthNow >= v && (!history[yearNow+'/'+v] || history[yearNow+'/'+v] && history[yearNow+'/'+v].length === 0)) {
            status = 'alert';
          }
        }
      })
      console.log('status', status)

      Object.keys(history).forEach((h) => {
        if (history[h].slice(-1)[0] && history[h].slice(-1)[0].checkDate && history[h].slice(-1)[0].checkDate.toDate().getTime() > lastCheckDateTimestamp) {
          lastCheckDateTimestamp = history[h].slice(-1)[0].checkDate.toDate().getTime()

          lastCheckDate = history[h].slice(-1)[0].checkDate;
          lastCheckUser = history[h].slice(-1)[0].checker;
          lastCheckUserName = history[h].slice(-1)[0].checkerUserName;

          revisionDate = history[h].slice(-1)[0].revisionDate;
        }
      })

      let sdsManagementRef = this.props.db.collection('sds_management').doc(this.props.id);
      sdsManagementRef.set({
        history: history,
        lastCheckDate: lastCheckDate,
        lastCheckUser: lastCheckUser,
        lastCheckUserName: lastCheckUserName,
        revisionDate: revisionDate,
        status: status
      }, {merge: true})
    } else {
      this.setState({
        confirmation: false
      });
    }
  }

  addDateInput = (isConfirm) => {
    if (isConfirm) {
      // let year = firebase.firestore.Timestamp.now().toDate().getFullYear();
      // let history = this.state.data.history || {};
      //
      // let dateInput = this.state.dateInput;
      // Object.keys(dateInput).forEach((key) => {
      //   let dates = dateInput[key] || {};
      //
      //   history[year+'/'+key] = {
      //     preferredMonth: year+'/'+key,
      //     checkDate: dates.checkDate ? firebase.firestore.Timestamp.fromDate(dates.checkDate) : null,
      //     checker: this.props.user.id,
      //     checkerUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
      //     revisionDate: dates.revisionDate ? firebase.firestore.Timestamp.fromDate(dates.revisionDate) : null
      //   }
      // });
      // data.history = history;

      this.setState({
        // newHistory: history,
        confirmation: true
      });
    } else {
      console.log('confirm false');
      this.props.cProps.history.push('/sdsManagement');
    }

  }

  confirmModalClose = (e) => {
    this.setState({
      confirmModal: false
    });

    this.props.cProps.history.push('/sdsManagementHistory/' + this.props.id);
  }

  componentDidMount() {
    this.getSDSs(this.props);
    console.log('this.props.id', this.props.id)
  }
  componentWillReceiveProps(nextProps) {
    this.getSDSs(nextProps);
  }

  render() {
    if (!(this.props.user && this.props.user.admin)) {
      return (
          <>
          </>
      )
    }

    const dateSDS = [];
    let a = 0;

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let dateNow = firebase.firestore.Timestamp.now().toDate();
    let yearNow = dateNow.getFullYear();
    let monthNow = dateNow.getMonth()+1;
    let dayNow = dateNow.getDate();

    let confirmButton = true;

    this.state.data.preferredDate && Object.keys(this.state.data.preferredDate).forEach((pd) => {
      if (this.state.data.preferredDate[pd]) {
        let history = this.state.data.history || {};

        let tempHistoryYear = 0;
        let lastCheckDate = '';
        let disabled = false;

        const date1 = new Date(yearNow+'/'+monthNow+'/'+dayNow);
        let date2Year = yearNow;
        let date2Month = monthNow;

        if (monthNow === 12) {
          date2Year += 1;
          date2Month = 1;
        } else {
          date2Month += 1;
        }

        const date2 = new Date(date2Year+'/'+date2Month+'/1');
        const diffTime = date2 - date1;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // console.log('diffDays',diffDays, pd*1, monthNow+1, pd*1 > monthNow+1, (pd*1 === monthNow+1 && diffDays > 7))

        // if (pd*1+1 === monthNow && diffDays <= 7 && 0 <= diffDays) {}

        if ((yearNow === date2Year && pd*1 > monthNow+1) ||
            (yearNow === date2Year && pd*1 === monthNow+1 && diffDays > 7)
            ||
            (yearNow !== date2Year && pd*1 === 1 && diffDays > 7) ||
            (yearNow !== date2Year && pd*1 !== 1 && history[yearNow+'/'+pd])
        ) {
          disabled = true;
        }

        Object.keys(history).forEach((hk) => {
          let hkArray = hk.split('/');
          let y = hkArray[0]*1;
          let m = hkArray[1];
          let hkHistory = history[hk].slice(-1)[0];
          if (m === pd) {
            if (tempHistoryYear < y) {
              tempHistoryYear = y;
              if (hkHistory && hkHistory['checkDate']) {
                lastCheckDate = hkHistory['checkDate'].toDate().getFullYear() + '/'
                    + n(hkHistory['checkDate'].toDate().getMonth()+1) + '/'
                    + n(hkHistory['checkDate'].toDate().getDate()) + '';
              }
            }
          }
        })

        let checkDate = <DatePicker
            dateFormat="yyyy/MM/dd HH:mm:ss"
            id="checkDate"
            className="form-control"
            showTimeInput
            selected={this.state.dateInput[pd] && this.state.dateInput[pd].checkDate}
            onChange={(v) => this.handleChange(pd, v, 'checkDate')}
            autoComplete={'off'}
            disabled={disabled}
            locale="ja-JP"
        />;

        let revisionDate = <DatePicker
            dateFormat="yyyy/MM/dd"
            id="revisionDate"
            className="form-control"
            selected={this.state.dateInput[pd] && this.state.dateInput[pd].revisionDate}
            onChange={(v) => this.handleChange(pd, v, 'revisionDate')}
            autoComplete={'off'}
            disabled={disabled}
            locale="ja-JP"
        />;

        if (this.state.confirmation) {
          let checkD = '';
          let revisionD = '';

          if (this.state.dateInput[pd] && this.state.dateInput[pd].checkDate) {
            checkD = new Date(this.state.dateInput[pd].checkDate);
            checkD = checkD.getFullYear() + '/' + n(checkD.getMonth()+1) + '/' + n(checkD.getDate()) + ' ' + n(checkD.getHours()) + ':' + n(checkD.getMinutes());
          }

          if (this.state.dateInput[pd] && this.state.dateInput[pd].revisionDate) {
            revisionD = new Date(this.state.dateInput[pd].revisionDate);
            revisionD = revisionD.getFullYear() + '/' + n(revisionD.getMonth()+1) + '/' + n(revisionD.getDate());
          }

          checkDate = checkD;
          revisionDate = revisionD;
        }

        let data = {
          'SDSチェック希望日': `希望月${a+1}：${pd}月`,
          '前回チェック日': lastCheckDate,
          '今回チェック日': checkDate,
          'SDS改定日': revisionDate
        };
        a++;
        dateSDS.push(data);

        if (this.state.dateInput[pd] && this.state.dateInput[pd].checkDate && this.state.dateInput[pd].revisionDate) {
          confirmButton = false;
        }

        if (this.state.dateInput[pd] && !(this.state.dateInput[pd].checkDate && this.state.dateInput[pd].revisionDate) && (this.state.dateInput[pd].checkDate || this.state.dateInput[pd].revisionDate)) {
          confirmButton = true;
        }
      }
    });

    const data = {
      columns: [
        {
          label: 'SDSチェック希望日',
          field: 'SDSチェック希望日',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '前回チェック日',
          field: '前回チェック日',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '今回チェック日',
          field: '今回チェック日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: 'SDS改定日',
          field: 'SDS改定日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        }
      ],
      rows: dateSDS
    }

    let sds = this.state.data;
    let title = '';
    if (sds.item) {
      title = sds['item'] && sds['item']['P4CLOUD商品名'];
    }

    return (
      <MDBContainer style={{maxWidth: 700}}>
        <MDBRow>
          {this.state.confirmation ?
            <h2>P4CLOUD管理者モード　入力内容の確認</h2> : <h2>P4CLOUD管理者モード</h2>
          }
        </MDBRow>
        <MDBRow>
          <span>商品名：{title}</span>
        </MDBRow>
        <br/>
        <MDBRow>
          {this.state.confirmation ?
              <span>以下の内容で登録します。</span> : <span>日付を入力してください。</span>
          }
        </MDBRow>
        <br/>
        <MDBRow>
          <h5>SDS定期チェック（毎年）</h5>
        </MDBRow>
        <MDBRow>
          <MDBTable responsive btn small>
            <MDBTableHead color="primary-color" textWhite columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
        </MDBRow>
        <MDBRow>
          <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>

            {this.state.confirmation ?
                <>
                  <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'} onClick={() => this.confirmationDateInput(false)} >戻る</MDBBtn>
                  <MDBBtn size="sm4" className={'text-nowrap'} onClick={() => this.confirmationDateInput(true)} >登録</MDBBtn>
                </>
                :
                <>
                  <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'} onClick={() => this.addDateInput(false)} >戻る</MDBBtn>
                  <MDBBtn size="sm4" className={'text-nowrap'} onClick={() => this.addDateInput(true)} disabled={confirmButton} >内容確認</MDBBtn>
                </>
            }
          </div>
        </MDBRow>

        <MDBModal isOpen={this.state.confirmModal} toggle={this.confirmModalClose}>
          <MDBModalHeader toggle={this.confirmModalClose}>P4CLOUD管理者モード　登録完了</MDBModalHeader>
          <MDBModalBody>
            {title}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.confirmModalClose} color="default" data-confirm={true} >確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
      </MDBContainer>
    )

  }
}

export default SDSManagementDate;
