import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom'
import { MDBContainer, MDBRow, MDBBtn, MDBChip, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact';
import CartSelect from '../../components/CartSelect/CartSelectStock';
import FILELink from './FILELink'
import SDSLink from './SDSLink'

class ItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '/sample.jpg',
      image2: '/sample.jpg',
      image3: '/sample.jpg',
      cartselect: false,
    }
  }

  getItemImage = () => {
    if (this.props.data.image) {
      let gsReference = this.props.storage.refFromURL(this.props.data.image)
      .getDownloadURL()
      .then( (url) => {
        this.setState({image: url})
      } )
    }
    if (this.props.data.image2) {
      let gsReference = this.props.storage.refFromURL(this.props.data.image2)
      .getDownloadURL()
      .then( (url) => {
        this.setState({image2: url})
      } )
    }
    if (this.props.data.image3) {
      let gsReference = this.props.storage.refFromURL(this.props.data.image3)
      .getDownloadURL()
      .then( (url) => {
        this.setState({image3: url})
      } )
    }
  }

  componentDidMount() {
    this.getItemImage();
  }

  addCart = () => {
    this.setState({cartselect: true});
  }


  render() {
    const style = {marginBottom: 10};

    const now = new Date();

    let files = [];

    for(var i in this.props.data['files']){
      let e=this.props.data['files'][i];
      files.push(
        <>
        <dd>
        <FILELink src={e['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={e['file']} >{e['name']}</MDBChip></FILELink>
        </dd>
        </>

      );
    }


    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="mb-3">
            <h2>{this.props.data['P4cloud商品名']}</h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="4" md="12" className="mb-3">
            <img src={this.state.image} className="img-fluid z-depth-1" alt="" />
          </MDBCol>
          <MDBCol lg="4" md="6" className="mb-3">
            <img src={this.state.image2} className="img-fluid z-depth-1" alt="" />
          </MDBCol>
          <MDBCol lg="4" md="6" className="mb-3">
            <img src={this.state.image3} className="img-fluid z-depth-1" alt="" />
          </MDBCol>
      </MDBRow>
      <MDBRow>
          <MDBCol md="12" className="mb-3">
            <dl>
            <dt>メーカー名</dt>
                <dd>{this.props.data['正式メーカー名']}</dd>
                {false &&
                <>
                <dt>商品コード</dt>
                <dd>{this.props.data['商品コード']}</dd>
                </>
                }

                {this.props.data['色'] ?
                <>
                <dt>色</dt>
                <dd>{this.props.data['色']}</dd>
                </>
                : ""}

                {this.props.data['容量'] ?
                <>
                <dt>容量</dt>
                <dd>{this.props.data['容量']}</dd>
                </>
                : ""}

                <dt>単位</dt>
                <dd>{this.props.data['単位']}</dd>
                {this.props.data['分類'] ?
                <>
                <dt>カテゴリ</dt>
                <dd>{this.props.data['分類']}</dd>
                </>
                : ""}

                {this.props.data['data'] ?
                <>
                 {this.props.data['data'].map((data) => (
                    <>
                    <dt>{data.title}</dt>
                    <dd>{data.value}</dd>
                    </>
                  ))}
                </>
                : ""}

                {this.props.data['売単価'] ?
                <>
                { this.props.userOrg && this.props.userOrg.maker ? <dt>価格</dt> : <dt>価格</dt> }
                <dd>{this.props.data['売単価'].toLocaleString() + "円"}</dd>
                </>
                : ""}

                {this.props.data['取引日'] ?
                <>
                <dt>取引日</dt>
                <dd>
                {this.props.data['取引日'].toDate().getFullYear()}年
                {this.props.data['取引日'].toDate().getMonth()+1}月
                {this.props.data['取引日'].toDate().getDate()}日
                </dd>
                </>
                : ""}

                {this.props.data['sds'] ?
                <>
                <dd>
                <SDSLink src={this.props.data['sds']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={this.props.data['sds']} >SDS表示</MDBChip></SDSLink>
                </dd>
                </>
                : ""}
                {files}


            </dl>
            {this.props.userOrg && this.props.userOrg.buyer &&
              <div className="text-center">
              {this.props.data['取引日'] && this.props.data['取引日'].toDate().getFullYear() >= 2018 ?
              <MDBBtn
                className="btn-lg btn-block"
                onClick={this.addCart}
              >再発注</MDBBtn>
              :
              <MDBBtn
                className="btn-lg btn-block"
                onClick={this.addCart}
              >見積もり依頼</MDBBtn>
              }
              </div>
            }


            {this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker &&
              <div className="text-center">
              <MDBBtn
                className="btn-lg btn-block"
                onClick={this.addCart}
              >再発注</MDBBtn>
              </div>
            }


          </MDBCol>
        </MDBRow>
        <CartSelect isOpen={this.state.cartselect} db={this.props.db} user={this.props.user} data={this.props.data}　org={this.props.org} dateNow={this.props.dateNow} />
    </MDBContainer>
    );
  }
}
export default ItemCard;
