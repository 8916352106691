import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyDzGJF0ZuiCzSmGlYxEXzjIpgQbcA9qZa4",
  authDomain: "p4cloud-51c31.firebaseapp.com",
  databaseURL: "https://p4cloud-51c31.firebaseio.com",
  projectId: "p4cloud-51c31",
  storageBucket: "p4cloud-51c31.appspot.com",
  messagingSenderId: "797718118085",
  appId: "1:797718118085:web:16c80ac7f5331435ab3aac"
};

firebase.initializeApp(firebaseConfig)

export default firebase
