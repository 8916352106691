import React from "react";
import { MDBSpinner, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBIcon, MDBNotification} from 'mdbreact';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import {} from '@material-ui/core'
import { BrowserRouter, Route, Link } from 'react-router-dom'
import firebase from "../../firebase";
import FavoriteCartAllSelect from "../CartSelect/FavoriteCartAllSelect";
import FavoriteCartAllSelectOtasuke from "../CartSelect/FavoriteCartAllSelectOtasuke";

class Favorite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      carts: [],
      last: null,
      reportPerPage: 12,
      modal: false,
      cartselect: false,
      cartNo: null,
      deleteCartID: null,
      deleteCartName: null,
      cartAddedModal: false,
    }
  }

  getCarts = async (nextProps) => {
    // if (this.state.loading) {
    //   return;
    // }
    // console.log('getCarts')

    let props = this.props;
    if (nextProps && nextProps.org && this.props.org !== nextProps.org) {
      props = nextProps
    }

    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      // console.log('menu', this.props.userOrg.id, this.props.org.id)
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    // console.log(collection)

    if(props.org && collection){
      this.setState({loading: true})
      let fsRef = props.db.collection('favorite_set').doc(props.user.id).collection('favorite_set').doc(props.org.id);
      let fs = await fsRef.get();
      let fsData = fs.data();

      let updateDate = 1674748800000; //2023-01-27 00:00:00

      if (fsData) {
        // let withDeletedItem = false;
        for (let i = 0; i < 30; i++) {
          let fCart = fsData[i];

          if (fCart) {
            let items = fCart.items;
            if (items) {
              delete fCart['deletedItem'];
              delete fCart['deletedText'];
              await Promise.all(items.map(async (item) => {
                let itemSell = await collection.doc(item.id).get();
                let itemSellData = itemSell.data();

                delete item['deletedItem'];
                delete item['deletedText'];

                if (!itemSellData) {
                  fCart.deletedItem = true;
                  fCart.deletedText = '販売終了商品が含まれています';
                  item.deletedItem = true;
                  item.deletedText = '販売終了商品が含まれています';
                } else if (!itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                  fCart.deletedItem = true;
                  fCart.deletedText = '要見積商品が含まれています';
                  item.deletedItem = true;
                  item.deletedText = '要見積商品が含まれています';
                } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                  fCart.deletedItem = true;
                  fCart.deletedText = '要見積商品が含まれています';
                  item.deletedItem = true;
                  item.deletedText = '要見積商品が含まれています';
                } else if (itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                  let expiration_date = itemSellData['price'][0]['expiration_date'];
                  let expDate = expiration_date.toDate();

                  if ((expDate.getTime() - this.props.dateNow) < 0) {
                    fCart.deletedItem = true;
                    fCart.deletedText = '価格有効期限切れ要見積';
                    item.deletedItem = true;
                    item.deletedText = '価格有効期限切れ要見積';
                  } else {
                    item.price = itemSellData['price'][0]['price'];
                  }
                } else {
                  item.price = itemSellData['price'][0]['price'];
                }

                // if (itemSellData && itemSellData['price']) {
                //   item.price = itemSellData['price'][0]['price'];
                // } else {
                //   withDeletedItem = true;
                //   fCart.items = fCart.items.filter((obj) => obj.id !== item.id);
                // }
              }));
            }
          }
        }

        //TODO: delete items from fav cart when itemSell deleted
        // if (withDeletedItem) {
        //   fsRef.set(fsData, {merge: true});
        // }
        this.setState({carts: fsData})
      }
      // props.db.collection('favorite_set').doc(props.user.id)
      //     .collection('favorite_set').doc(props.org.id)
      //     .get()
      //     .then((doc) => {
      //       if(doc.exists) {
      //         let data = doc.data();
      //         console.log(data)
      //         this.setState({carts: data})
      //       }
      //     })
      // console.log('data', this.state.carts);
    }

    this.setState({loading: false})
  }

  componentDidMount() {
    this.getCarts(this.props);
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  // componentWillReceiveProps(nextProps) {
  //   this.getCarts(nextProps);
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.org !== prevProps.org || this.props.userOrg !== prevProps.userOrg) {
      this.getCarts(this.props);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      // console.log('reach end');
      // this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    console.log("LAST:" , this.state.last);
    this.getCarts(this.props);
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);
  }

  delete = (e) => {
    console.log('delete', e.currentTarget.dataset.id);
    let id = e.currentTarget.dataset.id;
    let title = e.currentTarget.dataset.title;
    this.setState({
      modal: true,
      deleteCartID: id,
      deleteCartName: title
    })
  }

  cartAddedModalClose = (e) => {
    this.setState({cartAddedModal: false})
  }

  modalClose = (e) => {
    // console.log(e)
    let deleteCartId = e && e.currentTarget.dataset.id;
    if(deleteCartId){
      let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id)
      let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let setWithMerge = cartRef.set({
        [deleteCartId]: {},
        update: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })
          .then((doc) => {
            this.setState({
              modal: false,
              deleteCartID: null,
              deleteCartName: null
            }, () => {
              this.getCarts();
            });
          });
    } else {
      this.setState({
        modal: false,
        deleteCartID: null,
        deleteCartName: null
      })
    }
  }

  sendFavouriteCart = (e) => {
    console.log('sendFavouriteCart', e.currentTarget.dataset.id);
    let id = e.currentTarget.dataset.id;
    let self = this;
    this.setState({selectedItem: this.state.carts[id], cartNo: id}, () => {
      self.setState({cartselect: true});
    });
  }

  showHideCart = (val, cartId) => {
    this.setState({cartselect: val});
    console.log(cartId)
    if (cartId) {
      let props = this.props;
      if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker || props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
        // this.props.cProps.history.push("/cart/"+ cartId*1)
        toast.success("カートへの追加が完了しました。", {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        // this.props.cProps.history.push("/otasuke/cart/"+ cartId*1)
        toast.success("お助けカートへの追加が完了しました。", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

    }

  }

  render() {
    if (! this.props.org ) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let props = this.props;
    let cartType = "お助けカートに追加";
    let buttonText = "お助けカートに追加";
    let isBuyer = false;
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker || props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      //buyer
      isBuyer = true;
      cartType = "カートに追加";
      buttonText = "カートに追加";
    }

    let carts = [];
    for(let i=0; i<30; i++){
      if(this.state.carts[i] && this.state.carts[i].items){ // && this.state.carts[i].items.length > 0
        let items = this.state.carts[i].items;
        let cartName = this.state.carts[i].cartName || '名称未設定';
        let creatorName = this.state.carts[i].creatorUserName || '-'
        let date = '-';
        let time = '-';
        let amount = 0;
        let name= '';
        let recipient = this.state.carts[i].toUserName || '-';
        let deletedItem = this.state.carts[i].deletedItem;
        let deletedText = this.state.carts[i].deletedText;
        let deletedDescription = '';
        let d = [];

        if (this.state.carts[i].latestUpdate) {
          date = this.state.carts[i].latestUpdate.toDate().getFullYear() + '/'
              + n(this.state.carts[i].latestUpdate.toDate().getMonth() + 1) + '/'
              + n(this.state.carts[i].latestUpdate.toDate().getDate());
              time =  n(this.state.carts[i].latestUpdate.toDate().getHours()) + ':'
              + n(this.state.carts[i].latestUpdate.toDate().getMinutes());
        }

        items.forEach((item, index) => {
          if (!item.deletedItem) {
            amount += (item.price*item.count);
          } else {
            if (!d.includes(item.deletedText)) {
              d.push(item.deletedText)
              deletedDescription = <>{deletedDescription}<span style={{color: 'red'}}>{item.deletedText}</span><br /></>
            }
          }
          if(name != ''){
            name = <>{name}{item.name}<br /></>;
          }else{
            name = <>{item.name}<br /></>;
          }

        });

        if (deletedItem) {
          name = <>{name}{deletedDescription}</>
        }

        carts.push({
          'セット番号': <>{n(i+1)}</>,
          'セット名称': cartName,
          '内容': name,
          '金額': <div style={{textAlign: 'right'}}>{amount > 0 && amount.toLocaleString()}</div>,
          '詳細': <div style={{textAlign: 'center'}}>
              <Link to={"/favorite/cart/" + (i+1)}>
              <MDBBtn rounded size="sm" color={''} className={'custom-button-lightgreen text-nowrap'}>表示</MDBBtn>
            </Link>
          </div>,
          'カートに追加': <div style={{textAlign: 'center'}}>
            <MDBBtn rounded size="sm" color={''} className={'custom-button-green text-nowrap'} style={{minWidth: 120}} disabled={deletedItem} data-id={i} onClick={this.sendFavouriteCart}>{buttonText}</MDBBtn>
          </div>,
          '削除': <div style={{textAlign: 'center'}}>
            <MDBBtn rounded flat size="sm" data-id={i} data-title={cartName} onClick={this.delete}><MDBIcon icon="trash" size={'2x'} className="custom-text-grey"/></MDBBtn>
          </div>
        })
      }else{
        carts.push({
          'セット番号': <>{n(i+1)}</>,
          'セット名称': '名称未設定',
          '内容': '空です',
          '金額': <div style={{textAlign: 'right'}}>-</div>,
          '詳細': <div style={{textAlign: 'center'}}>
            <Link to={"/favorite/cart/" + (i+1)}>
              <MDBBtn rounded size="sm" color={''} className={'custom-button-lightgreen text-nowrap'}>表示</MDBBtn>
            </Link>
          </div>,
          'カートに追加': <div style={{textAlign: 'center'}}>-</div>,
          '削除': '',
        })
      }
    }

    const data = {
      columns: [
        {
          label: 'セット番号',
          field: 'セット番号',
          sort: 'asc',
          // width: 80,
          minimal: 'sm'
        },
        {
          label: 'セット名称',
          field: 'セット名称',
          sort: 'disabled',
          // width: 370,
          minimal: 'sm'
        },
        {
          label: '内容',
          field: '内容',
          sort: 'disabled',
          width: 100,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: '金額',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>詳細</div>,
          field: '詳細',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>{cartType}</div>,
          field: 'カートに追加',
          sort: 'disabled',
          width: 120,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>削除</div>,
          field: '削除',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        }
      ],
      rows: carts
    }


    return (
      <>
        <h2><img src={"/folder-star.png"} className={'folder-star-header'}/> お気に入りセット一覧</h2>
        <MDBTable responsive btn small>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          {!this.state.loading && <MDBTableBody rows={data.rows} />}
        </MDBTable>

        {/*<FavoriteCartAllSelect isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} favouriteCartId={this.state.cartNo} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} />*/}
        {isBuyer ?
            <FavoriteCartAllSelect isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} favouriteCartId={this.state.cartNo} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
            :
            <FavoriteCartAllSelectOtasuke isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} favouriteCartId={this.state.cartNo} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
        }

        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>お気に入りセット削除</MDBModalHeader>
          <MDBModalBody>
            お気に入りセット「{this.state.deleteCartName}」を削除します。 よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalClose} color="danger" data-id={this.state.deleteCartID} >OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.cartAddedModal} toggle={this.cartAddedModalClose}>
          <MDBModalHeader toggle={this.cartAddedModalClose}>1</MDBModalHeader>
          <MDBModalBody>
            カートへの追加が完了しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.cartAddedModalClose} color="" style={{backgroundColor: '#2D486A', color: '#ffffff'}}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

      </>
    )

  }
}

export default Favorite;
