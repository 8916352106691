import React, {createRef} from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow, MDBCol, MDBIcon, MDBInput,
  MDBContainer
} from 'mdbreact';
import FILELink, {db} from "../Item/FILELink";
import firebase from "../../firebase";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import "./SDSManagement.css";
import Typesense from "typesense";

const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);
class SDSManagementTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbSDSs: [],
      last: null,
      sdsPerPage: 12,
      receptionModal: false,
      selectedSDS: {},
      sdsStatus: null,
      sdsRiskStatus: null,
      sdsSort: null,
      algoriaPage: 1,
      algolia: false,
      searchwordA: '',
      notMore: false,
      selectedSDSList: [],
      downloading: false,
      downloadingAll: false,
      riskManagementApplyModal: false,
      riskManagementApplyItem: {},
      riskManagementApplySDS: {},
      riskManagementAppliedModal: false,
      riskManagementReceptionModal: false,
      riskManagementReceptionItem: {},
      riskManagementReceptionSDS: {},
      waitingRiskCount: 0,
      underManagementRiskCount: 0,
      pauseRiskCount: 0,
      showMenu: null,
      showMenuIndex: null,
      sortType: "asc",
    }

    this.statusRef = createRef();
    this.riskStatusRef = createRef();
    this.sortRef = createRef();
    this.sortTypeRef = createRef();
  }

  getSDSsA = async (props, newOrg, status, sort, word, riskStatus) => {
    if (this.state.loading) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.orgSeller && props.org && props.user && props.user.admin) {
      fromOrg = props.orgSeller.id;
      toOrg = props.org.id;
    }
    // console.log(fromOrg, toOrg, props)
    if (fromOrg === "" || toOrg === ""){
      return;
    }

    this.setState({loading: true, algolia: true});
    let tempResults = [];
    let filters = ``;

    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`;

    if (props.orgSeller && props.org && props.user && props.user.admin) {
      filters += ` && reception:true`
    }

    if (status) {
      filters += ` && status:${status}`;
    }

    if (riskStatus) {
      filters += ` && riskManagementStatus:${riskStatus}`
    }

    let clientIndexName;
    let sortType = this.state.sortType;
    let sort_by;
    switch (sort) {
      case 'product':
        if (sortType === 'desc') {
          // clientIndexName = 'P4SDSManagement_name_desc';
          sort_by = 'item.P4CLOUD商品名:desc'
        } else {
          // clientIndexName = 'P4SDSManagement_name_asc';
          sort_by = 'item.P4CLOUD商品名:asc'
        }
        break;
      case 'update':
        if (sortType === 'desc') {
          // clientIndexName = 'P4SDSManagement_update_desc';
          sort_by = 'revisionDate._seconds:desc'
        } else {
          // clientIndexName = 'P4SDSManagement_update_asc';
          sort_by = 'revisionDate._seconds:asc'
        }
        break;
      case 'check':
        if (sortType === 'desc') {
          // clientIndexName = 'P4SDSManagement_check_desc';
          sort_by = 'lastCheckDate._seconds:desc'
        } else {
          // clientIndexName = 'P4SDSManagement_check_asc';
          sort_by = 'lastCheckDate._seconds:asc'
        }
        break;
      case 'raApplication':
        if (sortType === 'desc') {
          // clientIndexName = 'P4SDSManagement_raApplication_desc';
          sort_by = 'riskManagementApplyDate._seconds:desc'
        } else {
          // clientIndexName = 'P4SDSManagement_raApplication_asc';
          sort_by = 'riskManagementApplyDate._seconds:asc'
        }
        break;
      case 'raReception':
        if (sortType === 'desc') {
          // clientIndexName = 'P4SDSManagement_raReception_desc';
          sort_by = 'riskManagementReceptionDate._seconds:asc'
        } else {
          // clientIndexName = 'P4SDSManagement_raReception_asc';
          sort_by = 'riskManagementReceptionDate._seconds:asc'
        }
        break;
      default:
        if (sortType === 'desc') {
          // clientIndexName = 'P4SDSManagement_reception_desc';
          sort_by = 'receptionDate._seconds:desc'
        } else {
          // clientIndexName = 'P4SDSManagement';
          sort_by = 'receptionDate._seconds:asc'
        }
        break;
    }

    let self = this;

    let searchParameters = {
      'q'         : word,
      'query_by'  : 'item.P4CLOUD商品名,applicationUserName,receptionUserName,riskManagementApplyUserName,riskManagementReceptionUserName,lastCheckUserName',
      'infix'     : 'always,off,off,off,off,off',
      'filter_by' : filters,
      'sort_by'   : sort_by,
      'page'      : this.state.algoriaPage,
      'per_page'  : this.state.sdsPerPage
    }
    await typesense.collections('sds_management').documents().search(searchParameters).then((docs) => {
      //docs.page=this.state.algoriaPage,
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })

    });

    let nextPage = self.state.algoriaPage + 1;
    let sdss = self.state.dbSDSs
    if (newOrg) {
      sdss = [];
    }
    tempResults.forEach(value => {
      let data = value;
      // data.created
      data.id = value.objectID;
      sdss.push(data)
    })

    let notMore = false;
    if (tempResults.length === 0) {
      notMore = true
    }

    self.setState({
      algoriaPage: nextPage,
      dbSDSs: sdss,
      loading: false,
      notMore: notMore
    })
    /*client.initIndex(clientIndexName)
        .search( word, {
          page: this.state.algoriaPage,
          filters: filters
        })
        .then(function(responses) {
          tempResults = responses.hits;
          // console.log(tempResults, filters, word)

          let nextPage = self.state.algoriaPage + 1;
          let sdss = self.state.dbSDSs
          if (newOrg) {
            sdss = [];
          }

          tempResults.forEach(value => {
            let data = value;
            // data.created
            data.id = value.objectID;
            sdss.push(data)
          })

          let notMore = false;
          if (tempResults.length === 0) {
            notMore = true
          }

          self.setState({
            algoriaPage: nextPage,
            dbSDSs: sdss,
            loading: false,
            notMore: notMore
          })
        });*/
  }

  getRiskCount = async (props) => {
    let fromOrg = "";
    let toOrg = "";
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.orgSeller && props.org && props.user && props.user.admin) {
      fromOrg = props.orgSeller.id;
      toOrg = props.org.id;
    }
    // console.log(fromOrg, toOrg, props)
    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let waitingSnapshotCount = await this.props.db.collection('sds_management')
        .where("fromOrganization", "==", fromOrg)
        .where("toOrganization", "==", toOrg)
        .where('riskManagementStatus', "==", 1).get();

    let underManagementSnapshotCount = await this.props.db.collection('sds_management')
        .where("fromOrganization", "==", fromOrg)
        .where("toOrganization", "==", toOrg)
        .where('riskManagementStatus', "==", 2).get();

    let pauseSnapshotCount = await this.props.db.collection('sds_management')
        .where("fromOrganization", "==", fromOrg)
        .where("toOrganization", "==", toOrg)
        .where('riskManagementStatus', "==", 3).get();

    this.setState({waitingRiskCount: waitingSnapshotCount.size, underManagementRiskCount: underManagementSnapshotCount.size, pauseRiskCount: pauseSnapshotCount.size})
    // console.log('countSnapshot',waitingSnapshotCount.size, underManagementSnapshotCount.size, pauseSnapshotCount.size);
  }

  getSDSs = async (props, newOrg, status, sort, riskStatus) => {
    // console.log('riskStatus', status, riskStatus)
    // console.log("###### getSDSs ########", props)

    if (this.state.loading) {
      return;
    }

    if (this.state.notMore) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.orgSeller && props.org && props.user && props.user.admin) {
      fromOrg = props.orgSeller.id;
      toOrg = props.org.id;
    }
    // console.log(fromOrg, toOrg, props)
    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let first;
    let sortField;
    let sortType = this.state.sortType;
    switch (sort) {
      case 'product':
        sortField = 'item.P4CLOUD商品名';
        // sortType = 'asc';
        break;
      case 'update':
        sortField = 'revisionDate';
        // sortType = 'desc'
        break;
      case 'check':
        sortField = 'lastCheckDate';
        // sortType = 'desc';
        break;
      case 'raApplication':
        sortField = 'riskManagementApplyDate';
        // sortType = 'desc';
        break;
      case 'raReception':
        sortField = 'riskManagementReceptionDate';
        // sortType = 'desc';
        break;
      default:
        sortField = 'receptionDate'
        // sortField = 'created';
        // sortType = 'asc'
        break;
    }

    this.setState({loading: true, algolia: false});

    if (status) {
      // console.log('status',status)
      if (riskStatus) {
        if (this.state.last) {
          first = this.props.db.collection('sds_management')
              .where("fromOrganization", "==", fromOrg)
              .where("toOrganization", "==", toOrg)
              .where("status", "==", status)
              .where('riskManagementStatus', "==", riskStatus*1)
              .orderBy(sortField,sortType)
              .startAfter(this.state.last)
              .limit(this.state.sdsPerPage);
        } else {
          first = this.props.db.collection('sds_management')
              .where("fromOrganization", "==", fromOrg)
              .where("toOrganization", "==", toOrg)
              .where("status", "==", status)
              .where('riskManagementStatus', "==", riskStatus*1)
              .orderBy(sortField,sortType)
              .limit(this.state.sdsPerPage);
        }
      } else {
        if (this.state.last) {
          first = this.props.db.collection('sds_management')
              .where("fromOrganization", "==", fromOrg)
              .where("toOrganization", "==", toOrg)
              .where("status", "==", status)
              .orderBy(sortField,sortType)
              .startAfter(this.state.last)
              .limit(this.state.sdsPerPage);
        } else {
          first = this.props.db.collection('sds_management')
              .where("fromOrganization", "==", fromOrg)
              .where("toOrganization", "==", toOrg)
              .where("status", "==", status)
              .orderBy(sortField,sortType)
              .limit(this.state.sdsPerPage);
        }
      }

    } else {
      if (riskStatus) {
        if (props.orgSeller && props.org && props.user && props.user.admin) {
          if (this.state.last) {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where('riskManagementStatus', "==", riskStatus*1)
                // .where("reception", "==", true)
                .orderBy(sortField,sortType)
                .startAfter(this.state.last)
                .limit(this.state.sdsPerPage);
          } else {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where('riskManagementStatus', "==", riskStatus*1)
                // .where("reception", "==", true)
                .orderBy(sortField,sortType)
                .limit(this.state.sdsPerPage);
          }
        } else {
          if (this.state.last) {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where('riskManagementStatus', "==", riskStatus*1)
                .orderBy(sortField,sortType)
                .startAfter(this.state.last)
                .limit(this.state.sdsPerPage);
          } else {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where('riskManagementStatus', "==", riskStatus*1)
                .orderBy(sortField,sortType)
                .limit(this.state.sdsPerPage);
          }
        }
      } else {
        if (props.orgSeller && props.org && props.user && props.user.admin) {
          if (this.state.last) {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                // .where("reception", "==", true)
                .orderBy(sortField,sortType)
                .startAfter(this.state.last)
                .limit(this.state.sdsPerPage);
          } else {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                // .where("reception", "==", true)
                .orderBy(sortField,sortType)
                .limit(this.state.sdsPerPage);
          }
        } else {
          if (this.state.last) {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .orderBy(sortField,sortType)
                .startAfter(this.state.last)
                .limit(this.state.sdsPerPage);
          } else {
            first = this.props.db.collection('sds_management')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .orderBy(sortField,sortType)
                .limit(this.state.sdsPerPage);
          }
        }
      }


    }

    let o = await first.get();
    if (o.docs.length > 0) {
      let last = o.docs[o.docs.length - 1];
      this.setState({last: last});

      let sdss = this.state.dbSDSs;

      if (newOrg) {
        sdss = [];
      }

      o.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        sdss.push(data)
      })
      this.setState({dbSDSs: sdss, loading: false})
    } else {
      this.setState({notMore: true, loading: false});
      if (newOrg === true) {
        this.setState({dbSDSs: []})
      }
    }


    // let paginate = first.get()
    // .then((snapshot) => {
    //   if(snapshot.docs.length > 0) {
    //     let last = snapshot.docs[snapshot.docs.length - 1];
    //
    //     this.setState({last: last})
    //
    //     let sdss = this.state.dbSDSs;
    //
    //     if (newOrg) {
    //       sdss = [];
    //     }
    //
    //     snapshot.forEach((doc) => {
    //       let data = doc.data();
    //       data.id = doc.id;
    //       sdss.push(data)
    //     })
    //     this.setState({dbSDSs: sdss, loading: false})
    //   } else {
    //     this.setState({notMore: true, loading: false});
    //     if (newOrg === true) {
    //       this.setState({dbSDSs: []})
    //     }
    //   }
    //
    // });

  }

  revisionAndCheck = async () => {
    let sds_managements = await this.props.db.collection('sds_management').orderBy('created', 'asc').get();
    sds_managements.forEach(doc => {
      let sds = doc.data();
      // console.log('sds_management', sds)

      if (sds['revisionDate']) {
        // sds['revisionDueDate'] = sds['revisionDate'].toDate().getTime();
        doc.ref.set({revisionDueDate: sds['revisionDate'].toDate().getTime()}, { merge: true });
      }

      if (sds['lastCheckDate']) {
        doc.ref.set({lastCheckDueDate: sds['lastCheckDate'].toDate().getTime()}, { merge: true });
      }

    })
    // console.log('all orders', orders, orders.docs, orders.docs.length);
  }

  componentDidMount() {
    //this.getSDSs();
    this.getSDSs(this.props, true);
    this.getRiskCount(this.props);
    // this.revisionAndCheck();
    this.props.loadBadge();
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);

    // this.changeStatus();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      searchwordA: '',
      last: null,
      dbSDSs: [],
      sdsStatus: null,
      algoriaPage: 1,
      algolia: false,
      notMore: false
    }, () => {
      this.getSDSs(nextProps, true);
      this.getRiskCount(this.props);
      this.props.loadBadge();
      // console.log('this.props',this.props)
    })

  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +1 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    if (this.state.loading === false) {
      if (this.state.algolia) {
        this.getSDSsA(this.props, false, this.state.sdsStatus, this.state.sdsSort, this.state.searchwordA, this.state.sdsRiskStatus);
      } else {
        this.getSDSs(this.props, false, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
      }
    }
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);
  }

  sdsManagementHistory = (sds) => {
    this.props.cProps.history.push('/sdsManagementHistory/'+sds.id);
  }

  sdsManagementRisk = (sds) => {
    this.props.cProps.history.push('/sdsManagementRisk/'+sds.id);
  }

  sdsManagementRiskUnPause = (sds, index) => {
    let sdsRef = this.props.db.collection('sds_management').doc(sds.id);
    sdsRef.set({
      riskManagementUnPause: true,
      riskManagementUnPauseUser: this.props.user.id,
      riskManagementUnPauseUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
      riskManagementStatus: 2
    }, {merge: true});

    let sdss = this.state.dbSDSs;
    sdss[index].riskManagementUnPause = true;
    sdss[index].riskManagementUnPauseUser = this.props.user.id;
    sdss[index].riskManagementUnPauseUserName = this.props.user.last_name + ' ' + this.props.user.first_name;
    sdss[index].riskManagementStatus = 2;
    this.setState({dbSDSs: sdss, showMenu: null, showMenuIndex: null});
    this.getRiskCount(this.props);
  }

  sdsManagementRiskPause = (sds, index) => {
    let sdsRef = this.props.db.collection('sds_management').doc(sds.id);
    sdsRef.set({
      riskManagementPause: true,
      riskManagementPauseUser: this.props.user.id,
      riskManagementPauseUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
      riskManagementStatus: 3,
    }, {merge: true});

    let sdss = this.state.dbSDSs;
    sdss[index].riskManagementPause = true;
    sdss[index].riskManagementPauseUser = this.props.user.id;
    sdss[index].riskManagementPauseUserName = this.props.user.last_name + ' ' + this.props.user.first_name;
    sdss[index].riskManagementStatus = 3;
    this.setState({dbSDSs: sdss, showMenu: null, showMenuIndex: null});
    this.getRiskCount(this.props);
  }

  sdsManagementRiskReception = (sds, index) => {
    let item = sds.item;

    this.setState({
      riskManagementReceptionModal: true,
      riskManagementReceptionSDS: sds,
      riskManagementReceptionIndex: index,
      riskManagementReceptionItem: item
    })
  }

  sdsManagementRiskApply = (sds, index) => {
    console.log('sds', sds);
    let item = sds.item;
    this.setState({
      riskManagementApplyModal: true,
      riskManagementApplySDS: sds,
      riskManagementApplyIndex: index,
      riskManagementApplyItem: item
    })
  }

  confirmReception = (sds) => {
    this.setState({
      receptionModal: true,
      selectedSDS: sds
    })
  }

  dateInput = (sds) => {
    this.props.cProps.history.push('/sdsManagementDate/'+sds.id);
  }

  riskManagementReceptionModalClose = (e) => {
    this.setState({riskManagementReceptionModal: false});

    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true';
    if (isConfirm) {
      console.log('confirm');
      let date = firebase.firestore.Timestamp.fromDate(new Date());
      let sdsRef = this.props.db.collection('sds_management').doc(this.state.riskManagementReceptionSDS.id);
      sdsRef.set({
        riskManagementReception: true,
        riskManagementReceptionDate: date,
        riskManagementReceptionUser: this.props.user.id,
        riskManagementReceptionUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
        riskManagementStatus: 2
      }, {merge: true}).then(() => {
        let sdss = this.state.dbSDSs;
        sdss[this.state.riskManagementReceptionIndex].riskManagementReception = true;
        sdss[this.state.riskManagementReceptionIndex].riskManagementReceptionDate = date;
        sdss[this.state.riskManagementReceptionIndex].riskManagementReceptionUser = this.props.user.id;
        sdss[this.state.riskManagementReceptionIndex].riskManagementReceptionUserName = this.props.user.last_name + ' ' + this.props.user.first_name;
        sdss[this.state.riskManagementReceptionIndex].riskManagementStatus = 2;
        this.setState({
          dbSDSs: sdss
        });
        this.getRiskCount(this.props);
        // this.setState({
        //   riskManagementAppliedModal: true
        // });
      })
    } else {
      console.log('not confirm')
    }
  }


  riskManagementAppliedModalClose = (e) => {
    this.setState({
      riskManagementAppliedModal: false
    });

    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true';
    if (isConfirm) {
      console.log('confirm')
      // this.props.cProps.history.push('/sdsManagementRisk/'+this.state.riskManagementApplySDS.id);
    }
  }

  riskManagementApplyModalClose = (e) => {
    this.setState({
      riskManagementApplyModal: false
    });

    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true';
    if (isConfirm) {
      console.log('confirm');
      let date = firebase.firestore.Timestamp.fromDate(new Date())
      let sdsRef = this.props.db.collection('sds_management').doc(this.state.riskManagementApplySDS.id);
      sdsRef.set({
        riskManagementApply: true,
        riskManagementApplyDate: date,
        riskManagementApplyUser: this.props.user.id,
        riskManagementApplyUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
        riskManagementStatus: 1
      }, {merge: true}).then(() => {
        let sdss = this.state.dbSDSs;
        console.log('this.state.riskManagementApplyIndex', this.state.riskManagementApplyIndex)
        sdss[this.state.riskManagementApplyIndex].riskManagementApply = true;
        sdss[this.state.riskManagementApplyIndex].riskManagementApplyDate = date;
        sdss[this.state.riskManagementApplyIndex].riskManagementApplyUser = this.props.user.id;
        sdss[this.state.riskManagementApplyIndex].riskManagementApplyUserName = this.props.user.last_name + ' ' + this.props.user.first_name;
        sdss[this.state.riskManagementApplyIndex].riskManagementStatus = 1;

        this.setState({
          dbSDSs: sdss
        });
        this.getRiskCount(this.props);

      })
    } else {
      console.log('not confirm')
    }
  }

  receptionModalClose = (e) => {
    this.setState({
      receptionModal: false
    });

    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true';
    if (isConfirm && this.state.selectedSDS.id) {
      let sdsRef = this.props.db.collection('sds_management').doc(this.state.selectedSDS.id);
      sdsRef.set({
        status: 'normal',
        reception: true,
        receptionDate: firebase.firestore.Timestamp.fromDate(new Date()),
        receptionUser: this.props.user.id,
        receptionUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
        receptionOrganization: this.props.user.organization
      }, {merge: true}).then(() => {
        console.log('success')
        this.setState({
          last: null,
          dbSDSs: []
        }, () => {
          this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
        })
      });


    }
  }

  selectSDSRiskType = (e) => {
    this.setState({
      dbSDSs: [],
      last: null,
      notMore: false,
      algoriaPage: 1,
      sdsRiskStatus: (e.target.value === 'すべて' || e.target.value === null) ? null : e.target.value
    }, () => {
      if (this.state.searchwordA) {
        this.getSDSsA(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.searchwordA, this.state.sdsRiskStatus);
      } else {
        this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
      }
    })
  }

  selectSDSType = (e) => {
    // console.log(e.target.status)
    this.setState({
      dbSDSs: [],
      last: null,
      notMore: false,
      algoriaPage: 1,
      sdsStatus: (e.target.value === 'すべて' || e.target.value === null) ? null : e.target.value
    }, () => {
      if (this.state.searchwordA) {
        this.getSDSsA(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.searchwordA, this.state.sdsRiskStatus);
      } else {
        this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
      }
    })
  }

  selectSDSSort = (e) => {
    this.setState({
      dbSDSs: [],
      last: null,
      notMore: false,
      algoriaPage: 1,
      sdsSort: (e.target.value === 'すべて' || e.target.value === null) ? null : e.target.value
    }, () => {
      if (this.state.searchwordA) {
        this.getSDSsA(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.searchwordA, this.state.sdsRiskStatus);
      } else {
        this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
      }
    })
  }

  sortChange = (e) => {
    // console.log(e.target.value)
    // return;
    let val = e.target.value || 'desc';
    this.setState({
      dbSDSs: [],
      last: null,
      notMore: false,
      algoriaPage: 1,
      sortType: val
    }, () => {
      if (this.state.searchwordA) {
        this.getSDSsA(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.searchwordA, this.state.sdsRiskStatus);
      } else {
        this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
      }
    })
  }

  clearFilter = () => {
    this.setState({
      dbSDSs: [],
      last: null,
      sdsStatus: '',
      sdsRiskStatus: '',
      sdsSort: '',
      searchwordA: '',
      algolia: false,
      algoriaPage: 1,
      notMore: false,
      sortType: 'asc'
    }, () => {
      this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus);
    })
  }

  handleSearchwordAChange = (e) => {
    this.setState({
      searchwordA: e.currentTarget.value,
    });
  }

  handleSearchwordAKeyDown = (e) => {
    let val = this.state.searchwordA;
    if (e.key === 'Enter') {
      this.setState({
        dbSDSs: [],
        last: null,
        notMore: false,
        algoriaPage: 1,
      }, ()=>{
        if (val) {
          this.getSDSsA(this.props, true, this.state.sdsStatus, this.state.sdsSort, val, this.state.sdsRiskStatus);
        } else {
          this.getSDSs(this.props, true, this.state.sdsStatus, this.state.sdsSort, this.state.sdsRiskStatus)
        }
      });
    }
  }

  sdsIsSelected = (docId) => {
    let filtered = this.state.selectedSDSList.filter(item => {
      if (item.objectID) {
        return item.objectID === docId
      } else {
        return item.id === docId
      }

    });
    return filtered.length > 0
  };

  sdsSelect = (selected, sds) => {
    let selectedSDSList;
    if (selected) {
      selectedSDSList = [...this.state.selectedSDSList, sds];
    } else {
      selectedSDSList = this.state.selectedSDSList.filter( sSDS => {
        if (sSDS.objectID) {
          return sSDS.objectID !== sds.objectID
        } else {
          return sSDS.id !== sds.id
        }
      });
    }

    this.setState({
      selectedSDSList: selectedSDSList
    })
  }

  sdsSelectAll = (selected) => {
    let selectedSDSList = [];
    if (selected === true) {
      this.state.dbSDSs.forEach((sds,index) => {
        // if (this.state.userType === "seller" && sds.readSeller === false || this.state.userType === "buyer" && sds.readBuyer === false || this.state.userType === "sellerBuyer" && sds.readBuyer === false || this.state.userType === "maker" && sds.readSeller === false) {
          selectedSDSList.push(sds);
          // console.log('sds',sds);
        // }
      });

      this.setState({
        selectedSDSList: selectedSDSList
      }, () => {
        // console.log(this.state.selectedOrderList)
      })
    } else {
      this.setState({
        selectedSDSList: selectedSDSList
      });
    }
  };

  downloadAllSDS = async () => {
    this.setState({downloadingAll: true});

    const jszip = new JSZip();

    let fromOrg = "";
    let toOrg = "";
    let props = this.props;
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (props.orgSeller && props.org && props.user && props.user.admin) {
      fromOrg = props.orgSeller.id;
      toOrg = props.org.id;
    }
    // console.log(fromOrg, toOrg, props)
    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let first;
    let sortField;
    let sortType = this.state.sortType;
    console.log(this.state.sdsSort)
    switch (this.state.sdsSort) {
      case 'product':
        sortField = 'item.P4CLOUD商品名';
        // sortType = 'asc';
        break;
      case 'update':
        sortField = 'revisionDate';
        // sortType = 'desc'
        break;
      case 'check':
        sortField = 'lastCheckDate';
        // sortType = 'desc';
        break;
      case 'raApplication':
        sortField = 'riskManagementApplyDate';
        // sortType = 'desc';
        break;
      case 'raReception':
        sortField = 'riskManagementReceptionDate';
        // sortType = 'desc';
        break;
      default:
        sortField = 'created';
        // sortType = 'asc'
        break;
    }

    let status = this.state.sdsStatus;

    if (status) {
      first = this.props.db.collection('sds_management')
          .where("fromOrganization", "==", fromOrg)
          .where("toOrganization", "==", toOrg)
          .where("status", "==", status)
          .orderBy(sortField,sortType)
    } else {
      if (props.orgSeller && props.org && props.user && props.user.admin) {
        first = this.props.db.collection('sds_management')
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .orderBy(sortField,sortType)
      } else {
        first = this.props.db.collection('sds_management')
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .orderBy(sortField,sortType)
      }

    }

    const sdsManagements = await first.get();
    let sdss = [];
    sdsManagements.forEach((doc) => {
      let data = doc.data();
      sdss.push(data);
    });

    let promises = sdss.map( async (sds, i) => {
      if (sds && sds.item && sds.item.files) {
        let promises1 = sds.item.files.map( async (file) => {
          if (file && file.name && (file.name.toLowerCase().indexOf("ｓｄｓ") !== -1 || file.name.toLowerCase().indexOf("sds") !== -1)) {
            const sdsFile = await db.collection('files').doc(file.file).get().then(doc => {
              return doc.data();
            });
            if (sdsFile) {
              const fileBlob = await this.props.storage.ref(sdsFile.file).getDownloadURL().then( (url) => {
                return fetch(url).then((response) => response.blob());
              })
              const fileName = sdsFile.file.split('/').pop();
              jszip.file(fileName, fileBlob);
            }
          }
        }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
        await promises1;
      }
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());

    await promises;
    const blob = await jszip.generateAsync({ type: 'blob' });
    // console.log('jszip', jszip)
    let date = new Date();
    let dateFormatted = date.getFullYear()+''+(date.getMonth()+1)+''+date.getDate()+''+date.getHours()+''+date.getMinutes()+''+date.getSeconds();
    saveAs(blob, 'SDS '+dateFormatted+'.zip');
    this.setState({downloadingAll: false})
  }

  downloadSelectedSDS = async () => {
    console.log('downloadSelectedSDS');
    this.setState({downloading: true})

    const jszip = new JSZip();

    let promises = this.state.selectedSDSList.map( async (sds) => {
      // console.log('selectedSDSList', sds);
      if (sds && sds.item && sds.item.files) {
        let promises1 = sds.item.files.map( async (file) => {
          if (file && file.name && (file.name.toLowerCase().indexOf("ｓｄｓ") !== -1 || file.name.toLowerCase().indexOf("sds") !== -1)) {
            const sdsFile = await db.collection('files').doc(file.file).get().then(doc => {
              return doc.data();
            });
            if (sdsFile) {
              const fileBlob = await this.props.storage.ref(sdsFile.file).getDownloadURL().then( (url) => {
                return fetch(url).then((response) => response.blob());
              })
              const fileName = sdsFile.file.split('/').pop();
              jszip.file(fileName, fileBlob);
            }
          }
        }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
        await promises1;
      }
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());

    await promises;
    const blob = await jszip.generateAsync({ type: 'blob' });
    console.log('jszip', jszip)
    let date = new Date();
    let dateFormatted = date.getFullYear()+''+(date.getMonth()+1)+''+date.getDate()+''+date.getHours()+''+date.getMinutes()+''+date.getSeconds();
    saveAs(blob, 'SDS '+dateFormatted+'.zip');
    this.setState({downloading: false})
  }

  showMenu = (sds, index) => {
    this.setState({
      showMenu: sds,
      showMenuIndex: index
    })
  }

  render() {

    if (! this.props.org ) {
      return (
          <>
          </>
      )
    }

    const sdss = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let isBuyer = false;
    let isSeller = false;
    // let isMaker = false;
    let isAdmin = false;

    if (this.props.user && this.props.user.admin) {
      isAdmin = true;
    } else {
      if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
          || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
      ){
        isBuyer = true;
      }
      if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
          || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
      ){
        isSeller = true;
      }
    }

    this.state.dbSDSs.forEach((sds,index) => {
      let preferredMonths = [];
      for (let i=1; i<=12; i++) {
        if (sds.preferredDate[i]) {
          preferredMonths.push(<p key={index+''+i}>{i}月</p>);
        }
      }

      let status = '';
      if (sds['status'] === 'normal') {
        status = <div className={'sdsBlue'}/>;
      } else if (sds['status'] === 'warning') {
        status = <div className={'sdsYellow'}/>;
      } else if (sds['status'] === 'alert') {
        status = <div className={'sdsRed'}/>;
      }

      let applicationUser = sds.applicationUserName || '';
      let applicationDate = '';

      let receptionUser = sds.receptionUserName || '';
      let receptionDate = '';

      let rmApplicationUser = sds.riskManagementApplyUserName || '';
      let rmApplicationDate = '';

      let rmReceptionUser = sds.riskManagementReceptionUserName || '';
      let rmReceptionDate = '';

      let lastCheckDate = '----/--/--';
      let lastCheckUser = sds.lastCheckUserName || '----';

      let revisionDate = '----/--/--';

      if (this.state.algolia) {
        if (sds['created']) {
          let aDate = new Date(sds['created']._seconds * 1000 + sds['created']._nanoseconds/1000000);
          applicationDate = aDate.getFullYear() + '/'
              + n(aDate.getMonth()+1) + '/'
              + n(aDate.getDate()) + '';
        }

        if (sds['receptionDate']) {
          let rDate = new Date(sds['receptionDate']._seconds * 1000 + sds['receptionDate']._nanoseconds/1000000);
          receptionDate = rDate.getFullYear() + '/'
              + n(rDate.getMonth()+1) + '/'
              + n(rDate.getDate()) + '';
        }

        if (sds['riskManagementApplyDate']) {
          let rmaDate = new Date(sds['riskManagementApplyDate']._seconds * 1000 + sds['riskManagementApplyDate']._nanoseconds/1000000);
          rmApplicationDate = rmaDate.getFullYear() + '/'
              + n(rmaDate.getMonth()+1) + '/'
              + n(rmaDate.getDate()) + '';
        }

        if (sds['riskManagementReceptionDate']) {
          let rmrDate = new Date(sds['riskManagementReceptionDate']._seconds * 1000 + sds['riskManagementReceptionDate']._nanoseconds/1000000);
          rmReceptionDate = rmrDate.getFullYear() + '/'
              + n(rmrDate.getMonth()+1) + '/'
              + n(rmrDate.getDate()) + '';
        }

        if (sds['lastCheckDate']) {
          let lcDate = new Date(sds['lastCheckDate']._seconds * 1000 + sds['lastCheckDate']._nanoseconds/1000000);
          lastCheckDate = lcDate.getFullYear() + '/'
              + n(lcDate.getMonth()+1) + '/'
              + n(lcDate.getDate()) + '';
        }

        if (sds['revisionDate']) {
          let rDate = new Date(sds['revisionDate']._seconds * 1000 + sds['revisionDate']._nanoseconds/1000000);
          revisionDate = rDate.getFullYear() + '/'
              + n(rDate.getMonth()+1) + '/'
              + n(rDate.getDate()) + '';
        }
      } else {
        if (sds['created']) {
          applicationDate = sds['created'].toDate().getFullYear() + '/'
              + n(sds['created'].toDate().getMonth()+1) + '/'
              + n(sds['created'].toDate().getDate()) + '';
        }

        if (sds['receptionDate']) {
          receptionDate = sds['receptionDate'].toDate().getFullYear() + '/'
              + n(sds['receptionDate'].toDate().getMonth()+1) + '/'
              + n(sds['receptionDate'].toDate().getDate()) + '';
        }

        if (sds['riskManagementApplyDate']) {
          rmApplicationDate = sds['riskManagementApplyDate'].toDate().getFullYear() + '/'
              + n(sds['riskManagementApplyDate'].toDate().getMonth()+1) + '/'
              + n(sds['riskManagementApplyDate'].toDate().getDate()) + '';
        }

        if (sds['riskManagementReceptionDate']) {
          rmReceptionDate = sds['riskManagementReceptionDate'].toDate().getFullYear() + '/'
              + n(sds['riskManagementReceptionDate'].toDate().getMonth()+1) + '/'
              + n(sds['riskManagementReceptionDate'].toDate().getDate()) + '';
        }

        if (sds['lastCheckDate']) {
          lastCheckDate = sds['lastCheckDate'].toDate().getFullYear() + '/'
              + n(sds['lastCheckDate'].toDate().getMonth()+1) + '/'
              + n(sds['lastCheckDate'].toDate().getDate()) + '';
        }

        if (sds['revisionDate']) {
          revisionDate = sds['revisionDate'].toDate().getFullYear() + '/'
              + n(sds['revisionDate'].toDate().getMonth()+1) + '/'
              + n(sds['revisionDate'].toDate().getDate()) + '';
        }
      }

      let sdsBtn = [];
      for(let i in sds['item']['files']) {
        let e = sds['item']['files'][i];
        if (e && e.name && (e.name.toLowerCase().indexOf("ｓｄｓ") !== -1 || e.name.toLowerCase().indexOf("sds") !== -1)) {
          sdsBtn.push(<FILELink key={i} src={e['file']} storage={this.props.storage} target="_blank" style={{display: 'flex', flex: 1}}>
            <MDBBtn size="sm4" color={''} style={{color: 'black', padding: '0.26em 1.5em', borderRadius: 15}} >SDS</MDBBtn>
          </FILELink>)
        }
      }

      if (isBuyer) {
        if (!sds.riskManagementStatus) {
          sdsBtn.push(<MDBBtn size={'sm4'} color={'default'} style={{color: 'black', padding: '0.26em 0.8em', wordBreak: 'keep-all', borderRadius: 15, width: '-webkit-fill-available'}} onClick={() => this.sdsManagementRiskApply(sds, index)}>リスクアセスメント管理申込</MDBBtn>)
        }
      }

      if (sds.riskManagementStatus === 1) {
        sdsBtn.push(<MDBBtn size={'sm4'} color={''} disabled={isBuyer} style={{color: '#2d486a', padding: '0.106em 0.32em', wordBreak: 'keep-all', borderRadius: 15, border: '2px solid #2d486a', width: '-webkit-fill-available'}} onClick={() => this.sdsManagementRiskReception(sds, index)}>リスクアセスメント管理受付待</MDBBtn>)
      }

      if (sds.riskManagementStatus === 2) {
        sdsBtn.push(<MDBBtn size={'sm4'} color={''} style={{color: '#fff', padding: '0.106em 0.32em', wordBreak: 'keep-all', backgroundColor: '#2bbbad', borderRadius: 15, border: '2px solid #2bbbad', width: '-webkit-fill-available'}} onClick={() => this.sdsManagementRisk(sds)}>リスクアセスメント管理中</MDBBtn>);
        // if (isBuyer) {
        //   sdsBtn.push(<MDBBtn size={'sm4'} color={''} style={{color: '#000', padding: '0.106em 0.32em', wordBreak: 'keep-all', backgroundColor: '#eeeeee', borderRadius: 2, border: '2px solid #eeeeee', alignSelf: 'flex-end'}} onClick={() => this.sdsManagementRiskPause(sds, index)}>一時停止</MDBBtn>)
        // }
      }

      if (sds.riskManagementStatus === 3) {
        //pause リスクアセスメント管理一時停止
        sdsBtn.push(<MDBBtn size={'sm4'} color={''} disabled={true} style={{color: '#000', padding: '0.106em 0.32em', wordBreak: 'keep-all', backgroundColor: '#eeeeee', borderRadius: 15, border: '2px solid #eeeeee', width: '-webkit-fill-available'}} onClick={() => this.sdsManagementRisk(sds)}>リスクアセスメント管理一時停止</MDBBtn>);
        // if (isBuyer) {
        //   sdsBtn.push(<MDBBtn size={'sm4'} color={''} style={{color: '#000', padding: '0.106em 0.32em', wordBreak: 'keep-all', backgroundColor: '#eeeeee', borderRadius: 2, border: '2px solid #eeeeee', alignSelf: 'flex-end'}} onClick={() => this.sdsManagementRiskUnPause(sds, index)}>管理再開</MDBBtn>)
        // }
      }

      let sdsId = sds.objectID ? sds.objectID : sds.id
      let checked = this.sdsIsSelected(sdsId);

      let data = {
        'check':<div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} id={"checkbox"+index} onChange={(e) => this.sdsSelect(e.target.checked, sds)} /> </div>,
        'status': <div style={{display: 'flex', justifyContent: 'center'}}>
          {status}
        </div>,
        '商品名': sds.item['P4CLOUD商品名'],
        'SDS': <div style={{display: 'flex', flexDirection: 'column'}}>
          {sdsBtn}
        </div>,
        'date': <div style={{display: 'flex', flexDirection: 'column'}}>
          <span className={'text-nowrap'}>SDS管理申込日：{applicationDate}</span>
          <span className={'text-nowrap'}>SDS管理申込者：{applicationUser}</span>
          <span className={'text-nowrap'}>SDS管理受付日：{receptionDate}</span>
          <span className={'text-nowrap'}>SDS管理受付者：{receptionUser}</span>
          <br/>
          <span className={'text-nowrap'}>RA管理ＩＤ：{rmApplicationDate && sdsId}</span>
          <span className={'text-nowrap'}>RA管理申込日：{rmApplicationDate}</span>
          <span className={'text-nowrap'}>RA申込者名：{rmApplicationUser}</span>
          <span className={'text-nowrap'}>RA管理受付日：{rmReceptionDate}</span>
          <span className={'text-nowrap'}>RA管理受付者：{rmReceptionUser}</span>
          <br/>
        </div>,
        'SDS更新希望月': <div style={{textAlign: 'center'}}>
          {preferredMonths}
        </div>,
        'SDS最終チェック日/実施者': <div>
          <div className={'text-nowrap'}>{lastCheckDate}　{lastCheckUser}</div>
          <div>
            <MDBBtn size="sm4" color={''} style={{color: '#2D486A', padding: '0.26em 1.5em'}} className={'text-nowrap'} onClick={() => this.sdsManagementHistory(sds)}>実施履歴</MDBBtn>
          </div>
        </div>,
        'SDS改定日': revisionDate,
      };

      if (isSeller) {
        if (receptionDate) {
          data.btn = <MDBBtn size="sm4" color={''} style={{color: '#2D486A', padding: '0.26em 1.5em'}} className={'text-nowrap'} disabled={true} >受付処理</MDBBtn>
        } else {
          data.btn = <MDBBtn size="sm4" color={''} style={{color: '#2D486A', padding: '0.26em 1.5em'}} className={'text-nowrap'} onClick={() => this.confirmReception(sds)} >受付処理</MDBBtn>;
        }
      }

      if (isAdmin) {
        if (receptionDate) {
          data.btn = <MDBBtn size="sm4" className={'text-nowrap'} onClick={() => this.dateInput(sds)} >日付入力</MDBBtn>;
        } else {
          data.btn = <MDBBtn size="sm4" className={'text-nowrap'} disabled={true} >日付入力</MDBBtn>
        }
      }

      data.menu = <div style={{display: 'flex', justifyContent: 'flex-end', width: 50, alignItems: 'center', position: 'relative'}}>
        <div className={'menuButtonView'}>
          {(sds.riskManagementStatus === 2 || sds.riskManagementStatus === 3) && <div className={'menuButton'} onClick={(e) => {
            e.stopPropagation();
            this.showMenu(sds, index);
          }}>
            <MDBIcon icon={'ellipsis-v'}/>
          </div>}
          {sds === this.state.showMenu && this.state.showMenuIndex === index && <div className={'menuArea'} style={{width: 250}}>
            {sds.riskManagementStatus === 2 && <div onClick={() => this.sdsManagementRiskPause(sds, index)}>
              リスクアセスメント管理 一時停止
            </div>}
            {sds.riskManagementStatus === 3 && <div onClick={() => this.sdsManagementRiskUnPause(sds, index)}>
              リスクアセスメント管理 再開
            </div>}
          </div>}
        </div>
      </div>

      sdss.push(data);

    });

    const data = {
      columns: [
        {
          label: <div style={{width: 50}}><MDBInput label=" " type="checkbox" id="checkboxAll" onChange={(e) => this.sdsSelectAll(e.target.checked)} /></div>,
          field: 'check',
          sort: 'asc',
          width: 50
        },
        {
          label: '',
          field: 'status',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: '商品名',
          sort: 'asc',
          // width: 80,
          minimal: 'sm'
        },
        {
          label: 'SDS',
          field: 'SDS',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <>
            申込日/申込者<br/>
            受付日/受付者
          </>,
          field: 'date',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: 'SDS更新希望月',
          field: 'SDS更新希望月',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: 'SDS最終チェック日/実施者',
          field: 'SDS最終チェック日/実施者',
          sort: 'asc',
          width: 100,
          minimal: 'sm'
        },
        {
          label: 'SDS改定日',
          field: 'SDS改定日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
      ],
      rows: sdss
    }

    if (isSeller || isAdmin) {
      data.columns.push({
        label: '',
        field: 'btn',
        sort: 'asc',
        width: 50,
        minimal: 'sm'
      })
    }

    data.columns.push({
      label: '',
      field: 'menu',
      sort: 'asc',
      width: 50,
      minimal: 'sm'
    })

    return (
      <>
        <h2>SDS管理一覧</h2>
        {this.state.showMenu && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1041}} onClick={() => this.setState({showMenu: null, showMenuIndex: null})}/>}
        <MDBRow>
          <MDBCol size={12} xl={12} lg={12} md={12} sm={12} style={{display: 'flex', }}>
            <div className="form-check form-check-inline" style={{alignItems: 'flex-end'}}>
              <div className="input-group md-form form-sm form-1 pl-0" style={{width: 200, height: 38, marginBottom: 16}}>
                <div className="input-group-prepend">
                  <span className="input-group-text lighten-3" id="basic-text1">
                    <MDBIcon className="text-white" icon="search" />
                  </span>
                </div>
                <input
                    className="form-control my-0 py-1"
                    type="text"
                    placeholder="文字列検索"
                    aria-label="文字列検索"
                    onChange={this.handleSearchwordAChange}
                    onKeyDown={this.handleSearchwordAKeyDown}
                    value={this.state.searchwordA}
                />
              </div>
            </div>

            <div className="form-check form-check-inline">
              <div className="form-group">
                <label htmlFor="status" style={{fontSize: 12}}>SDS管理ステータス</label>
                <select ref={this.statusRef} className="browser-default custom-select" id={'status'} style={{width: 200}} value={this.state.sdsStatus} onChange={(e) => this.selectSDSType(e)}>
                  <option value={''}>すべて</option>
                  <option value={'waiting'}>受付待</option>
                  <option value={'normal'}>管理中（正常更新済）</option>
                  <option value={'warning'}>管理中（更新期限１週間以内）</option>
                  <option value={'alert'}>管理中（更新期限経過中）</option>
                </select>
              </div>
            </div>

            <div className="form-check form-check-inline">
              <div className="form-group">
                <label htmlFor="status" style={{fontSize: 12}}>リスクアセスメント管理ステータス</label>
                <select ref={this.riskStatusRef} className="browser-default custom-select" id={'status'} style={{width: 200}} value={this.state.sdsRiskStatus} onChange={(e) => this.selectSDSRiskType(e)}>
                  <option value={''}>すべて</option>
                  <option value={1}>受付待</option>
                  <option value={2}>管理中</option>
                  <option value={3}>一時停止</option>
                </select>
              </div>
            </div>

            <div className="form-check form-check-inline">
              <div className="form-group">
                <label htmlFor="sort" style={{fontSize: 12}}>並べ替え</label>
                <div id={'sort'} style={{display: 'flex', gap: 10}}>
                  <select ref={this.sortRef} className="browser-default custom-select"  style={{width: 200}} value={this.state.sdsSort} onChange={(e) => this.selectSDSSort(e)}>
                    <option value={''}>SDS管理受付日順</option>
                    <option value={'product'}>商品名順</option>
                    <option value={'update'}>SDS最新改定日順</option>
                    <option value={'check'}>SDS最終チェック日順</option>
                    <option value={'raApplication'}>RA管理申込日順</option>
                    <option value={'raReception'}>RA管理受付日順</option>
                  </select>

                  <select ref={this.sortTypeRef} className={"browser-default custom-select"} id={"sortType"} style={{width: 100}} value={this.state.sortType} onChange={(e) => this.sortChange(e)}>
                    <option value={'desc'}>降順</option>
                    <option value={'asc'}>昇順</option>
                  </select>
                </div>
              </div>
            </div>

            {/*<div className={"form-check form-check-inline"}>*/}
            {/*  <div className={"form-group"}>*/}
            {/*    <select ref={this.sortTypeRef} className={"browser-default custom-select"} id={"sortType"} style={{width: 100}} value={this.state.sortType} onChange={(e) => this.sortChange(e)}>*/}
            {/*      <option value={'desc'}>降順</option>*/}
            {/*      <option value={'asc'}>昇順</option>*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={'form-check form-check-inline'} style={{alignItems: 'flex-end'}}>
              <MDBBtn
                  color="default" style={{padding: 10, marginBottom: 16, width: 72}} onClick={() => this.clearFilter()}>  {/*flat={true} outline={true}*/}
                リセット
              </MDBBtn>
            </div>
          </MDBCol>
          <MDBCol size={12} xl={12} lg={12} md={12} sm={12} style={{display: 'flex'}}>
            <div className="form-check form-check-inline">
              {this.state.selectedSDSList.length > 0 &&
                  <MDBBtn size={'sm'} color="default" style={{padding: 10, height: 38, width: 180}} disabled={this.state.downloading} onClick={() => this.downloadSelectedSDS()}>
                    {this.state.downloading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
                    {!this.state.downloading && <MDBIcon icon="download" style={{marginRight: 10}} />}
                    選択したSDSダウンロード
                  </MDBBtn>
              }
              <MDBBtn size={'sm'} className="text-nowrap custom-button-grey" color={'blue-grey'} style={{padding: 10, height: 38, width: 180}} disabled={this.state.downloadingAll} onClick={() => this.downloadAllSDS()}>
                {this.state.downloadingAll && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
                {!this.state.downloadingAll && <MDBIcon icon="download" style={{marginRight: 10}} />}
                すべてのSDSダウンロード
              </MDBBtn>
            </div>
            <div className="form-check form-check-inline">
              <div style={{fontSize: '0.8rem'}}>
                <div>
                  <span>リスクアセスメント</span>
                </div>
                <div>
                  <span>管理中：{this.state.underManagementRiskCount}　受付待：{this.state.waitingRiskCount}　一時停止：{this.state.pauseRiskCount}</span>
                </div>
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBTable responsive btn small>
            <MDBTableHead color={'primary-color'} textWhite>
              <tr>
                {/*{data.columns.map((item, index) => {*/}
                {/*  let width = 'auto';*/}
                {/*  return <th style={{width}}>{item.label}</th>*/}
                {/*})}*/}
                <th style={{width: 50}}><div style={{width: 50}}><MDBInput label=" " type="checkbox" id="checkboxAll" onChange={(e) => this.sdsSelectAll(e.target.checked)} /></div></th>
                <th style={{width: 50}}></th>
                <th>商品名</th>
                <th>SDS</th>
                <th>
                  <>
                    SDS管理申込日/申込者<br/>
                    SDS管理受付日/受付者<br/>
                    RA管理ＩＤ<br/>
                    RA管理申込日/申込者<br/>
                    RA管理受付日/管理受付者<br/>
                    {/*申込日/申込者<br/>*/}
                    {/*受付日/受付者*/}
                  </>
                </th>
                <th>SDS更新希望月</th>
                <th>SDS最終チェック日/実施者</th>
                <th>SDS改定日</th>
                {(isSeller || isAdmin) && <th/>}
                {isBuyer && <th style={{width: 50}}/>}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data.rows.map((item, index) => {
                return <tr>
                  <td style={{width: 50}}>{item['check']}</td>
                  <td style={{width: 50}}>{item['status']}</td>
                  <td>{item['商品名']}</td>
                  <td>{item['SDS']}</td>
                  <td>{item['date']}</td>
                  <td>{item['SDS更新希望月']}</td>
                  <td>{item['SDS最終チェック日/実施者']}</td>
                  <td>{item['SDS改定日']}</td>
                  {(isSeller || isAdmin) && <td>{item['btn']}</td>}
                  {isBuyer && <td style={{width: 50}}>{item['menu']}</td>}
                </tr>
              })
              }
            </MDBTableBody>
          </MDBTable>
        </MDBRow>

        {/*<MDBTable responsive btn small>*/}
        {/*  <MDBTableHead color="primary-color" textWhite columns={data.columns} />*/}
        {/*  <MDBTableBody rows={data.rows} />*/}
        {/*</MDBTable>*/}

        <MDBModal isOpen={this.state.receptionModal} toggle={this.receptionModalClose}>
          <MDBModalHeader toggle={this.receptionModalClose}>SDS管理受付</MDBModalHeader>
          <MDBModalBody>
            {this.state.selectedSDS['item'] && this.state.selectedSDS['item']['P4CLOUD商品名']}のSDS管理申請を受け付けます。 よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.receptionModalClose} color="" className={'custom-button-blue'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.receptionModalClose} color="default" data-confirm={true} >受け付ける</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.riskManagementApplyModal} toggle={this.riskManagementApplyModalClose}>
          <MDBModalHeader toggle={this.riskManagementApplyModalClose}>リスクアセスメント管理　申込</MDBModalHeader>
          <MDBModalBody>
            <div>リスクアセスメント管理を申し込みます。よろしいですか？</div>
            <div>商品名：{this.state.riskManagementApplyItem['P4CLOUD商品名']}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.riskManagementApplyModalClose} color="" className={'custom-button-blue'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.riskManagementApplyModalClose} color="default" data-confirm={true} >申し込む</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.riskManagementAppliedModal} toggle={this.riskManagementAppliedModalClose}>
          <MDBModalHeader toggle={this.riskManagementAppliedModalClose}>リスクアセスメント管理　申込完了</MDBModalHeader>
          <MDBModalBody>
            <div>以下の商品のリスクアセスメント管理を申し込みを受け付けました。</div>
            <div>商品名：{this.state.riskManagementApplyItem['P4CLOUD商品名']}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.riskManagementAppliedModalClose} color="default" data-confirm={true} >SDS管理一覧に戻る</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.riskManagementReceptionModal} toggle={this.riskManagementReceptionModalClose}>
          <MDBModalHeader toggle={this.riskManagementReceptionModalClose}>リスクアセスメント管理受付</MDBModalHeader>
          <MDBModalBody>
            {this.state.riskManagementReceptionItem['P4CLOUD商品名']}のリスクアセスメント管理申請を受け付けます。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.riskManagementReceptionModalClose} color="" className={'custom-button-blue'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.riskManagementReceptionModalClose} color="default" data-confirm={true} >受け付ける</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
      </>
    )

  }
}

export default SDSManagementTableList;
