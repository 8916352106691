import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
    MDBCollapse
} from "mdbreact";

class SupportCategory extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    console.log(this.props.data[this.props.category])
  }

  groupArrayObject = (array) => {
    return array.reduce((group, arr) => {
          const { type } = arr;
          group[type] = group[type] ?? [];
          group[type].push(arr);
          return group;
        }, {});
  }

  render() {
    return (
      <>
        <MDBContainer>
          <MDBRow style={{height: 100, background: '#2e75b6', color: '#ffffff', alignItems: 'center', paddingLeft: 20, paddingRight: 20, justifyContent: 'space-between'}}>
            <Link to={'/support'} style={{color: '#ffffff'}}>
              <img src="/logo.png" className="img-fluid" alt="HOME" style={{height: 22, marginRight: 10}} />
              サポートサイト
            </Link>
            <div>
              <Link to={'/support#operation'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>基本的な操作</Link>
              <Link to={'/support#faq'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>よくある質問</Link>
              <Link to={'/support#information'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>お役立ち情報</Link>
            </div>
          </MDBRow>
          <MDBRow>
            <div style={{padding: '10px 20px'}}>
              <Link to={'/support'}>ホーム</Link> » {this.props.data.categoryTitle[this.props.category]}
            </div>
          </MDBRow>
          <MDBRow style={{backgroundColor: '#f5f5f5'}}>
            <div style={{backgroundColor: '#ffffff', width: '100%', margin: 50, padding: 20}}>
              <h3 style={{padding: 20, paddingTop: 0}}>{this.props.data.categoryTitle[this.props.category]}</h3>

                {this.props.data[this.props.category][0].type && Object.keys(this.groupArrayObject(this.props.data[this.props.category])).map((v) => {
                  return <div style={{marginBottom: 24, marginLeft: 20}}>
                    {v}
                    <ul>
                      {this.groupArrayObject(this.props.data[this.props.category])[v].map(v1 => {
                        return <li style={{marginTop: v1.gap ? 24 : 0}}>
                          <Link to={v1.link}>{v1.title}</Link>
                        </li>
                      })}
                    </ul>
                  </div>
                })}

              <ul>
                {!this.props.data[this.props.category][0].type && this.props.data[this.props.category].map((v) => {
                  // console.log('v', v)
                  return <li style={{marginTop: v.gap ? 24 : 0}}>
                    <Link to={v.link}>{v.title}</Link>
                  </li>
                })}
              </ul>
            </div>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default SupportCategory;

