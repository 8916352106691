
import React from "react";
import { BrowserRouter, Route, Link } from 'react-router-dom'
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from 'mdbreact';
import ItemListCell from '../../components/Item/ItemListCell';
import ItemImage from '../../components/Item/ItemImage';
import "../Item/ItemsTableList.css";
import CartSelect from "../CartSelect/CartSelect";
import OtasukeCartSelect from "../CartSelect/OtasukeCartSelect";

class OtasukeCartHistoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbItems: [],
      last: null,
      itemsPerPage: 12,
      changed: false,
      searchword: '',
      cartselect: false,
      otasukeCartSelect: false,
      selectedItem: {},
      loadedAllItem: false,
      deleteModal: false,
    }
  }

  getItems = async (reset = false, word = '') => {
    // console.log(this.props.org.id, this.props.user.organization)
    if (this.state.loaded) {
      return;
    }
    if (this.state.loading) {
      return;
    }
    if(!this.props.userOrg){
      return;
    }
    if (!this.props.org){
      return;
    }
    if (this.state.loadedAllItem) {
      return;
    }

    //console.log("last",this.state.last);

    let searchWords = [];
    word = word.toUpperCase().replace(/[\!-\~]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
    let words = word.split(/\s+/);
    for (  var i = 0;  i < words.length;  i++  ) {
      for (  var j = 0;  j < words[i].length - 1;  j++  ) {
        searchWords.push(words[i][j] + words[i][j+1]);
      }
    }

    this.setState({loading: true})
    let first;

    if (this.props.userOrg && this.props.userOrg.buyer || this.props.userOrg && this.props.userOrg.reseller && this.props.org.maker) {
      first = this.props.db.collection('otasuke_carts_history')
          .where("toOrganization", "==", this.props.user.organization)
          .where("fromOrganization", "==", this.props.org.id)
          .where("sendHelpCart", "==", 1)
          .orderBy('created', 'desc')
          .limit(this.state.itemsPerPage);
    } else {
      first = this.props.db.collection('otasuke_carts_history')
          .where("toOrganization", "==", this.props.org.id)
          .where("fromOrganization", "==", this.props.user.organization)
          .orderBy('created', 'desc')
          .limit(this.state.itemsPerPage);
    }

    if(this.state.last && !reset){
      console.log('last after');
      first = first.startAfter(this.state.last)
    }

    let collection;
    let urigawa = false;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
      urigawa = true;
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
      urigawa = true;
    }

    if(first && collection){
      let och = await first.get()
      if (och.docs.length > 0) {
        let last = och.docs[och.docs.length - 1];
        this.setState({last: last})

        let items = this.state.dbItems;
        if (reset) {
          items = [];
        }

        // let withDeletedItem = false;
        for (let i=0; i<och.docs.length; i++) {
          let doc = och.docs[i];
          let data = doc.data();
          data.id = doc.id;


          // if (!urigawa) {
            // let promises = [];
            let cart = data.cart;
            delete cart['deletedItem'];
            delete cart['deletedText'];
            await Promise.all(cart.items.map(async (item, index) => {
              let itemSell = await collection.doc(item.id).get();
              let itemSellData = itemSell.data();

              delete item['deletedItem'];
              delete item['deletedText'];

              if (!itemSellData) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '販売終了商品が含まれています';
                if (!urigawa) {
                  cart.deletedText = '販売終了商品が含まれています';
                } else {
                  cart.deletedText = '販売終了商品が含まれています';
                }
              } else if (itemSellData && !itemSellData['price'] || itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '要見積商品が含まれています';
                if (!urigawa) {
                  cart.deletedText = '要見積商品が含まれています';
                } else {
                  cart.deletedText = '要見積商品が含まれています';
                }
              } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '要見積商品が含まれています';
                if (!urigawa) {
                  cart.deletedText = '要見積商品が含まれています';
                } else {
                  cart.deletedText = '要見積商品が含まれています';
                }
              } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                let expiration_date = itemSellData['price'][0]['expiration_date'];
                let expDate = expiration_date.toDate();

                if ((expDate.getTime() - this.props.dateNow) < 0) {
                  cart.deletedItem = true;
                  item.deletedItem = true;
                  if (!urigawa) {
                    cart.deletedText = '価格有効期限切れ要見積が含まれています';
                    item.deletedText = '価格有効期限切れ要見積が含まれています';
                  } else {
                    cart.deletedText = '要見積商品が含まれています';
                    item.deletedText = '要見積商品が含まれています';
                  }
                } else {
                  if (!urigawa) {
                    item.price = itemSellData['price'][0]['price'];
                  }
                }
              } else {
                if (!urigawa) {
                  item.price = itemSellData['price'][0]['price'];
                }
              }
              // if (itemSellData && itemSellData['price']) {
              //   item.price = itemSellData['price'][0]['price'];
              // } else {
              //   // withDeletedItem = true;
              //   cart.items = cart.items.filter((obj) => obj.id !== item.id);
              //   // let d = {...data};
              //   // delete d.id;
              //   // carts.items[index].deletedItemSell = true;
              //   // promises.push(this.props.db.collection('otasuke_carts_history').doc(doc.id).set(d, {merge: true}))
              // }
            }));

            // if (promises.length > 0) {
            //   Promise.all(promises)
            // }
          // }

          items.push(data)
        }
        this.setState({dbItems: items})
      } else {
        console.log('no doc more');
        this.setState({loadedAllItem: true});
      }

      this.setState({loading: false});
      // let paginate = first.get()
      //     .then((snapshot) => {
      //       this.setState({loading: false})
      //       if(snapshot.docs.length > 0) {
      //         let last = snapshot.docs[snapshot.docs.length - 1];
      //
      //         this.setState({last: last})
      //
      //         let items = this.state.dbItems;
      //         if (reset) {
      //           console.log('reset')
      //           items = [];
      //         }
      //         snapshot.forEach((doc) => {
      //           let data = doc.data();
      //           data.id = doc.id;
      //           items.push(data)
      //         })
      //         this.setState({dbItems: items})
      //       } else {
      //         console.log('no doc more');
      //         this.setState({loadedAllItem: true});
      //       }
      //     })
      //     .catch((e) => {
      //       console.log('error', e)
      //     });
    }
    else {
      this.setState({loading: false})
    }

  }

  componentDidMount() {
    this.getItems();
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps');
    if (this.props.org !== nextProps.org) {
      //console.log('componentWillReceiveProps : 1');
      this.setState({changed: true})
    }
    if (this.props.userOrg !== nextProps.userOrg) {
      //console.log('componentWillReceiveProps : 2');
      this.setState({changed: true})
    }
  }

  componentDidUpdate() {
    if(this.state.changed){
      this.setState({
        changed: false,
        loadedAllItem: false,
        dbItems: [],
      }, () => {
        this.getItems(true);
      });

    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +1 >= document.body.clientHeight) {
      // console.log('reach end', window.innerHeight, this.scrollTop(), document.body.clientHeight);
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
    );
  }

  onReachEnd = () => {
    // if(this.state.algoria){
    //   this.getItemsA(true, this.state.searchwordA);
    // }else{
      this.getItems(false, this.state.searchword);
    // }
  }

  handleSearchwordChange = (e) => {
    this.setState({
      searchword: e.currentTarget.value,
    });
    /*
    if(e.currentTarget.value.length > 1 || e.currentTarget.value.length==0){
      this.setState({
        dbItems: [],
        last: null,
      });
      this.getItems(true, e.currentTarget.value);
    }
    */
  }
  handleSearchwordKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        dbItems: [],
        last: null,
        loadedAllItem: false,
        algoria: false,
      }, () => {
        this.getItems(true, this.state.searchword);
      });
    }
  }

  handleSearchwordAChange = (e) => {
    this.setState({
      searchwordA: e.currentTarget.value,
    });
    /*
    if(e.currentTarget.value.length > 1 || e.currentTarget.value.length==0){
      this.setState({
        dbItems: [],
        last: null,
      });
      this.getItems(true, e.currentTarget.value);
    }
    */
  }

  modalDelClose = (e) => {
    let dId = e && e.currentTarget.dataset.id;

    if (dId) {
      console.log('delete', dId)
      let self = this;

      let otasukeCartHistoryRef = this.props.db.collection('otasuke_carts_history').doc(dId);
      otasukeCartHistoryRef.set({
        sendHelpCart: 2,
      }, { merge: true })
          .then(function () {
            console.log('success set order')
            self.setState({last: null, loadedAllItem: false}, () => {
              self.getItems(true);
            })
          })
          .catch(function (error) {
            console.error("Error set document: ", error);
          });
    }

    this.setState({
      deleteModal: false,
      deleteId: null,
      deleteTitle: null
    })
  }

  deleteHelpCart = (e) => {
    let dId = e && e.currentTarget.dataset.id;
    let dTitle = e && e.currentTarget.dataset.title;
    this.setState({
      deleteId: dId,
      deleteTitle: dTitle,
      deleteModal: true
    })
  }

  addCart = (item) => {
    let self = this;
    // console.log('item',item.id, item.objectID);
    this.setState({selectedItem: item}, () => {
      self.setState({cartselect: true});
    });
  }

  addOtasukeCart = (item) => {
    let self = this;
    this.setState({selectedItem: item}, () => {
      self.setState({otasukeCartSelect: true})
    })
  }

  addQuoteCart = (item) => {
    // let self = this;
    // // console.log('item',item.id, item.objectID);
    // this.setState({selectedItem: item}, () => {
    //   self.setState({cartselect: true});
    // });
  }

  showHideCart = (val) => {
    this.setState({cartselect: val});
  }

  showHideOtasukeCart = (val) => {
    this.setState({otasukeCartSelect: val});
  }

  showOtasukeDetail = (id) => {
    // this.dbOtasukeUpdate(id, "readBuyer", true)
    let otasukeRef = this.props.db.collection('otasuke_carts_history').doc(id);
    otasukeRef.set({
      readBuyer: true,
    }, { merge: true });
    this.props.readOtasuke(1);
    this.props.cProps.history.push("/otasuke/cart/detail/" + id);

  }

  dbOtasukeUpdate = (id,target,value) => {
    let dbOtasukeUpdated = this.state.dbItems.map((es)=>{
      if(es.id === id){
        // if(target === 'visited') {
        //   let visited = es.visited;
        //   if(visited){
        //     es[target] = Object.assign(visited, value);
        //   } else {
        //     es[target] = value;
        //   }
        // }else{
          es[target] = value;
        // }
      }
      return es;
    })
    this.setState({
      dbItems: dbOtasukeUpdated,
    })
  }

  render() {
    if (! this.props.org ) {
      return (
          <>
          </>
      )
    }
    const items = [];

    let userType = 'seller';
    if (this.props.userOrg && this.props.userOrg.buyer || this.props.userOrg && this.props.userOrg.reseller && this.props.org.maker) {
      userType = 'buyer';
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    this.state.dbItems.forEach((item,i) => {
      // let dateNo = item.cart.created.toDate().getFullYear() + ''
      //     + (item.cart.created.toDate().getMonth() + 1) + ''
      //     + item.cart.created.toDate().getDate();

      let date = '-';
      let time = '-';

      if (item.cart.sendHelpCartDate) {
        date = item.cart.sendHelpCartDate.toDate().getFullYear() + '/'
            + n(item.cart.sendHelpCartDate.toDate().getMonth() + 1) + '/'
            + n(item.cart.sendHelpCartDate.toDate().getDate());
            time =  n(item.cart.sendHelpCartDate.toDate().getHours()) + ':'
            + n(item.cart.sendHelpCartDate.toDate().getMinutes());
      }

      let amount = 0;
      let name='';
      let deletedDescription = '';
      let d = [];

      item.cart.items.forEach((it, index) => {
        if (!it.deletedItem) {
          amount += (it.price*it.count);
        } else {
          if (!d.includes(it.deletedText)) {
            d.push(it.deletedText)
            deletedDescription = <>{deletedDescription}<span style={{color: 'red'}}>{it.deletedText}</span><br /></>
          }
        }
        if(name != ''){
          name = <>{name}{it.name}<br /></>;
        }else{
          name = <>{it.name}<br /></>;
        }
      });

      // console.log(item)
      let deletedItem = item.cart.deletedItem;

      if (deletedItem) {
        name = <>{name}{deletedDescription}</>
      }

      if (userType === 'buyer') {
        let bold = item['readBuyer'] === false;

        items.push({
          'カートID': <span style={{fontWeight: bold ? 'bold' : ''}}>{item.id}</span>,
          '受信日時': <span style={{fontWeight: bold ? 'bold' : ''}}>{date}<br/>{time}</span>,
          '送信者': <span style={{fontWeight: bold ? 'bold' : ''}}>{item.cart.sendHelpCartUserName}</span>,
          '受取者': <span style={{fontWeight: bold ? 'bold' : ''}}>{item.toUserName}</span>,
          'お助けカート名称': <span style={{fontWeight: bold ? 'bold' : ''}}>{item.cart.cartName}</span>,
          '内容': <span style={{fontWeight: bold ? 'bold' : ''}}>{name}</span>,
          '金額': <div style={{textAlign: 'right', fontWeight: bold ? 'bold' : ''}}>{amount > 0 && amount.toLocaleString()}</div>,
          '詳細': <div style={{textAlign: 'center'}}>
            {/*<Link to={"/otasuke/cart/detail/" + item.id}>*/}
              <MDBBtn rounded onClick={(e) => this.showOtasukeDetail(item.id)} size="sm" color={''} className={'custom-button-lightgreen'}>表示</MDBBtn>
            {/*</Link>*/}
          </div>,
          '削除': <MDBBtn rounded flat
                        size="sm"
                        data-id={item.id}
                        data-title={item.cart.cartName}
                        onClick={this.deleteHelpCart}
          >
            <MDBIcon icon="trash" size={'2x'} className="custom-text-grey"/>
          </MDBBtn>
        })
      } else {
        items.push({
          'カートID': item.id,
          '送信日時': <span>{date}<br/>{time}</span>,
          '作成者/送信者': item.cart.creatorUserName,
          '送信先': item.toUserName,
          'お助けカート名称': item.cart.cartName,
          '内容': name,
          '金額': <div style={{textAlign: 'right'}}>{amount > 0 && amount.toLocaleString()}</div>,
          '詳細': <div style={{textAlign: 'center'}}>
            <Link to={"/otasuke/cart/detail/" + item.id}>
              <MDBBtn rounded size="sm" color={''} className={'custom-button-lightgreen'}>表示</MDBBtn>
            </Link>
          </div>
        })
      }

    });

    let columns = [
      {
        label: 'カートID',
        field: 'カートID',
        sort: 'asc',
        minimal: 'sm'
      },
      {
        label: '送信日時',
        field: '送信日時',
        sort: 'disabled',
        minimal: 'sm'
      },
      {
        label: '作成者/送信者',
        field: '作成者/送信者',
        sort: 'disabled',
        width: 100,
        minimal: 'sm'
      },
      {
        label: '送信先',
        field: '送信先',
        sort: 'disabled',
        minimal: 'sm'
      },
      {
        label: 'お助けカート名称',
        field: 'お助けカート名称',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      },
      {
        label: '内容',
        field: '内容',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      },
      {
        label: <div style={{textAlign: 'center'}}>金額</div>,
        field: '金額',
        sort: 'disabled',
        width: 30,
        minimal: 'sm'
      },
      {
        label: <div style={{textAlign: 'center'}}>詳細</div>,
        field: '詳細',
        sort: 'disabled',
        width: 30,
        minimal: 'sm'
      }
    ];

    if (userType === 'buyer') {
      columns = [
        {
          label: 'カートID',
          field: 'カートID',
          sort: 'asc',
          minimal: 'sm'
        },
        {
          label: '受信日時',
          field: '受信日時',
          sort: 'disabled',
          minimal: 'sm'
        },
        {
          label: '送信者',
          field: '送信者',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '受取者',
          field: '受取者',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: 'お助けカート名称',
          field: 'お助けカート名称',
          sort: 'disabled',
          minimal: 'sm'
        },
        {
          label: '内容',
          field: '内容',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: '金額',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>詳細</div>,
          field: '詳細',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>削除</div>,
          field: '削除',
          sort: 'disabled',
          width: 30,
          minimal: 'sm'
        }
      ];
    }

    const data = {
      columns: columns,
      rows: items
    }

    return (
        <>
          {/*<MDBContainer>*/}
            <MDBRow>
              <MDBCol>
                {
                  userType === 'buyer' ? <h2>お助けカート一覧</h2> : <h2>お助けカート送信履歴</h2>
                }
              </MDBCol>
            </MDBRow>
            {/*<MDBRow>*/}
            {/*  <MDBCol md="3">*/}
            {/*    <div className="input-group md-form form-sm form-1 pl-0">*/}
            {/*      <div className="input-group-prepend">*/}
            {/*        <span className="input-group-text lighten-3" id="basic-text1">*/}
            {/*          <MDBIcon className="text-white" icon="search" />*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*      <input*/}
            {/*          className="form-control my-0 py-1"*/}
            {/*          type="text"*/}
            {/*          placeholder="検索"*/}
            {/*          aria-label="検索"*/}
            {/*          onChange={this.handleSearchwordChange}*/}
            {/*          onKeyDown={this.handleSearchwordKeyDown}*/}
            {/*          value={this.state.searchword}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </MDBCol>*/}

            {/*</MDBRow>*/}
            <CartSelect isOpen={this.state.cartselect} isOpenEvent={(val) => this.showHideCart(val)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} dateNow={this.props.dateNow} />
            <OtasukeCartSelect isOpen={this.state.otasukeCartSelect} isOpenEvent={(val) => this.showHideOtasukeCart(val)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} dateNow={this.props.dateNow} />
          {/*</MDBContainer>*/}

          <MDBTable responsive btn autoWidth small striped>
            <MDBTableHead color="primary-color" textWhite columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>

          <MDBModal isOpen={this.state.deleteModal} toggle={this.modalDelClose}>
            <MDBModalHeader toggle={this.modalDelClose}>お助けカート削除</MDBModalHeader>
            <MDBModalBody>
              お助けカート「{this.state.deleteTitle}」を削除します。 よろしいですか？
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={this.modalDelClose} color={'secondary'} size={'sm'}>キャンセル</MDBBtn>
              <MDBBtn onClick={this.modalDelClose} color={'danger'} size={'sm'} data-id={this.state.deleteId}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
        </>
    )

  }
}

export default OtasukeCartHistoryList;
