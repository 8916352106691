import React from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBChip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon, MDBCol, MDBRow, MDBInput
} from 'mdbreact';
import StorageLink from './StorageLink'
import "./EstimatesTableList.css";
import firebase from "../../firebase";
import Typesense from "typesense";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import DatePicker from "react-datepicker";
const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);

class EstimatesTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbEstimates: [],
      last: null,
      estimatesPerPage: 12,
      applyModal: false,
      waitModal: false,
      rejectModal: false,
      deleteModal: false,
      searchwordA: '',
      algoriaPage: 1,
      algolia: false,
      sortType: 'desc',
      estimatesType: '1',
      selectedEstimatesList: [],
      downloading: false,
      downloadingAll: false,
      sortMainType: 'dueDate',

      sellerUsers: [],
      buyerUsers: [],

      sentDateStart: null,
      sentDateEnd: null,
      confirmDateStart: null,
      confirmDateEnd: null,
      issueDateStart: null,
      issueDateEnd: null,
      endDateStart: null,
      endDateEnd: null,
      deliveringDateStart: null,
      deliveringDateEnd: null,
      statusConfirmDateStart: null,
      statusConfirmDateEnd: null,
      sellerConfirmDateStart: null,
      sellerConfirmDateEnd: null,
      estimateNo: '',
      estimateId: '',
      title: '',
      sender: '',
      receiver: '',
      sellerReceiver: '',
      confirmer: '',
      filterType: 'estimateNo',


    }
  }

  getEstimatesA = async (props, newOrg, word) => {
    if (this.state.loading) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    let urigawa = false;
    let indexName = 'P4Estimates';

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let tempResults = [];

    this.setState({loading: true, algolia: true});
    let filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
    let query_by = '*';
    let q = '*'
    let infix = 'off';
    if (!urigawa) {
      filters += ` && delete:false`;
    } else {
      switch (this.state.estimatesType) {
        case '1':
          filters += ` && delete:false`;
          break;
        case '2':
          filters += ` && delete:true`;
          break;
      }
    }
    if (this.state.filterType === 'sentDate' && this.state.sentDateStart && this.state.sentDateEnd) {
      let startTimestamp = new Date(this.state.sentDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.sentDateEnd).setHours(23,59,59)/1000;
      console.log('sentDate search', startTimestamp, endTimestamp);
      filters += ` && created._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'confirmDate' && this.state.confirmDateStart && this.state.confirmDateEnd) {
      let startTimestamp = new Date(this.state.confirmDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.confirmDateEnd).setHours(23,59,59)/1000;
      console.log('confirmDate search', startTimestamp, endTimestamp);
      filters += ` && confirmDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'issueDate' && this.state.issueDateStart && this.state.issueDateEnd) {
      let startTimestamp = new Date(this.state.issueDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.issueDateEnd).setHours(23,59,59)/1000;
      console.log('issueDate search', startTimestamp, endTimestamp);
      filters += ` && date._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'endDate' && this.state.endDateStart && this.state.endDateEnd) {
      let startTimestamp = new Date(this.state.endDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.endDateEnd).setHours(23,59,59)/1000;
      console.log('endDate search', startTimestamp, endTimestamp);
      filters += ` && endDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'deliveringDate' && this.state.deliveringDateStart && this.state.deliveringDateEnd) {
      let startTimestamp = new Date(this.state.deliveringDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.deliveringDateEnd).setHours(23,59,59)/1000;
      console.log('deliveringDate search', startTimestamp, endTimestamp);
      filters += ` && deliveryDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'statusConfirmDate' && this.state.statusConfirmDateStart && this.state.statusConfirmDateEnd) {
      let startTimestamp = new Date(this.state.statusConfirmDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.statusConfirmDateEnd).setHours(23,59,59)/1000;
      console.log('statusConfirmDate search', startTimestamp, endTimestamp);
      filters += ` && applyDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'sellerConfirmDate' && this.state.sellerConfirmDateStart && this.state.sellerConfirmDateEnd) {
      let startTimestamp = new Date(this.state.sellerConfirmDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.sellerConfirmDateEnd).setHours(23,59,59)/1000;
      console.log('sellerConfirmDate search', startTimestamp, endTimestamp);
      filters += ` && urigawaConfirmDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'estimateNo' && this.state.estimateNo) {
      //filters += ` && number:'${this.state.estimateNo}'`;
      q = `${this.state.estimateNo}`
      query_by = 'number';
      infix = 'always';
    }
    if (this.state.filterType === 'estimateId' && this.state.estimateId) {
      q = `${this.state.estimateId}`
      //filters += ` && objectID:'${this.state.estimateId}'`;
      query_by = 'objectID';
      infix = 'always'
    }
    if (this.state.filterType === 'title' && this.state.title) {
      //filters += ` && title:'${this.state.title}'`;
      q = `${this.state.title}`
      query_by = 'title';
      infix = 'always';
    }
    if (this.state.filterType === 'sender' && this.state.sender) {
      filters += ` && fromUser:${this.state.sender}`;
    }

    if (this.state.filterType === 'receiver' && this.state.receiver) {
      filters += ` && toUser:${this.state.receiver}`;
    }
    if (this.state.filterType === 'sellerReceiver' && this.state.sellerReceiver) {
      filters += ` && urigawaConfirmUser:${this.state.sellerReceiver}`;
    }
    if (this.state.filterType === 'confirmer' && this.state.confirmer) {
      filters += ` && confirmUser:${this.state.confirmer}`;
    }
    let orderBy = 'dueDate';
    if (this.state.sortMainType === 'endDate') {
      orderBy = 'endDate._seconds';
    }
    if (this.state.sortMainType === 'created') {
      orderBy = 'created._seconds';
    }
    if (this.state.sortMainType === 'deliveryDate') {
      orderBy = 'deliveryDate._seconds';
    }
    console.log('filters', filters, q, query_by, infix);
    let searchParameters = {
      'q'         : q,
      'query_by'  : query_by,
      'infix'     : infix,
      "num_typos": 0,
      "typo_tokens_threshold": 0,
      'filter_by' : filters,
      'sort_by'   : `${orderBy}:${this.state.sortType}`,
      'page'      : this.state.algoriaPage,
      'per_page'  : this.state.estimatesPerPage
    }
    await typesense.collections('estimates').documents().search(searchParameters).then((docs) => {
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })
    });
    let nextPage = this.state.algoriaPage + 1;
    let newResults = this.state.dbEstimates.concat(tempResults);
    this.setState({algoriaPage: nextPage,dbEstimates:newResults,loading:false})
  }

  getEstimates = (props, newOrg) => {
    // console.log("###### getEstimates ########", props)

    if (this.state.loading) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    let urigawa = false;

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (fromOrg == "" || toOrg == ""){
      return;
    }
    let first;
    this.setState({loading: true, algolia: false})

    if (urigawa) {
      switch (this.state.estimatesType) {
        case '1':
          if (this.state.last) {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .where('delete', '==', false)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.estimatesPerPage);
          } else {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .where('delete', '==', false)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .limit(this.state.estimatesPerPage);
          }
          break;
        case '2':
          if (this.state.last) {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .where('delete', '==', true)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.estimatesPerPage);
          } else {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .where('delete', '==', true)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .limit(this.state.estimatesPerPage);
          }
          break;
        case '3':
          if (this.state.last) {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.estimatesPerPage);
          } else {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .limit(this.state.estimatesPerPage);
          }
          break;
        default:
          if (this.state.last) {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.estimatesPerPage);
          } else {
            first = this.props.db.collection('estimates')
                .where('fromOrganization', '==', fromOrg)
                .where('toOrganization', '==', toOrg)
                .where(this.state.sortMainType, "!=" , 0)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .limit(this.state.estimatesPerPage);
          }
          break;
      }
    } else {
      if(this.state.last){
        first = this.props.db.collection('estimates')
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .where("delete", "==", false)
            .where(this.state.sortMainType, "!=" , 0)
            .orderBy(this.state.sortMainType,this.state.sortType)
            .startAfter(this.state.last)
            .limit(this.state.estimatesPerPage);
      } else {
        first = this.props.db.collection("estimates")
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .where("delete", "==", false)
            .where(this.state.sortMainType, "!=" , 0)
            .orderBy(this.state.sortMainType,this.state.sortType)
            .limit(this.state.estimatesPerPage);
      }
    }

    let paginate = first.get()
    .then((snapshot) => {
      this.setState({loading: false})
      if(snapshot.docs.length > 0) {
        let last = snapshot.docs[snapshot.docs.length - 1];

        this.setState({last: last})

        let estimates = this.state.dbEstimates;

        if (newOrg === true) {
          estimates = [];
        }

        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          estimates.push(data)
        })

        this.setState({dbEstimates: estimates}, () => {
          let wrapper1 = document.getElementById('wrapper1');
          let div1 = document.getElementById('div1');
          let wrapper2 = document.getElementsByClassName('table-responsive')[0];
          let div2 = document.getElementById('div2');

          if (wrapper1 && div1 && wrapper2 && div2) {
            div1.style.width = div2.offsetWidth+'px';

            wrapper1.onscroll = function() {
              wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
              wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
          }
        })
      }else{

      }

    });

  }

  componentDidMount() {
    //this.getEstimates();
    this.getEstimates(this.props, true);
    this.getOrganizationUsers(this.props);
    this.props.loadBadge();
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  componentWillReceiveProps(nextProps) {

    // if (nextProps.org) {
    //   console.log('estimates', 'componentWillReceiveProps')

      this.setState({last: null, dbEstimates: []}, () => {

        this.getEstimates(nextProps, true);
        this.props.loadBadge();
      });
    // }
    this.getOrganizationUsers(nextProps);

  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  getOrganizationUsers = (props) => {
    let sellerUsers = [];
    let buyerUsers = [];

    // console.log('props', props);

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      // console.log('seller', props);
      sellerUsers = props.userOrg.users;
      buyerUsers = props.org.users;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      // console.log('seller', props);
      sellerUsers = props.userOrg.users;
      buyerUsers = props.org.users;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      // console.log('buyer', props);
      sellerUsers = props.org.users;
      buyerUsers = props.userOrg.users;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      // console.log('buyer', props);
      sellerUsers = props.org.users;
      buyerUsers = props.userOrg.users;
    }

    if (sellerUsers.length > 0 && buyerUsers.length > 0) {
      // console.log(sellerUsers, buyerUsers);
      this.setState({
        sellerUsers,
        buyerUsers
      })
    }
  }
  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      // console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    // console.log("LAST:" , this.state.last);
    if(this.state.algolia){
      this.getEstimatesA(this.props, false, this.state.searchwordA)
    } else {
      this.getEstimates(this.props);
    }

  }

  estimateConfirm = (e) => {
    console.log('estimate confirm')
  }

  urigawaConfirm = (e) => {
    let esId = e && e.currentTarget.dataset.id;
    let index = e && e.currentTarget.dataset.index;
    let item = this.state.dbEstimates[index];

    let estData = {};

    // estData.readSeller = true;
    // this.dbEstimatesUpdate(esId,"readSeller",true);
    // this.props.readEstimates(1);

    let visitDate = firebase.firestore.Timestamp.fromDate(new Date());
    if (item['apply'] && !item['urigawaConfirmDate']) {
      estData.urigawaConfirmDate = visitDate;
      estData.urigawaConfirmUser = this.props.user.id;
      estData.urigawaConfirmUserName = this.props.user.last_name + this.props.user.first_name
      this.dbEstimatesUpdate(esId,"urigawaConfirmDate",visitDate);
      this.dbEstimatesUpdate(esId,"urigawaConfirmUser",this.props.user.id);
      this.dbEstimatesUpdate(esId,"urigawaConfirmUserName",this.props.user.last_name + this.props.user.first_name);
    }

    let estimatesRef = this.props.db.collection('estimates').doc(esId);
    estimatesRef.set(estData, { merge: true });
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);

    let esId = e && e.currentTarget.dataset.id;
    let index = e && e.currentTarget.dataset.index;

    let item = this.state.dbEstimates[index];

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
        || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
        || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    console.log(kaigawa, urigawa)

    let estData = {
      visited: {[this.props.user.id]: true},
    }

    if (urigawa) {
      if (item.readSeller === false) {
        estData.readSeller = true;
        this.dbEstimatesUpdate(esId, "readSeller", true);
        this.props.readEstimates(1);
      }
    }

    if (kaigawa) {
      if (item.readBuyer === false) {
        estData.readBuyer = true;
        this.dbEstimatesUpdate(esId,"readBuyer",true);
        this.props.readEstimates(1);
      }
    }

    let visitDate = firebase.firestore.Timestamp.fromDate(new Date());
    if (urigawa) {
      // if (item['apply'] && !item['urigawaConfirmDate']) {
      //   estData.urigawaConfirmDate = visitDate;
      //   estData.urigawaConfirmUser = this.props.user.id;
      //   estData.urigawaConfirmUserName = this.props.user.last_name + this.props.user.first_name
      //   this.dbEstimatesUpdate(esId, "urigawaConfirmDate", visitDate);
      //   this.dbEstimatesUpdate(esId, "urigawaConfirmUser", this.props.user.id);
      //   this.dbEstimatesUpdate(esId, "urigawaConfirmUserName", this.props.user.last_name + this.props.user.first_name);
      // }

      // if (!(item['apply'] && item['urigawaConfirmDate'])) {
      //   estData.urigawaConfirmDate = visitDate;
      //   estData.urigawaConfirmUser = this.props.user.id;
      //   estData.urigawaConfirmUserName = this.props.user.last_name + this.props.user.first_name
      //   this.dbEstimatesUpdate(esId, "urigawaConfirmDate", visitDate);
      //   this.dbEstimatesUpdate(esId, "urigawaConfirmUser", this.props.user.id);
      //   this.dbEstimatesUpdate(esId, "urigawaConfirmUserName", this.props.user.last_name + this.props.user.first_name);
      // }
    }

    if (kaigawa) {
      if (!item.confirmDate && !(item['visited'] && item['visited'][item['toUser']]) && !item['reject']) {
        estData.confirmDate = visitDate
        estData.confirmUser = this.props.user.id;
        estData.confirmUserName = this.props.user.last_name + this.props.user.first_name;
        this.dbEstimatesUpdate(esId,"confirmDate",visitDate);
        this.dbEstimatesUpdate(esId,"confirmUserName",this.props.user.last_name + this.props.user.first_name);
      }
    }

    let estimatesRef = this.props.db.collection('estimates').doc(esId);
    estimatesRef.set(estData, { merge: true });
    this.dbEstimatesUpdate(esId,"visited",{[this.props.user.id]:true});
  }

  delete = (e) => {
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      deleteModal: true,
      deleteId: esId,
      deleteTitle: esTitle,
    })
  }

  deleteClose = (e) => {
    this.setState({
      deleteModal: false,
      deleteId: null,
      deleteTitle: null
    });

    let esId = e && e.currentTarget.dataset.id;
    if(esId){
      let estimatesRef = this.props.db.collection('estimates').doc(esId);
      let deleteDate = firebase.firestore.Timestamp.fromDate(new Date());
      estimatesRef.set({
        delete: true,
        deleteDate: deleteDate,
        deleteUser: this.props.user.id,
        deleteUserName: this.props.user.last_name + this.props.user.first_name
      }, { merge: true });
      this.dbEstimatesUpdate(esId,"delete",true);
      this.dbEstimatesUpdate(esId,"deleteDate",deleteDate);
      this.dbEstimatesUpdate(esId,"deleteUserName",this.props.user.last_name + this.props.user.first_name);
    }
  }

  applyFile = (e) => {
    console.log("applyFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      applyModal: true,
      applyId: esId,
      applyTitle: esTitle,
    })
  }
  rejectFile = (e) => {
    console.log("rejectFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      rejectModal: true,
      rejectId: esId,
      rejectTitle: esTitle,
    })
  }
  applyClose = (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
      applyTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let estimatesRef = this.props.db.collection('estimates').doc(esId);
      let applyDate = firebase.firestore.Timestamp.fromDate(new Date());
      estimatesRef.set({
        apply: {[this.props.user.id]: true},
        applyDate: applyDate,
        applyUser: this.props.user.id,
        applyUserName: this.props.user.last_name + this.props.user.first_name
      }, { merge: true });
      this.dbEstimatesUpdate(esId,"apply",{key:1});
      this.dbEstimatesUpdate(esId,"applyDate",applyDate);
      this.dbEstimatesUpdate(esId,"applyUserName",this.props.user.last_name + this.props.user.first_name);
    }

  }
  rejectClose = (e) => {
    this.setState({
      rejectModal: false,
      rejectId: null,
      rejectTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let estimatesRef = this.props.db.collection('estimates').doc(esId);
      let rejectDate = firebase.firestore.Timestamp.fromDate(new Date())
      estimatesRef.set({
        reject: {[this.props.user.id]: true},
        rejectDate: rejectDate,
        rejectUser: this.props.user.id,
        rejectUserName: this.props.user.last_name + this.props.user.first_name
      }, { merge: true });
      this.dbEstimatesUpdate(esId,"reject",{key:1});
      this.dbEstimatesUpdate(esId,"rejectDate",rejectDate);
      this.dbEstimatesUpdate(esId,"rejectUserName",this.props.user.last_name + this.props.user.first_name);
    }

  }

  waitFile = (e) => {
    console.log("waitFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      waitModal: true,
      waitId: esId,
      waitTitle: esTitle,
    })
  }
  waitClose = (e) => {
    this.setState({
      waitModal: false,
      waitId: null,
      waitTitle: null,
    })

    let esId = e && e.currentTarget.dataset.id;
    if(esId){
      let estimatesRef = this.props.db.collection('estimates').doc(esId);
      estimatesRef.set({
        wait: {[this.props.user.id]: true},
        waitDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });
      this.dbEstimatesUpdate(esId,"wait",{key:1});
    }
  }

  dbEstimatesUpdate = (id,target,value) => {
    let dbEstimatesUpdated = this.state.dbEstimates.map((es)=>{
      if(es.id === id){
        if(target === 'visited') {
          let visited = es.visited;
          if(visited){
            es[target] = Object.assign(visited, value);
          } else {
            es[target] = value;
          }
        }else{
          es[target] = value;
        }
      }
      return es;
    })
    this.setState({
      dbEstimates: dbEstimatesUpdated,
    })
  }

  handleSearchwordAChange = (e) => {
    this.setState({
      searchwordA: e.currentTarget.value,
    });
  }

  handleSearchwordAKeyDown = (e) => {
    let val = this.state.searchwordA;
    if (e.key === 'Enter') {
      this.setState({
        dbEstimates: [],
        last: null,
        algoriaPage: 1,selectedEstimatesList: []
      }, ()=>{
        console.log(val)
        if (val) {
          this.getEstimatesA(this.props, true, val);
        } else {
          this.getEstimates(this.props, true)
        }

      });
    }
  }

  sortChange = (val) => {
    this.setState({last: null, notMore: false, sortType: val, dbEstimates: [], algoriaPage: 1,selectedEstimatesList: []}, () => {
      if (this.state.algolia) {
        this.getEstimatesA(this.props, true, this.state.searchwordA);
      } else {
        this.getEstimates(this.props, true);
      }
    })
  }

  selectEstimatesType = (e) => {
    this.setState({
      estimatesType: e.target.value || '1',
      last: null,
      notMore: false,
      dbEstimates: [],
      algoriaPage: 1,
      selectedEstimatesList: []
    }, () => {
      if (this.state.algolia) {
        this.getEstimatesA(this.props, true, this.state.searchwordA);
      } else {
        this.getEstimates(this.props, true);
      }
    });
  }

  clearFilter = () => {
    this.setState({
      sentDateStart: null,
      sentDateEnd: null,
      confirmDateStart: null,
      confirmDateEnd: null,
      issueDateStart: null,
      issueDateEnd: null,
      endDateStart: null,
      endDateEnd: null,
      deliveringDateStart: null,
      deliveringDateEnd: null,
      statusConfirmDateStart: null,
      statusConfirmDateEnd: null,
      sellerConfirmDateStart: null,
      sellerConfirmDateEnd: null,
      estimateNo: '',
      estimateId: '',
      title: '',
      sender: '',
      receiver: '',
      sellerReceiver: '',
      confirmer: '',
      filterType: 'estimateNo',

      searchwordA: '',
      notMore: false,
      sortType: 'desc',
      estimatesType: '1',
      last: null,
      dbEstimates: [],
      algolia: false,
      algoriaPage: 1,selectedEstimatesList: []
    }, () => {
      if (this.state.algolia) {
        this.getEstimatesA(this.props, true, this.state.searchwordA);
      } else {
        this.getEstimates(this.props, true);
      }
    });
  }
  estimatesIsSelected = (docId) => {
    let filtered = this.state.selectedEstimatesList.filter(item => {
      if (item.objectID) {
        return item.objectID === docId
      } else {
        return item.id === docId
      }

    });
    return filtered.length > 0
  };

  estimatesSelect = (selected, data) => {
    let selectedList;
    if (selected) {
      selectedList = [...this.state.selectedEstimatesList, data];
    } else {
      selectedList = this.state.selectedEstimatesList.filter( est => {
        if (est.objectID) {
          return est.objectID !== data.objectID
        } else {
          return est.id !== data.id
        }
      });
    }

    this.setState({
      selectedEstimatesList: selectedList
    })
  }

  estimatesSelectAll = (selected) => {
    let selectedList = [];
    if (selected === true) {
      this.state.dbEstimates.forEach((data,index) => {
        selectedList.push(data);
      });
      this.setState({
        selectedEstimatesList: selectedList
      }, () => {
      })
    } else {
      this.setState({
        selectedEstimatesList: selectedList
      });
    }
  };

  formatDate = (date = new Date())=> {
    const year = date.toLocaleString('jp', {year: 'numeric'});
    const month = date.toLocaleString('jp', {
      month: '2-digit',
    });
    const day = date.toLocaleString('jp', {day: '2-digit'});
    const hour = date.toLocaleString('jp', {hour: '2-digit'});
    const min = date.toLocaleString('jp', {minute: '2-digit'});
    const second = date.toLocaleString('jp', {second: '2-digit'});
    let dateFormatted = year + '-'+ month + '-' + day + ' ' + hour.substring(0,2) + '' + (min * 1 < 10 ? '0'+'' + min : min) + '' + (second * 1 < 10 ? '0'+'' + second : second);
    return dateFormatted
  }

  downloadSelectedEstimates = async () => {
    this.setState({downloading: true})

    const jszip = new JSZip();

    let promises = this.state.selectedEstimatesList.map( async (data) => {
      if (data && data.file) {
        const fileBlob = await this.props.storage.ref(data.file).getDownloadURL().then( (url) => {
          return fetch(url).then((response) => response.blob());
        })
        const fileName = data.file.split('/').pop();
        jszip.file(fileName, fileBlob);
      }
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
    await promises;
    const blob = await jszip.generateAsync({ type: 'blob' });
    //console.log('jszip', jszip)
    let date = new Date();
    let dateFormatted = this.formatDate(date);
    saveAs(blob, 'Estimates_'+dateFormatted+'.zip');
    this.setState({downloading: false})
  }
  selectSortMainType = (e) => {
    this.setState({
      sortMainType: e.target.value || 'dueDate',
      last: null,
      notMore: false,
      dbEstimates: [],
      algoriaPage: 1
    }, () => {
      if (this.state.algolia) {
        this.getEstimatesA(this.props, true, this.state.searchwordA);
      } else {
        this.getEstimates(this.props, true);
      }
    });
  }


  handleChange = (e,type) => {
  console.log('handleChange e,type',e,type);
    switch (type) {
      case 'sender':
        this.setState({
          sender: e.target.value || ''
        })
        break;
      case 'receiver':
        this.setState({
          receiver: e.target.value || ''
        })
        break;
      case 'sellerReceiver':
        this.setState({
          sellerReceiver: e.target.value || ''
        })
        break;
      case 'confirmer':
        this.setState({
          confirmer: e.target.value || ''
        })
        break;
      case 'title':
        this.setState({
          title: e.currentTarget.value || ''
        })
        break;
      case 'estimateId':
        this.setState({
          estimateId: e.currentTarget.value || ''
        })
        break;
      case 'estimateNo':
        this.setState({
          estimateNo: e.currentTarget.value || ''
        })
        break;

      case 'sentDateStart':
        this.setState({
          sentDateStart: e
        })
        break;
      case 'sentDateEnd':
        this.setState({
          sentDateEnd: e
        })
        break;
      case 'confirmDateStart':
        this.setState({
          confirmDateStart: e
        })
        break;
      case 'confirmDateEnd':
        this.setState({
          confirmDateEnd: e
        })
        break;
      case 'issueDateStart':
        this.setState({
          issueDateStart: e
        })
        break;
      case 'issueDateEnd':
        this.setState({
          issueDateEnd: e
        })
        break;
      case 'endDateStart':
        this.setState({
          endDateStart: e
        })
        break;
      case 'endDateEnd':
        this.setState({
          endDateEnd: e
        })
        break;
      case 'deliveringDateStart':
        this.setState({
          deliveringDateStart: e
        })
        break;
      case 'deliveringDateEnd':
        this.setState({
          deliveringDateEnd: e
        })
        break;
      case 'statusConfirmDateStart':
        this.setState({
          statusConfirmDateStart: e
        })
        break;
      case 'statusConfirmDateEnd':
        this.setState({
          statusConfirmDateEnd: e
        })
        break;
      case 'sellerConfirmDateStart':
        this.setState({
          sellerConfirmDateStart: e
        })
        break;
      case 'sellerConfirmDateEnd':
        this.setState({
          sellerConfirmDateEnd: e
        })
        break;
    }
  }

  filterTypeChange = (e) => {
    let value = e.target.value || 'number';
    // console.log('filterType', value);
    let obj = {
      filterType: value
    };
    if (value === 'sender') {
      obj.sender = this.state.sellerUsers[0].user;
    }
    if (value === 'sellerReceiver') {
      obj.sellerReceiver = this.state.sellerUsers[0].user;
    }
    if (value === 'confirmer') {
      obj.confirmer = this.state.buyerUsers[0].user;
    }
    if (value === 'receiver') {
      obj.receiver = this.state.buyerUsers[0].user;
    }
    this.setState(obj)
  }

  estimatesFilter = () => {
    console.log('estimatesFilter filter');
    this.setState({
      algoriaPage: 1,
      notMore: false,
      algolia: true,
      last: null,
      dbEstimates: [],
      selectedEstimatesList: [],
    }, () => {
      this.getEstimatesA(this.props, true,null);
    })
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
    || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
      || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    if(! kaigawa && ! urigawa){
      return (
        <>
        </>
      )
    }

    const estimates = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    this.state.dbEstimates.forEach((estimate,index) => {

      let created,date;
      let confirmDate = '', endDate='', deliveryDate='';
      if (this.state.algolia) {
        let cDate = new Date(estimate['created']._seconds * 1000 + estimate['created']._nanoseconds/1000000);
        created = cDate.getFullYear() + '/'
            + n(cDate.getMonth()+1) + '/'
            + n(cDate.getDate()) + ' '
            + n(cDate.getHours()) + ":"
            + n(cDate.getMinutes());

        let dDate = new Date(estimate['date']._seconds * 1000 + estimate['date']._nanoseconds/1000000);
        date = dDate.getFullYear() + '/'
            + n(dDate.getMonth()+1) + '/'
            + n(dDate.getDate());

        if (estimate['confirmDate']) {
          let coDate = new Date(estimate['confirmDate']._seconds * 1000 + estimate['confirmDate']._nanoseconds/1000000);
          confirmDate = coDate.getFullYear() + '/'
              + n(coDate.getMonth()+1) + '/'
              + n(coDate.getDate()) + ' '
              + n(coDate.getHours()) + ":"
              + n(coDate.getMinutes());
        }

        if (estimate['endDate']) {
          let eDate = new Date(estimate['endDate']._seconds * 1000 + estimate['endDate']._nanoseconds/1000000);
          endDate = eDate.getFullYear() + '/'
              + n(eDate.getMonth()+1) + '/'
              + n(eDate.getDate())
        }
        if (estimate['deliveryDate']) {
          let dDate = new Date(estimate['deliveryDate']._seconds * 1000 + estimate['deliveryDate']._nanoseconds/1000000);
          deliveryDate = dDate.getFullYear() + '/'
              + n(dDate.getMonth()+1) + '/'
              + n(dDate.getDate())
        }
      } else {
        created = estimate['created'].toDate().getFullYear() + '/'
            + n(estimate['created'].toDate().getMonth()+1) + '/'
            + n(estimate['created'].toDate().getDate()) + ' '
            + n(estimate['created'].toDate().getHours()) + ":"
            + n(estimate['created'].toDate().getMinutes());

        date = estimate['date'].toDate().getFullYear() + '/'
            + n(estimate['date'].toDate().getMonth()+1) + '/'
            + n(estimate['date'].toDate().getDate());

        if (estimate['confirmDate']) {
          confirmDate = estimate['confirmDate'].toDate().getFullYear() + '/'
              + n(estimate['confirmDate'].toDate().getMonth()+1) + '/'
              + n(estimate['confirmDate'].toDate().getDate()) + ' '
              + n(estimate['confirmDate'].toDate().getHours()) + ":"
              + n(estimate['confirmDate'].toDate().getMinutes());
        }

        if (estimate['endDate']) {
          endDate = estimate['endDate'].toDate().getFullYear() + '/'
              + n(estimate['endDate'].toDate().getMonth()+1) + '/'
              + n(estimate['endDate'].toDate().getDate())
        }

        if (estimate['deliveryDate']) {
          deliveryDate = estimate['deliveryDate'].toDate().getFullYear() + '/'
            + n(estimate['deliveryDate'].toDate().getMonth()+1) + '/'
            + n(estimate['deliveryDate'].toDate().getDate())
        }
      }

      let visited = '';
      let apply = '';
      let toVisited = '';

      // console.log(urigawa, kaigawa, estimate['created']['seconds'] < 1664575298)

      if ( urigawa ){
        if((estimate['created']['seconds'] < 1664575298 && estimate['visited'] && estimate['visited'][estimate['toUser']]) || estimate['confirmDate']) {
          visited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
        } else {
          visited = <MDBChip bgColor="danger-color " text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }
        if(estimate['apply'] && Object.keys(estimate['apply']).length > 0) {
          const applyUserName = estimate['applyUserName'] || ''

          let applyDate = '';
          if (this.state.algolia) {
            if (estimate['applyDate']) {
              let aDate = new Date(estimate['applyDate']._seconds * 1000 + estimate['applyDate']._nanoseconds/1000000);
              applyDate = aDate.getFullYear() + '/'
                  + n(aDate.getMonth()+1) + '/'
                  + n(aDate.getDate()) + ' '
                  + n(aDate.getHours()) + ":"
                  + n(aDate.getMinutes())
            }
          } else {
            if (estimate['applyDate']) {
              applyDate = estimate['applyDate'].toDate().getFullYear() + '/'
                  + n(estimate['applyDate'].toDate().getMonth()+1) + '/'
                  + n(estimate['applyDate'].toDate().getDate()) + ' '
                  + n(estimate['applyDate'].toDate().getHours()) + ":"
                  + n(estimate['applyDate'].toDate().getMinutes())
            }
          }


          apply = <div className={"text-nowrap"}>
            {applyUserName}
            {applyUserName && <br/>}
            <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">受注済</MDBChip>
            <br/>
            {applyDate}
          </div>;
        } else if(estimate['reject'] && Object.keys(estimate['reject']).length > 0) {
          const rejectUserName = estimate['rejectUserName'] || ''

          let rejectDate = '';

          if (this.state.algolia) {
            if (estimate['rejectDate']) {
              let rDate = new Date(estimate['rejectDate']._seconds * 1000 + estimate['rejectDate']._nanoseconds/1000000);
              rejectDate = rDate.getFullYear() + '/'
                  + n(rDate.getMonth()+1) + '/'
                  + n(rDate.getDate()) + ' '
                  + n(rDate.getHours()) + ":"
                  + n(rDate.getMinutes())
            }
          } else {
            if (estimate['rejectDate']) {
              rejectDate = estimate['rejectDate'].toDate().getFullYear() + '/'
                  + n(estimate['rejectDate'].toDate().getMonth()+1) + '/'
                  + n(estimate['rejectDate'].toDate().getDate()) + ' '
                  + n(estimate['rejectDate'].toDate().getHours()) + ":"
                  + n(estimate['rejectDate'].toDate().getMinutes())
            }
          }


          apply = <div className={"text-nowrap"}>
            {rejectUserName}
            <br/>
            <MDBChip bgColor=" pink lighten-2" text="white" className="text-nowrap disabled">却下</MDBChip>
            <br/>
            {rejectDate}
          </div>;
        } else if(estimate['delete']) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled gray-background">削除済</MDBChip>;
        } else { //if(estimate['wait'] && Object.keys(estimate['wait']).length > 0)
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">未受注</MDBChip>;
        }
      }

      if ( kaigawa ){
        if(this.props.user.authorizer){
          if(estimate['visited'] && estimate['visited'][estimate['toUser']] || estimate['confirmDate']) {
            toVisited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
          } else {
            toVisited = <MDBChip bgColor="danger-color disabled" text="white" className="text-nowrap disabled">未確認</MDBChip>;
          }
        }


        if((estimate['created']['seconds'] < 1664575298 && estimate['visited'] && estimate['visited'][this.props.user.id]) || estimate['confirmDate']) {
          visited = <MDBChip className="text-nowrap disabled" disabled>確認済</MDBChip>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }

        if(estimate['apply'] && Object.keys(estimate['apply']).length > 0) {
          const applyUserName = estimate['applyUserName'] || ''

          let applyDate = '';
          if (this.state.algolia) {
            if (estimate['applyDate']) {
              let aDate = new Date(estimate['applyDate']._seconds * 1000 + estimate['applyDate']._nanoseconds/1000000);
              applyDate = aDate.getFullYear() + '/'
                  + n(aDate.getMonth()+1) + '/'
                  + n(aDate.getDate()) + ' '
                  + n(aDate.getHours()) + ":"
                  + n(aDate.getMinutes())
            }
          } else {
            if (estimate['applyDate']) {
              applyDate = estimate['applyDate'].toDate().getFullYear() + '/'
                  + n(estimate['applyDate'].toDate().getMonth()+1) + '/'
                  + n(estimate['applyDate'].toDate().getDate()) + ' '
                  + n(estimate['applyDate'].toDate().getHours()) + ":"
                  + n(estimate['applyDate'].toDate().getMinutes())
            }
          }

          apply = <div className={"text-nowrap"}>
            {applyUserName}
            {applyUserName && <br/>}
            <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">発注済</MDBChip>
            <br/>
            {applyDate}
          </div>;
        } else if(estimate['reject'] && Object.keys(estimate['reject']).length > 0) {
          const rejectUserName = estimate['rejectUserName'] || ''

          let rejectDate = '';
          if (this.state.algolia) {
            if (estimate['rejectDate']) {
              let rDate = new Date(estimate['rejectDate']._seconds * 1000 + estimate['rejectDate']._nanoseconds/1000000);
              rejectDate = rDate.getFullYear() + '/'
                  + n(rDate.getMonth()+1) + '/'
                  + n(rDate.getDate()) + ' '
                  + n(rDate.getHours()) + ":"
                  + n(rDate.getMinutes())
            }
          } else {
            if (estimate['rejectDate']) {
              rejectDate = estimate['rejectDate'].toDate().getFullYear() + '/'
                  + n(estimate['rejectDate'].toDate().getMonth()+1) + '/'
                  + n(estimate['rejectDate'].toDate().getDate()) + ' '
                  + n(estimate['rejectDate'].toDate().getHours()) + ":"
                  + n(estimate['rejectDate'].toDate().getMinutes())
            }
          }


          apply = <div className={"text-nowrap"}>
            {rejectUserName}
            <br/>
            <MDBChip bgColor=" pink lighten-2" text="white" className="text-nowrap disabled">却下</MDBChip>
            <br/>
            {rejectDate}
          </div>;
        } else { //if(estimate['wait'] && Object.keys(estimate['wait']).length > 0)
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">未発注</MDBChip>;
        }
      }

      let bold;
      if (urigawa) {
        bold = estimate['readSeller'] === false
      }

      if (kaigawa) {
        bold = estimate['readBuyer'] === false
      }
      let estId = estimate.objectID ? estimate.objectID : estimate.id
      let checked = this.estimatesIsSelected(estId);
      let data = {
        'check':<div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} id={"checkbox"+index} onChange={(e) => this.estimatesSelect(e.target.checked, estimate)} /> </div>,
        '見積番号':<span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{estimate['number']}<br/>{estimate['id']}</span>,
        '送信者':<span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{estimate['fromUserName']}<br/>{created}<br/>{estimate['toUserName']}</span>,
        '見積受取者':<span className="text-nowrap">{estimate['confirmUserName'] && <><span className="text-nowrap">{estimate['confirmUserName']}</span><br/></>}{visited}<br/>{confirmDate}</span>,
        '見積タイトル':  <div style={{fontWeight: bold ? 'bold' : ''}}>{estimate['title']}</div>,
        '発行日': <span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{date}<br/>{endDate}</span>,
        '納品日': <span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{deliveryDate}</span>,
        'ステータス': <span className={'text-nowrap'}>{apply}</span>,
      };

      if ( kaigawa ){
        let urigawaConfirmUserName = estimate['urigawaConfirmUserName'] || '';
        let urigawaConfirmDate = '';

        if (this.state.algolia) {
          if (estimate['urigawaConfirmDate']) {
            let ucDate = new Date(estimate['urigawaConfirmDate']._seconds * 1000 + estimate['urigawaConfirmDate']._nanoseconds/1000000);
            urigawaConfirmDate = ucDate.getFullYear() + '/'
                + n(ucDate.getMonth()+1) + '/'
                + n(ucDate.getDate()) + ' '
                + n(ucDate.getHours()) + ":"
                + n(ucDate.getMinutes())
          }
        } else {
          if (estimate['urigawaConfirmDate']) {
            urigawaConfirmDate = estimate['urigawaConfirmDate'].toDate().getFullYear() + '/'
                + n(estimate['urigawaConfirmDate'].toDate().getMonth()+1) + '/'
                + n(estimate['urigawaConfirmDate'].toDate().getDate()) + ' '
                + n(estimate['urigawaConfirmDate'].toDate().getHours()) + ":"
                + n(estimate['urigawaConfirmDate'].toDate().getMinutes())
          }
        }

        data['a'] = estimate['apply'] ? estimate['urigawaConfirmDate'] ? <div className={'text-nowrap'}>
              {urigawaConfirmUserName}
              <br/>
              <MDBChip
                  size="sm4"
                  className="text-nowrap disabled"
              >
                受注確認済
              </MDBChip>
              <br/>
              {urigawaConfirmDate}
            </div> :
          <MDBChip
              bgColor="danger-color"
              text="white"
              size="sm4"
              className="text-nowrap disabled"
          >
            受注未確認
          </MDBChip> : '';
        data['b'] = <StorageLink src={estimate['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={estimate.id} data-index={index} onClick={this.openFile} >表示</MDBChip></StorageLink>;
        data['操作'] = ''

        //TODO: if all user can approve enable this line
        // if(estimate['apply'] && Object.keys(estimate['apply']).length > 0) {
        // } else if(estimate['reject'] && Object.keys(estimate['reject']).length > 0) {
        // } else {
        //   data['操作'] = (
        //       <div className="text-nowrap">
        //         <MDBChip bgColor="default-color" text="white" className="text-nowrap" data-id={estimate.id} data-title={estimate.title} onClick={this.applyFile}>承認</MDBChip>
        //         <br />
        //         <MDBChip bgColor="warning-color-dark" text="white" className="text-nowrap" data-id={estimate.id} data-title={estimate.title} onClick={this.rejectFile}>却下</MDBChip>
        //       </div>
        //   )
        // }

        if(this.props.user.authorizer || estimate['toUser'] === this.props.user.id){
          if(estimate['apply'] && Object.keys(estimate['apply']).length > 0) {
          } else if(estimate['reject'] && Object.keys(estimate['reject']).length > 0) {
          } else {
            data['操作'] = (
              <div className="text-nowrap">
              <MDBChip bgColor="default-color" text="white" className="text-nowrap" data-id={estimate.id} data-title={estimate.title} onClick={this.applyFile}>承認</MDBChip>
              <br />
              <MDBChip bgColor="warning-color-dark" text="white" className="text-nowrap" data-id={estimate.id} data-title={estimate.title} onClick={this.rejectFile}>却下</MDBChip>
              </div>
            )
          }
        }else{
          data['a'] = estimate['urigawaConfirmDate'] ? <div className={'text-nowrap'}>
                {urigawaConfirmUserName}
                <br/>
                <MDBChip
                    size="sm4"
                    className="text-nowrap disabled"
                >
                  受注確認済
                </MDBChip>
                <br/>
                {urigawaConfirmDate}
              </div> :
              <MDBChip
                  bgColor="danger-color"
                  text="white"
                  size="sm4"
                  className="text-nowrap disabled"
              >
                受注未確認
              </MDBChip>;
          data['b'] = <StorageLink src={estimate['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={estimate.id} data-index={index} onClick={this.openFile} >表示</MDBChip></StorageLink>;
          if( (estimate['wait'] && Object.keys(estimate['wait']).length > 0)
            || (estimate['apply'] && Object.keys(estimate['apply']).length > 0)
          ) {
          } else {
            if(estimate['toUser']==this.props.user.id){
              data['操作'] = <MDBChip bgColor="default-color" text="white" className="text-nowrap" data-id={estimate.id} data-title={estimate.title} onClick={this.waitFile}>承認依頼</MDBChip>
            }
          }
        }
      }

      if (urigawa) {
        data['a'] = <StorageLink src={estimate['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={estimate.id} data-index={index} onClick={this.openFile} >表示</MDBChip></StorageLink>

        let urigawaConfirmUserName = estimate['urigawaConfirmUserName'] || '';

        let urigawaConfirmDate='';
        if (this.state.algolia) {
          if (estimate['urigawaConfirmDate']) {
            let ucDate = new Date(estimate['urigawaConfirmDate']._seconds * 1000 + estimate['urigawaConfirmDate']._nanoseconds/1000000);
            urigawaConfirmDate = ucDate.getFullYear() + '/'
                + n(ucDate.getMonth()+1) + '/'
                + n(ucDate.getDate()) + ' '
                + n(ucDate.getHours()) + ":"
                + n(ucDate.getMinutes())
          }
        } else {
          if (estimate['urigawaConfirmDate']) {
            urigawaConfirmDate = estimate['urigawaConfirmDate'].toDate().getFullYear() + '/'
                + n(estimate['urigawaConfirmDate'].toDate().getMonth()+1) + '/'
                + n(estimate['urigawaConfirmDate'].toDate().getDate()) + ' '
                + n(estimate['urigawaConfirmDate'].toDate().getHours()) + ":"
                + n(estimate['urigawaConfirmDate'].toDate().getMinutes())
          }
        }

        let urigawaConfirmStatus = estimate['urigawaConfirmDate'] ? <MDBChip
                bgColor="default-color"
                text="black"
                size="sm4"
                className="text-nowrap gray-square disabled"
            >
              受注確認済
            </MDBChip> :
            <MDBChip
                bgColor="default-color"
                text="white"
                size="sm4"
                className="text-nowrap green-square"
                data-id={estimate.id}
                data-index={index}
                onClick={this.urigawaConfirm}
            >
              受注確認
            </MDBChip>;

        data['b'] = estimate['apply'] ? <div className={"text-nowrap"}>
          {urigawaConfirmUserName}
          {urigawaConfirmUserName && <br/>}
          {urigawaConfirmStatus}
          <br/>
          {urigawaConfirmDate}
        </div> : ''

        data['c'] = (!estimate['apply'] && !estimate['reject'] && !estimate['delete'] ) ? <div style={{textAlign: 'center'}}>
          <MDBBtn rounded flat size="sm"
                  data-id={estimate.id}
                  data-title={estimate['title']}
              onClick={this.delete}
          >
            <MDBIcon
                icon="trash"
                size={'2x'}
                className="custom-text-grey"/>
          </MDBBtn>
        </div>: ''
      }
      estimates.push(data);

    });



    const data = {
      columns: [
        {
          label: <div style={{width: 50}}><MDBInput label=" " type="checkbox" id="checkboxAll" onChange={(e) => this.estimatesSelectAll(e.target.checked)} /></div>,
          field: 'check',
          sort: 'asc',
          width: 50
        },
        {
          label: <div><row>見積番号</row><br/><row>見積書ID</row></div>,
          field: '見積番号',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>送信者</row><br/><row>送信日時</row><br/><row>見積受取者</row></div>,
          field: '送信者',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>見積確認者</row><br/><row>確認日時</row></div>,
          field: '見積受取者',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: '見積タイトル',
          field: '見積タイトル',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>発行日</row><br/><row>有効期限</row></div>,
          field: '発行日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: '納品日',
          field: '納品日',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: <div><row>ステータス</row><br/><row>確定日時</row></div>,
          field: 'ステータス',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: (urigawa) ? '詳細' : <div><row>受注確認者</row><br/><row>確認日時</row></div>,
          field: 'a',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: (urigawa) ? <div><row>受注確認者</row><br/><row>確認日時</row></div> : '詳細',
          field: 'b',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: (urigawa) ? '削除' : '操作',
          field: 'c',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
      ],
      rows: estimates
    }

    // if(kaigawa) {
    //   data.columns.push({
    //     label: '操作',
    //     field: '操作',
    //     sort: 'disabled',
    //     width: 50,
    //     minimal: 'sm'
    //   });
    //
    // }

    let selectOption = <>
      <option value={1}>送信済一覧</option>
      <option value={2}>削除済一覧</option>
      <option value={3}>すべて</option>
    </>

    return (
      <>
      <h2>工事見積書一覧</h2>

        <div style={{display:'flex',flexDirection:'column',flex:1,justifyContent:'flex-start'}}>
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                <div style={{margin:0}}>
                  <select style={{height:'38px'}} className="browser-default custom-select" value={this.state.filterType} onChange={(e) => this.filterTypeChange(e)}>
                    <option value={'estimateNo'}>見積番号</option>
                    <option value={'estimateId'}>見積書ID</option>
                    <option value={'sender'}>送信者</option>
                    <option value={'sentDate'}>送信日時</option>
                    <option value={'receiver'}>見積受取者</option>
                    <option value={'confirmer'}>見積確認者</option>
                    <option value={'confirmDate'}>確認日時</option>
                    <option value={'title'}>見積タイトル</option>
                    <option value={'issueDate'}>発行日</option>
                    <option value={'endDate'}>有効期限</option>
                    <option value={'deliveringDate'}>納品日</option>
                    <option value={'statusConfirmDate'}>ステータス確定日時</option>
                    <option value={'sellerReceiver'}>受注確認者</option>
                    <option value={'sellerConfirmDate'}>販売側確認日時</option>
                  </select>
                </div>
                {this.state.filterType === 'issueDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"issueDate"}
                            className={"form-control"}
                            selected={this.state.issueDateStart}
                            onChange={(e) => this.handleChange(e,'issueDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"issueDate"}
                            className={"form-control"}
                            selected={this.state.issueDateEnd}
                            onChange={(e) => this.handleChange(e,'issueDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'endDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"endDate"}
                            className={"form-control"}
                            selected={this.state.endDateStart}
                            onChange={(e) => this.handleChange(e,'endDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"endDate"}
                            className={"form-control"}
                            selected={this.state.endDateEnd}
                            onChange={(e) => this.handleChange(e,'endDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'deliveringDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"deliveringDate"}
                            className={"form-control"}
                            selected={this.state.deliveringDateStart}
                            onChange={(e) => this.handleChange(e,'deliveringDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"deliveringDate"}
                            className={"form-control"}
                            selected={this.state.deliveringDateEnd}
                            onChange={(e) => this.handleChange(e,'deliveringDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'statusConfirmDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"statusConfirmDate"}
                            className={"form-control"}
                            selected={this.state.statusConfirmDateStart}
                            onChange={(e) => this.handleChange(e,'statusConfirmDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"statusConfirmDate"}
                            className={"form-control"}
                            selected={this.state.statusConfirmDateEnd}
                            onChange={(e) => this.handleChange(e,'statusConfirmDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'sellerConfirmDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"sellerConfirmDate"}
                            className={"form-control"}
                            selected={this.state.sellerConfirmDateStart}
                            onChange={(e) => this.handleChange(e,'sellerConfirmDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"sellerConfirmDate"}
                            className={"form-control"}
                            selected={this.state.sellerConfirmDateEnd}
                            onChange={(e) => this.handleChange(e,'sellerConfirmDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'sentDate' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"sentDate"}
                            className={"form-control"}
                            selected={this.state.sentDateStart}
                            onChange={(e) => this.handleChange(e,'sentDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"sentDate"}
                            className={"form-control"}
                            selected={this.state.sentDateEnd}
                            onChange={(e) => this.handleChange(e,'sentDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'confirmDate' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"confirmDate"}
                            className={"form-control"}
                            selected={this.state.confirmDateStart}
                            onChange={(e) => this.handleChange(e,'confirmDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"confirmDate"}
                            className={"form-control"}
                            selected={this.state.confirmDateEnd}
                            onChange={(e) => this.handleChange(e,'confirmDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {(this.state.filterType === 'estimateNo') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={(e) => this.handleChange(e,'estimateNo')}
                          value={this.state.estimateNo}
                      />
                    </div>
                }
                {(this.state.filterType === 'estimateId') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={(e) => this.handleChange(e,'estimateId')}
                          value={this.state.estimateId}
                      />
                    </div>
                }
                {(this.state.filterType === 'title') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={(e) => this.handleChange(e,'title')}
                          value={this.state.title}
                      />
                    </div>
                }
                {this.state.filterType === 'sender' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.sender} onChange={(e) => this.handleChange(e,'sender')}>
                        {this.state.sellerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                {this.state.filterType === 'sellerReceiver' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.sellerReceiver} onChange={(e) => this.handleChange(e,'sellerReceiver')}>
                        {this.state.sellerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                {this.state.filterType === 'confirmer' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.confirmer} onChange={(e) => this.handleChange(e,'confirmer')}>
                        {this.state.buyerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                {this.state.filterType === 'receiver' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.receiver} onChange={(e) => this.handleChange(e,'receiver')}>
                        {this.state.buyerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                <div>
                  <MDBBtn
                      color="default"
                      type="submit"
                      style={{width: '90px', margin: 0, height: 38, padding: '0.64rem 1.14rem'}}
                      onClick={this.estimatesFilter}
                  >検　索</MDBBtn>
                </div>
              </div>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'space-between',alignItems:'center'}}>
            <div>
            <MDBBtn size={'sm'} color="default" style={{padding: 10, height: 38, width: 200,margin:0}}  disabled={this.state.selectedEstimatesList.length > 0 ? this.state.downloading : true} onClick={() => this.downloadSelectedEstimates()}>
              {this.state.downloading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
              {!this.state.downloading && <MDBIcon icon="download" style={{marginRight: 10}} />}
              選択したPDF一括ダウンロード
            </MDBBtn>
        </div>
          <div style={{alignItems: 'center',color: '#495057',display:'flex',justifyContent:'flex-end',flexDirection:'row'}}>
            <div style={{margin: 0,width:252}}>
              <select style={{height:'38px'}} className="browser-default custom-select" value={this.state.sortMainType} onChange={(e) => this.selectSortMainType(e)}>
                <option value={'dueDate'}>発行日順で並べ替え</option>
                <option value={'endDate'}>有効期限で並べ替え</option>
                <option value={'created'}>送信日時で並べ替え</option>
                <option value={'deliveryDate'}>納品日で並べ替え</option>
              </select>
            </div>
            <div className="form-check form-check-inline" style={{marginLeft: 10}}>
              <input type="radio" className="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample"
                     checked={this.state.sortType === 'asc'} onClick={() => this.sortChange('asc')}/>
              <label className="form-check-label" htmlFor="materialInline1">昇順</label>
            </div>

            <div className="form-check form-check-inline">
              <input type="radio" className="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample"
                     checked={this.state.sortType === 'desc'} onClick={() => this.sortChange('desc')}/>
              <label className="form-check-label" htmlFor="materialInline2">降順</label>
            </div>

            {
                urigawa && <>
                  <div className="form-check form-check-inline" style={{marginLeft: 20}}>
                    表示
                  </div>

                  <div className="form-check form-check-inline">
                    <select className="browser-default custom-select" value={this.state.estimatesType} onChange={(e) => this.selectEstimatesType(e)}>
                      {selectOption}
                    </select>
                  </div>
                </>
            }

            <div >
              <MDBBtn
                  color="default" style={{width: '90px', height: 38, padding: '0.64rem 1.14rem',marginRight:0}} flat={true} outline={true} onClick={() => this.clearFilter()}>
                リセット
              </MDBBtn>
            </div>
          </div>
        </div>
        </div>
        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>
      <MDBTable responsive btn small id={"div2"}>
        <MDBTableHead color="primary-color" textWhite columns={data.columns} />
        <MDBTableBody rows={data.rows} />
      </MDBTable>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

      <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
          <MDBModalHeader toggle={this.applyClose}
                  className="text-center default-color white-text"
                  titleClass="w-100 font-weight-bold"
          >承認(発注確定)</MDBModalHeader>
          <MDBModalBody>
          {this.state.applyTitle} の工事見積書内容での発注を承認しますか？<br />
          承認すると発注が確定し、販売会社に通知されます。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.applyClose} color="default" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectClose}>
          <MDBModalHeader toggle={this.rejectClose}
                  className="text-center warning-color-dark white-text"
                  titleClass="w-100 font-weight-bold"
          >却下（仕入先の工事見積書発行者へ差戻）</MDBModalHeader>
          <MDBModalBody>
            「{this.state.rejectTitle}」の工事見積書内容を却下（仕入先の工事見積書発行者へ差戻）しますか？
            却下すると仕入先の工事見積書発行者に差し戻され、あらためて工事見積書発行依頼をする必要があります。
            本工事見積書での注文はできなくなります。<br />
            よろしいですか？
          {/*{this.state.rejectTitle} の工事見積書内容を却下（担当者に差戻）しますか？<br />*/}
          {/*却下すると担当者に差し戻され、担当者から販売会社に再見積依頼をする必要があります。<br />*/}
          {/*本工事見積書での注文はできなくなります。<br />*/}
          {/*よろしいですか？*/}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.rejectClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.rejectClose} color="danger" data-id={this.state.rejectId}>却下（仕入先に差戻）する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.waitModal} toggle={this.waitClose}>
          <MDBModalHeader toggle={this.waitClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
            {this.state.waitTitle} の工事見積書から発注するために、上長に承認依頼をします。<br />
            よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.waitClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.waitClose} color="danger" data-id={this.state.waitId} >上長に承認依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.deleteModal} toggle={this.deleteClose}>
          <MDBModalHeader toggle={this.deleteClose}>工事見積書削除</MDBModalHeader>
          <MDBModalBody>
            工事見積書「{this.state.deleteTitle}」を削除します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.deleteClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.deleteClose} color="danger" data-id={this.state.deleteId} >削除</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

      </>
    )

  }
}

export default EstimatesTableList;
