import React from 'react';
import firebase from '../../firebase'

export const db = firebase.firestore();


class SDSLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
    }
  }

  getDownloadURL = () => {
    if (this.props.src) {
      let sdsRef = db.collection('sds').doc(this.props.src);
      let getDoc = sdsRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such sds document!')
          } else {
            let sds = doc.data();
            let gsReference = this.props.storage.ref(sds['file'])
            .getDownloadURL()
            .then( (url) => {
              this.setState({file: url})
            } )
          }
        })
        .catch(err => {
          console.log('Error getting user document', err)
        })
  
    }
  }

  componentDidMount() {
    this.getDownloadURL();
  }

  render() {
    if (this.state.file==='') {
      return <></>
    }

    return (
      <a href={this.state.file} target={this.props.target}>{this.props.children}</a>
    )
  }
}

SDSLink.defaultProps = {
  target: "_blank",
}

export default SDSLink;