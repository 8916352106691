import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBIcon,
    MDBCollapse
} from "mdbreact";

class SupportUpdateDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      group: {}
    }
  }

  groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  componentDidMount() {
    let group = this.groupBy(this.props.data['update-list'], 'date');
    let data = this.props.data['update-list'].filter((v) => v.id === this.props.id*1);
    this.setState({data: data[0], group: group})
    console.log(group)
  }

  render() {
    return (
      <>
        <MDBContainer>
          <MDBRow style={{height: 100, background: '#2e75b6', color: '#ffffff', alignItems: 'center', paddingLeft: 20, paddingRight: 20, justifyContent: 'space-between'}}>
            <Link to={'/support'} style={{color: '#ffffff'}}>
              <img src="/logo.png" className="img-fluid" alt="HOME" style={{height: 22, marginRight: 10}} />
              サポートサイト
            </Link>
            <div>
              <Link to={'/support#operation'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>基本的な操作</Link>
              <Link to={'/support#faq'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>よくある質問</Link>
              <Link to={'/support#information'} style={{padding: 10, cursor: 'pointer', color: '#ffffff'}}>お役立ち情報</Link>
            </div>
          </MDBRow>
          <MDBRow style={{backgroundColor: '#f5f5f5', padding: 50}}>
            <MDBCol xs="12" sm="12" md="12" lg="12">
              <div style={{backgroundColor: '#ffffff', padding: 40}}>
                {this.state.data['data']}
              </div>
            </MDBCol>
            {/*TODO: ognoonii group*/}
            {/*<MDBCol xs="12" sm="12" md="2" lg="2">*/}
            {/*  <div style={{backgroundColor: '#ffffff', padding: '20px 10px'}}>*/}
            {/*    {Object.keys(this.state.group).map((v) => {*/}
            {/*      return <div style={{margin: 10}}><Link to={'/supportUpdateGroup/'+v}>{v}</Link></div>*/}
            {/*    })}*/}
            {/*  </div>*/}
            {/*</MDBCol>*/}
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}
export default SupportUpdateDetail;

