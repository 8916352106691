import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

import Upload from "../Upload/Upload";


import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';

import "react-datepicker/dist/react-datepicker.css";
import "./Catalog.css";

import firebase from "../../firebase"

registerLocale('ja', ja);
setDefaultLocale('ja')

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      title: '',
      titlekana: '',
      files: [],
      modal: false,
      errModal: false,
    }
  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleTitleChange = (e) => {
    this.setState({
      title: e.currentTarget.value
    });
  }
  handleTitleKanaChange = (e) => {
    this.setState({
      titlekana: e.currentTarget.value
    });
  }

  modalClose = () => {
    this.setState({
      modal: false,
      title: '',
      toUser: '',
      files: [],
    })
    this.refs.UploadComponent.resetFile();
  }
  errModalClose = () => {
    this.setState({
      errModal: false
    })
  }



  fileUpload = (e) => {
    e.preventDefault();

    console.log("fileUpload",e);
    console.log(this.state.files[0]);


    if(this.state.files.length > 0){
      const file = this.state.files[0];

      //this.props.storage.refFromURL(this.props.src);
      console.log(file.name);

      var fileRef = this.props.storage.ref().child('catalog/' + '/' + this.props.user.id + '/' + file.name);

      fileRef.getDownloadURL().then((onResolve) => {
        console.log("Same File Exist");
        this.setState({
          errModal: true,
        })
      }, (onReject) => {
        console.log(fileRef.location.bucket);
        console.log(fileRef.location.path);
  
        fileRef.put(file).then(function(snapshot) {
          console.log('Uploaded a blob or file!');
        });      
        this.dataSet(fileRef);

      });
    }
  }

  dataSet = (snapshot) => {
    
    console.log("dataSet");
    console.log(this.state.startDate);
    console.log(this.state.startDate2);

    let catalogRef = this.props.db.collection('catalog').doc();
    let dueDate = new Date(this.state.startDate);

    let setWithMerge = catalogRef.set({
      title: this.state.title,
      titlekana: this.state.titlekana,
      date: firebase.firestore.Timestamp.fromDate(this.state.startDate),
      dueDate: dueDate.getTime(), 
      toOrganization: this.props.org.id,
      fromUser: this.props.user.id,
      fromUserName: this.props.user.last_name + this.props.user.first_name,
      fromOrganization: this.props.user.organization,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      file: snapshot.location.path,
    }, { merge: true });
    this.setState({
      modal: true,
    })
  }

  componentDidMount() {
  }

  onFilesAdded = (files) => {
    this.setState({
      files: files
    });
    console.log(files);
  }

  handleInputChange = inputName => value => {
    const nextValue = value;
    this.setState({
        [inputName]: nextValue
    });
  };

  render() {
    const isEnable = (this.state.files.length > 0) 
    && (this.state.startDate !== '')
    && (this.state.title !== '')
    && (this.state.titlekana !== '')

    if ( (this.props.userOrg && this.props.userOrg.reseller) 
      || (this.props.userOrg && this.props.userOrg.maker) 
    ){
    }else{
      return (
        <>
        </>
      )
    }

    return (
      <>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="mb-3">
            <h2>カタログ登録</h2>
            <form>
            <label htmlFor="catalogDate" className="grey-text">
              カタログ発行日付指定
            </label>
            <DatePicker 
              dateFormat="yyyy/MM/dd"
              id="catalogDate"
              className="form-control"
              selected={this.state.startDate}
              onChange={this.handleChange}
              locale="ja-JP"
            />
            <br />

            <label htmlFor="catalogTitle" className="grey-text">
              カタログタイトル
            </label>
            <input
              type="text"
              id="catalogTitle"
              className="form-control"
              name="title"
              onChange={this.handleTitleChange}
              value={this.state.title}
            />
            <br />

            <label htmlFor="catalogTitleKana" className="grey-text">
              カタログタイトル（ふりがな）
            </label>
            <input
              type="text"
              id="catalogTitleKana"
              className="form-control"
              name="title"
              onChange={this.handleTitleKanaChange}
              value={this.state.titlekana}
            />
            <br />

            <label htmlFor="catalogFile" className="grey-text">
              カタログ PDF選択
            </label>
            <Upload onFilesAdded={this.onFilesAdded} ref="UploadComponent" />
            
            {false &&
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="catalogFile"
                  aria-describedby="inputGroupFileAddon01"
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  ファイルを選択してください
                </label>
              </div>
            </div>
            }


            <div className="text-center mt-4">
              <MDBBtn 
                color="default" 
                type="submit" 
                disabled={!isEnable}
                onClick={this.fileUpload}
              >新規カタログ登録</MDBBtn>
            </div>
          </form>

          </MDBCol>
        </MDBRow>

        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>登録完了</MDBModalHeader>
          <MDBModalBody>
            カタログを登録しました
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errModal} toggle={this.errModalClose}>
          <MDBModalHeader className="warning-color-dark white-text" toggle={this.errModalClose}>重複確認</MDBModalHeader>
          <MDBModalBody>
            同名のカタログが存在するので処理を中止しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="orange darken-3" onClick={this.errModalClose}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>


      </MDBContainer>
      </>
    )
  }
}
export default Catalog;

