import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { MDBChip, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, toast } from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"
import { isNumber } from 'util';

import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';

import "react-datepicker/dist/react-datepicker.css";

import "./Cart.css";
import FavoriteCartAllSelect from "../CartSelect/FavoriteCartAllSelect";
import FavoriteCartAllSelectOtasuke from "../CartSelect/FavoriteCartAllSelectOtasuke";

class FavoriteCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartNo: 0,
      carts: [],
      cartName: '',
      cartselect: false,
      modal: false,
      modalSave: false,
      modalDelete: false,
      carriage: 0,
      loading: false,
    }
  }


  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }



    if(this.props.org && collection){
      let fsRef = this.props.db.collection('favorite_set').doc(this.props.user.id).collection('favorite_set').doc(this.props.org.id);
      let fs = await fsRef.get();
      let fsData = fs.data();

      if (!fsData) {
        return;
      }

      let total = 0;
      let updateDate = 1674748800000; //2023-01-27 00:00:00

      // let withDeletedItem = false;
      for (let i=0; i<30; i++) {
        let cart = fsData[i];

        if (cart && cart.items) {
          let items = cart.items;
          delete cart['deletedItem'];
          await Promise.all(items.map(async (item) => {
            let itemSell = await collection.doc(item.id).get();
            let itemSellData = itemSell.data();

            delete item['deletedItem'];
            delete item['deletedText'];

            if (!itemSellData) {
              item.deletedItem = true;
              item.deletedText = '販売終了';
              cart.deletedItem = true;
            } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
              item.deletedItem = true;
              item.deletedText = '要見積';
              cart.deletedItem = true;
            } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
              item.deletedItem = true;
              item.deletedText = '要見積';
              cart.deletedItem = true;
            } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
              let expiration_date = itemSellData['price'][0]['expiration_date'];
              let expDate = expiration_date.toDate();

              if ((expDate.getTime() - this.props.dateNow) < 0) {
                item.deletedItem = true;
                item.deletedText = '要見積';
                cart.deletedItem = true;
              } else {
                item.price = itemSellData['price'][0]['price'];
              }
            } else {
              item.price = itemSellData['price'][0]['price'];
            }

            // console.log(cart)
            // if (itemSellData && itemSellData['price']) {
            //   item.price = itemSellData['price'][0]['price'];
            // } else {
            //   withDeletedItem = true;
            //   cart.items = cart.items.filter((obj) => obj.id !== item.id);
            // }
          }));
        }
      }

      // if (withDeletedItem) {
      //   fsRef.set(fsData, {merge: true});
      // }

      if(fsData[this.state.cartNo] && fsData[this.state.cartNo].items && fsData[this.state.cartNo].items.length){
        let items = fsData[this.state.cartNo].items;
        items.map(async (item) => {
          if (item && !item.deletedItem) {
              total += item.count * item.price;
          }
        });

        this.setState({carts: fsData})
      }

      let carriage_free = 0;
      let carriage = 0;
      if(this.props.org.maker && this.props.userOrg && this.props.userOrg.sellers){
        // メーカーU -> 販社
        this.props.userOrg.sellers.map((seller) => {
          if(seller.organization == this.props.org.id){
            carriage_free = seller.carriage_free ? seller.carriage_free : 0;
            carriage = seller.carriage ? seller.carriage : 0;
          }
        })
      }

      if(this.props.org.reseller && this.props.userOrg){
        // 販社U -> 購入U
        carriage_free = this.props.userOrg.carriage_free ? this.props.userOrg.carriage_free : 0;
        carriage = this.props.userOrg.carriage ? this.props.userOrg.carriage : 0;
      }
      let postage = total < carriage_free ? carriage : 0 ;
      this.setState({carriage: postage})

//       this.props.db.collection('favorite_set').doc(this.props.user.id)
//       .collection('favorite_set').doc(this.props.org.id)
//       .get()
//       .then((doc) => {
//         if(doc.exists) {
//           const data = doc.data();
//           // console.log('carts', data)
//           this.setState({carts: data})
// // console.log('dataaa',data);
//           // 運賃計算
//           let total=0;
//           if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items && this.state.carts[this.state.cartNo].items.length){
//             let items = this.state.carts[this.state.cartNo].items;
//             items.forEach((item, index) => {
//               total += item.count * item.price;
//             });
//           }
//           let carriage_free = 0;
//           let carriage = 0;
//           if(this.props.org.maker && this.props.userOrg && this.props.userOrg.sellers){
//             // メーカーU -> 販社
//             this.props.userOrg.sellers.map((seller) => {
//               if(seller.organization == this.props.org.id){
//                 carriage_free = seller.carriage_free ? seller.carriage_free : 0;
//                 carriage = seller.carriage ? seller.carriage : 0;
//               }
//             })
//           }
//           if(this.props.org.reseller && this.props.userOrg){
//             // 販社U -> 購入U
//             carriage_free = this.props.userOrg.carriage_free ? this.props.userOrg.carriage_free : 0;
//             carriage = this.props.userOrg.carriage ? this.props.userOrg.carriage : 0;
//           }
//           let postage = total < carriage_free ? carriage : 0 ;
//           this.setState({carriage: postage})
//
//         }
//       })
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.org !== prevProps.org || this.props.userOrg !== prevProps.userOrg || this.props.id !== prevProps.id) {
      this.getCarts();
    }

    let cart = 0;
    cart = this.props.id - 1;
    if(isNaN(cart)){
      cart=0;
    }

    // console.log(this.state.cartNo, cart)

    if (this.state.cartNo != cart){
      this.setState({cartNo: cart});
      this.getCarts();
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.getCarts();
  }

  decrease = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("decrease:");
    console.log(itemId);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        if(cart[index]['count']>0){
          cart[index]['count']--;
        }
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  description = (e) => {
    // console.log('ee',e);
    let value = e.currentTarget.value;
    const itemId = e.currentTarget.parentNode.dataset.id;

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['description'] = value;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  increase = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("increase:");
    console.log(itemId);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];

    console.log("carts", carts, cartBase)
    if(!cartBase){
      cartBase={
        items: [],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count']++;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  updateNum = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNum:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    console.log("Number");
    this.setState({
      numupdate: true,
    })

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });
  }

  updateNumSave = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNumSave:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    let carts = {};
    Object.assign(carts, this.state.carts);

    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }


  dellCart = (e) => {
    const itemId = e.currentTarget.dataset.id;
    console.log("dellCart:");
    console.log(itemId);

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
      };
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    let newCart = [];
    cart.forEach((item, index) => {
      if(index != itemId){
        newCart.push(cart[index]);
      }
    });
    carts[this.state.cartNo].items = newCart;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then((doc) => {
          this.getCarts();
        });
  }

  handleOrderNoChange = (e) => {
    this.updateCartData("orderNo",e.currentTarget.value);
  }

  handleCartNameChange = (e) => {
    console.log("cartName",e.currentTarget.value)
    this.updateCartData("cartName", e.currentTarget.value)
  }

  handleToUserChange = (e) => {
    if (e.currentTarget.selectedIndex > 0) {
      this.updateCartData("toUserName",e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name);
      this.updateCartData("toUser",e.currentTarget.value);
    } else {
      this.updateCartData("toUserName",'');
      this.updateCartData("toUser",'');
    }
  }
  handleOrderNoukiChange = (e) => {
    this.updateCartData("orderNouki",e.currentTarget.value);
  }
  handleOrderNoukiDayChange = (e) => {
    this.updateCartData("orderNouki","希望");
    this.updateCartData("orderNoukiDay",e.currentTarget.value);
  }
  handleOrderNoukiDateChange = (e) => {
    this.updateCartData('orderNouki', '日付指定');
    let date = firebase.firestore.Timestamp.fromDate(e);
    this.updateCartData('orderNoukiDate', date);
  }
  handleOrderBasyoChange = (e) => {
    this.updateCartData("orderBasyo",e.currentTarget.value);
  }
  handleOrderZipcodeChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderZipcode",e.currentTarget.value);
  }
  handleOrderAddr1Change = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderAddr1",e.currentTarget.value);
  }
  handleOrderAddr2Change = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderAddr2",e.currentTarget.value);
  }
  handleOrderJigyosyoChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderJigyosyo",e.currentTarget.value);
  }
  handleOrderTantoChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderTanto",e.currentTarget.value);
  }
  handleOrderPhoneChange = (e) => {
    this.updateCartData("orderBasyo","登録住所以外");
    this.updateCartData("orderPhone",e.currentTarget.value);
  }
  handleOrderBikouChange = (e) => {
    this.updateCartData("orderBikou",e.currentTarget.value);
  }

  sendFavoriteCart = (e) => {
    e.preventDefault();
    let self = this;
    this.setState({selectedItem: this.state.carts[this.state.cartNo]}, () => {
      self.setState({cartselect: true});
    });
  }

  showHideCart = (val, cartId) => {
    // console.log(val, cartId)
    this.setState({cartselect: val});
    if (cartId) {
      let props = this.props;
      if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker || props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
        this.props.cProps.history.push("/cart/"+ cartId*1)
      } else {
        this.props.cProps.history.push("/otasuke/cart/"+ cartId*1)
      }
    }

  }

  sendSaveCart = (e) => {
    e.preventDefault();

    this.setState({
      modalSave: true,
    })
  }

  deleteFavoriteCart = (e) => {
    e.preventDefault();
    this.setState({
      modalDelete: true
    })
  }

  addProduct = (e) => {
    // "/items?cn="+ (this.state.cartNo)
    this.saveCart()
    this.props.cProps.history.push("/items?cn="+ (this.state.cartNo))
  }

  saveCart = (e) => {
    // const itemId = e.currentTarget.parentNode.dataset.id;
    // console.log("cartName:", itemId, itemName);

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    // console.log(carts, cartBase)
    if(!cartBase){
      cartBase={
        items:[],
        creatorUser: this.props.user.id,
        creatorUserName: this.props.user.last_name + " " + this.props.user.first_name,
        sendHelpCart: 0
      };
      carts[this.state.cartNo] = cartBase;
    }

    let date = firebase.firestore.Timestamp.fromDate(new Date());
    cartBase.latestUpdate = date;
    carts[this.state.cartNo].latestUpdate = date;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let self = this;
    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: firebase.firestore.Timestamp.fromDate(new Date()),
    }, { merge: true })
        .then(function () {
          self.getCarts();
        })
        .catch(function (error) {
          console.error("Error set document: ", error);
        });



    // //this.getCarts();
    this.setState({
      modalSave: false,
    })
  }

  modalSaveClose = (e) => {
    this.setState({
      modalSave: false,
    })
  }

  modalDeleteClose = (e) => {
    this.setState({
      modalDelete: false
    })

    let deleteCartId = e && e.currentTarget.dataset.id;
    if(deleteCartId){
      let self = this;
      let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
      let cartRef = cartMainRef.collection('favorite_set').doc(this.props.org.id);

      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let setWithMerge = cartRef.set({
        [deleteCartId]: {},
        update: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })
          .then((doc) => {
            console.log('deleted')
            self.getCarts();
            // self.props.cProps.history.push('/otasuke/carts')
          });
    }
  }

  updateCartData = (key, val) => {
    let cartData = {};
    if(this.state.carts[this.state.cartNo]){
      cartData = this.state.carts[this.state.cartNo];
    }
    if(cartData.requested) {
      return;
    }

    cartData[key] = val;
    let carts = this.state.carts;
    carts[this.state.cartNo] = cartData;
    if (!cartData.items) {
      cartData.items = [];
      cartData.created = firebase.firestore.Timestamp.fromDate(new Date());
      cartData.rejected = false;
      cartData.creatorUser = this.props.user.id;
      cartData.creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;
    }

    // console.log(carts, cartData, this.state.cartNo, key, val)

    this.setState({
      carts: carts
    });
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let cart = [];
    let total = 0;
    let postage = 0;
    let taxes = {};
    let taxTotal = 0;

    let cartData = {
      items: [],
      cartName: '',
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderNoukiDate: '',
      orderBasyo: '登録住所',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      requested: false,
    };

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let props = this.props;
    let isBuyer = false;
    if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker || props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      isBuyer = true;
    }

    if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items){ // && this.state.carts[this.state.cartNo].items.length

      Object.assign(cartData, this.state.carts[this.state.cartNo]);
      let items = this.state.carts[this.state.cartNo].items;
      items.forEach((item, index) => {
        let date = item.update.toDate().getFullYear() + '/'
            + n(item.update.toDate().getMonth() + 1) + '/'
            + n(item.update.toDate().getDate())+ ' '
            + n(item.update.toDate().getHours()) + ':'
            + n(item.update.toDate().getMinutes());
        let priceText = <><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</>;
        if (item.deletedItem) {
          priceText = <><span style={{color: 'red'}}>{item.deletedText}</span></>
        }

              cart.push({
                date: date,
                code: item.code,
                name: <Link to={"/items/" + item.id}>{item.name}</Link>,
                maker: item.maker,
                count:
                <div>
                  <div style={{textAlign: 'right', width: '8rem'}}>{priceText}</div>
                  <div className="def-number-input number-input" data-id={index}>
                    <button onClick={this.decrease} className="minus">－</button>
                    <input
                    className="quantity"
                    type="number"
                    value={item.count}
                    onChange={this.updateNum}
                    onBlur={this.updateNumSave}
                    />
                    <button onClick={this.increase} className="plus">＋</button>
                  </div>
                </div>
                ,
                price: <div style={{textAlign: "right"}}>{!item.deletedItem && (item.count * item.price).toLocaleString() + '円'}</div>,
                btn: <div style={{textAlign: "right"}}>
                  <MDBBtn size="sm4" className="text-nowrap"
                          onClick={this.dellCart}
                          data-id={index}
                  >削除</MDBBtn>
                </div>,
              })
        if (!item.deletedItem) {
          if (taxes[item.tax]) {
            taxes[item.tax] += (item.count * item.price * item.tax);
          } else {
            taxes[item.tax] = (item.count * item.price * item.tax);
          }
          taxTotal += parseInt((item.count * item.price * item.tax), 10);
          total += item.count * item.price;
        }

      });
      postage = this.state.carriage;
      total += postage;
    }

    const data = {
      columns: [
        {
          label: '年月日',
          field: 'date',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品コード',
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: "center"}}>金額</div>,
          field: 'price,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: "center"}}>削除</div>,
          field: 'delete',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      rows: cart
    }

    let disable = !(cart.length > 0) || cartData.deletedItem;
    let deleteDisable = !(cart.length > 0);
    let cartName = cartData.cartName

    return (
      <>
        <h2><img src={"/folder-star.png"} className={"folder-star-header"}/>お気に入りセット{this.state.cartNo + 1}</h2>

        <div className="form-group" style={{display: "-webkit-inline-box", margin: "25px 0"}}>
          <label htmlFor="cartName" className="col-form-label" style={{marginRight: 10}}>セット名称</label>
          <input
              type="text"
              className="form-control"
              id="cartName"
              style={{height:'35px', width:'500px'}}
              // placeholder="発注番号"
              onChange={this.handleCartNameChange}
              value={cartData.cartName}
          />
        </div>

        <MDBTable btn responsive>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <div style={{textAlign: 'center'}}>
          {/*<Link to={"/items?cn="+ (this.state.cartNo)}>*/}
            <MDBBtn
                className="text-nowrap"
                onClick={this.addProduct}
            >商品を追加する</MDBBtn>
          {/*</Link>*/}
        </div>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{total.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map(value => {
              return <tr>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>

        <MDBBtn
            className="btn-block"
            color="success"
            disabled={disable}
            onClick={this.sendFavoriteCart}
        >{isBuyer ? 'このセットをカートに追加' : 'このセットをお助けカートに追加'}</MDBBtn>

        <div style={{textAlign: 'center'}}>
          <MDBBtn
              className="text-nowrap custom-button-red"
              color=""
              disabled={deleteDisable}
              onClick={this.deleteFavoriteCart}
          >このセットを削除する</MDBBtn>
          <MDBBtn
              className="text-nowrap"
              disabled={disable && !cartName}
              onClick={this.sendSaveCart}
          >このセットを保存する</MDBBtn>
        </div>

        {isBuyer ?
            <FavoriteCartAllSelect isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} favouriteCartId={this.state.cartNo} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
        :
            <FavoriteCartAllSelectOtasuke isOpen={this.state.cartselect} isOpenEvent={(val, cartId) => this.showHideCart(val, cartId)} favouriteCartId={this.state.cartNo} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
        }


        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>お気に入りセット追加</MDBModalHeader>
          <MDBModalBody>
            お気に入りセット「{cartData.cartName}」をカートに追加します。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose} color={''} className={'custom-button-grey'} size={'sm'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalClose} color={'danger'} size={'sm'} data-id={this.state.cartNo} data-discard={true} >カートを空にして 追加する</MDBBtn>
            <MDBBtn onClick={this.modalClose} size={'sm'} data-id={this.state.cartNo} >カートに追加する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalSave} toggle={this.modalSaveClose}>
          <MDBModalHeader toggle={this.modalSaveClose}>お気に入りセット保存</MDBModalHeader>
          <MDBModalBody>
            お気に入りセット「{cartData.cartName}」を保存します。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalSaveClose} color={''} className={'custom-button-blue'} size={'sm'}>キャンセル</MDBBtn>
            <MDBBtn onClick={this.saveCart} color={'danger'} size={'sm'}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalDelete} toggle={this.modalDeleteClose}>
          <MDBModalHeader toggle={this.modalDeleteClose}>お気に入りセット削除</MDBModalHeader>
          <MDBModalBody>
            お気に入りセット「{cartData.cartName}」を削除します。 よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDeleteClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalDeleteClose} color="danger" data-id={this.state.cartNo} >OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default FavoriteCart;
