import React from 'react';


class StorageLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
    }
  }

  getDownloadURL = () => {
    if (this.props.src) {
      
      let gsReference = this.props.storage.ref(this.props.src)
      .getDownloadURL()
      .then( (url) => {
        this.setState({file: url})
      } )
      
     //this.setState({file: this.props.src})

    }
  }

  componentDidMount() {
    this.getDownloadURL();
  }

  render() {

    return (
      <a href={this.state.file} target={this.props.target}>{this.props.children}</a>
    )
  }
}

StorageLink.defaultProps = {
  target: "_blank",
}

export default StorageLink;