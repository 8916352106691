import React from "react";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBChip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon, MDBCol, MDBRow, MDBInput
} from 'mdbreact';
import StorageLink from './StorageLink'
import firebase from "../../firebase";
import Typesense from "typesense";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import DatePicker from "react-datepicker";
const algoliasearch = require("algoliasearch");
const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_SEARCH_API_KEY
);
const indexReport = client.initIndex("P4Report");

const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);
class ReportTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbReports: [],
      last: null,
      reportPerPage: 12,
      searchwordA: '',
      algoriaPage: 1,
      algolia: false,
      sortType: 'desc',
      reportsType: '1',

      selectedReportList: [],
      downloading: false,
      downloadingAll: false,
      sortMainType: 'dueDate',
      sellerUsers: [],
      buyerUsers: [],
      sentDateStart: null,
      sentDateEnd: null,
      confirmDateStart: null,
      confirmDateEnd: null,
      issueDateStart: null,
      issueDateEnd: null,
      reportNo: '',
      reportId: '',
      title: '',
      sender: '',
      receiver: '',
      confirmer: '',
      filterType: 'reportNo',
    }
  }

  getReportsA = async (props, newOrg, word) => {
    if (this.state.loading) {
      return;
    }

    if(props.org){
    } else{
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    let urigawa = false;
    let indexName = 'P4Report'

    if(props.userOrg && props.userOrg.maker) {
      fromOrg = props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = props.user.organization;
    }
    if (fromOrg == ""){
      return;
    }

    let tempResults = [];

    this.setState({loading: true, algolia: true});
    let filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
    let query_by = '*';
    let q = '*'
    let infix = 'off'
    if (!urigawa) {
      filters += ` && deleted:false`
    } else {
      switch (this.state.reportsType) {
        case '1':
          filters += ` && deleted:false`;
          break;
        case '2':
          filters += ` && deleted:true`;
          break;
        /*default:
          filters += ` && (deleted:true ||  deleted:false)`;
          break;*/
      }
    }
    if (this.state.filterType === 'sentDate' && this.state.sentDateStart && this.state.sentDateEnd) {
      let startTimestamp = new Date(this.state.sentDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.sentDateEnd).setHours(23,59,59)/1000;
      console.log('sentDate search', startTimestamp, endTimestamp);
      filters += ` && created._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'confirmDate' && this.state.confirmDateStart && this.state.confirmDateEnd) {
      let startTimestamp = new Date(this.state.confirmDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.confirmDateEnd).setHours(23,59,59)/1000;
      console.log('confirmDate search', startTimestamp, endTimestamp);
      filters += ` && confirmDate._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'issueDate' && this.state.issueDateStart && this.state.issueDateEnd) {
      let startTimestamp = new Date(this.state.issueDateStart).getTime()/1000;
      let endTimestamp = new Date(this.state.issueDateEnd).setHours(23,59,59)/1000;
      console.log('issueDate search', startTimestamp, endTimestamp);
      filters += ` && date._seconds:[${startTimestamp}..${endTimestamp}]`;
    }
    if (this.state.filterType === 'reportNo' && this.state.reportNo) {
      q = `${this.state.reportNo}`
      query_by = 'number';
      infix = 'always'
    }
    if (this.state.filterType === 'reportId' && this.state.reportId) {
      q = `${this.state.reportId}`
      query_by = 'objectID';
      infix = 'always'
    }
    if (this.state.filterType === 'title' && this.state.title) {
      //filters += ` && title:'${this.state.title}'`;
      q = `${this.state.title}`
      query_by = 'title';
      infix = 'always'
    }
    if (this.state.filterType === 'sender' && this.state.sender) {
      filters += ` && fromUser:${this.state.sender}`;
    }
    if (this.state.filterType === 'receiver' && this.state.receiver) {
      filters += ` && toUser:${this.state.receiver}`;
    }
    if (this.state.filterType === 'confirmer' && this.state.confirmer) {
      filters += ` && confirmUser:${this.state.confirmer}`;
    }
    let orderBy = 'dueDate';
    if (this.state.sortMainType === 'created') {
      orderBy = 'created._seconds';
    }

    let searchParameters = {
      'q'         : q,
      'query_by'  : query_by,
      'infix'     : infix,
      "num_typos": 0,
      "typo_tokens_threshold": 0,
      'filter_by' : filters,
      'sort_by'   : `${orderBy}:${this.state.sortType}`,
      'page'      : this.state.algoriaPage,
      'per_page'  : this.state.reportPerPage
    }
    await typesense.collections('report').documents().search(searchParameters). then((docs) => {
      //docs.page=this.state.algoriaPage,
      //tempResults.push(docs.hits.document);
      console.log(docs);
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })
    });
    let nextPage = this.state.algoriaPage + 1;
    let newResults = this.state.dbReports.concat(tempResults);
    this.setState({algoriaPage: nextPage,dbReports:newResults,loading:false})
  }

  getReports = (props, newOrg) => {
    // console.log("###### getReports ########", props)

    if (this.state.loading) {
      return;
    }

    if(props.org){
    } else{
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    let urigawa = false;

    if(props.userOrg && props.userOrg.maker) {
      fromOrg = props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = props.user.organization;
      toOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = props.user.organization;
    }
    if (fromOrg == ""){
      return;
    }

    // let s = this.props.db.collection('report').orderBy('created', 'desc');
    // s.get().then((snapshot) => {
    //   console.log(snapshot.docs.length);
    //   snapshot.forEach((doc) => {
    //     let data = doc.data();
    //     console.log(data)
    //     if (data.deleted && data.deleted !== true) {
    //       console.log('deleted true',data);
    //       this.props.db.collection('report').doc(doc.id).update({deleted: true});
    //     }
    //
    //     if (!data.deleted && data.deleted !== false) {
    //       console.log('deleted false',data)
    //       this.props.db.collection('report').doc(doc.id).update({deleted: false});
    //     }
    //   })
    // });

    let first;
    this.setState({loading: true, algolia: false})

    if (urigawa) {
      switch (this.state.reportsType) {
        case '1':
          if(this.state.last){
            first = this.props.db.collection('report')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("deleted", "==", false)
                .orderBy('dueDate',this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.reportPerPage);
          }else{
            first = this.props.db.collection("report")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("deleted", "==", false)
                .orderBy('dueDate',this.state.sortType)
                .limit(this.state.reportPerPage);
          }
          break;
        case '2':
          if(this.state.last){
            first = this.props.db.collection('report')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("deleted", "==", true)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.reportPerPage);
          }else{
            first = this.props.db.collection("report")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("deleted", "==", true)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .limit(this.state.reportPerPage);
          }
          break;
        default:
          if(this.state.last){
            first = this.props.db.collection('report')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.reportPerPage);
          }else{
            first = this.props.db.collection("report")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .orderBy(this.state.sortMainType,this.state.sortType)
                .limit(this.state.reportPerPage);
          }
          break;
      }
    } else {
      if(this.state.last){
        first = this.props.db.collection('report')
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .where("deleted", "==", false)
            .orderBy(this.state.sortMainType,this.state.sortType)
            .startAfter(this.state.last)
            .limit(this.state.reportPerPage);
      }else{
        first = this.props.db.collection("report")
            .where("fromOrganization", "==", fromOrg)
            .where("toOrganization", "==", toOrg)
            .where("deleted", "==", false)
            .orderBy(this.state.sortMainType,this.state.sortType)
            .limit(this.state.reportPerPage);
      }
    }


    let paginate = first.get()
    .then((snapshot) => {
      this.setState({loading: false})
      if(snapshot.docs.length > 0) {
        let last = snapshot.docs[snapshot.docs.length - 1];

        this.setState({last: last})


        let reports = this.state.dbReports;

        if (newOrg === true) {
          reports = [];
        }

        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          reports.push(data)
        })
        this.setState({dbReports: reports}, () => {
          let wrapper1 = document.getElementById('wrapper1');
          let div1 = document.getElementById('div1');
          let wrapper2 = document.getElementsByClassName('table-responsive')[0];
          let div2 = document.getElementById('div2');

          if (wrapper1 && div1 && wrapper2 && div2) {
            div1.style.width = div2.offsetWidth+'px';

            wrapper1.onscroll = function() {
              wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
              wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
          }
        })
      }else{
        if (newOrg === true) {
          this.setState({dbReports: []})
        }
      }

    });

  }

  componentDidMount() {
    //this.getReports();
    this.getReports(this.props, true);
    this.getOrganizationUsers(this.props);
    this.props.loadBadge();
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({last: null, dbReports: []}, () => {
      this.getReports(nextProps, true);
      this.props.loadBadge();
    })
    this.getOrganizationUsers(nextProps);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  getOrganizationUsers = (props) => {
    let sellerUsers = [];
    let buyerUsers = [];

    // console.log('props', props);

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      // console.log('seller', props);
      sellerUsers = props.userOrg.users;
      buyerUsers = props.org.users;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      // console.log('seller', props);
      sellerUsers = props.userOrg.users;
      buyerUsers = props.org.users;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      // console.log('buyer', props);
      sellerUsers = props.org.users;
      buyerUsers = props.userOrg.users;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      // console.log('buyer', props);
      sellerUsers = props.org.users;
      buyerUsers = props.userOrg.users;
    }

    if (sellerUsers.length > 0 && buyerUsers.length > 0) {
      // console.log(sellerUsers, buyerUsers);
      this.setState({
        sellerUsers,
        buyerUsers
      })
    }
  }


  reportIsSelected = (docId) => {
    let filtered = this.state.selectedReportList.filter(item => {
      if (item.objectID) {
        return item.objectID === docId
      } else {
        return item.id === docId
      }

    });
    return filtered.length > 0
  };

  reportSelect = (selected, data) => {
    let selectedList;
    if (selected) {
      selectedList = [...this.state.selectedReportList, data];
    } else {
      selectedList = this.state.selectedReportList.filter( est => {
        if (est.objectID) {
          return est.objectID !== data.objectID
        } else {
          return est.id !== data.id
        }
      });
    }

    this.setState({
      selectedReportList: selectedList
    })
  }

  reportSelectAll = (selected) => {
    let selectedList = [];
    if (selected === true) {
      this.state.dbReports.forEach((data,index) => {
        selectedList.push(data);
      });
      this.setState({
        selectedReportList: selectedList
      }, () => {
      })
    } else {
      this.setState({
        selectedReportList: selectedList
      });
    }
  };

  formatDate = (date = new Date())=> {
    const year = date.toLocaleString('jp', {year: 'numeric'});
    const month = date.toLocaleString('jp', {
      month: '2-digit',
    });
    const day = date.toLocaleString('jp', {day: '2-digit'});
    const hour = date.toLocaleString('jp', {hour: '2-digit'});
    const min = date.toLocaleString('jp', {minute: '2-digit'});
    const second = date.toLocaleString('jp', {second: '2-digit'});
    let dateFormatted = year + '-'+ month + '-' + day + ' ' + hour.substring(0,2) + '' + (min * 1 < 10 ? '0'+'' + min : min) + '' + (second * 1 < 10 ? '0'+'' + second : second);
    return dateFormatted
  }

  downloadSelectedReport = async () => {
    this.setState({downloading: true})

    const jszip = new JSZip();

    let promises = this.state.selectedReportList.map( async (data) => {
      if (data && data.file) {
        const fileBlob = await this.props.storage.ref(data.file).getDownloadURL().then( (url) => {
          return fetch(url).then((response) => response.blob());
        })
        const fileName = data.file.split('/').pop();
        jszip.file(fileName, fileBlob);
      }
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
    await promises;
    const blob = await jszip.generateAsync({ type: 'blob' });
    let date = new Date();
    let dateFormatted = this.formatDate(date);
    saveAs(blob, 'Report_'+dateFormatted+'.zip');
    this.setState({downloading: false})
  }



  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    console.log("LAST:" , this.state.last);
    if(this.state.algolia){
      this.getReportsA(this.props, false, this.state.searchwordA);
    } else {
      this.getReports(this.props, false);
    }
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);

    let esId = e.currentTarget.dataset.id;
    let urigawa = e.currentTarget.dataset.urigawa;
    let index = e.currentTarget.dataset.index;
    urigawa = urigawa === 'true'

    let item = this.state.dbReports[index];

    let reportSet = {
      visited: {[this.props.user.id]: true},
    }
    if (!urigawa) {
      let date = firebase.firestore.Timestamp.fromDate(new Date())

      if (!item.confirmDate && !(item['visited'] && item['visited'][item['toUser']])) {
        reportSet.confirmDate = date
        reportSet.confirmUser = this.props.user.id;
        reportSet.confirmUserName = this.props.user.last_name + this.props.user.first_name;
        this.dbReportUpdate(esId,"confirmDate",date);
        this.dbReportUpdate(esId,"confirmUserName",this.props.user.last_name + this.props.user.first_name);
      }

      if (item.readBuyer === false) {
        reportSet.readBuyer = true;
        this.dbReportUpdate(esId, "readBuyer", true);
        this.props.readReport(1)
      }
    } else {
      if (item.readSeller === false) {
        reportSet.readSeller = true;
        this.dbReportUpdate(esId, "readSeller", true);
        this.props.readReport(1)
      }
    }

    let reportRef = this.props.db.collection('report').doc(esId);
    reportRef.set(reportSet, { merge: true });
    this.dbReportUpdate(esId,"visited",{[this.props.user.id]:true});
  }

  delete = (e) => {
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;

    this.setState({
      deleteModal: true,
      deleteId: esId,
      deleteTitle: esTitle,
    })
  }

  deleteClose = (e) => {
    this.setState({
      deleteModal: false,
      deleteId: null,
      deleteTitle: null,
    });

    let esId = e && e.currentTarget.dataset.id;
    if(esId){
      let date = firebase.firestore.Timestamp.fromDate(new Date())

      let billsRef = this.props.db.collection('report').doc(esId);
      billsRef.set({
        deleted: true,
        deletedUser: this.props.user.id,
        deletedUserName: this.props.user.last_name + this.props.user.first_name,
        deletedDate: date
      }, { merge: true });
      this.dbReportUpdate(esId,"deleted",{key:1});
    }
  }

  dbReportUpdate = (id,target,value) => {
    let dbReportUpdated = this.state.dbReports.map((es)=>{
      if(es.id === id){
        if(target === 'visited') {
          let visited = es.visited;
          if(visited){
            es[target] = Object.assign(visited, value);
          } else {
            es[target] = value;
          }
        }else{
          es[target] = value;
        }
      }
      return es;
    })
    this.setState({
      dbReports: dbReportUpdated,
    })
  }

  handleSearchwordAChange = (e) => {
    this.setState({
      searchwordA: e.currentTarget.value,
    });
  }

  handleSearchwordAKeyDown = (e) => {
    let val = this.state.searchwordA;
    if (e.key === 'Enter') {
      this.setState({
        dbReports: [],
        last: null,
        algoriaPage: 1,
      }, ()=>{
        if (val) {
          this.getReportsA(this.props, true, val);
        } else {
          this.getReports(this.props, true)
        }
      });
    }
  }

  sortChange = (val) => {
    this.setState({last: null, notMore: false, sortType: val, dbReports: [], algoriaPage: 1,selectedReportList: []}, () => {
      if (this.state.algolia) {
        this.getReportsA(this.props, true, this.state.searchwordA);
      } else {
        this.getReports(this.props, true);
      }

    })
  }

  selectReportsType = (e) => {
    this.setState({
      reportsType: e.target.value || '1',
      last: null,
      notMore: false,
      dbReports: [],
      algoriaPage: 1,selectedReportList: []
    }, () => {
      if (this.state.algolia) {
        this.getReportsA(this.props, true, this.state.searchwordA);
      } else {
        this.getReports(this.props, true);
      }
    });
  }

  clearFilter = () => {
    this.setState({
      downloading: false,
      downloadingAll: false,
      sortMainType: 'dueDate',
      sentDateStart: null,
      sentDateEnd: null,
      confirmDateStart: null,
      confirmDateEnd: null,
      issueDateStart: null,
      issueDateEnd: null,
      reportNo: '',
      reportId: '',
      title: '',
      sender: '',
      receiver: '',
      confirmer: '',
      filterType: 'reportNo',
      searchwordA: '',
      notMore: false,
      sortType: 'desc',
      reportsType: '1',
      last: null,
      dbReports: [],
      algolia: false,
      algoriaPage: 1,selectedReportList: []
    }, () => {
      if (this.state.algolia) {
        this.getReportsA(this.props, true, this.state.searchwordA);
      } else {
        this.getReports(this.props, true);
      }
    });
  }

  selectSortMainType = (e) => {
    this.setState({
      sortMainType: e.target.value || 'dueDate',
      last: null,
      notMore: false,
      dbReports: [],
      algoriaPage: 1
    }, () => {
      if (this.state.algolia) {
        this.getReportsA(this.props, true, this.state.searchwordA);
      } else {
        this.getReports(this.props, true);
      }
    });
  }

  filterTypeChange = (e) => {
    let value = e.target.value || 'number';
    // console.log('filterType', value);
    let obj = {
      filterType: value
    };
    if (value === 'sender') {
      obj.sender = this.state.sellerUsers[0].user;
    }
    if (value === 'confirmer') {
      obj.confirmer = this.state.buyerUsers[0].user;
    }
    if (value === 'receiver') {
      obj.receiver = this.state.buyerUsers[0].user;
    }
    this.setState(obj)
  }
  handleChange = (e,type) => {
    switch (type) {
      case 'sender':
        this.setState({
          sender: e.target.value || ''
        })
        break;
      case 'receiver':
        this.setState({
          receiver: e.target.value || ''
        })
        break;
      case 'confirmer':
        this.setState({
          confirmer: e.target.value || ''
        })
        break;
      case 'reportId':
        this.setState({
          reportId: e.currentTarget.value || ''
        })
        break;
      case 'title':
        this.setState({
          title: e.currentTarget.value || ''
        })
        break;
      case 'reportNo':
        this.setState({
          reportNo: e.currentTarget.value || ''
        })
        break;
      case 'sentDateStart':
        this.setState({
          sentDateStart: e
        })
        break;
      case 'sentDateEnd':
        this.setState({
          sentDateEnd: e
        })
        break;
      case 'confirmDateStart':
        this.setState({
          confirmDateStart: e
        })
        break;
      case 'confirmDateEnd':
        this.setState({
          confirmDateEnd: e
        })
        break;
      case 'issueDateStart':
        this.setState({
          issueDateStart: e
        })
        break;
      case 'issueDateEnd':
        this.setState({
          issueDateEnd: e
        })
        break;
    }
  }
  reportFilter = () => {
    console.log('reportFilter filter');
    this.setState({
      algoriaPage: 1,
      notMore: false,
      algolia: true,
      last: null,
      dbReports: [],
      selectedReportList: [],
    }, () => {
      this.getReportsA(this.props, true,null);
    })
  }
  render() {
    if (!this.props.org) {
      return (
          <>
            取引先を選択してください。
          </>
      )
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
        || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
        || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    if(! kaigawa && ! urigawa){
      return (
          <>
          </>
      )
    }

    const reports = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    this.state.dbReports.forEach((report,index) => {
      let created;
      let confirmDate = '';
      let startDate = '';

      if (this.state.algolia) {
        let cDate = new Date(report['created']._seconds * 1000 + report['created']._nanoseconds/1000000);
        created = cDate.getFullYear() + '/'
            + n(cDate.getMonth()+1) + '/'
            + n(cDate.getDate()) + ' '
            + n(cDate.getHours()) + ":"
            + n(cDate.getMinutes());

        if (report['confirmDate']) {
          let coDate = new Date(report['confirmDate']._seconds * 1000 + report['confirmDate']._nanoseconds/1000000);
          confirmDate = coDate.getFullYear() + '/'
              + n(coDate.getMonth()+1) + '/'
              + n(coDate.getDate()) + ' '
              + n(coDate.getHours()) + ":"
              + n(coDate.getMinutes());
        }

        if (report['date']) {
          let dDate = new Date(report['date']._seconds * 1000 + report['date']._nanoseconds/1000000);
          startDate = dDate.getFullYear() + '/'
              + n(dDate.getMonth()+1) + '/'
              + n(dDate.getDate());
        }
      } else {
        created = report['created'].toDate().getFullYear() + '/'
            + n(report['created'].toDate().getMonth()+1) + '/'
            + n(report['created'].toDate().getDate()) + ' '
            + n(report['created'].toDate().getHours()) + ":"
            + n(report['created'].toDate().getMinutes());

        if (report['confirmDate']) {
          confirmDate = report['confirmDate'].toDate().getFullYear() + '/'
              + n(report['confirmDate'].toDate().getMonth()+1) + '/'
              + n(report['confirmDate'].toDate().getDate()) + ' '
              + n(report['confirmDate'].toDate().getHours()) + ":"
              + n(report['confirmDate'].toDate().getMinutes());
        }

        if (report['date']) {
          startDate = report['date'].toDate().getFullYear() + '/'
              + n(report['date'].toDate().getMonth()+1) + '/'
              + n(report['date'].toDate().getDate());
        }
      }

      let visited = '';
      if(urigawa) {
        if (report['visited'] && report['visited'][report['toUser']] || report['confirmDate']) { //[estimates2['toUser']]
          visited = <>{report['confirmUserName'] && <><span className="text-nowrap">{report['confirmUserName']}</span><br/></>}<MDBChip className="text-nowrap disabled">確認済</MDBChip></>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }
      }

      if ( kaigawa ){
        if(report['visited'] && report['visited'][this.props.user.id] || report['confirmDate']) { //[this.props.user.id]
          visited = <>{report['confirmUserName'] && <><span className="text-nowrap">{report['confirmUserName']}</span><br/></>}<MDBChip className="text-nowrap disabled">確認済</MDBChip></>;
        } else {
          visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }
      }

      let bold;
      if (urigawa) {
        bold = report['readSeller'] === false
      }

      if (kaigawa) {
        bold = report['readBuyer'] === false
      }
      let reportId = report.objectID ? report.objectID : report.id
      let checked = this.reportIsSelected(reportId);
      let data = {
        'check':<div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} id={"checkbox"+index} onChange={(e) => this.reportSelect(e.target.checked, report)} /> </div>,
        '請求番号':<span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{report['number']}<br/>{report['id']}</span>,
        '送信者': <span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{report['fromUserName']}<br/>{created}</span>,
        '見積受取者': <span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{report['toUserName']}</span>,
        '確認日時': <span>{visited}<br/>{confirmDate}</span>,
        '発行日': <span style={{fontWeight: bold ? 'bold' : ''}} className={'text-nowrap'}>{startDate}</span>,
        'タイトル': <div style={{fontWeight: bold ? 'bold' : '' }}>{report['title']}</div>,
        '詳細': <StorageLink src={report['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={report.id} data-urigawa={urigawa} data-index={index} onClick={this.openFile} >表示</MDBChip></StorageLink>,
        // '内容表示': <StorageLink src={report['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={report.id} onClick={this.openFile} >内容表示</MDBChip></StorageLink>,
      };

      if (urigawa) {
        if (report.deleted) {
          data['確認'] = <div style={{textAlign: 'center'}}>
            <MDBChip className="text-nowrap disabled custom-button-gray" style={{margin: '0 auto'}}>削除済</MDBChip>
          </div>
        } else {
          data['確認'] = <div style={{textAlign: 'center'}}>
            <MDBBtn rounded flat size="sm"
                    data-id={report.id}
                    data-title={report['title']}
                    onClick={this.delete}
            >
              <MDBIcon
                  icon="trash"
                  size={'2x'}
                  className="custom-text-grey"/>
            </MDBBtn>
          </div>
        }

      }

      reports.push(data);

    });

    const data = {
      columns: [
        {
          label: <div style={{width: 50}}><MDBInput label=" " type="checkbox" id="checkboxAll" onChange={(e) => this.reportSelectAll(e.target.checked)} /></div>,
          field: 'check',
          sort: 'asc',
          width: 50
        },
        {
          label: <div><row>報告書番号</row><br/><row>報告書ID</row></div>,
          field: '報告書番号',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>送信者</row><br/><row>送信日時</row></div>,
          field: '送信者',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: '報告受取者',
          field: '報告受取者',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>報告書確認者</row><br/><row>確認日時</row></div>,
          field: '確認日時',
          sort: 'disabled',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>発行日</row></div>,
          field: '発行日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: '報告書タイトル',
          field: '報告書タイトル',
          sort: 'disabled',
          width: 370,
          minimal: 'sm'
        },
        {
          label: '詳細',
          field: '詳細',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        }
      ],
      rows: reports
    }

    if(urigawa) {
      data.columns.push({
        label: <div style={{textAlign: 'center'}}>削除</div>,
        field: '削除',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      });
    }

    let selectOption = <>
      <option value={1}>送信済一覧</option>
      <option value={2}>削除済一覧</option>
      <option value={3}>すべて</option>
    </>

    return (
      <>
      <h2>報告書一覧</h2>
        {/*<MDBRow>
          <MDBCol md="3">
            <div className="input-group md-form form-sm form-1 pl-0">
              <div className="input-group-prepend">
                <span className="input-group-text lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search" />
                </span>
              </div>
              <input
                  className="form-control my-0 py-1"
                  type="text"
                  placeholder="文字列検索"
                  aria-label="文字列検索"
                  onChange={this.handleSearchwordAChange}
                  onKeyDown={this.handleSearchwordAKeyDown}
                  value={this.state.searchwordA}
              />
            </div>
          </MDBCol>
          <MDBCol md={'9'} style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', color: '#495057'}}>
            <div>
              <label style={{margin: 0}}>発行日順で並べ替え：</label>
              <div className="form-check form-check-inline">
                <input type="radio" className="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample"
                       checked={this.state.sortType === 'asc'} onClick={() => this.sortChange('asc')}/>
                <label className="form-check-label" htmlFor="materialInline1">昇順</label>
              </div>

              <div className="form-check form-check-inline">
                <input type="radio" className="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample"
                       checked={this.state.sortType === 'desc'} onClick={() => this.sortChange('desc')}/>
                <label className="form-check-label" htmlFor="materialInline2">降順</label>
              </div>

              {
                  urigawa && <>
                    <div className="form-check form-check-inline" style={{marginLeft: 20}}>
                      表示
                    </div>

                    <div className="form-check form-check-inline">
                      <select className="browser-default custom-select" value={this.state.reportsType} onChange={(e) => this.selectReportsType(e)}>
                        {selectOption}
                      </select>
                    </div>

                    <div className={'form-check form-check-inline'}>
                      <MDBBtn
                          color="default" style={{padding: 10}} flat={true} outline={true} onClick={() => this.clearFilter()}>
                        リセット
                      </MDBBtn>
                    </div>
                  </>
              }
            </div>
          </MDBCol>
        </MDBRow>
        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>*/}
        <div style={{display:'flex',flexDirection:'column',flex:1,justifyContent:'flex-start'}}>
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                <div style={{margin:0}}>
                  <select style={{height:'38px'}} className="browser-default custom-select" value={this.state.filterType} onChange={(e) => this.filterTypeChange(e)}>
                    <option value={'reportNo'}>報告書番号</option>
                    <option value={'reportId'}>報告書ID</option>
                    <option value={'sender'}>送信者</option>
                    <option value={'sentDate'}>送信日時</option>
                    <option value={'receiver'}>報告書受取者</option>
                    <option value={'confirmer'}>報告書確認者</option>
                    <option value={'confirmDate'}>確認日時</option>
                    <option value={'issueDate'}>発行日</option>
                    <option value={'title'}>報告書タイトル</option>
                  </select>
                </div>
                {this.state.filterType === 'issueDate' &&
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"issueDate"}
                            className={"form-control"}
                            selected={this.state.issueDateStart}
                            onChange={(e) => this.handleChange(e,'issueDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"issueDate"}
                            className={"form-control"}
                            selected={this.state.issueDateEnd}
                            onChange={(e) => this.handleChange(e,'issueDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'sentDate' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"sentDate"}
                            className={"form-control"}
                            selected={this.state.sentDateStart}
                            onChange={(e) => this.handleChange(e,'sentDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"sentDate"}
                            className={"form-control"}
                            selected={this.state.sentDateEnd}
                            onChange={(e) => this.handleChange(e,'sentDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {this.state.filterType === 'confirmDate' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <div className="d-md-flex flex-row mt-0 align-items-center">
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"confirmDate"}
                            className={"form-control"}
                            selected={this.state.confirmDateStart}
                            onChange={(e) => this.handleChange(e,'confirmDateStart')}
                            locale={"ja-JP"}
                        />
                        <div className="p-2">〜</div>
                        <DatePicker
                            dateFormat={"yyyy/MM/dd"}
                            id={"confirmDate"}
                            className={"form-control"}
                            selected={this.state.confirmDateEnd}
                            onChange={(e) => this.handleChange(e,'confirmDateEnd')}
                            locale={"ja-JP"}
                        />
                      </div>
                    </div>
                }
                {(this.state.filterType === 'reportNo') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={(e) => this.handleChange(e,'reportNo')}
                          value={this.state.reportNo}
                      />
                    </div>
                }
                {(this.state.filterType === 'reportId') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={(e) => this.handleChange(e,'reportId')}
                          value={this.state.reportId}
                      />
                    </div>
                }
                {(this.state.filterType === 'title') &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder=""
                          aria-label=""
                          onChange={(e) => this.handleChange(e,'title')}
                          value={this.state.title}
                      />
                    </div>
                }
                {this.state.filterType === 'sender' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.sender} onChange={(e) => this.handleChange(e,'sender')}>
                        {this.state.sellerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                {this.state.filterType === 'confirmer' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.confirmer} onChange={(e) => this.handleChange(e,'confirmer')}>
                        {this.state.buyerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                {this.state.filterType === 'receiver' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, width: 418,height:'38px'}}>
                      <select className="browser-default custom-select" value={this.state.receiver} onChange={(e) => this.handleChange(e,'receiver')}>
                        {this.state.buyerUsers.map((user) => {
                          return <option value={user.user}>{user.name}</option>
                        })}
                      </select>
                    </div>
                }
                <div>
                  <MDBBtn
                      color="default"
                      type="submit"
                      style={{width: '90px', margin: 0, height: 38, padding: '0.64rem 1.14rem'}}
                      onClick={this.reportFilter}
                  >検　索</MDBBtn>
                </div>
              </div>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'space-between',alignItems:'center'}}>
            <div>
              <MDBBtn size={'sm'} color="default" style={{padding: 10, height: 38, width: 200,margin:0}}  disabled={this.state.selectedReportList.length > 0 ? this.state.downloading : true} onClick={() => this.downloadSelectedReport()}>
                {this.state.downloading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
                {!this.state.downloading && <MDBIcon icon="download" style={{marginRight: 10}} />}
                選択したPDF一括ダウンロード
              </MDBBtn>
            </div>
            <div style={{alignItems: 'center',color: '#495057',display:'flex',justifyContent:'flex-end',flexDirection:'row'}}>
              <div style={{margin: 0,width:220}}>
                <select style={{height:'38px'}} className="browser-default custom-select" value={this.state.sortMainType} onChange={(e) => this.selectSortMainType(e)}>
                  <option value={'dueDate'}>発行日順で並べ替え</option>
                  <option value={'created'}>送信日時で並べ替え</option>
                </select>
              </div>
              <div className="form-check form-check-inline" style={{marginLeft: 10}}>
                <input type="radio" className="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample"
                       checked={this.state.sortType === 'asc'} onClick={() => this.sortChange('asc')}/>
                <label className="form-check-label" htmlFor="materialInline1">昇順</label>
              </div>

              <div className="form-check form-check-inline">
                <input type="radio" className="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample"
                       checked={this.state.sortType === 'desc'} onClick={() => this.sortChange('desc')}/>
                <label className="form-check-label" htmlFor="materialInline2">降順</label>
              </div>

              {
                  urigawa && <>
                    <div className="form-check form-check-inline" style={{marginLeft: 20}}>
                      表示
                    </div>

                    <div className="form-check form-check-inline">
                      <select className="browser-default custom-select" value={this.state.reportsType} onChange={(e) => this.selectReportsType(e)}>
                        {selectOption}
                      </select>
                    </div>
                  </>
              }

              <div >
                <MDBBtn
                    color="default" style={{width: '90px', height: 38, padding: '0.64rem 1.14rem',marginRight:0}} flat={true} outline={true} onClick={() => this.clearFilter()}>
                  リセット
                </MDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>
      <MDBTable responsive btn small id={"div2"}>
        <MDBTableHead color="primary-color" textWhite columns={data.columns} />
        <MDBTableBody rows={data.rows} />
      </MDBTable>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

        <MDBModal isOpen={this.state.deleteModal} toggle={this.deleteClose}>
          <MDBModalHeader toggle={this.deleteClose}>報告書削除</MDBModalHeader>
          <MDBModalBody>
            見積書「{this.state.deleteTitle}」を削除します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.deleteClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.deleteClose} color="danger" data-id={this.state.deleteId}>削除</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    )

  }
}

export default ReportTableList;
