import React, {useState, useEffect} from "react";
import {MDBTable, MDBTableBody, MDBTableHead, MDBIcon, MDBBtn} from "mdbreact";
// import { MDBAccordion, MDBAccordionItem} from 'mdb-react-ui-kit';
import {Accordion, Item} from 'devextreme-react/accordion';
import DatePicker from "react-datepicker";
import moment from "moment";
import {toast} from "react-toastify";
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import {useFieldArray} from "react-hook-form";
import MeasurementsItems from "./MeasurementsItems";
import {format} from 'date-fns';
import firebase from "../../../firebase";
const Measurements = (props) => {
    const [dataIndex, setDataIndex] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [idx, setIdx] = useState(null);
    // Define your custom comparator function to sort by datetime
    const [insertionDate, setInsertionDate] = useState(null);
    const [removeIndex, setRemoveIndex] = useState(null);
    const control = props.control;
    const register = props.register;
    const [which, setWhich] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [removeHead, setRemoveHead] = useState(null);
    useEffect(() => {}, [props.reloader, props.appendItem])
    useEffect(() => {
        setWhich(props.which);
        (which === props.which && props.appendComment !== null && props.appendComment !== undefined) && addComments();
    }, [props.appendComment])

    useEffect( () => {
    }, [dataIndex])
    // props.getValues(`process[${props.nestIndex}].items[${props.itemIndex}][${props.which}Measure]`)
    const {fields} = useFieldArray({
        control,
        name: `process[${props.nestIndex}].items`
    });
    const {fields: fieldComments, remove: fieldRemoveComments, append: fieldAppendComments,} = useFieldArray({
        control,
        name: `process[${props.nestIndex}].fieldComments`,
        key: "insertedDate"
    });
    const {fields: labComments, remove: labRemoveComments, append: labAppendComments,} = useFieldArray({
        control,
        name: `process[${props.nestIndex}].labComments`,
        key: "insertedDate"
    });
    const {
        fields: automaticComments,
        remove: automaticRemoveComments,
        append: automaticAppendComments,
    } = useFieldArray({
        control,
        name: `process[${props.nestIndex}].automaticComments`,
        key: "insertedDate"
    });
    useEffect(() => {
        // setWhich(props.which);
        if(removeIndex!==null) {
            handleColumn(removeIndex);
        }
    }, [removeIndex])
    const startAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd HH:mm:00.000');
        return new Date(parsed);
    };
    const addComments = async () => {
        let fieldComment = await props.db.collection('measurement').doc(props.getValues(`id`)).collection('process').doc(props.getValues(`process[${props.nestIndex}].id`)).collection(`${props.which}Comments`)
            .where("insertedDate", ">=", startAt(props.appendItem.insertedDate))
            .where("insertedDate", "<", startAt((new Date(props.appendItem.insertedDate)).setMinutes((new Date(props.appendItem.insertedDate)).getMinutes() + 1)))
            .limit(1).get();
        //console.log('fieldComment`)',fieldComment.docs.length);
        let same = props.getValues(`process[${props.nestIndex}][${props.which}Comments]`).find((z) => z && z.insertedDate && moment(z.id === undefined ? z.insertedDate : z.insertedDate.toDate()).isSame(props.appendComment.insertedDate, 'minute'));
        //console.log('same comments', same);
        if (fieldComment.docs.length === 0 && same === undefined) {
            switch (props.which) {
                case 'field':
                    fieldAppendComments(props.appendComment)
                    break;
                case 'lab':
                    labAppendComments(props.appendComment)
                    break;
                case 'automatic':
                    automaticAppendComments(props.appendComment)
                    break;
            }
        } else return


    }

    const whichComments = props.which === 'field' ? fieldComments : (props.which === 'lab' ? labComments : automaticComments)
    /*const dateFormatter = (date) => {
        const format = 'YYYY/MM/DD';
        if (moment(date).isValid()) {
            return moment(date).format(format);
        }
        return moment(date.toDate()).format(format);
    }*/

    function compareDatesDesc(a, b) {
        const dateA = props.dateFormatter(a.insertedDate);
        const dateB = props.dateFormatter(b.insertedDate);

        if (dateA < dateB) {
            return 1;
        }
        if (dateA > dateB) {
            return -1;
        }
        return 0;
    }

    // Define your custom comparator function to sort by datetime
    function compareDatesAsc(a, b) {
        const dateA = props.dateFormatter(a.insertedDate);
        const dateB = props.dateFormatter(b.insertedDate);
        if (dateA < dateB) {
            return -1;
        }
        if (dateA > dateB) {
            return 1;
        }
        return 0;
    }
    /*const {fields:fieldHead, remove: removeHead} = useFieldArray({
        control,
        name: `process[${props.nestIndex}].items[0][${props.which}Measure]`,
        key: "insertedDate"
    });*/
    props.getValues(`process[${props.nestIndex}][${props.which}Comments`).sort(compareDatesDesc);
    whichComments.sort(compareDatesDesc);
    props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure]`).sort(compareDatesAsc);
    //console.log('whichComments',  whichComments)
    const handleColumnRemove = (n) => {
        switch (props.which) {
            case 'field':
                fieldRemoveComments(props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - n);
                break;
            case 'lab':
                labRemoveComments(props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - n);
                break;
            case 'automatic':
                automaticRemoveComments(props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - n);
                break;
        }
    }

    const handleColumn = (n) => {
        console.log('n',n);
        //setRemoveIndex(n);
        //removeHead(n)
        //setIdx(1000);
        toast.success("測定データが削除されました", {
            position: toast.POSITION.TOP_RIGHT
        });
        //setDataIndex(!dataIndex);
        setRemoveIndex(null);
    }
    const deleteMeasures = (n) => {
        for(let i=0;i < props.getValues(`process[${props.nestIndex}].items`).length; i++) {
            let data = {}
            let id = props.getValues(`process[${props.nestIndex}].items[${i}][${props.which}Measure[${n}].id`);
            if(props.getValues(`process[${props.nestIndex}].items[${i}][${props.which}Measure[${n}].id`)!==undefined) {
                //console.log('i', i);
                data.which = props.which;
                data.processId = props.getValues(`process[${props.nestIndex}].items[${i}][${props.which}Measure[${n}].processId`);
                data.itemId = props.getValues(`process[${props.nestIndex}].items[${i}][${props.which}Measure[${n}].itemsId`);
                data.id = id;
                props.deleteMeasure(data);
            }
        }
        if(props.getValues(`process[${props.nestIndex}][${props.which}Comments[${props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - n}].id`)!==undefined) {
            let com = {}
            //console.log('i com', props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - n);
            com.which = props.which;
            com.processId = props.getValues(`process[${props.nestIndex}].id`);
            com.id = props.getValues(`process[${props.nestIndex}][${props.which}Comments[${props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - n}].id`);
            props.deleteMeasure(com);
        }
    }
    //console.log('log', props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure]`));
    fields.sort(compareDatesAsc)
    const handleChange = (e) => {

        setInsertionDate(null);
        setIdx(e === idx ? null : e);
    }
    const dateHandler = (date) => {
        setInsertionDate(date);
    }
    const addValueRows = async (x) => {
        if (!insertionDate) return setIdx(null);
        let fieldComment = await props.db.collection('measurement').doc(props.getValues(`id`)).collection('process').doc(props.getValues(`process[${props.nestIndex}].id`)).collection(`${props.which}Comments`)
            .where("insertedDate", ">=", startAt(insertionDate))
            .where("insertedDate", "<", startAt((new Date(insertionDate)).setMinutes((new Date(insertionDate)).getMinutes() + 1)))
            .limit(1).get();
        //console.log('addValueRows_fieldComment `)',fieldComment.docs.length);
        props.getValues(`process[${props.nestIndex}].items`).forEach((item, itemIndex) => {
            let same = item[`${props.which}Measure`].find((z) => z && z.insertedDate && moment(z.id === undefined ? z.insertedDate : z.insertedDate.toDate()).isSame(insertionDate, 'minute'));
            if (fieldComment.docs.length === 0 && same === undefined) {
                props.setValue(`process[${props.nestIndex}].items[${itemIndex}][${props.which}Measure][${x}].insertedDate`,
                props.getValues(`process[${props.nestIndex}].items[${itemIndex}][${props.which}Measure][${x}].id`) !==undefined ? firebase.firestore.Timestamp.fromDate(insertionDate) : insertionDate);
                props.setValue(`process[${props.nestIndex}].items[${itemIndex}][${props.which}Measure][${x}].changed`, true);
            }
            else return;

        });
        let sameComment = props.getValues(`process[${props.nestIndex}][${props.which}Comments]`).find((z) => z && z.insertedDate && moment(z.id === undefined ? z.insertedDate : z.insertedDate.toDate()).isSame(insertionDate, 'minute'));
        //if (sameComment) return;
        if (fieldComment.docs.length === 0 && sameComment === undefined) {
            props.setValue(`process[${props.nestIndex}][${props.which}Comments][${props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - x}].insertedDate`,
            props.getValues(`process[${props.nestIndex}][${props.which}Comments][${props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - x}].id`) !== undefined ? firebase.firestore.Timestamp.fromDate(insertionDate) : insertionDate);
            props.setValue(`process[${props.nestIndex}][${props.which}Comments][${props.getValues(`process[${props.nestIndex}][${props.which}Comments`).length - 1 - x}].changed`, true);
            setEditIndex({index: x, insertionDate: insertionDate});
            setRemoveIndex(null);
            setInsertionDate(null);
            setIdx(null);
            setDataIndex(!dataIndex);
            setRefresh(!refresh)
            toast.success("測定データが更新されました", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else return

    }
    const handleToleranceColorExcel = (value, lvlMin, lvlMax, rangeSettings) => {
        let color = '';
        switch (rangeSettings) {
            case '上限下限共':
                color = (value * 1 >= lvlMin * 1 && value * 1 <= lvlMax * 1 ? '' : 'FF0000');
                break;
            case '下限のみ':
                color = (value * 1 >= lvlMin * 1 ? '' : 'FF0000');
                break;
            case '上限のみ':
                color = (value * 1 <= lvlMax * 1 ? '' : 'FF0000');
                break;
            default:
                color = (value * 1 >= lvlMin * 1 && value * 1 <= lvlMax * 1 ? '' : 'FF0000');
                break;
        }
        return color;
    }
    const formatDatetime = (datetime) => {
        const dateStr = new Date(datetime).toLocaleDateString(
            "ja-JP"
        );
        const timeStr = new Date(datetime).toLocaleTimeString();
        return `${dateStr} ${timeStr}`;
    }
    const exportData = async () => {
        try {
            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet('Data');
            // Header
            const headerRow = worksheet.getRow(1);
            headerRow.width = 60;
            const columnFromIndex = 3;
            //const columnToIndex = columnFromIndex + 3;
            headerRow.getCell(1).value = '管理項目';
            headerRow.getCell(1).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};
            headerRow.getCell(2).value = '許容範囲';
            headerRow.getCell(2).alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};
            props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure]`).forEach((vr, vr_x) => {
                    headerRow.getCell(columnFromIndex + vr_x).alignment = {
                        horizontal: 'left',
                        vertical: 'middle',
                        wrapText: true
                    }
                    headerRow.getCell(columnFromIndex + vr_x).value = formatDatetime(new Date(moment(vr.id === undefined ? vr.insertedDate : vr.insertedDate.toDate())))
                }
            )

            props.getValues(`process[${props.nestIndex}].items`).forEach((item, item_index) => {
                const row = worksheet.getRow(2 + item_index);
                row.getCell(1).value = item.item_name;
                row.getCell(2).value = `${item.tolerance_lvl_min}~${item.tolerance_lvl_max} ${item.unit}`;
                item[`${props.which}Measure`].forEach((vr, vr_x) => {
                        //color: handleToleranceColor(vl.value,item.tolerance_lvl_min,item.tolerance_lvl_max,item.range_settings)
                        row.getCell(columnFromIndex + vr_x).alignment = {
                            horizontal: 'center',
                            vertical: 'middle',
                            wrapText: true
                        };
                        row.getCell(columnFromIndex + vr_x).font = {color: {argb: handleToleranceColorExcel(vr.value, item.tolerance_lvl_min, item.tolerance_lvl_max, item.range_settings)}};
                        //row.getCell(columnFromIndex + vr_x).numFmt = '#,##0.00';
                        row.getCell(columnFromIndex + vr_x).value = !isNaN(parseFloat(vr.value)) ? parseFloat(vr.value) : '';
                    }
                )
            })
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer]);
                saveAs(blob, `${props.getValues(`process[${props.nestIndex}].process_name`)}-${props.which}Measure - ${new moment().format('YYYY-MM-DD_HHmmss')}.xlsx`)
            })


        } catch (e) {
            console.log('e -> ', e.message);
            alert('エラー');
        }
    }
    const endAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd HH:mm:00.000');
        return new Date(parsed);
    };
    const nextReloadDar = async () => {
        if(!props.enabledNext) {
            let data = {}
            data.processId = props.getValues(`process[${props.nestIndex}].id`);
            data.processIndex =props.nestIndex;
            data.which=props.which;
            props.nextData(data);
        }
    }
    return (
        <div className={'measure'}>
            <div className="overflow-auto">
                <div style={{
                    backgroundColor: props.color,
                    margin: '10px 0',
                    padding: '0px',
                    color: '#FFF',
                    display: 'flex',
                    justifyContent: 'space-between',flexDirection:'row',alignItems:'center'
                }}>
                    <span style={{marginLeft:5}}>{props.title}</span>
                    <MDBBtn className="form-check form-check-inline" rounded color="white" size={'sm'} disabled={props.getValues(`process[${props.nestIndex}][${which}NotMore]`)} onClick={() => nextReloadDar()}><span style={{fontSize:12}}>次の{props.limit}件</span> </MDBBtn>
                </div>
                <MDBTable autoWidth={true}
                          responsive fixed small
                          responsiveSm
                          responsiveMd
                          responsiveLg
                          responsiveXl
                          bordered={true} className="table-fixed w-full"
                >
                    <MDBTableHead style={{backgroundColor: '#f2f2f2', width: '100%'}}>
                        <tr style={{justifyContent: 'center', alignItems: 'center', flex: 1, alignContent: 'center'}}>
                            <th style={{
                                textAlign: 'center',
                                minWidth: 100,
                                backgroundColor: '#f2f2f2',
                                position: 'sticky',
                                left: 0,
                                zIindex: 1
                            }}>管理項目
                            </th>
                            <th style={{
                                textAlign: 'center',
                                minWidth: 150,
                                backgroundColor: '#f2f2f2',
                                position: 'sticky',
                                left: 100,
                                zIindex: 1
                            }}>許容範囲
                            </th>
                            {
                                props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure]`).map((vr, vr_x) => (
                                    <th //hidden={moment(vr.insertedDate.seconds === undefined ? new Date() : vr.insertedDate.toDate()).format('YYYY/MM/DD') >= moment(dateFormatter(new Date())).subtract(props.searchChart, 'months').format('YYYY/MM/DD') ? false : true}
                                        key={vr_x} style={{alignItems: 'center', textAlign: 'center'}}>
                                        <span key={vr_x}>{(props.dateFormatter(props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure][${vr_x}].insertedDate`))).substring(5, 10)}<br/>{(props.dateFormatter(props.getValues(`process[${props.nestIndex}].items[0][${props.which}Measure][${vr_x}].insertedDate`))).substring(11, 16)}<br/></span>
                                        {props.editClicked && idx === vr_x &&
                                            <DatePicker id="datePicker1" className={'datePicker'}
                                                        selected={insertionDate ? insertionDate : (new Date(moment(vr.id === undefined ? vr.insertedDate : vr.insertedDate.toDate()).format('YYYY/MM/DD HH:mm')))}
                                                        showTimeInput timeInputLabel="Time:"
                                                        dateFormat="yyyy/MM/dd HH:mm" maxDate={new Date()}
                                                        onChange={(date) => dateHandler(date)}/>}
                                        <div style={{marginTop: 3}}>
                                            {props.editClicked && idx === vr_x &&
                                                <MDBIcon style={{cursor: 'pointer'}} size={'lg'} fas icon="undo"
                                                         onClick={() => handleChange(vr_x)}/>}
                                            {props.editClicked && idx === vr_x &&
                                                <MDBIcon style={{cursor: 'pointer', marginLeft: 5}} size={'lg'} fas
                                                         icon="save" onClick={() => addValueRows(vr_x)}/>}
                                        </div>
                                        <div>
                                            {props.editClicked && idx !== vr_x &&
                                                <MDBIcon style={{cursor: 'pointer'}} size={'lg'} fas icon="edit"
                                                         onClick={() => handleChange(vr_x)}/>}
                                            {props.editClicked && idx !== vr_x &&
                                                <MDBIcon style={{marginLeft: 5, cursor: 'pointer'}} size={'lg'} fas
                                                         icon="trash" onClick={() => {
                                                    //handleColumn(vr_x)
                                                    deleteMeasures(vr_x);
                                                    handleColumnRemove(vr_x)
                                                             setRemoveIndex(vr_x);

                                                   // handleColumn(vr_x,vr.processId);
                                                }}/>}
                                        </div>

                                    </th>
                                ))}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {fields.map((item, item_index) => (
                            <tr key={item_index} style={{textAlign: 'center', alignItems: 'center'}}>
                                <td style={{
                                    textAlign: 'center',
                                    minWidth: 100,
                                    backgroundColor: '#f2f2f2',
                                    position: 'sticky',
                                    left: 0,
                                    zIindex: 1
                                }}>{item.item_name}
                                </td>
                                <td style={{
                                    textAlign: 'center',
                                    minWidth: 150,
                                    backgroundColor: '#f2f2f2',
                                    position: 'sticky',
                                    left: 100,
                                    zIindex: 1
                                }}>
                                    {item.tolerance_lvl_min}~{item.tolerance_lvl_max}{item.unit}
                                </td>
                                <MeasurementsItems reloader={props.reloader} refresh={refresh} editIndex={editIndex}
                                                   refreshRemoveIndex={dataIndex} appendItem={props.appendItem}
                                                   removeIndex={removeIndex} //searchChart={props.searchChart}
                                                   editClicked={props.editClicked} nestIndex={props.nestIndex}
                                                   itemIndex={item_index} {...{control, register}}
                                                   getValues={props.getValues} setValue={props.setValue}
                                                   dateFormatter={props.dateFormatter} which={props.which}
                                                   title={props.title} color={props.color} db={props.db}/>
                            </tr>

                        ))}
                    </MDBTableBody>
                </MDBTable>
            </div>

            <div style={{marginBottom: '10px', marginTop: '10px'}} id="accordion">
                <Accordion collapsible={true} multiple={false} defaultSelectedIndex={-1}
                           itemTitleRender={(data) => {
                               return <div style={{fontSize: '16px'}}><MDBIcon fas icon="comments"/> &nbsp; コメント</div>
                           }}>
                    <Item title="コメント">
                        {props.getValues(`process[${props.nestIndex}][${props.which}Comments`).map((cmnt, cmnt_index) => (
                            // cmnt.comment && (
                            <div style={{display: 'flex', alignItems: 'center', margin: '10px 0', paddingLeft: '10px'}}
                                 key={cmnt.insertedDate}
                                //hidden={moment(cmnt.insertedDate.seconds === undefined ? new Date() : cmnt.insertedDate.toDate()).format('YYYY/MM/DD') >= moment(dateFormatter(new Date())).subtract(props.searchChart, 'months').format('YYYY/MM/DD') ? false : true}
                            >
                                <span style={{flexGrow: .02}} onClick={(event => event.target.value = null)}
                                      aria-readonly={true}>{props.dateFormatter(cmnt.insertedDate)}</span>
                                <input key={cmnt.insertedDate} className={'form-control'} style={{
                                    flex: 1,
                                    backgroundColor: !props.editClicked ? 'white' : (cmnt.edit ? '#FFFDE8' : '')
                                }} readOnly={!props.editClicked}
                                       {...props.register(`process[${props.process_index}][${props.which}Comments][${cmnt_index}].comment`)}
                                       onChange={(e) => {
                                           props.setValue(`process[${props.process_index}][${props.which}Comments][${cmnt_index}]`, {
                                               comment: e.target.value,
                                               edit: cmnt.id === undefined ? false : true,
                                               insertedDate: cmnt.insertedDate,
                                               id: cmnt.id,
                                               changed: true,
                                           })
                                           //setDataIndex(!dataIndex);
                                       }}
                                />
                            </div>
                            // )
                        ))}
                    </Item>
                </Accordion>
            </div>
        </div>
    )
}

export default Measurements;