import React from "react";
import BillsTableList from "../Bills/BillsTableList";
import {
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBIcon,
    MDBBtn,
    MDBModalHeader,
    MDBModalBody, MDBModalFooter, MDBModal, MDBInput
} from "mdbreact";
import {Link} from 'react-router-dom'
import firebase from "../../firebase";

class DeliveryAddressList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dbDeliveryAddresses: [],
            last: null,
            dePerPage: 12,
            delModal: false,
            cn: null,
            ct: null,
            selectedItemList: [],
            reachMax: false,
        }
    }

    componentDidMount() {
        // console.log('componentDidMount')
        this.getDeliveryAddressList(this.props,true);

        let cn = new URLSearchParams(this.props.search).get("cn");
        let ct = new URLSearchParams(this.props.search).get("ct");
        console.log(cn, ct)
        this.setState({
            cn: cn,
            ct: ct
        })

        window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log('componentWillReceiveProps', nextProps)
        let cn = new URLSearchParams(nextProps.search).get("cn");
        let ct = new URLSearchParams(nextProps.search).get("ct");
        // console.log(cn)
        this.setState({
            cn: cn,
            ct: ct,
            last: null
        }, () => {
            this.getDeliveryAddressList(nextProps,true)
        })

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
    }

    watchCurrentPosition() {
        if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
            console.log('reach end');
            this.onReachEnd();
        }
    }

    scrollTop() {
        return Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
        );
    }

    onReachEnd = () => {
        console.log('onReachEnd')

        this.getDeliveryAddressList(this.props, false)
    }

    getDeliveryAddressList = (props, newOrg) => {
        if(this.props.org){
            this.setState({loading: true})

            let toOrg = "";
            let fromOrg = "";
            let urigawa = false;
            let userType = "";

            if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
                userType = "seller";
                toOrg = this.props.user.organization;
                fromOrg = props.org.id;
                urigawa = true;
            }
            if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
                userType = "maker";
                toOrg = this.props.user.organization;
                fromOrg = props.org.id;
                urigawa = true;
            }
            if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
                userType = "sellerBuyer";
                toOrg = props.org.id;
                fromOrg = this.props.user.organization;
            }
            if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
                userType = "buyer";
                toOrg = props.org.id;
                fromOrg = this.props.user.organization;
            }

            let collection = 'address_book_buy'

            if (urigawa) {
                collection = 'address_book_sell';
            }

            this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book')
                .orderBy('dueDate','desc')
                .get()
                .then((snapshot) => {
                    // console.log(snapshot.docs.length)
                    let reachMax = true;
                    if (snapshot.docs.length < 50) {
                        reachMax = false;
                    }
                    this.setState({
                        reachMax: reachMax
                    })
                });

            let first;
            if(this.state.last){
                first = this.props.db.collection(collection).doc(this.props.user.id)
                    .collection('address_book').doc(this.props.org.id)
                    .collection('address_book')
                    .orderBy('dueDate','desc')
                    .startAfter(this.state.last)
                    .limit(this.state.dePerPage);
            } else {
                // console.log('last null')
                first = this.props.db.collection(collection).doc(this.props.user.id)
                    .collection('address_book').doc(this.props.org.id)
                    .collection('address_book')
                    .orderBy('dueDate','desc')
                    .limit(this.state.dePerPage);
            }

            first.get()
                .then((snapshot) => {
                    this.setState({loading: false})
                    if(snapshot.docs.length > 0) {
                        let last = snapshot.docs[snapshot.docs.length - 1];

                        this.setState({last: last})


                        let deliveryAddresses = this.state.dbDeliveryAddresses;

                        if (newOrg === true) {
                            deliveryAddresses = [];
                        }

                        snapshot.forEach((doc) => {
                            let data = doc.data();
                            data.id = doc.id;
                            // console.log(deliveryAddresses, data)
                            deliveryAddresses.push(data)
                        })

                        this.setState({dbDeliveryAddresses: deliveryAddresses, loading: false});
                    } else {
                        if (newOrg === true) {
                            this.setState({dbDeliveryAddresses: []})
                        }
                    }
                })
        }
    };

    delModalClose = (e) => {
        this.setState({
            delModal: false
        })
        let daId = e && e.currentTarget.dataset.id;

        let urigawa = false;

        if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
            urigawa = true;
        }
        if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
            urigawa = true;
        }

        let collection = 'address_book_buy'

        if (urigawa) {
            collection = 'address_book_sell';
        }

        if (daId) {
            let addressBookBuyRef = this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book').doc(daId);

            let self = this;
            addressBookBuyRef.delete().then(function() {
                self.setState({last: null}, () => {
                    self.getDeliveryAddressList(self.props, true)
                })

            });

        }
    }

    duplicateDeliveryAddress = (data) => {
        // console.log(data);

        if (this.state.reachMax) {
            return;
        }

        let urigawa = false;

        if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
            urigawa = true;
        }
        if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
            urigawa = true;
        }

        let collection = 'address_book_buy'

        if (urigawa) {
            collection = 'address_book_sell';
        }

        let addressBookBuyRef = this.props.db.collection(collection).doc(this.props.user.id)
            .collection('address_book').doc(this.props.org.id)
            .collection('address_book').doc();

        let dueDate = new Date();

        let setWithMerge = addressBookBuyRef.set({
            propertyName: data.propertyName,
            postCode: data.postCode,
            address1: data.address1,
            address2: data.address2,
            officeName: data.officeName,
            personInCharge: data.personInCharge,
            phoneNumber: data.phoneNumber,
            dueDate: dueDate.getTime(),
            created: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true })
            .then((res) => {
                this.setState({last: null}, () => {
                    this.getDeliveryAddressList(this.props,true);
                })
            });
    }

    delDeliveryAddress = (e) => {
        let daId = e && e.currentTarget.dataset.id;
        this.setState({
            delModal: true,
            delId: daId
        })
    }

    itemIsSelected = (doc) => {
        let filtered = [];
        if (doc.objectID) {
            filtered = this.state.selectedItemList.filter(item => item.objectID === doc.objectID);
        } else if (doc.id) {
            filtered = this.state.selectedItemList.filter(item => item.id === doc.id);
        }

        return filtered.length > 0
    };

    itemSelect = (selected, item) => {
        // console.log(selected, this.state.selectedItemList, item);
        let selectedItemList;
        if (selected) {
            selectedItemList = [item];
        } else {
            if (item.objectID) {
                selectedItemList = this.state.selectedItemList.filter( sItem => sItem.objectID !== item.objectID );
            } else if (item.id) {
                selectedItemList = this.state.selectedItemList.filter( sItem => sItem.id !== item.id );
            }

        }

        this.setState({
            selectedItemList: selectedItemList
        })
    }

    deliveryAddressAddToCart = () => {
        console.log(this.state.selectedItemList, this.props.user.id, this.props.org.id)

        if (this.state.selectedItemList.length > 0) {
            let cartId = this.state.cn;
            let collection = 'carts';

            if (this.state.ct === 'otasuke') {
                collection = 'otasuke_carts';
            }
            let cartRef = this.props.db.collection(collection).doc(this.props.user.id)
                .collection(collection).doc(this.props.org.id);

            cartRef.get()
                .then((doc) => {
                    if (doc.exists) {
                        let data = doc.data();
                        // console.log(data)
                        let carts = data;

                        let cart = carts[cartId]

                        // console.log(cart, this.state.selectedItemList[0])

                        cart.orderBasyo = 'アドレス帳から選択する';
                        cart.deliveryAddress = this.state.selectedItemList[0];

                        console.log(carts)

                        let setWithMerge = cartRef.set({
                            [cartId]: carts[cartId],
                            update: firebase.firestore.Timestamp.fromDate(new Date()),
                        }, { merge: true })
                            .then(() => {
                                if (this.state.ct === 'normal') {
                                    this.props.cProps.history.push('/cart/'+(cartId*1+1))
                                } else {
                                    this.props.cProps.history.push('/otasuke/cart/'+(cartId*1+1))
                                }

                            });
                    }
                });


        }
    }

    render() {
        if (!this.props.org) {
            return (
                <>
                    取引先を選択してください。
                </>
            )
        }

        let cancelLink = '';
        if (this.state.ct === 'normal') {
            cancelLink = "/cart/"+(this.state.cn*1+1)
        } else {
            cancelLink = "/otasuke/cart/"+(this.state.cn*1+1)
        }



        return (
            <>
                <h2>納品場所管理(アドレス帳)</h2>

                <div>
                    {this.state.dbDeliveryAddresses.map((data, index) => {
                        let checked = this.itemIsSelected(data);

                        return <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, border: '1px solid #0000001F', borderRadius: 4}}>
                            {this.state.cn && <div style={{width: 40}}>
                                <MDBInput label=" " type="radio" checked={checked} id={"checkbox"+index}
                                          onChange={(e) => this.itemSelect(e.target.checked, data)}
                                />
                            </div>}

                            <div style={{display: 'flex', flex: 1}}>
                                <div style={{flex: 1, padding: '15px 30px'}}>
                                    <div>物件名：{data.propertyName}</div>
                                    <div>{data.postCode} {data.address1} {data.address2} {data.officeName}</div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', margin: 10}}>
                                    <Link to={"/deliveryAddress/"+data.id + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : '')} style={{padding: 10}}>
                                        <MDBIcon icon="ellipsis-h" size="lg" style={{color: '#878787'}}/>
                                    </Link>
                                    <Link to={"/deliveryAddressEdit/"+data.id + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : '')} style={{padding: 10}}>
                                        <MDBIcon icon="pencil" size="lg" style={{color: '#878787'}}/>
                                    </Link>
                                    <div style={{padding: 10, cursor: 'pointer'}} data-id={data.id} onClick={(e) => this.duplicateDeliveryAddress(data)}>
                                        <MDBIcon icon="clone" size="lg" style={{color: this.state.reachMax ? '#85858578' : '#878787'}}/>
                                    </div>
                                    <div style={{padding: 10, cursor: 'pointer'}} data-id={data.id} onClick={this.delDeliveryAddress}>
                                        <MDBIcon icon="trash" size="lg" style={{color: '#878787'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

                <div style={{display: 'flex', columnGap: 20, justifyContent: 'center', padding: 50}}>
                    {this.state.cn && <Link to={cancelLink}>
                        <MDBBtn
                            className="btn-lg btn-block"
                            color=""
                            style={{width: 200, backgroundColor: '#707070', color: '#ffffff'}}
                        >キャンセル</MDBBtn>
                    </Link>}

                    {this.state.reachMax ?
                        <div style={{width: 260}}>
                            <MDBBtn
                                className="btn-lg btn-block"
                                color=""
                                style={{width: 260, backgroundColor: '#2D486A', color: '#ffffff'}}
                                disabled={true}
                            >新しい納品場所を登録する</MDBBtn>
                            <span style={{color: 'red', marginTop: 10, fontSize: 12}}>保存可能な件数は５０件です。 <br/>いずれかを削除するか編集してご利用下さい。</span>
                        </div>
                        :
                        <Link to={"/deliveryAddressAdd" + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : '')}>
                            <MDBBtn
                                className="btn-lg btn-block"
                                color=""
                                style={{width: 260, backgroundColor: '#2D486A', color: '#ffffff'}}
                                disabled={this.state.reachMax}
                            >新しい納品場所を登録する</MDBBtn>
                        </Link>
                    }

                    {this.state.cn && <MDBBtn
                        className="btn-lg btn-block"
                        color=""
                        style={{width: 260, height: 55, backgroundColor: '#2D486A', color: '#ffffff'}}
                        // disabled={!isEnable}
                        onClick={this.deliveryAddressAddToCart}
                    >納品場所に設定する</MDBBtn>}
                </div>

                <MDBModal isOpen={this.state.delModal} toggle={this.delModalClose}>
                    <MDBModalHeader toggle={this.delModalClose}>納品場所削除</MDBModalHeader>
                    <MDBModalBody>
                        この納品場所を削除します。よろしいですか？ <br/><br/>
                        ※この納品場所を削除しても、この納品場所への注文はキャンセルされません。
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.delModalClose} color="secondary">キャンセル</MDBBtn>
                        <MDBBtn onClick={this.delModalClose} color={''} style={{backgroundColor: '#FF3547', color: '#ffffff'}} data-id={this.state.delId}>削除</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}

export default DeliveryAddressList;