import React from "react";
import {Link} from 'react-router-dom'
import {
    MDBSpinner,
    MDBBtn,
    MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from "mdbreact";
import firebase from "../../firebase";

class DeliveryAddressAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dbDeliveryAddresses: [],
            last: null,
            dePerPage: 12,
            regModal: false,
            alertModal: false,
            data: {},
            fromOtasuke: false,
            otasukeHistoryId: null,

            propertyName: '',
            postCode: '',
            address1: '',
            address2: '',
            officeName: '',
            personInCharge: '',
            phoneNumber: '',

            cn: null,
            ct: null
        }
    }

    componentDidMount() {
        // console.log('componentDidMount')
        if (this.props.id) {
            this.getDeliveryAddress()
        }

        let cn = new URLSearchParams(this.props.search).get("cn");
        let ct = new URLSearchParams(this.props.search).get("ct");
        // console.log(cn, this.props.user)
        this.setState({
            cn: cn,
            ct: ct
        })

        let fromState = this.props.cProps.location.state;

        if (fromState) {
            this.setState({
                propertyName: fromState.propertyName || '',
                postCode: fromState.postCode || '',
                address1: fromState.address1 || '',
                address2: fromState.address2 || '',
                officeName: fromState.officeName || '',
                personInCharge: fromState.personInCharge || '',
                phoneNumber: fromState.phoneNumber || '',
                fromOtasuke: true,
                otasukeHistoryId: fromState.otasukeHistoryId
            })
        }

        console.log(this.props.cProps.location.state)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log('componentWillReceiveProps', nextProps.search)

        console.log(nextProps.cProps)

        let cn = new URLSearchParams(nextProps.search).get("cn");
        let ct = new URLSearchParams(nextProps.search).get("ct");
        this.setState({
            cn: cn,
            ct: ct
        })
    }

    onReachEnd = () => {
        console.log('onReachEnd')
    }

    getDeliveryAddress = () => {
        if(this.props.org){
            let urigawa = false;
            if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
                urigawa = true;
            }
            if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
                urigawa = true;
            }

            let collection = 'address_book_buy'
            if (urigawa) {
                collection = 'address_book_sell';
            }

            this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book').doc(this.props.id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data();
                        data.id = doc.id;
                        // console.log('data',data)

                        // this.setState({data: data})
                        this.setState({
                            propertyName: data.propertyName,
                            postCode: data.postCode,
                            address1: data.address1,
                            address2: data.address2,
                            officeName: data.officeName,
                            personInCharge: data.personInCharge,
                            phoneNumber: data.phoneNumber
                        })
                    }
                })
        }
    }

    handlePropertyNameChange = (e) => {
        this.setState({
            propertyName: e.currentTarget.value
        });
    }

    handlePostCodeChange = (e) => {
        this.setState({
            postCode: e.currentTarget.value
        });
    }

    handleAddress1Change = (e) => {
        this.setState({
            address1: e.currentTarget.value
        });
    }

    handleAddress2Change = (e) => {
        this.setState({
            address2: e.currentTarget.value
        });
    }

    handleOfficeNameChange = (e) => {
        this.setState({
            officeName: e.currentTarget.value
        });
    }

    handlePersonInChargeChange = (e) => {
        this.setState({
            personInCharge: e.currentTarget.value
        });
    }

    handlePhoneNumberChange = (e) => {
        this.setState({
            phoneNumber: e.currentTarget.value
        });
    }

    cancel = (e) => {
        e.preventDefault();

        if (this.state.fromOtasuke) {
            this.props.cProps.history.push("/otasuke/cart/detail/" + this.state.otasukeHistoryId)
        } else {
            this.props.cProps.history.push("/deliveryAddress" + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : ''))
        }

    }

    deliveryAddressRegister = (e) => {
        e.preventDefault();

        if (!(
            this.state.propertyName !== '' &&
            this.state.postCode !== '' &&
            this.state.address1 !== '' &&
            this.state.address2 !== '' &&
            this.state.officeName !== '' &&
            this.state.personInCharge !== '' &&
            this.state.phoneNumber !== '')) {
            this.setState({
                alertModal: true
            })
        } else {
            this.setState({
                regModal: true
            })
        }

    }

    alertModalClose = (e) => {
        this.setState({
            alertModal: false
        })
    }

    regModalClose = (e) => {
        this.setState({
            regModal: false
        })

        // let dueDate = new Date(this.state.startDate);

        let urigawa = false;
        if(this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) {
            urigawa = true;
        }
        if(this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller) {
            urigawa = true;
        }

        let collection = 'address_book_buy'
        if (urigawa) {
            collection = 'address_book_sell';
        }

        if (this.props.id) {
            let addressBookBuyRef = this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book').doc(this.props.id);

            let dueDate = new Date();

            let setWithMerge = addressBookBuyRef.set({
                propertyName: this.state.propertyName,
                postCode: this.state.postCode,
                address1: this.state.address1,
                address2: this.state.address2,
                officeName: this.state.officeName,
                personInCharge: this.state.personInCharge,
                phoneNumber: this.state.phoneNumber
            }, { merge: true })
                .then(() => {
                    this.redirectLink()
                });
        } else {
            let addressBookBuyRef = this.props.db.collection(collection).doc(this.props.user.id)
                .collection('address_book').doc(this.props.org.id)
                .collection('address_book').doc();

            let dueDate = new Date();

            let setWithMerge = addressBookBuyRef.set({
                propertyName: this.state.propertyName,
                postCode: this.state.postCode,
                address1: this.state.address1,
                address2: this.state.address2,
                officeName: this.state.officeName,
                personInCharge: this.state.personInCharge,
                phoneNumber: this.state.phoneNumber,
                dueDate: dueDate.getTime(),
                created: firebase.firestore.Timestamp.fromDate(new Date()),
            }, { merge: true })
                .then(() => {
                    this.redirectLink()
                });
        }
    }

    redirectLink = () => {
        if (this.state.fromOtasuke) {
            this.props.cProps.history.push("/otasuke/cart/detail/" + this.state.otasukeHistoryId)
        } else {
            this.props.cProps.history.push("/deliveryAddress" + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : ''))
        }
        // this.props.cProps.history.push('/deliveryAddress' + (this.state.cn ? '?cn='+this.state.cn : '') + (this.state.ct ? '&ct='+this.state.ct : ''))
    }

    render() {
        if (!this.props.org) {
            return (
                <>
                    取引先を選択してください。
                </>
            )
        }

        let deliveryAddresses = [];

        this.state.dbDeliveryAddresses.forEach((deliveryAddress,index) => {});

        const data = {
            columns: [
                {
                    label: '発行日',
                    field: '発行日',
                    sort: 'asc',
                    width: 80,
                    minimal: 'sm'
                },
                {
                    label: 'タイトル',
                    field: 'タイトル',
                    sort: 'disabled',
                    width: 370,
                    minimal: 'sm'
                },
                {
                    label: '内容表示',
                    field: '内容表示',
                    sort: 'disabled',
                    width: 50,
                    minimal: 'sm'
                },
            ],
            rows: deliveryAddresses
        }

        let title = "納品場所追加(アドレス帳)";
        if (this.props.id) {
            title = "納品場所編集(アドレス帳)"
        }

        return (
            <>
                <h2>{title}</h2>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>物件名</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handlePropertyNameChange}
                                value={this.state.propertyName}
                            />
                        </div>
                    </div>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>郵便番号</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handlePostCodeChange}
                                value={this.state.postCode}
                            />
                        </div>
                    </div>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>住所1（都道府県・市区町村)</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handleAddress1Change}
                                value={this.state.address1}
                            />
                        </div>
                    </div>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>住所2（番地・建物名)</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handleAddress2Change}
                                value={this.state.address2}
                            />
                        </div>
                    </div>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>事業所名</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handleOfficeNameChange}
                                value={this.state.officeName}
                            />
                        </div>
                    </div>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>担当者名</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handlePersonInChargeChange}
                                value={this.state.personInCharge}
                            />
                        </div>
                    </div>
                    <div style={{margin: '10px 0', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: 250}}>電話番号</div>
                        <div style={{flex: 1}}>
                            <input
                                type="text"
                                className="form-control"
                                id="orderTanto"
                                onChange={this.handlePhoneNumberChange}
                                value={this.state.phoneNumber}
                            />
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', columnGap: 20, justifyContent: 'center', padding: 50}}>
                    <MDBBtn
                        className="btn-lg btn-block"
                        color=""
                        style={{width: 200, backgroundColor: '#707070', color: '#ffffff'}}
                        // disabled={!isEnable}
                        onClick={this.cancel}
                    >キャンセル</MDBBtn>
                    <MDBBtn
                        className="btn-lg btn-block"
                        color=""
                        style={{width: 200, backgroundColor: '#2D486A', color: '#ffffff'}}
                        // disabled={!isEnable}
                        onClick={this.deliveryAddressRegister}
                    >登録</MDBBtn>
                </div>

                <MDBModal isOpen={this.state.regModal} toggle={this.regModalClose}>
                    <MDBModalHeader toggle={this.regModalClose}>納品場所保存</MDBModalHeader>
                    <MDBModalBody>
                        この納品場所をアドレス帳に保存しました。
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/*<MDBBtn onClick={this.regModalClose} color="secondary">キャンセル</MDBBtn>*/}
                        <MDBBtn onClick={this.regModalClose} data-confirm={true}>確　認</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.alertModal} toggle={this.alertModalClose}>
                    <MDBModalHeader toggle={this.alertModalClose}>未入力項目があります。</MDBModalHeader>
                    <MDBModalBody>
                        全項目を入力してから「登録」をクリックしてください。
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.alertModalClose} color="" style={{backgroundColor: '#2D486A', color: '#ffffff'}}>確認</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

            </>
        )
    }
}

export default DeliveryAddressAdd;